import { Spin } from 'antd';
import { number } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Chart } from 'react-google-charts';
import colors from '../../../styles/colors';
import { formatar, telaGrande } from '../../../utils';
import { GraficoHeader } from '../../layout';
import { renderItemLegenda } from '../functions';
import { ChartContainer, LoadingContainer } from '../styles';

const tooltipConfig = { role: 'tooltip', type: 'string', p: { html: true } };
const styleConfig = { role: 'style' };
const corProducao = colors.primaria;
const options = {
  titleTextStyle: {
    color: '#607d8b',
    fontName: 'Fira Sans',
    fontSize: 14,
    bold: false,
    italic: false,
  },
  hAxis: {
    title: 'Data',
    titleTextStyle: { color: '#607d8b' },
    gridlines: { count: 0 },
    textStyle: {
      color: '#000000D9',
      fontName: 'Fira Sans',
      fontSize: '13',
    },
  },
  vAxis: {
    minValue: 0,
    title: 'Minutos',
    gridlineColor: '#fff',
    titleTextStyle: { color: '#607d8b' },
  },
  legend: {
    position: 'top',
    alignment: 'center',
    textStyle: { color: '#607d8b', fontName: 'Fira Sans', fontSize: '12' },
  },
  areaOpacity: 0.24,
  lineWidth: 1,
  backgroundColor: 'transparent',
  chartArea: {
    backgroundColor: 'transparent',
    height: '80%',
  },
  bar: { groupWidth: telaGrande() ? '40' : null },
  displayMode: 'regions',
  colors: [corProducao],
  title: 'Gráfico de Produção',
  tooltip: { isHtml: true, trigger: 'visible' },
  seriesType: 'bars',
  isStacked: true,
  vAxes: {
    0: { title: 'Tempo Apontado' },
  },
};

const GraficoProducao = ({ celulaUid, funcionarioUid }) => {
  const [dadosGrafico, setDadosGrafico] = useState(['']);
  const data = [
    ['Data', 'Tempo Apontado', tooltipConfig, styleConfig],
    ...dadosGrafico,
  ];

  const renderTooltip = ({
    tempoApontado,
    trabalhado,
    quantidadeApontada,
    eficiencia,
  }) => {
    return `
      <div>
        ${renderItemLegenda(
          `Produção: ${formatar('n-decimal', tempoApontado ?? 0, 2)} min`,
        )}
        ${renderItemLegenda(
          `Trabalhado: ${formatar('n-decimal', trabalhado ?? 0, 2)} min`,
        )}
        ${renderItemLegenda(
          `Execuções: ${formatar('n-decimal', quantidadeApontada ?? 0, 2)}`,
        )}
        ${renderItemLegenda(
          `Eficiência: ${formatar('n-decimal', eficiencia ?? 0, 2)}%`,
        )}
      </div>
    `;
  };

  const carregarProps = useCallback((propriedades, formato) => {
    const { tempoApontado, data } = propriedades;
    const tooltip = renderTooltip(propriedades);
    return [
      formatar(formato, data),
      tempoApontado ?? 0,
      tooltip,
      `color: ${corProducao}; opacity: 0.4;`,
    ];
  }, []);

  return (
    <>
      <GraficoHeader
        celulaUid={celulaUid}
        funcionarioUid={funcionarioUid}
        carregarProps={carregarProps}
        setDadosGrafico={setDadosGrafico}
      />
      <ChartContainer>
        <Chart
          width="100%"
          height={telaGrande() ? '500px' : '400px'}
          chartType="ComboChart"
          rootProps={{ 'data-testid': '1' }}
          options={options}
          data={data}
          loader={
            <LoadingContainer>
              <Spin spinning />
            </LoadingContainer>
          }
        />
      </ChartContainer>
    </>
  );
};

GraficoProducao.propTypes = {
  celulaUid: number,
  funcionarioUid: number,
};

GraficoProducao.defaultProps = {
  celulaUid: null,
  funcionarioUid: null,
};

export default GraficoProducao;

export const CADASTRAR = 'cadastrar';
export const EDITAR = 'editar';
export const FILTRAR = 'filtrar';
export const ATIVAR_FILTRO = 'ativo';
export const BUSCAR = 'buscar';
export const CLONAR = 'clonar';

export const ORDENACAO = 'ordenacao';
export const EFICIENCIA = 'eficiencia';
export const ALFABETICA = 'alfabetica';

export const SEM_TAB = 'sem-tab';
export const APURACAO = 'apuracao';
export const OBSERVACAO = 'observacao';
export const PREMIO = 'premio';
export const PREMIO_CELULA = 'premio-celula';
export const PREMIACAO = 'premiacao';
export const PERIODO = 'periodo';
export const BALANCEAMENTO = 'balanceamento';
export const PONTO = 'ponto';
export const PARADA = 'parada';
export const GERAR = 'gerar-pontos';
export const TURNO = 'turno';
export const AJUSTE = 'ajuste';
export const OPERACAO = 'operacao';
export const ORDEM = 'ordem';
export const ORDEM_RECEBER = 'ordem-receber';
export const ORDEM_CONCLUIDA = 'ordem-concluida';
export const ORDEM_PRECO = 'ordem-preco';
export const RECEBIMENTO = 'recebimento';
export const MOTIVO = 'motivo';
export const MAQUINA = 'maquina';
export const DIA_NAO_TRABALHADO = 'dia-nao-trabalhado';
export const GERAR_LANCAMENTO = 'gerar-lancamentos';
export const LANCAMENTO_SAIDA = 'lancamento-saida';
export const LANCAMENTO_ENTRADA = 'lancamento-entrada';
export const LANCAMENTO = 'lancamento';
export const APONTAMENTO = 'apontamento';
export const FUNCIONARIO = 'funcionario';
export const CONTA = 'conta';
export const CLIENTE = 'cliente';
export const CELULA = 'celula';
export const DEMISSAO = 'demissao';
export const SITUACAO = 'situacao';
export const PACOTE = 'pacote';
export const TAB = 'tab';

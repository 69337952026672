import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import {
  Tabela,
  Filtros,
  Header,
  BotaoPrimario,
  BotaoDefault,
} from '../../components/layout';
import { ContaForm } from '../../components/forms';
import { useApi, useUrl } from '../../hooks';
import { ModalBusca } from '../../components/modais';
import { NaturezaConta } from '../../utils/enum';
import { Container } from '../styles';
import { CONTAS } from '../../components/layout/Tabela/nomesCabecalhos';
import { CONTA_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  FILTRAR,
  ATIVAR_FILTRO,
  CADASTRAR,
  BUSCAR,
  CONTA,
} from '../../hooks/useUrl/acoes';

const { Tags, TituloComFiltro } = Filtros;

const Contas = ({ history }) => {
  const { buscar, deletar } = useApi();
  const [contas, setContas] = useState([]);
  const [filtro, setFiltro] = useState('');
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const busca = pegarValorQuery(BUSCAR);
  const mostrarCadastro = existeQuery(CADASTRAR, CONTA);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    const carregarContas = async () => {
      const { data } = await buscar(
        `/contas?${filtro}${
          busca ? `&$buscar=${busca}` : ''
        }&$limite=10&$pagina=${pagina}`,
      );

      const processado = data?.map(conta => ({
        ...conta,
        natureza: NaturezaConta[conta.natureza],
      }));

      setContas(cont => [...cont, ...processado]);
    };
    carregarContas();
  }, [buscar, filtro, busca, pagina]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPagina(page => page + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#final'));
    return () => intersectionObserver.disconnect();
  }, []);

  const deletarConta = async ({ uid, codigo, nome }) => {
    await deletar(
      `/contas/${uid}`,
      `A conta ${codigo} - ${nome} foi excluida`,
      () => setContas(contas.filter(({ uid: contaUid }) => contaUid !== uid)),
    );
  };

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro titulo="Contas" history={history} busca={busca} />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, CONTA)}
          >
            Nova conta
          </BotaoPrimario>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabela
        cabecalho={CONTAS}
        dados={contas}
        loading={loading}
        celulaSelecionada={({ uid }) => history.push(`/contas/${uid}`)}
        acoes={
          telaGrande()
            ? [{ titulo: EXCLUIR, onClick: deletarConta, confirmacao: true }]
            : null
        }
      />

      <ContaForm
        aoFechar={() => removerQuery(CADASTRAR, CONTA)}
        mostrar={mostrarCadastro}
        afterSubmit={conta => {
          if (conta) {
            setContas([
              ...contas,
              { ...conta, natureza: NaturezaConta[conta.natureza] },
            ]);
            removerQuery(CADASTRAR, CONTA);
          }
        }}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={CONTA_LAYOUT}
      />
      <div id="final" />
    </Container>
  );
};

Contas.propTypes = {
  history: objectOf(any).isRequired,
};

export default Contas;

export const CLIENTE_LAYOUT = 'CLIENTE';
export const CELULA_LAYOUT = 'CELULA';
export const TURNO_LAYOUT = 'TURNO';
export const MOTIVO_LAYOUT = 'MOTIVO';
export const PONTO_FUNCIONARIO_LAYOUT = 'PONTO_FUNCIONARIO';
export const CONTA_LAYOUT = 'CONTA';
export const FUNCIONARIO_LAYOUT = 'FUNCIONARIO';
export const MAQUINA_LAYOUT = 'MAQUINA';
export const ORDEM_PRODUCAO_LAYOUT = 'ORDEM_PRODUCAO';
export const OPERACAO_LAYOUT = 'OPERACAO';
export const LANCAMENTO_LAYOUT = 'LANCAMENTO';
export const DIA_NAO_TRABALHADO_LAYOUT = 'DIA_NAO_TRABALHADO';

import React from 'react';
import { Empty } from 'antd';
import { any, arrayOf, func, objectOf, string } from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import { OptionButton, Select } from './styles';

const { Option } = Select;

const SelectDinamico = React.forwardRef(
  (
    { children, form, nomeCampo, aoAdicionar, onSelect, aoFiltrar, ...props },
    ref,
  ) => {
    return (
      <Select
        ref={ref}
        showSearch
        bordered
        allowClear
        onSearch={str => {
          if (aoFiltrar && str.length >= 2) {
            aoFiltrar(str);
          }
        }}
        notFoundContent={
          <Empty
            description="Nada Encontrado"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }
        onChange={() => {}}
        optionFilterProp="children"
        filterOption={(input, { children }) =>
          aoFiltrar
            ? true
            : children?.toLowerCase?.().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(value, option) => {
          const { tipocadastro } = option;
          if (onSelect) onSelect(value, option);
          if (tipocadastro) form.setFieldsValue({ [nomeCampo]: null });
        }}
        {...props}
      >
        {children}
        <Option value="Criar Novo" tipocadastro="true">
          <OptionButton type="button" onClick={aoAdicionar}>
            <PlusCircleOutlined /> Novo
          </OptionButton>
        </Option>
      </Select>
    );
  },
);

SelectDinamico.propTypes = {
  form: objectOf(any).isRequired,
  nomeCampo: string.isRequired,
  aoAdicionar: func,
  aoFiltrar: func,
  onSelect: func,
  children: arrayOf(any),
};

SelectDinamico.defaultProps = {
  children: [],
  onSelect: null,
  aoAdicionar: null,
  aoFiltrar: null,
};

export default SelectDinamico;

import { any, number, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi, useUrl } from '../../../hooks';
import { CELULA, FUNCIONARIO, TAB } from '../../../hooks/useUrl/acoes';
import { Tabs } from '../../../styles/global';
import { Tabela } from '../../layout';
import {
  CELULA_TURNO,
  FUNCIONARIOS,
} from '../../layout/Tabela/nomesCabecalhos';

const { TabPane } = Tabs;

const DetalhesTurnoTab = ({ history, turnoUid }) => {
  const { buscar } = useApi();
  const [celulas, setCelulas] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const { loading } = useSelector(reducer => reducer, shallowEqual);
  const { pegarValorQuery, adicionarQuery } = useUrl(history);
  const tabAtiva = pegarValorQuery(TAB) ?? CELULA;

  useEffect(() => {
    const buscarFuncionarios = async () => {
      const { data } = await buscar(
        `/funcionarios?$rel[celula]=nome&turnoUid[eq]=${turnoUid}&$ordem=nomeCompleto,uid`,
      );
      setFuncionarios(data);
    };

    const buscarCelulas = async () => {
      const { data } = await buscar(
        `/celulas?turnoUidPadrao[eq]=${turnoUid}&$ordem=nome,uid`,
      );
      setCelulas(data);
    };

    if (turnoUid) {
      buscarCelulas();
      buscarFuncionarios();
    }
  }, [turnoUid, buscar]);

  return (
    <Tabs
      defaultActiveKey={tabAtiva}
      activeKey={tabAtiva}
      onChange={key => adicionarQuery(TAB, key)}
    >
      <TabPane tab="Células" key={CELULA}>
        <Tabela
          loading={loading}
          cabecalho={CELULA_TURNO}
          dados={celulas}
          celulaSelecionada={({ uid }) => history.push(`/celulas/${uid}`)}
        />
      </TabPane>
      <TabPane tab="Funcionários" key={FUNCIONARIO}>
        <Tabela
          loading={loading}
          cabecalho={FUNCIONARIOS}
          dados={funcionarios}
          celulaSelecionada={({ uid }) => history.push(`/funcionarios/${uid}`)}
        />
      </TabPane>
    </Tabs>
  );
};

DetalhesTurnoTab.propTypes = {
  history: objectOf(any).isRequired,
  turnoUid: number,
};

DetalhesTurnoTab.defaultProps = {
  turnoUid: null,
};

export default DetalhesTurnoTab;

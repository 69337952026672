export default {
  titulo: 'Tabela de Contas',
  cabecalho: {
    desktop: [
      {
        titulo: 'Código',
        atributo: 'codigo',
      },
      {
        titulo: 'Nome',
        atributo: 'nome',
        variavel: true,
      },
      {
        titulo: 'Natureza',
        atributo: 'natureza',
      },
    ],
    mobile: [
      {
        titulo: 'Contas',
        variavel: true,
        agrupar: [
          {
            custom: ({ codigo, nome }) => `${codigo} - ${nome}`,
          },
          {
            titulo: 'Natureza',
            atributo: 'natureza',
          },
        ],
      },
    ],
  },
};

import React, { useState, useEffect } from 'react';
import { DatePicker, message, Col } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import {
  objectOf,
  any,
  bool,
  string,
  func,
  oneOfType,
  number,
} from 'prop-types';
import moment from 'moment';
import { Tabela, Filtros } from '../../layout';
import { TabContainer, Statistic, Button, Card, Row } from './styles';
import { useApi, useUrl } from '../../../hooks';
import { ModalBusca } from '../../modais';
import { LancamentoForm } from '../../forms';
import { formatar } from '../../../utils';
import { ESTORNAR, EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import { LANCAMENTOS } from '../../layout/Tabela/nomesCabecalhos';
import { MesesAno } from '../../../utils/enum';
import { Tabs } from '../../../styles/global';
import {
  CADASTRAR,
  GERAR_LANCAMENTO,
  LANCAMENTO_ENTRADA,
  LANCAMENTO_SAIDA,
} from '../../../hooks/useUrl/acoes';

const { CriarLancamentoForm, GerarLancamentoForm } = LancamentoForm;
const ANO = 'ano';
const MES = 'mes';
const { Tags } = Filtros;
const { TabPane } = Tabs;
const tabConfig = [
  { tab: 'Janeiro', idMes: '01' },
  { tab: 'Fevereiro', idMes: '02' },
  { tab: 'Março', idMes: '03' },
  { tab: 'Abril', idMes: '04' },
  { tab: 'Maio', idMes: '05' },
  { tab: 'Junho', idMes: '06' },
  { tab: 'Julho', idMes: '07' },
  { tab: 'Agosto', idMes: '08' },
  { tab: 'Setembro', idMes: '09' },
  { tab: 'Outubro', idMes: '10' },
  { tab: 'Novembro', idMes: '11' },
  { tab: 'Dezembro', idMes: '12' },
];

const ContasTab = ({
  history,
  apresentarBalanco,
  contaUid,
  busca,
  callback,
  transparente,
}) => {
  const { buscar, criar, deletar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const [filtro, setFiltro] = useState('');
  const [lancamentoConta, setLancamentoConta] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const ano = pegarValorQuery(ANO) ?? moment().format('YYYY');
  const mes = pegarValorQuery(MES) ?? moment().format('MM');
  const [lancamentosContas, setLancamentoContas] = useState([]);
  const [saldo, setSaldo] = useState(0);
  const [entrada, setEntrada] = useState(0);
  const [saida, setSaida] = useState(0);
  const mostrarGerador = existeQuery(CADASTRAR, GERAR_LANCAMENTO);
  const tipoLancamento = existeQuery(CADASTRAR, LANCAMENTO_ENTRADA)
    ? 'entrada'
    : 'saida';
  const mostrarLancamento =
    existeQuery(CADASTRAR, LANCAMENTO_ENTRADA) ||
    existeQuery(CADASTRAR, LANCAMENTO_SAIDA);

  useEffect(() => {
    const calcularBalanco = () => {
      let calcEntrada = 0;
      let calcSaida = 0;
      let calcSaldo = 0;
      if (lancamentosContas) {
        lancamentosContas.forEach(lancamento => {
          const { valor } = lancamento ?? {};
          if (valor) {
            if (Number(valor) > 0) {
              calcEntrada += Number(valor);
            } else {
              calcSaida += Number(valor);
            }
          }
        });
        calcSaldo = lancamentosContas?.reduce((total, lancamento) => {
          const { valor } = lancamento ?? {};
          return total + Number(valor ?? 0);
        }, 0);
      }
      setEntrada(calcEntrada);
      setSaida(calcSaida);
      setSaldo(calcSaldo);
    };
    calcularBalanco();
  }, [lancamentosContas]);

  useEffect(() => {
    const carregarLancamentosContas = async () => {
      const dataFinal = moment(`${ano}-${mes}`, 'YYYY-MM')
        .endOf('month')
        .format('DD');
      const { data } = await buscar(
        `/lancamentos/?${filtro}&$rel[conta]=codigo,nome,natureza&data[gte]=${ano}-${
          mes || '01'
        }-01&data[lte]=${ano}-${mes || '12'}-${dataFinal}&$ordem=data,uid${
          busca ? `&$buscar=${busca}` : ''
        }`,
      );
      if (contaUid) {
        setLancamentoContas(
          data?.filter(({ contaUid: uid }) => uid === Number(contaUid)),
        );
      } else {
        setLancamentoContas(data ?? []);
      }
    };

    carregarLancamentosContas();
  }, [ano, mes, contaUid, buscar, filtro, busca]);

  const estornarLancamentoConta = async uid => {
    const { lancamentoUidEstornado, data, historico } = lancamentosContas?.find(
      lancamento => lancamento.uid === uid,
    );
    if (lancamentoUidEstornado) {
      return message.error('Você não pode estornar um estorno');
    }
    if (
      lancamentosContas?.find(
        ({ lancamentoUidEstornado }) => lancamentoUidEstornado === uid,
      )
    ) {
      return message.error('Você já efetuou um estorno para esse lançamento');
    }

    const dados = {
      data,
      historico: historico ? `Estorno de ${historico}` : 'Estorno',
      lancamentoUidEstornado: uid,
    };

    const { data: lancamento } = await criar(
      '/lancamentos/',
      dados,
      'Valor estornado com sucesso!',
    );

    return lancamento;
  };

  const deletarLancamento = async ({ uid, valor, data, conta }) => {
    await deletar(
      `/lancamentos/${uid}`,
      `O lançamento de ${valor} em ${formatar('data', data)} na conta
      ${conta.nome} foi excluído`,
      () => {
        setLancamentoContas(
          lancamentosContas?.filter(({ uid: lancUid }) => lancUid !== uid),
        );
        if (callback) callback();
      },
    );
  };

  const retornarQuery = query => filtro !== query && setFiltro(query);
  return (
    <>
      <TabContainer transparente={`${transparente}`}>
        {apresentarBalanco && (
          <Row gutter={16}>
            <Col span={24}>
              <Card transparente={`${transparente}`} bordered={false}>
                <Row align="middle" gutter={20}>
                  <Col>
                    <Statistic
                      decimalSeparator=","
                      groupSeparator="."
                      valor={saldo}
                      title={MesesAno[Number(mes)]}
                      value={formatar('decimal', saldo)}
                      precision={2}
                      prefix="R$"
                    />
                  </Col>
                  <Col>
                    <Statistic
                      decimalSeparator=","
                      groupSeparator="."
                      valor={entrada}
                      title="Entradas"
                      value={formatar('decimal', entrada)}
                      precision={2}
                      prefix="R$"
                    />
                  </Col>
                  <Col>
                    <Statistic
                      decimalSeparator=","
                      groupSeparator="."
                      valor={saida}
                      title="Saídas"
                      value={formatar('decimal', saida)}
                      precision={2}
                      prefix="R$"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <Button
          disabled={loading}
          htmlType="button"
          onClick={() => adicionarQuery('filtrar', 'ativo')}
        >
          Filtrar
        </Button>
        <DatePicker
          disabled={loading}
          onChange={(date, dateString) => {
            adicionarQuery(ANO, dateString);
          }}
          picker="year"
          placeholder="Selecione o ano"
          defaultValue={moment()}
          value={moment(ano, 'YYYY')}
          allowClear={false}
        />
      </TabContainer>
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabs
        transparente={`${transparente}`}
        defaultActiveKey={mes}
        activeKey={mes}
        onChange={key => {
          adicionarQuery(MES, key);
        }}
      >
        {tabConfig.map(({ tab, idMes }) => (
          <TabPane tab={tab} key={idMes}>
            <Tabela
              loading={loading}
              cabecalho={LANCAMENTOS}
              dados={lancamentosContas}
              celulaSelecionada={lancamento => {
                setLancamentoConta(lancamento);
                if (lancamento.valor < 0) {
                  return adicionarQuery(CADASTRAR, LANCAMENTO_SAIDA);
                }
                return adicionarQuery(CADASTRAR, LANCAMENTO_ENTRADA);
              }}
              acoes={[
                {
                  titulo: ESTORNAR,
                  confirmacao: true,
                  onClick: async ({ uid }) => {
                    const novoLancamento = await estornarLancamentoConta(uid);
                    if (novoLancamento?.uid) {
                      setLancamentoContas([
                        novoLancamento,
                        ...lancamentosContas,
                      ]);
                    }
                  },
                },
                {
                  titulo: EXCLUIR,
                  onClick: deletarLancamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPane>
        ))}
      </Tabs>

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro="LANCAMENTO"
      />

      <GerarLancamentoForm
        aoFechar={() => removerQuery(CADASTRAR, GERAR_LANCAMENTO)}
        mostrar={mostrarGerador}
        mes={mes}
        ano={ano}
        afterSubmit={lancamentos => {
          if (lancamentos) {
            setLancamentoContas(lancamentosContas.concat(lancamentos));
            removerQuery(CADASTRAR, GERAR_LANCAMENTO);
          }
        }}
      />

      <CriarLancamentoForm
        ano={ano}
        mes={mes}
        aoFechar={() => {
          setLancamentoConta(null);
          removerQuery(
            CADASTRAR,
            tipoLancamento === 'entrada'
              ? LANCAMENTO_ENTRADA
              : LANCAMENTO_SAIDA,
          );
        }}
        mostrar={mostrarLancamento}
        tipoLancamento={tipoLancamento}
        lancamento={lancamentoConta}
        afterSubmit={novoLancamento => {
          if (novoLancamento) {
            const data = moment(novoLancamento.data);
            adicionarQuery(ANO, data.format('YYYY'));
            adicionarQuery(MES, data.format('MM'));
            const index = lancamentosContas.findIndex(
              ({ uid }) => uid === novoLancamento.uid,
            );
            if (index < 0) {
              setLancamentoContas([novoLancamento, ...lancamentosContas]);
            } else {
              lancamentosContas[index] = novoLancamento;
              setLancamentoContas([...lancamentosContas]);
            }
            removerQuery(
              CADASTRAR,
              tipoLancamento === 'entrada'
                ? LANCAMENTO_ENTRADA
                : LANCAMENTO_SAIDA,
            );
          }
          if (callback) callback();
        }}
      />
    </>
  );
};

ContasTab.propTypes = {
  history: objectOf(any).isRequired,
  callback: func,
  apresentarBalanco: bool,
  transparente: bool,
  contaUid: oneOfType([string, number]),
  busca: string,
};

ContasTab.defaultProps = {
  apresentarBalanco: false,
  transparente: false,
  callback: null,
  contaUid: null,
  busca: '',
};

export default ContasTab;

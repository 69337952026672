import React from 'react';
import { objectOf, any } from 'prop-types';
import { Button } from 'antd';
import { Filtros, Header } from '../../components/layout';
import { ContasTab } from '../../components/tabs';
import { useUrl } from '../../hooks';
import { Container } from '../styles';
import {
  CADASTRAR,
  BUSCAR,
  GERAR_LANCAMENTO,
  LANCAMENTO_SAIDA,
  LANCAMENTO_ENTRADA,
} from '../../hooks/useUrl/acoes';
import BotaoDefault from '../../components/layout/BotaoDefault';

const { TituloComFiltro } = Filtros;

const LancamentosContas = ({ history }) => {
  const { adicionarQuery, pegarValorQuery } = useUrl(history);
  const busca = pegarValorQuery(BUSCAR);

  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          title={
            <TituloComFiltro
              titulo="Lançamentos de contas"
              history={history}
              busca={busca}
            />
          }
          extra={[
            <BotaoDefault
              key="1"
              type="default"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, GERAR_LANCAMENTO)}
            >
              Gerar lançamentos
            </BotaoDefault>,
            <Button
              key="2"
              type="primary"
              htmlType="button"
              danger
              onClick={() => adicionarQuery(CADASTRAR, LANCAMENTO_SAIDA)}
            >
              Nova saída
            </Button>,
            <Button
              key="3"
              type="primary"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, LANCAMENTO_ENTRADA)}
            >
              Nova entrada
            </Button>,
          ]}
        />
      </Container>

      <ContasTab
        apresentarBalanco
        history={history}
        busca={busca}
        transparente
      />
    </>
  );
};

LancamentosContas.propTypes = {
  history: objectOf(any).isRequired,
};

export default LancamentosContas;

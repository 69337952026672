import moment from 'moment';

const operacoesNumeros = [
  { nome: 'Entre', operador: '[between]' },
  { nome: 'Maior que', operador: '[gt]' },
  { nome: 'Maior ou igual a', operador: '[gte]' },
  { nome: 'Menor que', operador: '[lt]' },
  { nome: 'Menor ou igual a', operador: '[lte]' },
  { nome: 'Igual a', operador: '[eq]' },
];

const operacoesLetras = [{ nome: 'Contenha', operador: '[like]' }];

const operacoesComuns = [
  { nome: 'Em Branco', operador: '[is]' },
  { nome: 'Preenchido', operador: '[not]' },
];

export const getOperacoes = tipoCampo => {
  switch (tipoCampo) {
    case 'string':
      return [...operacoesLetras, ...operacoesComuns];
    case 'numero':
      return [...operacoesComuns, ...operacoesNumeros];
    case 'data':
      return [...operacoesComuns, ...operacoesNumeros];
    case 'hora':
      return [...operacoesComuns, ...operacoesNumeros];
    case 'ativo':
      return null;
    default:
      return [...operacoesComuns, ...operacoesLetras, ...operacoesNumeros];
  }
};

export const montarNome = (valor1, valor2, chave) => {
  const index = chave.indexOf('[');
  const nomeChave = chave.substr(0, index).split('.')[0];
  const operador = chave.substr(index, chave.indexOf(']'));
  let valor1Formatado = valor1;
  let valor2Formatado = valor2;
  if (nomeChave === 'data') {
    valor1Formatado = moment(valor1).format('DD-MM-YYYY');
    valor2Formatado = valor2 && moment(valor2).format('DD-MM-YYYY');
  }
  if (valor2Formatado && operador === '[between]') {
    return `${nomeChave}: Entre ${valor1Formatado} e ${valor2Formatado}`;
  }
  if (
    operador === '[eq]' &&
    (valor1Formatado === 'true' || valor1Formatado === 'false')
  ) {
    return `${nomeChave}: ${
      valor1Formatado === 'true' ? 'Verdadeiro' : 'Falso'
    }`;
  }
  const { nome } = getOperacoes().find(op => op.operador === operador);
  if (nome === 'Preenchido' || nome === 'Em Branco') {
    valor1Formatado = '';
  }
  return `${nomeChave}: ${nome} ${valor1Formatado}`;
};

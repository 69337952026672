import React from 'react';
import { Form, Spin, Drawer } from 'antd';
import { any, bool, func, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../../hooks';
import { BotaoPrimario, Campo } from '../../../layout';
import { onFinish } from '../../commonFunctions';
import { telaGrande } from '../../../../utils';
import { Input } from '../../commonStyles';

const ClonarOrdemForm = ({ afterSubmit, ordem, mostrar, aoFechar }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const clonarOrdem = async ({ numero }) => {
    const { uid, numero: numeroOrdem, nome } = ordem;
    const { data } = await criar(
      `/ordens-producao/clonar/${uid}`,
      { numero },
      ({ numero: numeroNovaOrdem }) =>
        `A ordem de produção ${numeroOrdem} - ${nome} foi clonada com o novo número ${numeroNovaOrdem}`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 220 : '100%'}
      title="Clonar ordem"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={values =>
            onFinish(
              { ...values },
              clonarOrdem,
              null,
              afterSubmit,
              form.resetFields,
            )
          }
        >
          <Campo
            fluido
            name="numero"
            label="Número da Nova Ordem"
            mostrarFoco={mostrar}
          >
            <Input type="text" placeholder="Digite um número" maxLength={10} />
          </Campo>

          <Campo fluido>
            <BotaoPrimario type="primary" htmlType="submit">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

ClonarOrdemForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  ordem: objectOf(any),
  mostrar: bool,
};

ClonarOrdemForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  ordem: null,
};

export default ClonarOrdemForm;

import styled from 'styled-components';
import { Tag as AntTag } from 'antd';

export const Tag = styled(AntTag)`
  margin: 10px;
  height: 30px;
  padding: 5px;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 500;
  text-transform: capitalize;
`;

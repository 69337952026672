export default {
  titulo: 'Paradas',
  cabecalho: {
    desktop: [
      {
        titulo: 'Motivo',
        aninhamento: 'motivoParada',
        atributo: 'nome',
      },
      {
        titulo: 'Máquina',
        aninhamento: 'maquina',
        atributo: 'nome',
      },
      {
        titulo: 'Tempo',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
        custom: ({ tempo }, format) => `${format(tempo)} min`,
      },
      { titulo: 'Observações', atributo: 'observacao', variavel: true },
    ],
    mobile: [
      {
        titulo: 'Paradas',
        variavel: true,
        agrupar: [
          { aninhamento: 'motivoParada', atributo: 'nome' },
          { aninhamento: 'maquina', atributo: 'nome' },
          {
            tipo: 'n-decimal',
            enumerador: 0,
            custom: ({ tempo }, format) => `${format(tempo)} min`,
          },
        ],
      },
    ],
  },
};

import { Table } from 'antd';
import { any, arrayOf, func, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi, useUrl } from '../../../hooks';
import { Tabs, SummaryCell } from '../../../styles/global';
import { formatar, telaGrande } from '../../../utils';
import { ParadaForm } from '../../forms';
import { BotaoPrimario, Tabela } from '../../layout';
import { EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import { TabelaHeader } from '../styles';
import {
  FUNCIONARIO_APONTAMENTO_DATA,
  PARADAS,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  APONTAMENTO,
  CADASTRAR,
  PARADA,
  TAB,
} from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;
const { Row } = Table.Summary;

const DetalhesPontoTab = ({
  history,
  pontoFuncionario,
  setPontoFuncionario,
  apontamentos,
  setApontamentos,
}) => {
  const { buscar, deletar } = useApi();
  const [paradas, setParadas] = useState([]);
  const [parada, setParada] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { adicionarQuery, existeQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const mostrarParada = existeQuery(CADASTRAR, PARADA);
  const tabAtiva = pegarValorQuery(TAB) ?? APONTAMENTO;

  useEffect(() => {
    const carregarParadas = async () => {
      const { data } = await buscar(
        `/registros-ponto/${pontoFuncionario.uid}/paradas?$rel[motivoParada]=nome&$rel[maquina]=nome`,
      );
      setParadas(data);
    };
    if (pontoFuncionario.uid) {
      carregarParadas();
    }
  }, [pontoFuncionario, buscar]);

  useEffect(() => {
    if (apontamentos?.length === 0) {
      adicionarQuery(TAB, PARADA);
    }
  }, [adicionarQuery, apontamentos]);

  const deletarParada = async ({ uid, motivoParada, tempo, falta }) => {
    await deletar(
      `/registros-ponto/${pontoFuncionario.uid}/paradas/${uid}`,
      `A parada de ${tempo} minutos por conta de ${motivoParada?.nome} foi excluída`,
      () => {
        const { falta: faltaFunc, paradaApontada } = pontoFuncionario;
        setPontoFuncionario({
          ...pontoFuncionario,
          falta: falta ? Number(faltaFunc) - Number(tempo) : faltaFunc,
          paradaApontada: !falta
            ? Number(paradaApontada) - Number(tempo)
            : paradaApontada,
        });
        setParadas(paradas.filter(({ uid: paradaUid }) => paradaUid !== uid));
      },
    );
  };

  const paradaCallback = novaParada => {
    if (novaParada) {
      const index = paradas.findIndex(({ uid }) => uid === novaParada.uid);
      if (index >= 0) {
        paradas[index] = novaParada;
        setParadas([...paradas]);
      } else {
        setParadas([novaParada, ...paradas]);
      }
      const { falta: faltaFunc, paradaApontada } = pontoFuncionario;
      setPontoFuncionario({
        ...pontoFuncionario,
        falta: novaParada.falta
          ? Number(faltaFunc) + Number(novaParada.tempo)
          : faltaFunc,
        paradaApontada: !novaParada.falta
          ? Number(paradaApontada) + Number(novaParada.tempo)
          : paradaApontada,
      });
      removerQuery(CADASTRAR, PARADA);
      setParada(null);
    }
  };

  const deletarApontamento = async ({
    uid,
    operacao,
    quantidade,
    data,
    funcionario,
  }) => {
    await deletar(
      `/apontamentos/${uid}`,
      `O apontamento de ${quantidade} peça(s) de ${operacao?.nome} da ordem ${
        operacao?.ordemProducao?.numero
      } em ${formatar('data', data)} por ${
        funcionario?.nomeCompleto
      } foi excluído`,
    );
    setApontamentos(
      apontamentos.filter(({ uid: apontamentoUid }) => apontamentoUid !== uid),
    );
  };

  const renderSummaryApontamentos = () => {
    const { quantidadeApontada, tempoApontado } = pontoFuncionario ?? {};
    if (quantidadeApontada && tempoApontado) {
      if (telaGrande()) {
        return (
          <Row>
            <SummaryCell colSpan="3" />
            <SummaryCell>
              {formatar('n-decimal', quantidadeApontada, 2)}
            </SummaryCell>
            <SummaryCell />
            <SummaryCell>
              {formatar('n-decimal', tempoApontado, 2)} min
            </SummaryCell>
            <SummaryCell />
          </Row>
        );
      }
      return (
        <>
          <Row>
            <SummaryCell colSpan="2">
              Qtde Apontada: {formatar('n-decimal', quantidadeApontada, 2)}
            </SummaryCell>
          </Row>
          <Row>
            <SummaryCell colSpan="2">
              Tempo Apontado: {formatar('n-decimal', tempoApontado, 2)} min
            </SummaryCell>
          </Row>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Tabs
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => adicionarQuery(TAB, key)}
      >
        {apontamentos?.length > 0 && (
          <TabPane tab="Apontamentos" key={APONTAMENTO}>
            <Tabela
              loading={loading}
              cabecalho={FUNCIONARIO_APONTAMENTO_DATA}
              dados={apontamentos}
              summary={renderSummaryApontamentos}
              acoes={[
                {
                  titulo: EXCLUIR,
                  onClick: deletarApontamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPane>
        )}
        <TabPane tab="Paradas" key={PARADA}>
          <Tabela
            titulo={() => (
              <TabelaHeader>
                <div>
                  <h4>Paradas do Funcionário</h4>
                </div>
                <div>
                  <BotaoPrimario
                    type="primary"
                    hidden={
                      pontoFuncionario?.previsto === pontoFuncionario?.falta
                    }
                    disabled={loading}
                    onClick={() => adicionarQuery(CADASTRAR, PARADA)}
                  >
                    Informar uma Parada
                  </BotaoPrimario>
                </div>
              </TabelaHeader>
            )}
            loading={loading}
            cabecalho={PARADAS}
            dados={paradas}
            celulaSelecionada={paradaSelecionada => {
              setParada(paradaSelecionada);
              adicionarQuery(CADASTRAR, PARADA);
            }}
            acoes={[
              { titulo: EXCLUIR, onClick: deletarParada, confirmacao: true },
            ]}
          />
        </TabPane>
      </Tabs>

      <ParadaForm
        mostrar={mostrarParada}
        parada={parada}
        pontoUid={pontoFuncionario?.uid}
        afterSubmit={paradaCallback}
        aoFechar={() => {
          removerQuery(CADASTRAR, PARADA);
          setParada(null);
        }}
      />
    </>
  );
};

DetalhesPontoTab.propTypes = {
  pontoFuncionario: objectOf(any).isRequired,
  history: objectOf(any).isRequired,
  setPontoFuncionario: func.isRequired,
  apontamentos: arrayOf(any).isRequired,
  setApontamentos: func.isRequired,
};

export default DetalhesPontoTab;

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from '../../styles';
import { setConfiguracoes } from '../../../store/modules/sistema/actions';
import { BotaoPrimario, Campo, Header } from '../../../components/layout';
import { useApi } from '../../../hooks';
import { verificarBotoes } from '../functions';
import { FormContainer } from '../styles';
import {
  lerObjectProp,
  onFinish,
} from '../../../components/forms/commonFunctions';
import { Input } from '../../../components/forms/commonStyles';

const Valores = () => {
  const [sofreuAlteracao, setSofreuAlteracao] = useState({
    custoMinutoMinimo: false,
    custoMinutoIdeal: false,
  });
  const dispatch = useDispatch();
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const resetarCampos = useCallback(() => {
    lerObjectProp(
      {
        custoMinutoIdeal: configuracoes?.custoMinutoIdeal,
        custoMinutoMinimo: configuracoes?.custoMinutoMinimo,
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [configuracoes, form.resetFields, form.setFieldsValue]);

  useEffect(() => resetarCampos(), [resetarCampos]);

  const editarConfiguracao = async values => {
    const { data } = await alterar(
      '/configuracoes',
      values,
      {},
      'Dados atualizados com sucesso!',
    );

    return data;
  };

  const afterSubmit = config => {
    if (config) {
      setSofreuAlteracao(false);
      dispatch(setConfiguracoes(config));
    }
  };

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title="Configurações de Custo / Minuto"
        extra={[
          <Button
            disabled={loading}
            key="1"
            htmlType="button"
            hidden={
              !sofreuAlteracao.custoMinutoIdeal &&
              !sofreuAlteracao.custoMinutoMinimo
            }
            onClick={() => {
              resetarCampos();
              setSofreuAlteracao(false);
            }}
          >
            Descartar a alteração
          </Button>,
          <BotaoPrimario
            hidden={
              !sofreuAlteracao.custoMinutoIdeal &&
              !sofreuAlteracao.custoMinutoMinimo
            }
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => form.submit()}
          >
            Salvar
          </BotaoPrimario>,
        ]}
      />
      <FormContainer>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onChange={({ target }) =>
              verificarBotoes(target, configuracoes, setSofreuAlteracao)
            }
            onFinish={({ custoMinutoIdeal, custoMinutoMinimo }) => {
              onFinish(
                {
                  custoMinutoMinimo: Number(custoMinutoMinimo),
                  custoMinutoIdeal: Number(custoMinutoIdeal),
                  objectUid: 1,
                },
                null,
                editarConfiguracao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="custoMinutoIdeal"
              label="Custo Minuto Ideal"
              mostrarFoco={
                !sofreuAlteracao.custoMinutoIdeal &&
                !sofreuAlteracao.custoMinutoMinimo
              }
            >
              <Input
                name="custoMinutoIdeal"
                type="number"
                placeholder="Digite o custo"
                min={0}
              />
            </Campo>

            <Campo name="custoMinutoMinimo" label="Custo Minuto Mínimo">
              <Input
                name="custoMinutoMinimo"
                type="number"
                placeholder="Digite os minutos"
                min={0}
              />
            </Campo>
          </Form>
        </Spin>
      </FormContainer>
    </Container>
  );
};

export default Valores;

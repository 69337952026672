import React from 'react';
import {
  AreaChartOutlined,
  BankOutlined,
  BarcodeOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  FormOutlined,
  FundOutlined,
  GiftOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  PushpinOutlined,
  SaveOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SkinOutlined,
  StopOutlined,
  TableOutlined,
  TagsOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { CELULA, SEM_TAB, TAB } from '../../../hooks/useUrl/acoes';

export default [
  {
    icon: <ScheduleOutlined />,
    submenu: {
      title: 'Planejamento',
      itens: [
        {
          linkTo: '/planejamento',
          label: 'Planejamento',
          icon: <ScheduleOutlined />,
        },
      ],
    },
  },
  {
    icon: <BarcodeOutlined />,
    submenu: {
      title: 'Produção',
      itens: [
        { linkTo: '/', label: 'Dashboard', icon: <AreaChartOutlined /> },
        {
          linkTo: '/kanban-producao',
          label: 'Kanban de Produção',
          icon: <ProjectOutlined />,
        },
        {
          linkTo: '/ordens-producao',
          label: 'Ordens de Produção',
          icon: <SkinOutlined />,
        },
        {
          linkTo: `/apontamentos?${SEM_TAB}=true`,
          label: 'Apontamentos por Funcionário',
          icon: <TagsOutlined />,
        },
        {
          linkTo: `/apontamentos?${TAB}=${CELULA}&${SEM_TAB}=true`,
          label: 'Apontamentos por Célula',
          icon: <TagsOutlined />,
        },
        {
          linkTo: '/apontamentos',
          label: 'Apontamentos',
          icon: <TagsOutlined />,
        },
        {
          linkTo: '/compararApontamentos',
          label: 'Comparar Apontamentos',
          icon: <TagsOutlined />,
        },
        {
          linkTo: '/registros-ponto',
          label: 'Registros de Ponto',
          icon: <FieldTimeOutlined />,
        },
      ],
    },
  },
  {
    icon: <DollarOutlined />,
    submenu: {
      title: 'Financeiro',
      itens: [
        {
          linkTo: '/lancamentos-contas',
          label: 'Lançamentos',
          icon: <DollarOutlined />,
        },
        { linkTo: '/contas', label: 'Contas', icon: <BankOutlined /> },
        { linkTo: '/dre', label: 'DRE', icon: <FundOutlined /> },
      ],
    },
  },
  {
    icon: <FormOutlined />,
    submenu: {
      title: 'Cadastros',
      itens: [
        { linkTo: '/clientes', label: 'Clientes', icon: <TeamOutlined /> },
        {
          linkTo: '/registros-ponto',
          label: 'Registros de Ponto',
          icon: <FieldTimeOutlined />,
        },
        {
          linkTo: '/funcionarios',
          label: 'Funcionários',
          icon: <IdcardOutlined />,
        },

        {
          linkTo: '/celulas',
          label: 'Célula de Trabalho',
          icon: <TableOutlined />,
        },
        {
          linkTo: '/turnos',
          label: 'Turnos',
          icon: <UserSwitchOutlined />,
        },
        { linkTo: '/maquinas', label: 'Máquinas', icon: <PushpinOutlined /> },
        {
          linkTo: '/motivos',
          label: 'Motivos',
          icon: <StopOutlined />,
        },
        {
          linkTo: '/dias-nao-trabalhados',
          label: 'Dias Não Trabalhados',
          icon: <CalendarOutlined />,
        },
      ],
    },
  },
  {
    icon: <GiftOutlined />,
    submenu: {
      title: 'Prêmios',
      itens: [
        {
          linkTo: '/premios',
          label: 'Dashboard Prêmios',
          icon: <AreaChartOutlined />,
        },
        {
          linkTo: '/apuracoes',
          label: 'Prêmios',
          icon: <GiftOutlined />,
        },
      ],
    },
  },
  {
    icon: <SettingOutlined />,
    submenu: {
      title: 'Configurações',
      itens: [
        {
          icon: <OrderedListOutlined />,
          submenu: {
            title: 'Métricas',
            itens: [
              {
                linkTo: '/config-valor',
                label: 'Valores',
                icon: <CalculatorOutlined />,
              },
              {
                linkTo: '/config-tempo',
                label: 'Tempos',
                icon: <FieldTimeOutlined />,
              },
              {
                linkTo: '/config-premio',
                label: 'Prêmios',
                icon: <GiftOutlined />,
              },
            ],
          },
        },
        {
          icon: <SaveOutlined />,
          submenu: {
            title: 'Produção',
            itens: [
              {
                linkTo: '/config-ordem',
                label: 'Ordens de Produção',
                icon: <SkinOutlined />,
              },
              {
                linkTo: '/config-apontamento',
                label: 'Apontamentos',
                icon: <TagsOutlined />,
              },
            ],
          },
        },
      ],
    },
  },
];

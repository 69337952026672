import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Select, Spin } from 'antd';
import { object, func, objectOf, arrayOf, any, bool } from 'prop-types';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { BotaoPrimario, Campo, SelectDinamico } from '../../../layout';
import { useApi } from '../../../../hooks';
import { MaquinaForm } from '../..';
import { telaGrande } from '../../../../utils';
import { Input } from '../../commonStyles';

const { Option } = Select;

const OperacaoForm = ({
  afterSubmit,
  ordem,
  operacoes,
  operacao,
  aoFechar,
  mostrar,
}) => {
  const { alterar, criar, buscar, existeNoLog } = useApi();
  const [form] = Form.useForm();
  const [maquinas, setMaquinas] = useState([]);
  const [mostrarFormMaquina, setMostrarMaquina] = useState(false);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarMaquinas = async () => {
      const url = `/maquinas`;
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setMaquinas(data);
      }
    };
    if (mostrar) {
      carregarMaquinas();
    }
  }, [buscar, existeNoLog, mostrar]);

  useEffect(() => {
    lerObjectProp(operacao, form.setFieldsValue, form.resetFields);
  }, [operacao, form.setFieldsValue, form.resetFields]);

  const alterarOperacao = async (values, id) => {
    const { maquinaUid, nome } = values;
    const { data } = await alterar(
      `/ordens-producao/${ordem.uid}/operacoes/${id}`,
      {
        ...values,
        maquinaUid:
          maquinaUid === '' || typeof maquinaUid === 'undefined'
            ? null
            : maquinaUid,
      },
      operacao,
      `A operação ${operacao?.sequencia} - ${nome} foi alterada`,
    );
    return data;
  };

  const criarOperacao = async values => {
    const { data } = await criar(
      `/ordens-producao/${ordem.uid}/operacoes`,
      values,
      `A operação ${operacoes.length + 1}x ${values.nome} foi criada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title={operacao?.uid ? 'Alterar Operação' : 'Nova Operação'}
      onClose={aoFechar}
    >
      {mostrarFormMaquina && (
        <MaquinaForm
          mostrar={mostrarFormMaquina}
          aoVoltar={() => setMostrarMaquina(false)}
          afterSubmit={maquina => {
            setMaquinas([maquina, ...maquinas]);
            form.setFieldsValue({
              maquinaUid: maquina?.uid,
            });
          }}
        />
      )}
      {!mostrarFormMaquina && (
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="criar-operacao"
            onFinish={values => {
              onFinish(
                { ...values, objectUid: operacao?.uid },
                criarOperacao,
                alterarOperacao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="nome"
              label="Nome da Operação"
              obrigatorio
              mostrarFoco={mostrar}
              fluido
              comPopover
              tituloPopover="Nome da Operação"
              textoPopover="Informe aqui o nome da operação."
            >
              <Input type="text" placeholder="Digite o nome" maxLength={100} />
            </Campo>

            <Campo
              name="maquinaUid"
              label="Máquina de Costura"
              fluido
              comPopover
              tituloPopover="Máquina"
              textoPopover="Informe aqui a máquina para a operação. Você também pode criar uma nova máquina clicando em Novo ao final da lista."
            >
              <SelectDinamico
                form={form}
                nomeCampo="maquinaUid"
                placeholder="Selecione uma máquina"
                aoAdicionar={() => setMostrarMaquina(true)}
              >
                {maquinas?.map(maquina => (
                  <Option key={maquina.uid} value={maquina.uid}>
                    {maquina.nome}
                  </Option>
                ))}
              </SelectDinamico>
            </Campo>

            <Campo name="tempoCalcular" label="Tempo total" obrigatorio fluido>
              <Input
                type="number"
                placeholder="Digite o tempo"
                maxLength={15}
              />
            </Campo>

            <Campo
              name="quantidadeCalcular"
              label="Quantidade Produzida no Tempo Acima"
              obrigatorio
              fluido
              comPopover
              tituloPopover="Quantidade Produzida no Tempo Acima"
              textoPopover="Informe a quantidade de operações que são executadas no período informado. Depois lembre-se de clicar em Salvar. O sistema manterá esta tela aberta para que você cadastre todas as operações da ordem. Quando concluir basta clicar no ícone fechar X localizado no canto superior direito."
            >
              <Input
                type="number"
                placeholder="Digite uma quantidade"
                maxLength={15}
              />
            </Campo>

            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

OperacaoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  operacao: objectOf(any),
  ordem: objectOf(any),
  operacoes: arrayOf(object).isRequired,
  mostrar: bool,
};

OperacaoForm.defaultProps = {
  aoFechar: null,
  mostrar: false,
  afterSubmit: null,
  operacao: null,
  ordem: null,
};

export default OperacaoForm;

export default {
  titulo: 'Prêmios',
  cabecalho: {
    desktop: [
      {
        titulo: 'Célula',
        aninhamento: 'celula',
        atributo: 'nome',
        variavel: true,
      },
      {
        enumerador: 2,
        tipo: 'n-decimal',
        titulo: 'Faturamento',
        atributo: 'valorFaturado',
        width: '120px',
        align: 'right',
      },
      {
        enumerador: 2,
        titulo: '% Faturamento',
        tipo: 'n-decimal',
        align: 'right',
        width: '120px',
        custom: ({ percentualFaturamento }, format) =>
          `${format(percentualFaturamento ?? 0)}%`,
      },
      {
        enumerador: 2,
        titulo: 'Prêmio',
        tipo: 'n-decimal',
        atributo: 'valorDistribuido',
        align: 'right',
        width: '120px',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Prêmio',
        agrupar: [
          {
            aninhamento: 'funcionario',
            atributo: 'nomeCompleto',
          },
          {
            custom: ({ celula }) => `Célula: ${celula?.nome}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorFaturado }, format) =>
              `Faturamento: ${format(valorFaturado ?? 0)}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ percentualFaturamento }, format) =>
              `% Faturamento: ${format(percentualFaturamento ?? 0)}%`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorDistribuido }, format) =>
              `Prêmio: ${format(valorDistribuido ?? 0)}`,
          },
        ],
      },
    ],
  },
};

import styled from 'styled-components';
import {
  Modal as AntModal,
  DatePicker as AntDatePicker,
  Select as AntSelect,
  Button,
} from 'antd';

export const Modal = styled(AntModal)`
  width: 75% !important;
  .ant-modal-body {
    padding: 0px;
  }
  @media only screen and (max-device-width: 640px) {
    width: 100% !important;
    height: 100% !important;
    .ant-modal-close-x {
      line-height: 30px !important;
      width: 30px !important;
    }
  }
`;

export const DatePicker = styled(AntDatePicker)`
  @media only screen and (max-device-width: 640px) {
    margin: 15px 0px 10px;
    display: block;
  }
`;

export const ModalButton = styled(Button)`
  float: right;
  margin-top: 20px;
`;

export const TabContainer = styled.div`
  padding: 5px;
  background-color: #f0f0f0;
  overflow: auto;
  @media only screen and (min-device-width: 640px) {
    max-height: 500px !important;
    margin-top: 40px;
    padding: 24px;
  }
`;

export const TabelaContainer = styled.div`
  background: #fff;
  margin-bottom: 40px;
`;

export const Select = styled(AntSelect)`
  display: block;
  width: 100%;
  margin: 0px;
`;

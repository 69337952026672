import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, arrayOf, func, objectOf } from 'prop-types';
import { isArray } from 'lodash';
import { useApi, useUrl } from '../../../hooks';
import { formatar, telaGrande } from '../../../utils';
import { OrdemProducaoForm } from '../../forms';
import { BotaoPrimario, BotaoTutorial, Filtros, Tabela } from '../../layout';
import { ESTORNAR, EXCLUIR, IMPRIMIR } from '../../layout/Tabela/nomesAcoes';
import { TabelaOperacoesBalanceadas } from '../../layout/Tabela/cabecalhos';
import { TabelaHeader, Button } from '../styles';
import { BalanceamentoHeader, BalanceamentoContainer } from './styles';
import { Tabs, SummaryCell } from '../../../styles/global';
import {
  BALANCEAMENTO,
  BUSCAR,
  CADASTRAR,
  CELULA,
  CLONAR,
  EDITAR,
  FUNCIONARIO,
  OPERACAO,
  PACOTE,
  RECEBIMENTO,
  TAB,
} from '../../../hooks/useUrl/acoes';
import {
  APONTAMENTOS_CELULA_ORDEM,
  APONTAMENTOS_ORDEM_PRODUCAO,
  LANCAMENTOS,
  METAS_PRODUCAO,
  OPERACOES_BALANCEADAS,
  OPERACOES_FUNCIONARIO,
  OPERACOES_ORDEM_PRODUCAO,
  OPERACOES_PACOTE,
  OPERACOES_TEMPO,
  PACOTES_ORDEM_PRODUCAO,
} from '../../layout/Tabela/nomesCabecalhos';

const { OperacaoForm, PacoteForm, ClonarOperacaoForm } = OrdemProducaoForm;
const { TabPane } = Tabs;
const { TituloComFiltro } = Filtros;
const { Row } = Table.Summary;

const DetalhesOrdemTab = ({
  ordemProducao,
  operacoes,
  history,
  setOperacoes,
  setOrdemProducao,
  carregarOrdemProducao,
  imprimirTags,
  pacotes,
  setPacotes,
  carregarOperacoes,
  recebimentos,
  setRecebimentos,
}) => {
  const { buscar, deletar, alterar, criar } = useApi();
  const [mostrarTotal, setMostrarTotal] = useState(true);
  const [linhasExpandidas, setLinhasExpandidas] = useState([]);
  const [apontamentos, setApontamentos] = useState([]);
  const [balanceamentos, setBalanceamentos] = useState([]);
  const [apontamentosCelula, setApontamentosCelula] = useState([]);
  const [operacoesPacotes, setOperacoesPacotes] = useState([]);
  const [metasProducao, setMetasProducao] = useState([]);
  const [pacoteSelecionado, setPacoteSelecionado] = useState({});
  const [operacaoSelecionada, setOperacaoSelecionada] = useState({});
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const tabAtiva = pegarValorQuery(TAB) ?? OPERACAO;
  const busca = pegarValorQuery(BUSCAR);
  const mostrarClonarOperacao = existeQuery(CLONAR, OPERACAO);
  const mostrarOperacao = existeQuery(CADASTRAR, OPERACAO);
  const mostrarEdicaoPacote = existeQuery(EDITAR, PACOTE);
  const mostrarCadastroPacote = existeQuery(CADASTRAR, PACOTE);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const buscarBalanceamento = async () => {
      const { data } = await buscar(
        `/ordens-producao/${ordemProducao?.uid}/operacoes/balanceamento`,
      );
      setBalanceamentos(data);
    };

    if (ordemProducao?.quantidadeBalanceamento) {
      buscarBalanceamento();
    }
  }, [buscar, ordemProducao]);

  useEffect(() => {
    const buscarMetas = async () => {
      const { data } = await buscar(
        `/ordens-producao/${ordemProducao?.uid}/metas-producao`,
      );
      setMetasProducao(data);
    };

    if (balanceamentos?.length > 0) {
      buscarMetas();
    }
  }, [buscar, balanceamentos, ordemProducao]);

  useEffect(() => {
    const carregarApontamentosCelula = async () => {
      const { data } = await buscar(
        `/apontamentos-celula?$ordem=-data,celulaUid&ordemProducaoUid[eq]=${ordemProducao?.uid}&$rel[celula]=nome`,
      );
      setApontamentosCelula(data);
    };

    if (ordemProducao?.uid) {
      carregarApontamentosCelula();
    }
  }, [buscar, ordemProducao.uid]);

  const carregarOperacoesPacotes = async uid => {
    const { data } = await buscar(
      `/pacotes/${uid}/operacoes?$rel[operacao]=nome,sequencia&$rel[pacote]=quantidade`,
    );
    setOperacoesPacotes(data);
  };

  const carregarApontamentos = async uid => {
    const { data } = await buscar(
      `/apontamentos/?$ordem=dataCriacao&ordemProducaoOperacaoUid[eq]=${uid}&$rel[funcionario]=nomeCompleto&$rel[operacao]=nome,uid&$rel[operacao>ordemProducao]=nome,numero`,
    );
    setApontamentos(data);
  };

  const deletarApontamento = async ({
    uid,
    operacao,
    quantidade,
    data,
    funcionario,
    celula,
  }) => {
    const mensagem = `O apontamento de ${quantidade} peça(s) ${
      operacao ? `de ${operacao.nome}` : ''
    } da ordem ${ordemProducao?.numero} em ${formatar('data', data)} ${
      funcionario
        ? `por ${funcionario.nomeCompleto}`
        : `pela célula ${celula?.nome}`
    } foi excluído`;
    if (funcionario) {
      await deletar(`/apontamentos/${uid}`, mensagem, () => {
        setApontamentos(
          apontamentos.filter(
            ({ uid: apontamentoUid }) => apontamentoUid !== uid,
          ),
        );
      });
    } else if (celula) {
      await deletar(`/apontamentos-celula/${uid}`, mensagem, () => {
        setApontamentosCelula(
          apontamentosCelula.filter(
            ({ uid: apontamentoUid }) => apontamentoUid !== uid,
          ),
        );
      });
    }
  };

  const deletarOperacao = async ({ uid, sequencia, nome, tempo }) => {
    await deletar(
      `/ordens-producao/${ordemProducao.uid}/operacoes/${uid}`,
      `A operação ${sequencia} - ${nome} foi excluída`,
      () =>
        setOperacoes(
          operacoes.filter(({ uid: operacaoUid }) => operacaoUid !== uid),
        ),
    );
    const { tempoOperacoes, tempoFinal } = ordemProducao;
    setOrdemProducao({
      ...ordemProducao,
      tempoOperacoes: tempoOperacoes - tempo,
      tempoFinal: tempoFinal - tempo,
    });
  };

  const switchOnChange = async (valor, { uid }) => {
    const { data } = await alterar(
      `/ordens-producao/${ordemProducao.uid}/operacoes/${uid}`,
      {
        redistribuirTempo: valor,
      },
      {},
    );
    if (data) {
      carregarOperacoes();
    }
  };

  const renderSummaryOperacoes = operacoesTabela => {
    const { tempoAjuste, dataInicio } = ordemProducao ?? {};
    const monstrarTempoTotal = !!(tempoAjuste && mostrarTotal);

    const ultimaCelula = () => {
      let colSpan = 0;
      if (monstrarTempoTotal && dataInicio) {
        colSpan = 2;
      }
      if (monstrarTempoTotal && !dataInicio) {
        colSpan = 3;
      }
      if (!monstrarTempoTotal && !dataInicio) {
        colSpan = 1;
      }
      return colSpan > 0 && <SummaryCell colSpan={colSpan} />;
    };
    const tempoO = `${formatar(
      'n-decimal',
      operacoesTabela.reduce((acc, { tempoOriginal }) => {
        acc += tempoOriginal;
        return acc;
      }, 0),
      4,
    )} min`;
    const tempoT = `${formatar(
      'n-decimal',
      operacoesTabela.reduce((acc, { tempo }) => {
        acc += tempo;
        return acc;
      }, 0),
      4,
    )} min`;

    if (!telaGrande()) {
      return (
        <Row>
          <SummaryCell colSpan="2">
            <span>
              {!telaGrande() && 'Unitário: '}
              {tempoO}
            </span>
            <span>
              {monstrarTempoTotal && (
                <SummaryCell>
                  {!telaGrande() && 'Ajustado: '}
                  {tempoT}
                </SummaryCell>
              )}
            </span>
          </SummaryCell>
        </Row>
      );
    }

    return (
      <Row>
        <SummaryCell colSpan="4" />
        <SummaryCell>{tempoO}</SummaryCell>
        {monstrarTempoTotal && <SummaryCell>{tempoT}</SummaryCell>}
        {ultimaCelula()}
      </Row>
    );
  };

  const renderSummaryRecebimentos = recebimentosTabela => {
    return (
      <Row>
        <SummaryCell />
        <SummaryCell>
          {formatar(
            'moeda',
            recebimentosTabela.reduce((acc, { valor }) => {
              acc += valor;
              return acc;
            }, 0),
          )}
        </SummaryCell>
        <SummaryCell colSpan="2" />
      </Row>
    );
  };

  const renderSummaryCelulas = apontamentos => {
    return apontamentos?.length > 0 ? (
      <Row>
        <SummaryCell colSpan="2" />
        <SummaryCell>
          {formatar(
            'n-decimal',
            apontamentos.reduce((acc, { quantidade }) => {
              acc += quantidade;
              return acc;
            }, 0),
            0,
          )}
        </SummaryCell>
        <SummaryCell colSpan="4">
          {formatar(
            'n-decimal',
            apontamentos.reduce((acc, { valorTotal }) => {
              acc += valorTotal;
              return acc;
            }, 0),
            2,
          )}
        </SummaryCell>
        <SummaryCell />
      </Row>
    ) : null;
  };

  const renderSummaryPacotes = pacotes => {
    const qtd = formatar(
      'n-decimal',
      pacotes.reduce((acc, { quantidade }) => {
        acc += quantidade;
        return acc;
      }, 0),
      0,
    );
    return (
      <Row>
        {telaGrande() ? (
          <>
            <SummaryCell colSpan="4" />
            <SummaryCell>{qtd}</SummaryCell>
            <SummaryCell colSpan="2" />
          </>
        ) : (
          <SummaryCell colSpan="3">Quantidade: {qtd}</SummaryCell>
        )}
      </Row>
    );
  };

  const deletarPacote = async ({ uid, quantidade, tamanho, cor }) => {
    await deletar(
      `/ordens-producao/${ordemProducao.uid}/pacotes/${uid}`,
      `Pacote ${quantidade}x ${tamanho ?? ''} - ${
        cor ?? ''
      } excluido com sucesso!`,
      () => {
        setOrdemProducao({
          ...ordemProducao,
          quantidadeTotalPacotes:
            ordemProducao?.quantidadeTotalPacotes - quantidade,
        });
        setPacotes(pacotes.filter(({ uid: pacoteUid }) => pacoteUid !== uid));
      },
    );
  };

  const estornarLancamentoConta = async uid => {
    const { lancamentoUidEstornado, data, historico } = recebimentos?.find(
      lancamento => lancamento.uid === uid,
    );
    if (lancamentoUidEstornado) {
      return message.error('Você não pode estornar um estorno');
    }
    if (
      recebimentos?.find(
        ({ lancamentoUidEstornado }) => lancamentoUidEstornado === uid,
      )
    ) {
      return message.error('Você já efetuou um estorno para esse lançamento');
    }

    const { data: lancamento } = await criar(
      '/lancamentos/',
      {
        data,
        historico: historico ? `Estorno de ${historico}` : 'Estorno',
        lancamentoUidEstornado: uid,
      },
      'Valor estornado com sucesso!',
    );

    return lancamento;
  };

  const deletarLancamento = async ({ uid, valor, data, conta }) => {
    const { data: retorno } = await deletar(
      `/lancamentos/${uid}`,
      `O lançamento de ${valor} em ${formatar('data', data)} na conta
      ${conta.nome} foi excluído`,
      () => {
        const arrLancamentos = recebimentos?.filter(
          ({ uid: lancUid }) => lancUid !== uid,
        );
        setRecebimentos(arrLancamentos);
        if (arrLancamentos?.length === 0) {
          if (ordemProducao?.quantidadeApontada) {
            adicionarQuery(TAB, FUNCIONARIO);
          } else {
            adicionarQuery(TAB, CELULA);
          }
        }
      },
    );
    if (retorno) {
      setOrdemProducao(retorno?.ordemProducao);
    }
  };

  const imprimirBalanceamento = async () => {
    const { data } = await criar(
      `/ordens-producao/${ordemProducao?.uid}/operacoes/imprimir-balanceamentos`,
      {},
      null,
      {
        responseType: 'arraybuffer',
      },
    );
    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      window.focus();
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => {
          removerQuery(BUSCAR);
          adicionarQuery(TAB, key);
          setLinhasExpandidas([]);
        }}
      >
        {ordemProducao?.dataInicio && (
          <>
            {(ordemProducao?.quantidadeApontada ||
              ordemProducao?.quantidadeApontada === 0) && (
              <TabPane tab="Apontamentos por Funcionário" key={FUNCIONARIO}>
                <Tabela
                  loading={loading}
                  expandedRowKeys={linhasExpandidas}
                  cabecalho={OPERACOES_FUNCIONARIO}
                  dados={operacoes?.map(op => ({
                    ...op,
                    saldo: ordemProducao?.dataConclusao
                      ? 0
                      : ordemProducao?.quantidade - op.quantidadeApontada,
                  }))}
                  rowClassName={ordem => {
                    return ordem?.dataConclusao ? 'verde' : null;
                  }}
                  titulo={() => (
                    <TabelaHeader>
                      <div>
                        <h4>Apontamentos por Funcionário</h4>
                        <TituloComFiltro history={history} busca={busca} />
                      </div>
                    </TabelaHeader>
                  )}
                  onExpand={(expanded, { uid }) => {
                    if (expanded === true) {
                      setMostrarTotal(false);
                      carregarApontamentos(uid);
                      return setLinhasExpandidas([uid]);
                    }
                    setMostrarTotal(true);
                    return setLinhasExpandidas([]);
                  }}
                  expandir={() => (
                    <Tabela
                      loading={loading}
                      titulo
                      dados={apontamentos}
                      cabecalho={APONTAMENTOS_ORDEM_PRODUCAO}
                      acoes={[
                        {
                          titulo: EXCLUIR,
                          onClick: deletarApontamento,
                          confirmacao: true,
                        },
                      ]}
                    />
                  )}
                />
              </TabPane>
            )}
            {apontamentosCelula?.length > 0 && (
              <TabPane tab="Apontamentos por Célula" key={CELULA}>
                <Tabela
                  cabecalho={APONTAMENTOS_CELULA_ORDEM}
                  dados={apontamentosCelula}
                  loading={loading}
                  summary={renderSummaryCelulas}
                  acoes={[
                    {
                      titulo: EXCLUIR,
                      onClick: deletarApontamento,
                      confirmacao: true,
                    },
                  ]}
                />
              </TabPane>
            )}
          </>
        )}
        {ordemProducao?.quantidadeBalanceamento && (
          <TabPane tab="Balanceamento" key={BALANCEAMENTO}>
            <BalanceamentoHeader>
              <span>
                Quantidade de funcionários:{' '}
                {ordemProducao?.quantidadeFuncionarios ?? 0}
              </span>
              <span>
                Produtividade:{' '}
                {ordemProducao?.produtividadeBalanceamento ?? 100}%
              </span>
              <span>
                Capacidade de produção:{' '}
                {ordemProducao?.quantidadeBalanceamento ?? 0} peças/hora
              </span>
            </BalanceamentoHeader>
            <Tabela
              dados={metasProducao}
              loading={loading}
              cabecalho={METAS_PRODUCAO}
              rowKey="produtividade"
            />
            <BalanceamentoContainer>
              <Tabela
                dados={balanceamentos}
                loading={loading}
                expandedRowKeys={linhasExpandidas}
                cabecalho={OPERACOES_BALANCEADAS}
                titulo={() => (
                  <TabelaHeader>
                    <h4>{TabelaOperacoesBalanceadas.titulo}</h4>
                    <Button
                      disabled={loading}
                      tamanho="pequeno"
                      type="button"
                      onClick={imprimirBalanceamento}
                    >
                      Imprimir balanceamento
                    </Button>
                  </TabelaHeader>
                )}
              />
            </BalanceamentoContainer>
          </TabPane>
        )}
        <TabPane tab="Operações" key={OPERACAO}>
          <Tabela
            celulaOnChange={{ Switch: switchOnChange }}
            loading={loading}
            dados={operacoes}
            summary={operacoes?.length > 0 && renderSummaryOperacoes}
            expandedRowKeys={linhasExpandidas}
            titulo={() => (
              <TabelaHeader>
                <div>
                  <h4>Operações da Ordem</h4>
                  <TituloComFiltro history={history} busca={busca} />
                </div>
                <div>
                  <Button
                    disabled={loading}
                    hidden={operacoes?.length > 0}
                    tamanho="pequeno"
                    type="button"
                    onClick={() => adicionarQuery(CLONAR, OPERACAO)}
                  >
                    Clonar operações
                  </Button>

                  <BotaoTutorial
                    disabled={loading}
                    tamanho="pequeno"
                    type="primary"
                    esconder={mostrarOperacao || operacoes?.length !== 0}
                    hidden={
                      ordemProducao?.dataInicio ||
                      pacotes?.length > 0 ||
                      ordemProducao?.quantidadeBalanceamento
                    }
                    onClick={() => {
                      setOperacaoSelecionada(null);
                      adicionarQuery(CADASTRAR, OPERACAO);
                    }}
                    content={
                      <h5>
                        Vamos cadastrar uma nova operação. Para isso, clique no
                        botão Nova Operação.
                      </h5>
                    }
                  >
                    Nova Operação
                  </BotaoTutorial>
                </div>
              </TabelaHeader>
            )}
            celulaSelecionada={
              !ordemProducao?.dataInicio
                ? ({ uid }) => {
                    setOperacaoSelecionada(
                      operacoes.find(operacao => operacao.uid === uid),
                    );
                    adicionarQuery(CADASTRAR, OPERACAO);
                  }
                : null
            }
            cabecalho={
              ordemProducao?.tempoAjuste
                ? OPERACOES_TEMPO
                : OPERACOES_ORDEM_PRODUCAO
            }
            acoes={
              !ordemProducao?.dataInicio
                ? [
                    {
                      titulo: EXCLUIR,
                      onClick: deletarOperacao,
                      confirmacao: true,
                    },
                  ]
                : null
            }
          />
        </TabPane>
        {operacoes?.length > 0 && (
          <TabPane tab="Pacotes" key={PACOTE}>
            <Tabela
              loading={loading}
              dados={pacotes}
              cabecalho={PACOTES_ORDEM_PRODUCAO}
              summary={pacotes?.length > 0 && renderSummaryPacotes}
              celulaSelecionada={pacote => {
                setPacoteSelecionado(pacote);
                adicionarQuery(EDITAR, PACOTE);
              }}
              acoes={[
                {
                  titulo: IMPRIMIR,
                  onClick: ({ uid }) => imprimirTags({ pacotesUids: [uid] }),
                },
                {
                  titulo: EXCLUIR,
                  onClick: deletarPacote,
                  confirmacao: true,
                  naoMostrarSe: ({ quantidadeApontada }) =>
                    quantidadeApontada !== null,
                },
              ]}
              titulo={() => (
                <TabelaHeader>
                  <div>
                    <h4>Pacotes da Ordem</h4>
                    <TituloComFiltro history={history} busca={busca} />
                  </div>
                  <div>
                    <Button
                      disabled={loading}
                      hidden={
                        operacoes?.length === 0 ||
                        pacotes?.length === 0 ||
                        ordemProducao?.dataConclusao
                      }
                      key="1"
                      type="button"
                      tamanho="pequeno"
                      onClick={() =>
                        imprimirTags({
                          ordensProducaoUids: [ordemProducao?.uid],
                        })
                      }
                    >
                      Imprimir Tags
                    </Button>
                    <BotaoPrimario
                      hidden={
                        ordemProducao?.dataInicio ||
                        ordemProducao?.quantidadeTotalPacotes ===
                          ordemProducao?.quantidade
                      }
                      disabled={loading}
                      tamanho="pequeno"
                      type="primary"
                      onClick={() => adicionarQuery(CADASTRAR, PACOTE)}
                    >
                      Novo Pacote
                    </BotaoPrimario>
                  </div>
                </TabelaHeader>
              )}
              onExpand={(expanded, { uid }) => {
                if (expanded === true) {
                  carregarOperacoesPacotes(uid);
                  return setLinhasExpandidas([uid]);
                }
                return setLinhasExpandidas([]);
              }}
              expandedRowKeys={linhasExpandidas}
              expandir={() => (
                <Tabela
                  loading={loading}
                  titulo
                  dados={operacoesPacotes}
                  cabecalho={OPERACOES_PACOTE}
                />
              )}
            />
          </TabPane>
        )}
        {recebimentos?.length > 0 && (
          <TabPane tab="Recebimentos" key={RECEBIMENTO}>
            <Tabela
              loading={loading}
              cabecalho={LANCAMENTOS}
              dados={recebimentos}
              summary={renderSummaryRecebimentos}
              acoes={[
                {
                  titulo: ESTORNAR,
                  confirmacao: true,
                  onClick: async ({ uid }) => {
                    const novoLancamento = await estornarLancamentoConta(uid);
                    if (novoLancamento?.uid) {
                      setRecebimentos([...recebimentos, novoLancamento]);
                    }
                  },
                },
                {
                  titulo: EXCLUIR,
                  onClick: deletarLancamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPane>
        )}
      </Tabs>

      <OperacaoForm
        mostrar={mostrarOperacao}
        operacao={operacaoSelecionada}
        operacoes={operacoes}
        ordem={ordemProducao}
        aoFechar={() => {
          setOperacaoSelecionada({});
          removerQuery(CADASTRAR, OPERACAO);
        }}
        afterSubmit={operacao => {
          if (operacao) {
            const index = operacoes.findIndex(
              ({ uid }) => uid === operacao.uid,
            );
            if (index === -1) {
              carregarOrdemProducao();
              setOperacoes([...operacoes, operacao]);
            } else {
              operacoes[index] = operacao;
              carregarOrdemProducao();
              setOperacoes([...operacoes]);
            }
            setOperacaoSelecionada({});
          }
        }}
      />

      <ClonarOperacaoForm
        aoFechar={() => removerQuery(CLONAR, OPERACAO)}
        mostrar={mostrarClonarOperacao}
        ordemUid={ordemProducao?.uid}
        afterSubmit={operacoesClonadas => {
          if (operacoesClonadas) {
            setOperacoes(operacoesClonadas);
            const tempoOperacoes = operacoesClonadas.reduce(
              (soma, { tempo }) => {
                const total = soma + tempo;
                return total;
              },
              0,
            );
            const { tempoAjuste } = ordemProducao ?? {};
            const tempoFinal = (tempoAjuste ?? 0) + tempoOperacoes;
            setOrdemProducao({
              ...ordemProducao,
              tempoOperacoes,
              tempoFinal,
            });
            removerQuery(CLONAR, OPERACAO);
          }
        }}
      />

      <PacoteForm
        ordemUid={ordemProducao?.uid}
        mostrar={mostrarCadastroPacote || mostrarEdicaoPacote}
        pacote={mostrarEdicaoPacote ? pacoteSelecionado : null}
        aoFechar={() =>
          mostrarEdicaoPacote
            ? removerQuery(EDITAR, PACOTE)
            : removerQuery(CADASTRAR, PACOTE)
        }
        afterSubmit={novoPacote => {
          if (mostrarEdicaoPacote && novoPacote) {
            removerQuery(EDITAR, PACOTE);
            const index = pacotes?.findIndex(
              ({ uid }) => uid === novoPacote.uid,
            );
            pacotes[index] = novoPacote;
            setPacotes([...pacotes]);
            carregarOrdemProducao();
          } else if (novoPacote?.length > 0 && isArray(novoPacote)) {
            removerQuery(CADASTRAR, PACOTE);
            const arr = [novoPacote, ...pacotes].flat();
            setPacotes(arr);
            setOrdemProducao({
              ...ordemProducao,
              quantidadeTotalPacotes: arr.reduce((soma, { quantidade }) => {
                soma += quantidade;
                return soma;
              }, 0),
            });
          }
        }}
      />
    </>
  );
};

DetalhesOrdemTab.propTypes = {
  ordemProducao: objectOf(any),
  operacoes: arrayOf(any).isRequired,
  recebimentos: arrayOf(any).isRequired,
  pacotes: arrayOf(any).isRequired,
  history: objectOf(any).isRequired,
  setOrdemProducao: func.isRequired,
  setRecebimentos: func.isRequired,
  carregarOperacoes: func.isRequired,
  setOperacoes: func.isRequired,
  setPacotes: func.isRequired,
  carregarOrdemProducao: func.isRequired,
  imprimirTags: func.isRequired,
};

DetalhesOrdemTab.defaultProps = {
  ordemProducao: null,
};

export default DetalhesOrdemTab;

import axios from 'axios';

const vextaHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Authorization',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  'Content-Type': 'application/json;charset=UTF-8',
};

const apiServidor = axios.create({
  baseURL: process.env.REACT_APP_API_BACKEND,
  headers: vextaHeaders,
});

apiServidor.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

apiServidor.setToken = function setToken(token) {
  if (token) {
    this.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    this.defaults.headers.common.Authorization = undefined;
  }
};

const apiServidorAuth = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH,
  headers: vextaHeaders,
});

apiServidorAuth.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { apiServidor, apiServidorAuth };

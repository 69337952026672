import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  List as AntList,
  Card as AntCard,
  Button as AntButton,
  Badge as AntBadge,
  Row as AntRow,
  Col as AntCol,
} from 'antd';
import colors from '../../styles/colors';

const getRgba = cor => {
  switch (cor) {
    case 'red':
      return '#fa9e9e66 ';
    case 'orange':
      return '#facc9e66 ';
    case 'yellow':
      return '#faeb9e66 ';
    default:
      return 'transparent';
  }
};

const getRgbaBorda = cor => {
  switch (cor) {
    case 'red':
      return '#fa9e9e ';
    case 'orange':
      return '#facc9e ';
    case 'yellow':
      return '#faeb9e';
    default:
      return 'transparent';
  }
};

export const RowContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    overflow-x: scroll;
  }
`;

export const Badge = styled(AntBadge)`
  margin: 0px 0px 10px 5px;
  ${({ transparente }) =>
    transparente &&
    `
  .ant-badge-count {
    border: 1px solid #cccc;
    background: transparent;
    color: #cccc;
  }`}
`;

export const Col = styled(AntCol)`
  @media only screen and (max-device-width: 640px) {
    width: 260px;
    max-width: 260px;
    display: inline-table;
  }
`;

export const Row = styled(AntRow)`
  flex-flow: initial;
`;

export const Link = styled(RouterLink)`
  margin: 0px 10px 0px 0px;
  font-size: 30px;
  color: ${colors.primaria_fundo_claro};

  &:hover {
    color: ${colors.secundaria};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ItemContainer = styled(CardContainer)`
  cursor: ${({ onClick }) => onClick && 'pointer'};
  display: block;
  border-radius: 5px;
  border: 1px solid ${({ borda }) => (borda ? getRgbaBorda(borda) : '#f0f0f0')};
`;

export const Item = styled(AntList.Item)`
  border: none !important;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const FlexContainer = styled.div`
  display: flex;
  h4 {
    margin-left: 10px;
  }
`;

export const List = styled(AntList)`
  border: none;
  ${CardContainer}:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Card = styled(AntCard)`
  min-height: 327px;
  border-radius: 5px;
  .ant-card-head {
    height: 58px;
  }
  .ant-card-body {
    padding: 0px;
  }
  .ant-list-items {
    padding: 7px;
  }
  @media only screen and (max-device-width: 640px) {
    margin-bottom: 15px;
  }
`;

export const TransparentButton = styled(AntButton)`
  border: none;
  box-shadow: none;
  background: transparent;

  &:hover {
    color: ${colors.primaria};
  }
`;

export const CardHeader = styled.div`
  padding: 0px 12px 0px 24px;
  background: ${({ cor }) => getRgba(cor)};
  overflow: hidden;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  h1 {
    margin: 0;
    font-size: 30px;
  }
  span {
    font-size: 13px;
    color: #000;
  }
`;

import React, { useState } from 'react';
import { func, string } from 'prop-types';

import { Popover } from 'antd';
import { BotaoDefault, BotaoPrimario } from '../index';

const BotaoQuitarOrdem = ({ children, Onclick }) => {
  const [open, setOpen] = useState(false);

  const fechar = () => {
    setOpen(false);
  };

  const quadro = () => {
    return (
      <>
        <div>
          <BotaoDefault type="button" tamanho="pequeno" onClick={fechar}>
            Fechar
          </BotaoDefault>
          <BotaoPrimario
            type="button"
            tamanho="pequeno"
            onClick={() => {
              Onclick();
              fechar();
            }}
          >
            Quitar
          </BotaoPrimario>
        </div>
      </>
    );
  };

  return (
    <>
      <Popover
        title="Quitar ordem ?"
        trigger="click"
        visible={open}
        content={quadro}
      >
        <BotaoDefault
          type="button"
          tamanho="pequeno"
          onClick={() => setOpen(true)}
        >
          {children}
        </BotaoDefault>
      </Popover>
    </>
  );
};

BotaoQuitarOrdem.propTypes = {
  children: string,
  Onclick: func,
};

BotaoQuitarOrdem.defaultProps = {
  children: '',
  Onclick: null,
};

export default BotaoQuitarOrdem;

import TabelaMesesAno from './TabelaMesesAno';

const cab = [
  {
    titulo: 'Código',
    aninhamento: 'conta',
    atributo: 'codigo',
    align: 'right',
    compacta: true,
  },
  {
    titulo: 'Conta',
    aninhamento: 'conta',
    atributo: 'nome',
    variavel: true,
    align: 'left',
  },
  ...TabelaMesesAno.cabecalho.desktop.map(item => ({
    ...item,
    aninhamento: 'meses',
    clickable: true,
  })),
];

const tabela = {
  titulo: 'Saldo Mensal Por Conta',
  cabecalho: {
    desktop: cab,
    mobile: cab,
  },
};

export default tabela;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import {
  UpCircleOutlined,
  CheckCircleOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { formatar, telaGrande } from '../../../utils';
import { DetalhesOrdemTab } from '../../../components/tabs';
import { useApi, useUrl } from '../../../hooks';
import { ALTERAR } from '../../../components/layout/Tabela/nomesAcoes';
import { LancamentoForm, OrdemProducaoForm } from '../../../components/forms';
import { Link } from '../../styles';
import {
  AlertaTutorial,
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
  UploadImagem,
  BotaoDefault,
  BotaoQuitarOrdem,
} from '../../../components/layout';
import {
  Situacao,
  SubTitulo,
  TituloContainer,
  Tag,
  SituacaoContainer,
} from './styles';
import {
  CADASTRAR,
  BUSCAR,
  CLONAR,
  ORDEM,
  AJUSTE,
  ORDEM_PRECO,
  RECEBIMENTO,
  BALANCEAMENTO,
  EDITAR,
} from '../../../hooks/useUrl/acoes';

const {
  OrdemForm,
  TempoAjusteForm,
  ClonarOrdemForm,
  AlterarPrecoForm,
  BalanceamentoForm,
} = OrdemProducaoForm;
const { CriarLancamentoForm } = LancamentoForm;

const DetalhesOrdemProducao = ({ history, match }) => {
  const { id } = match.params;
  const { buscar, criar, alterar, deletar } = useApi();
  const { loading, mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );
  const { existeQuery, pegarValorQuery, adicionarQuery, removerQuery } = useUrl(
    history,
  );
  const [ordemProducao, setOrdemProducao] = useState({});
  const [recebimentos, setRecebimentos] = useState([]);
  const [operacoes, setOperacoes] = useState([]);
  const [pacotes, setPacotes] = useState([]);
  const busca = pegarValorQuery(BUSCAR);
  const desktop = telaGrande();
  const mostrarAjuste = existeQuery(CADASTRAR, AJUSTE);
  const mostrarCadastro = existeQuery(CADASTRAR, ORDEM);
  const mostrarClonarOrdem = existeQuery(CLONAR, ORDEM);
  const mostrarOrdemPreco = existeQuery(ALTERAR, ORDEM_PRECO);
  const mostrarBalanceamento = existeQuery(EDITAR, BALANCEAMENTO);
  const mostrarCadastroRecebimento = existeQuery(CADASTRAR, RECEBIMENTO);

  const editarImagem = async ({ file }) => {
    const formData = new FormData();
    formData.append('foto', file);
    const { data } = await alterar(
      `/ordens-producao/${id}/foto`,
      formData,
      null,
      null,
      true,
    );
    if (data) {
      setOrdemProducao({ ...ordemProducao, ...data });
    }
  };

  const deletarImagem = async () => {
    const { data } = await deletar(
      `/ordens-producao/${id}/foto`,
      'Imagem removida com sucesso',
    );
    if (data) {
      setOrdemProducao({ ...ordemProducao, ...data });
    }
  };

  /* eslint-disable react/prop-types */
  const descritores = [
    {
      titulo: 'Cliente',
      itens: [
        {
          custom: ({ cliente }) => cliente?.nomeFantasia || 'Não Informado',
          icone: ({ cliente }) => cliente?.logo40Url,
          nome: ({ cliente }) => <h4>{cliente?.apelido || 'Nome Fantasia'}</h4>,
        },
        { nome: 'Número do Cliente', propriedade: 'numeroCliente' },
        {
          nome: 'Data Combinada',
          custom: ({ dataCombinadoCliente }) =>
            dataCombinadoCliente
              ? formatar('data', dataCombinadoCliente)
              : 'Não Informada',
        },
      ],
    },
    {
      titulo: 'Tempo',
      itens: [
        {
          nome: 'Tempo das Operações',
          custom: ({ tempoOperacoes }) =>
            `${formatar('n-decimal', tempoOperacoes ?? 0, 4)} min`,
        },
        {
          nome: 'Tempo de Ajuste',
          aoAlterar: () => adicionarQuery(CADASTRAR, AJUSTE),

          esconderAlteracao: ordemProducao?.dataQuitacao,
          custom: ({ percentualAjuste, tempoAjuste }) =>
            percentualAjuste
              ? `(${formatar('n-decimal', percentualAjuste, 2)}%) ${formatar(
                  'n-decimal',
                  tempoAjuste ?? 0,
                  4,
                )} min`
              : `${formatar('n-decimal', tempoAjuste ?? 0, 4)} min`,
        },
        {
          nome: 'Tempo Final',
          custom: ({ tempoFinal }) =>
            `${formatar('n-decimal', tempoFinal ?? 0, 4)} min`,
        },
      ],
    },
    {
      titulo: 'Valores',
      itens: [
        {
          nome: ({ precoInformado }) => (
            <h4>
              Preço{' '}
              <Tag color={precoInformado ? 'orange' : 'green'}>
                {precoInformado ? 'Informado' : 'Sugerido'}
              </Tag>
            </h4>
          ),
          custom: ({ preco }) =>
            preco ? formatar('moeda', preco) : 'Não informado',
          aoAlterar: () => adicionarQuery(ALTERAR, ORDEM_PRECO),
        },
        {
          nome: 'Quantidade',
          custom: ({ quantidade }) => {
            return formatar('n-decimal', quantidade ?? 0, 0);
          },
        },
        {
          nome: 'Valor Total',
          custom: ({ valorTotal }) => formatar('moeda', valorTotal ?? 0),
        },
      ],
    },
    {
      titulo: 'Foto do Produto',
      custom: () => (
        <UploadImagem
          deletarImagem={deletarImagem}
          editarImagem={editarImagem}
          imagem={{
            imagemUrl: ordemProducao?.fotoUrl,
            imagem40Url: ordemProducao?.foto40Url,
            imagem100Url: ordemProducao?.foto100Url,
          }}
        />
      ),
    },
  ];
  /* eslint-enable react/prop-types */
  useEffect(() => {
    const carregarRecebimentos = async () => {
      const { data } = await buscar(
        `/lancamentos/?$rel[conta]=codigo,nome,natureza&$ordem=data,uid&ordemProducaoUid[eq]=${ordemProducao?.uid}`,
      );
      setRecebimentos(data);
    };
    const carregarPacotesOrdemProducao = async () => {
      const { data } = await buscar(
        `/ordens-producao/${ordemProducao?.uid}/pacotes?${
          busca ? `&$buscar=${busca}` : ''
        }`,
      );
      setPacotes(data);
    };
    if (ordemProducao?.uid) {
      carregarRecebimentos();
      carregarPacotesOrdemProducao();
    }
  }, [buscar, ordemProducao.uid, busca]);

  const carregarDadosOrdemProducao = useCallback(async () => {
    const { data } = await buscar(
      `/ordens-producao/${id}/?$rel[cliente]=apelido,logo40,logo40Url,nomeFantasia`,
    );
    setOrdemProducao(data ?? {});
  }, [id, buscar]);

  const carregarOperacoesOrdemProducao = useCallback(async () => {
    const { data } = await buscar(
      `/ordens-producao/${id}/operacoes?$rel[maquina]=nome&$ordem=sequencia${
        busca ? `&$buscar=${busca}` : ''
      }`,
    );
    setOperacoes(data);
  }, [id, buscar, busca]);

  useEffect(() => {
    carregarDadosOrdemProducao();
    carregarOperacoesOrdemProducao();
  }, [carregarDadosOrdemProducao, carregarOperacoesOrdemProducao]);

  const redistribuirOperacoes = async () => {
    const { numero, nome } = ordemProducao ?? {};
    const { data } = await criar(
      `/ordens-producao/${id}/redistribuir-tempos`,
      {},
      `Operações da ordem ${numero} - ${nome} foram redistribuidas`,
    );
    if (data) {
      setOperacoes(data);
    }
  };

  const renderTitulo = () => {
    const {
      nome,
      numero,
      dataConclusao,
      dataInicio,
      dataQuitacao,
      referencia,
      valorRecebido,
      valorTotal,
    } = ordemProducao ?? {};

    return (
      <TituloContainer>
        <span>{`${numero ?? ''} - ${nome ?? ''}`}</span>
        <SubTitulo>{referencia}</SubTitulo>
        <SituacaoContainer>
          {dataInicio && (
            <Situacao cor="hsl(209 10% 45% / 0.6)">
              <UpCircleOutlined />
              <span>Iniciada em {formatar('data', dataInicio)}</span>
            </Situacao>
          )}
          {dataConclusao && (
            <Situacao cor="hsl(209 70% 45% / 0.6)">
              <CheckCircleOutlined />
              <span>Concluida em {formatar('data', dataConclusao)}</span>
            </Situacao>
          )}
          {dataQuitacao && (
            <Situacao cor="hsl(130 70% 40% / 0.6)">
              <DollarOutlined />
              <span>Recebida em {formatar('data', dataQuitacao)}</span>
            </Situacao>
          )}
          {!dataQuitacao && valorRecebido && (
            <Situacao cor="#fa8c16">
              <DollarOutlined />
              <span>
                Saldo de {formatar('moeda', valorTotal - valorRecebido)}
              </span>
            </Situacao>
          )}
        </SituacaoContainer>
      </TituloContainer>
    );
  };

  const imprimirTags = async body => {
    const { data } = await criar('/pacotes/imprimir-tags', body, null, {
      responseType: 'arraybuffer',
    });
    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      window.focus();
    }
  };

  const deletarOrdem = async () => {
    await deletar(
      `/ordens-producao/${id}`,
      `A ordem de produção ${ordemProducao?.numero} - ${ordemProducao?.nome} foi excluída`,
      () => history.goBack(),
    );
  };

  const renderAlert = () =>
    mostrarTutorial &&
    operacoes?.length > 0 && (
      <AlertaTutorial detalhes>
        <span>
          Sucesso! Você cadastrou uma nova ordem e suas operações. Você pode
          continuar cadastrando, ou{' '}
          <Link to="/">clicar aqui para voltar ao seu dashboard</Link> de boas
          vindas e ver as próximas etapas.
        </span>
      </AlertaTutorial>
    );

  const quitarOperacao = async () => {
    const { data } = await criar(`/ordens-producao/${id}/quitar`);

    if (data) {
      setOrdemProducao(data);
    }
  };
  return (
    <>
      {desktop && renderAlert()}
      <Header
        descricao
        classe="Ordem"
        onBack={() => history.goBack()}
        titulo={renderTitulo}
        extra={[
          <BotaoQuitarOrdem key="5" Onclick={quitarOperacao}>
            Quitar ordem Produção
          </BotaoQuitarOrdem>,
          <BotaoDefault
            disabled={loading}
            hidden={!ordemProducao?.tempoFinal}
            key="1"
            type="button"
            tamanho="pequeno"
            onClick={() => adicionarQuery(EDITAR, BALANCEAMENTO)}
          >
            Balanceamento
          </BotaoDefault>,
          <BotaoDefault
            disabled={loading}
            hidden={
              !ordemProducao?.tempoAjuste ||
              ordemProducao?.dataConclusao ||
              ordemProducao?.quantidadeApontadaCelula ||
              ordemProducao?.quantidadeApontada >= 0
            }
            key="2"
            type="button"
            tamanho="pequeno"
            onClick={redistribuirOperacoes}
          >
            Redistribuir Operações
          </BotaoDefault>,
          <BotaoDefault
            disabled={loading}
            hidden={operacoes?.length === 0}
            key="3"
            type="button"
            tamanho="pequeno"
            onClick={() => adicionarQuery(CLONAR, ORDEM)}
          >
            Clonar
          </BotaoDefault>,
          <BotaoDefault
            disabled={loading}
            key="4"
            onClick={() => adicionarQuery(CADASTRAR, RECEBIMENTO)}
            hidden={
              !(ordemProducao?.dataConclusao && !ordemProducao?.dataQuitacao)
            }
          >
            Receber
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            type="primary"
            key="5"
            onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        {!desktop && renderAlert()}
        <Descritor objeto={ordemProducao} propriedades={descritores} />
        {!desktop && (
          <BotaoExcluir onConfirm={deletarOrdem}>Excluir Ordem</BotaoExcluir>
        )}
      </Header>

      <DetalhesOrdemTab
        pacotes={pacotes}
        history={history}
        operacoes={operacoes}
        recebimentos={recebimentos}
        ordemProducao={ordemProducao}
        setRecebimentos={setRecebimentos}
        setOrdemProducao={setOrdemProducao}
        carregarOrdemProducao={carregarDadosOrdemProducao}
        carregarOperacoes={carregarOperacoesOrdemProducao}
        imprimirTags={imprimirTags}
        setOperacoes={setOperacoes}
        setPacotes={setPacotes}
      />

      <OrdemForm
        aoFechar={() => removerQuery(CADASTRAR, ORDEM)}
        mostrar={mostrarCadastro}
        ordem={ordemProducao}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            setOrdemProducao(novaOrdem);
            removerQuery(CADASTRAR, ORDEM);
          }
        }}
      />

      <BalanceamentoForm
        aoFechar={() => removerQuery(EDITAR, BALANCEAMENTO)}
        mostrar={mostrarBalanceamento}
        ordem={ordemProducao}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            setOrdemProducao(novaOrdem);
            removerQuery(EDITAR, BALANCEAMENTO);
          }
        }}
      />

      <ClonarOrdemForm
        aoFechar={() => removerQuery(CLONAR, ORDEM)}
        mostrar={mostrarClonarOrdem}
        ordem={ordemProducao}
        afterSubmit={ordem => {
          if (ordem) {
            removerQuery(CLONAR, ORDEM);
            history.push(`/ordens-producao/${ordem.uid}`);
          }
        }}
      />

      <CriarLancamentoForm
        aoFechar={() => removerQuery(CADASTRAR, RECEBIMENTO)}
        mostrar={mostrarCadastroRecebimento}
        ordem={ordemProducao}
        afterSubmit={recebimento => {
          if (recebimento) {
            const { ordem } = recebimento;
            setOrdemProducao(ordem);
            removerQuery(CADASTRAR, RECEBIMENTO);
            setRecebimentos([recebimento, ...recebimentos]);
          }
        }}
      />

      <TempoAjusteForm
        aoFechar={() => removerQuery(CADASTRAR, AJUSTE)}
        mostrar={mostrarAjuste}
        ordem={ordemProducao}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            setOrdemProducao(novaOrdem);
            removerQuery(CADASTRAR, AJUSTE);
          }
        }}
      />

      <AlterarPrecoForm
        aoFechar={() => removerQuery(ALTERAR, ORDEM_PRECO)}
        mostrar={mostrarOrdemPreco}
        ordem={ordemProducao}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            setOrdemProducao(novaOrdem);
            removerQuery(ALTERAR, ORDEM_PRECO);
          }
        }}
      />
    </>
  );
};

DetalhesOrdemProducao.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesOrdemProducao;

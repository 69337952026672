import React, { useEffect } from 'react';
import { Drawer, Form, Spin, Select } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, bool, func, objectOf } from 'prop-types';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { telaGrande } from '../../../../utils';
import { Input } from '../../commonStyles';

const { Option } = Select;
const valoresProdutividade = [100, 90, 80, 70];

const BalanceamentoForm = ({ mostrar, aoFechar, afterSubmit, ordem }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        quantidadeFuncionarios: ordem?.quantidadeFuncionarios,
        produtividadeBalanceamento: ordem?.produtividadeBalanceamento ?? 100,
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [form.resetFields, form.setFieldsValue, ordem]);

  const editarBalanceamento = async values => {
    const { data } = await alterar(
      `/ordens-producao/${ordem?.uid}`,
      {
        ...values,
        quantidadeFuncionarios: Number(values.quantidadeFuncionarios),
      },
      ordem,
      () =>
        `A ordem de produção ${ordem?.numero} - ${ordem?.nome} foi balanceada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title="Balanceamento"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, objectUid: ordem?.uid },
              null,
              editarBalanceamento,
              novaOrdem => {
                if (afterSubmit && novaOrdem) afterSubmit(novaOrdem);
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="quantidadeFuncionarios"
            label="Quantidade de Funcionários"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <Input
              type="number"
              placeholder="Digite a quantidade"
              maxLength={9}
            />
          </Campo>
          <Campo
            fluido
            name="produtividadeBalanceamento"
            label="Produtividade"
            obrigatorio
          >
            <Select placeholder="Selecione a produtividade" bordered showSearch>
              {valoresProdutividade.map(valor => (
                <Option key={valor} value={valor}>
                  {valor}%
                </Option>
              ))}
            </Select>
          </Campo>
          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

BalanceamentoForm.propTypes = {
  ordem: objectOf(any).isRequired,
  aoFechar: func,
  mostrar: bool,
  afterSubmit: func,
};

BalanceamentoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default BalanceamentoForm;

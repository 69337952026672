export const verificarUrl = (texto, search) => {
  let searchAntigo;
  if (search.indexOf(`&${texto}`) === -1) {
    if (search.indexOf(`${texto}&`) === -1) {
      searchAntigo = texto;
    } else {
      searchAntigo = `${texto}&`;
    }
  } else {
    searchAntigo = `&${texto}`;
  }
  return searchAntigo;
};

import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { ContaForm } from '../../../components/forms';
import { ContasTab } from '../../../components/tabs';
import {
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
} from '../../../components/layout';
import { useApi, useUrl } from '../../../hooks';
import { CONTA, CADASTRAR } from '../../../hooks/useUrl/acoes';
import { NaturezaConta } from '../../../utils/enum';
import { formatar, telaGrande } from '../../../utils';

const descritores = [
  {
    titulo: 'Dados da Conta',
    itens: [
      { nome: 'Natureza', custom: ({ natureza }) => NaturezaConta[natureza] },
      {
        nome: 'Histórico Padrão',
        propriedade: 'historicoPadrao',
      },
    ],
  },
  {
    titulo: 'Recorrência Mensal',
    naoTemSe: ({ diaPrevisto, valorPrevisto }) =>
      !diaPrevisto && !valorPrevisto,
    itens: [
      {
        nome: 'Valor Previsto',
        custom: ({ valorPrevisto }) =>
          formatar('moeda', Math.abs(valorPrevisto || 0)),
      },
      {
        nome: 'Dia Previsto',
        propriedade: 'diaPrevisto',
      },
    ],
  },
];

const DetalhesConta = ({ history, match }) => {
  const { id } = match.params;
  const { buscar, deletar } = useApi();
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const mostrarEdicao = existeQuery(CADASTRAR, CONTA);
  const [conta, setConta] = useState({});
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarDadosConta = async () => {
      const { data } = await buscar(`/contas/${id}`);
      setConta(data);
    };
    carregarDadosConta();
  }, [id, buscar]);

  const deletarConta = async () => {
    const { uid, codigo, nome } = conta;
    await deletar(
      `/contas/${uid}`,
      `A conta ${codigo} - ${nome} foi excluida`,
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        classe="Conta"
        onBack={() => history.goBack()}
        titulo={`${conta?.codigo ?? ''} ${conta?.nome ?? ''}`}
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, CONTA)}
            key="1"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        <Descritor objeto={conta} propriedades={descritores} />
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarConta}>Excluir Conta</BotaoExcluir>
        )}
      </Header>

      <ContasTab history={history} contaUid={id} />

      <ContaForm
        aoFechar={() => removerQuery(CADASTRAR, CONTA)}
        mostrar={mostrarEdicao}
        conta={conta}
        afterSubmit={novaConta => {
          if (novaConta) {
            setConta(novaConta);
            removerQuery(CADASTRAR, CONTA);
          }
        }}
      />
    </>
  );
};

DetalhesConta.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesConta;

import {
  SET_LOADING,
  SET_TOKEN,
  SET_TOKEN_VALIDO,
  SET_CONFIGURACOES,
  SET_COLAPSAR_MENU,
  SET_MOSTRAR_TUTORIAL,
} from './operacoes';

export const setLoading = valor => {
  return { type: SET_LOADING, valor };
};

export const setMostrarTutorial = valor => {
  return { type: SET_MOSTRAR_TUTORIAL, valor };
};

export const setColapsarMenu = valor => {
  return { type: SET_COLAPSAR_MENU, valor };
};

export const setToken = valor => {
  return { type: SET_TOKEN, valor };
};

export const setTokenValido = valor => {
  return { type: SET_TOKEN_VALIDO, valor };
};

export const setConfiguracoes = valor => {
  return { type: SET_CONFIGURACOES, valor };
};

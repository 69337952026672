import styled from 'styled-components';
import { Menu as AntMenu, Layout as AntLayout, Button } from 'antd';
import { Link as DomLink } from 'react-router-dom';
import logo from '../../assets/nova-logo.svg';
import logoMini from '../../assets/nova-logo-mini.svg';

const alterarlogo = colapsar => {
  return colapsar === 'false' ? logo : logoMini;
};

export const Hr = styled.hr`
  height: 1px;
  overflow: visible;
  width: ${({ colapsar }) => (colapsar === 'true' ? '50%' : '71%')};
  margin: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin: 1, 5em 0;
`;

export const Menu = styled(AntMenu)`
  display: block;
  height: fit-content;
  background: transparent !important;
  border: none;
  @media only screen and (max-device-width: 640px) {
    text-align: center;
    width: ${({ colapsar }) => (colapsar === 'true' ? '40px' : '100%')};
  }
`;
export const MenuContainer = styled.div`
  align-items: center;
  width: ${({ colapsar }) => (colapsar === 'true' ? '60px' : '230px')};
  transition: 0.3s linear;
  @media only screen and (min-device-width: 640px) {
    min-height: ${({ fluido }) => fluido && '90%'};
    display: flex;
    position: fixed;
  }
  @media only screen and (max-device-width: 640px) {
    width: ${({ colapsar }) => (colapsar === 'true' ? '0px' : '100%')};
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 13px;
  color: rgba(255, 255, 255, 0.65);
  outline: none;
  font-size: 20px;
  padding: 0px;
  width: fit-content !important;
  background: transparent !important;
  border: none;
  margin: 0px !important;
`;

export const Sider = styled(AntLayout.Sider)`
  background: ${({ cor }) => cor};
  min-height: ${({ esconder }) => (esconder ? '90vh' : '100vh')};
  display: ${({ esconder }) => esconder === 'true' && 'none'};
  @media only screen and (min-device-width: 640px) {
    ${({ colapsar }) =>
      colapsar === 'true'
        ? `
      flex: 0 0 60px !important;
      max-width: 60px !important;
      min-width: 60px !important;
      `
        : `
      flex: 0 0 230px !important;
      max-width: 230px !important;
      min-width: 230px !important;
      width: 230px !important;
      `}
  }
  @media only screen and (max-device-width: 640px) {
    ${({ colapsar }) =>
      colapsar === 'false'
        ? `
      flex: 0 0 100% !important;
      max-width: 100% !important;
      `
        : `
      flex: 0 0 0px !important;
      max-width: 0px !important;
      min-width: 0px !important;
      width: 0px !important;
  `}
  }
`;

export const HeaderMenu = styled(Menu)`
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: inherit;
  .ant-menu-item {
    padding-left: 0px !important;
  }
`;

export const LogoContainer = styled.div`
  height: ${({ colapsar }) => (colapsar === 'false' ? '90px' : '30px')};
  transition: margin 0.4s;
  background-image: url(${({ colapsar }) => alterarlogo(colapsar)});
  transition: background-image 0.2s;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${({ colapsar }) =>
    colapsar === 'false' ? '-19px 0px -8px 17%' : '15px'};

  @media only screen and (max-device-width: 640px) {
    margin: ${({ colapsar }) =>
      colapsar === 'false' ? '4px 0px 0px 34.5%' : '15px'};
    ${({ colapsar }) =>
      colapsar === 'true' &&
      `
      background-image: none;
      `}
  }
`;

export const Layout = styled(AntLayout)`
  height: max-content;
`;

export const Item = styled(Menu.Item)`
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  align-items: center;
  transition: 0.3s linear;
  ${({ compadding }) =>
    compadding === 'true' && `padding: 0px 0px 45px 0px !important;`}
  @media only screen and (min-device-width: 640px) {
    padding-left: ${({ colapsar }) => colapsar !== 'true' && `10px !important`};
  }
`;

export const SubMenu = styled(Menu.SubMenu)`
  text-align: left;
  ul {
    background-color: transparent !important;
  }
  ${Item} {
    @media only screen and (min-device-width: 640px) {
      padding-left: ${({ colapsar }) =>
        colapsar !== 'true' && `40px !important`};
    }
  }
  .ant-menu-submenu-title {
    font-size: 12px;
    height: 30px !important;
    @media only screen and (max-device-width: 640px) {
      padding-left: ${({ colapsar }) =>
        colapsar === 'true' && '12px !important'};
      display: ${({ colapsar }) => colapsar === 'true' && `none !important`};
      width: 40px;
    }
    span {
      font-weight: 100;
    }
  }
`;

export const FooterItem = styled(Item)`
  @media only screen and (max-device-width: 640px) {
    margin-top: 20px !important;
  }
  @media only screen and (min-device-width: 640px) {
    position: absolute !important;
    bottom: 1em;
  }
`;

export const MenuInterno = styled(Menu)`
  display: ${({ esconder }) => (esconder === 'false' ? 'none' : 'flex')};
  align-items: flex-end;
  @media only screen and (min-device-width: 640px) {
    display: ${({ esconder }) => (esconder === 'false' ? 'none' : 'block')};
    position: absolute;
    right: 0;
    background: #fff !important;
    margin-top: 15px !important;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
    min-width: 100px;
    ${Item} {
      padding: 0px 10px !important;
      color: #000;
    }
    ${Item}:hover {
      background: #f0f0f0 !important;
      color: #000;
    }
  }
`;

export const Header = styled(AntLayout.Header)`
  padding: 0px;
  background-color: #fff;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10;
`;

export const Link = styled(DomLink)`
  font-size: 12px;
`;

export const Avatar = styled(Menu.Item)`
  background-color: transparent !important;
  cursor: default;
  @media only screen and (max-device-width: 640px) {
    margin-top: 25px !important;
    height: 100% !important;
  }
  @media only screen and (min-device-width: 640px) {
    ${Link} {
      color: #000 !important;
    }
    position: absolute !important;
    right: 0;
    height: 64px;
  }
  div {
    display: flex;
    align-content: center;
    @media only screen and (max-device-width: 640px) {
      height: 40px;
      margin-top: 0px;
      justify-content: center;
      margin-bottom: 15px;
    }
  }

  ${Item} {
    margin: 0px;
    @media only screen and (max-device-width: 640px) {
      height: 40px !important;
      margin-left: 10px;
      height: 100% !important;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
`;

export const Content = styled(Layout.Content)`
  overflow: auto !important;
  width: auto;
  min-width: inherit;
  background: #eeecea;
`;

export default {
  titulo: 'Motivos da Parada',
  cabecalho: {
    desktop: [
      {
        titulo: 'Nome',
        atributo: 'nome',
        variavel: true,
        tag: {
          texto: ({ falta }) =>
            falta ? 'Considerado como Falta' : 'Não é Considerado Falta',
          color: ({ falta }) => (falta ? 'red' : 'blue'),
        },
      },
    ],
    mobile: [
      {
        titulo: 'Nome',
        variavel: true,
        agrupar: [
          {
            atributo: 'nome',
            tag: {
              texto: ({ falta }) =>
                falta ? 'Considerado como Falta' : 'Não é Considerado Falta',
              color: ({ falta }) => (falta ? 'red' : 'blue'),
            },
          },
        ],
      },
    ],
  },
};

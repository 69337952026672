import React from 'react';
import { Row } from 'antd';
import { any, objectOf, string } from 'prop-types';
import { TituloEstatistica, Statistic, StatisticContainer } from './styles';

const Estatistica = ({ titulo, configuracao, children }) => {
  return (
    <div>
      {titulo && <TituloEstatistica>{titulo}</TituloEstatistica>}
      <Row>
        {configuracao ? (
          Object.keys(configuracao).map((key, index) => (
            <StatisticContainer key={index}>
              <span className="ant-statistic-title">{key}</span>
              <Statistic cor={configuracao[key]?.color}>
                {configuracao[key]?.value}
              </Statistic>
            </StatisticContainer>
          ))
        ) : (
          <StatisticContainer>
            <Statistic>{children}</Statistic>
          </StatisticContainer>
        )}
      </Row>
    </div>
  );
};

Estatistica.propTypes = {
  titulo: string,
  configuracao: objectOf(any),
  children: objectOf(any),
};

Estatistica.defaultProps = {
  titulo: null,
  configuracao: null,
  children: null,
};

export default Estatistica;

import styled, { createGlobalStyle } from 'styled-components';
import { Tabs as AntTabs } from 'antd';
import colors from './colors';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif !important;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  body{
    overflow: overlay;
    &::-webkit-scrollbar{
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.secundaria};
    }   
  }

  //alterando estilos de todos os links da aplicação
  a{
    color: ${colors.primaria_fundo_claro}
  }
  a:hover{
    color: ${colors.primaria}
  }

  //alterando padrão de cores do select globalmente
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria_fundo_claro}
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: ${colors.primaria};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${colors.primaria};
  }

  //Alterando cor de fundo do switch-checked
    .ant-switch-checked{
    background-color: ${colors.primaria_fundo_claro};
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected 
  .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start 
  .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end 
  .ant-picker-cell-inner{
    color: #fff;
    background: ${colors.primaria_fundo_claro};
  }

  .ant-message{
    top: unset;
    bottom: 8px;
  }

  //Alterando cor do fundo do menu lateral
  .ant-layout-sider {
    background-color: ${colors.secundaria};
  }

  //Alterando cor do texto do menu superior
  .ant-menu-horizontal > .ant-menu-item-selected a{
    color: ${colors.primaria_fundo_claro}
  }

  .ant-menu-horizontal > .ant-menu-item-selected a:hover{
    color: ${colors.primaria_fundo_claro}
  }

  .ant-menu-horizontal > .ant-menu-item-active a:hover{
    color: ${colors.primaria_fundo_claro}
  }
  

  //Alterando cor da borda do menu superior
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: ${colors.primaria_fundo_claro};
    border-bottom: 2px solid ${colors.primaria};
  }


  //alterando cor dos menus laterais esquerdo com o hover
  .ant-menu-dark .ant-menu-item, 
  .ant-menu-dark .ant-menu-item-group-title, 
  .ant-menu-dark .ant-menu-item > a, 
  .ant-menu-dark .ant-menu-item > span > a{

    &:hover{
      color: ${colors.primaria};
    }
  }

  //Alterando classes do componente dataPicker
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${colors.primaria};
  }

  .ant-picker-focused{
    box-shadow: 0 0 0 3px ${colors.primaria};
  }

  .ant-picker-today-btn{
    color: ${colors.primaria}
  }
`;

export const SummaryCell = styled.td`
  @media only screen and (min-device-width: 640px) {
    text-align: ${({ align }) => align ?? 'right'};
  }
  font-weight: 500;
  background: rgb(245, 245, 245);
  min-width: 120px;
`;

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav-wrap {
    background: ${({ transparente }) =>
      transparente !== 'true' ? '#fcfbfa' : 'transparent'};
    padding: ${({ tirarmargem }) => (!tirarmargem ? '0 1.5em;' : '0px')};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.primaria_fundo_claro};
  }
  .ant-tabs-tab:hover {
    color: ${colors.primaria_fundo_claro};
  }

  .ant-tabs-ink-bar {
    background-color: ${colors.primaria_fundo_claro};
  }

  ${({ tirarmargem }) => {
    return !tirarmargem
      ? `
    margin-bottom: 16px;
    .ant-tabs-content-holder {
      padding: 0 1.5em;
    }
    `
      : `
    margin-bottom: 0px;
    .ant-tabs-content-holder {
      padding: 0px;
    }
  `;
  }}
`;

export const Icone = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width ?? '40px'};
  background-size: ${({ width }) => width ?? '40px'};
  height: ${({ height }) => height ?? '40px'};
  font-size: 14pt;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f5f3f1'};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ absoluto }) =>
    absoluto &&
    `
  position: absolute;
  margin-top: 4px;
`};
  ${({ imagem }) =>
    imagem &&
    `
    background-image: url(${imagem});
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

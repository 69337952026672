import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Tag } from 'antd';
import { objectOf, any } from 'prop-types';
import { formatar, telaGrande } from '../../../utils';
import { useApi, useUrl } from '../../../hooks';
import { Link } from '../../styles';
import { PontoFuncionarioForm } from '../../../components/forms';
import { DetalhesPontoTab } from '../../../components/tabs';
import { BUSCAR, CADASTRAR, PONTO } from '../../../hooks/useUrl/acoes';
import {
  BotaoConfirmar,
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
} from '../../../components/layout';
import {
  TempoApontado,
  Eficiencia,
  SubTituloContainer,
  TituloContainer,
} from './styles';

const { PontoForm } = PontoFuncionarioForm;

/* eslint-disable react/prop-types */
const descritores = [
  {
    titulo: 'Tempos',
    itens: [
      {
        nome: 'Minutos Trabalhados',
        custom: ({ trabalhado, previsto }) =>
          `${trabalhado} de ${previsto} previstos`,
      },
      {
        nome: 'Paradas',
        custom: ({ paradaApontada }) =>
          paradaApontada && paradaApontada !== 0
            ? `${paradaApontada} minuto(s)`
            : 'Não Informado',
      },
      {
        nome: 'Faltas',
        custom: ({ falta }) =>
          falta && falta !== 0 ? `${falta} minuto(s)` : 'Não Informado',
      },
    ],
  },
  {
    titulo: 'Primeiro Horário',
    itens: [
      { nome: 'Entrada', propriedade: 'entrada1' },
      { nome: 'Saída', propriedade: 'saida1' },
    ],
  },
  {
    titulo: 'Segundo Horário',
    naoTemSe: ({ saida2, entrada2 }) => !saida2 && !entrada2,
    itens: [
      {
        nome: 'Entrada',
        propriedade: 'entrada2',
      },
      {
        nome: 'Saída',
        propriedade: 'saida2',
      },
    ],
  },
  {
    titulo: 'Terceiro Horário',
    naoTemSe: ({ saida3, entrada3 }) => !saida3 && !entrada3,
    itens: [
      {
        nome: 'Entrada',
        propriedade: 'entrada3',
      },
      {
        nome: 'Saída',
        propriedade: 'saida3',
      },
    ],
  },
];
/* eslint-enable react/prop-types */

const PontosFuncionarios = ({ history, match }) => {
  const { id } = match.params;
  const { buscar, criar, deletar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const [apontamentos, setApontamentos] = useState([]);
  const [pontoFuncionario, setPontoFuncionario] = useState({});
  const mostrarCadastro = existeQuery(CADASTRAR, PONTO);
  const busca = pegarValorQuery(BUSCAR);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarPontoFuncionario = async () => {
      const { data } = await buscar(
        `/registros-ponto/${id}/?$rel[celula]=nome&$rel[funcionario]=nomeCompleto`,
      );
      setPontoFuncionario(data);
    };

    carregarPontoFuncionario();
  }, [id, busca, buscar]);

  useEffect(() => {
    const carregarApontamentos = async () => {
      const { data } = await buscar(
        `/apontamentos?data[eq]=${pontoFuncionario.data}&funcionarioUid[eq]=${pontoFuncionario.funcionarioUid}&$rel[funcionario]=nomeCompleto&$rel[operacao]=nome&$rel[operacao>ordemProducao]=nome,numero`,
      );
      setApontamentos(data);
    };
    if (pontoFuncionario.uid) {
      carregarApontamentos();
    }
  }, [pontoFuncionario, buscar]);

  const faltarPonto = async () => {
    const { uid, data: dia, funcionario } = pontoFuncionario;
    const { data } = await criar(
      `/registros-ponto/${uid}/faltar`,
      {},
      `O ponto do dia ${formatar('data', dia)} de ${
        funcionario?.nomeCompleto
      } foi lançado como falta`,
    );
    if (data) {
      setPontoFuncionario(data);
    }
  };

  const deletarPonto = async () => {
    const { uid, data, funcionario } = pontoFuncionario;
    await deletar(
      `/registros-ponto/${uid}`,
      `O ponto do dia ${formatar('data', data)} de ${
        funcionario?.nomeCompleto
      } foi excluído`,
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        classe="Ponto de"
        onBack={() => history.goBack()}
        titulo={() => (
          <TituloContainer>
            <Link to={`/funcionarios/${pontoFuncionario?.funcionarioUid}`}>
              {pontoFuncionario?.funcionario?.nomeCompleto}
            </Link>
            {` em ${formatar('data', pontoFuncionario?.data)}`}
          </TituloContainer>
        )}
        subTitulo={() => (
          <SubTituloContainer>
            <Link to={`/celulas/${pontoFuncionario?.celulaUid}`}>
              {pontoFuncionario?.celula?.nome}
            </Link>
            {pontoFuncionario?.previsto === pontoFuncionario?.falta && (
              <Tag color="red">Falta Integral</Tag>
            )}
            {pontoFuncionario?.tempoApontado && (
              <TempoApontado>
                {formatar('n-decimal', pontoFuncionario?.tempoApontado, 2)}{' '}
                Produzidos{' '}
              </TempoApontado>
            )}
            {pontoFuncionario?.eficiencia && (
              <Eficiencia>
                ({formatar('n-decimal', pontoFuncionario?.eficiencia, 2)}
                {'% '}
                Eficiência)
              </Eficiencia>
            )}
          </SubTituloContainer>
        )}
        extra={[
          <BotaoConfirmar
            titulo="Lançar como falta integral?"
            textoCancelar="Cancelar"
            textoOk="Lançar"
            disabled={loading}
            onConfirmar={faltarPonto}
            key="1"
            hidden={
              pontoFuncionario?.previsto === pontoFuncionario?.falta ||
              apontamentos?.length > 0
            }
          >
            Falta Integral
          </BotaoConfirmar>,
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, PONTO)}
            key="2"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        <Descritor objeto={pontoFuncionario} propriedades={descritores} />
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarPonto}>Excluir Ponto</BotaoExcluir>
        )}
      </Header>

      <DetalhesPontoTab
        history={history}
        setPontoFuncionario={setPontoFuncionario}
        pontoFuncionario={pontoFuncionario}
        setApontamentos={setApontamentos}
        apontamentos={apontamentos}
      />

      <PontoForm
        aoFechar={() => removerQuery(CADASTRAR, PONTO)}
        mostrar={mostrarCadastro}
        ponto={pontoFuncionario}
        afterSubmit={ponto => {
          if (ponto) {
            setPontoFuncionario(ponto);
            removerQuery(CADASTRAR, PONTO);
          }
        }}
      />
    </>
  );
};

PontosFuncionarios.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default PontosFuncionarios;

export const verificarBotoes = ({ value, name }, configuracoes, setState) => {
  setState(oldState =>
    value
      ? {
          ...oldState,
          [name]:
            Number(value) !== configuracoes?.[name] &&
            !(value === '' && !configuracoes?.[name]),
        }
      : {
          ...oldState,
          [name]: configuracoes?.[name] !== null,
        },
  );
};

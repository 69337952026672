import React, { useState, useEffect, useCallback } from 'react';
import { objectOf, any } from 'prop-types';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { LancamentoForm } from '../../components/forms';
import Tabela from './tabela';
import { ContasTab } from '../../components/tabs';
import { useApi, useUrl } from '../../hooks';
import { TabelaMesesAno } from '../../components/layout/Tabela/cabecalhos';
import { CADASTRAR, LANCAMENTO } from '../../hooks/useUrl/acoes';
import { NaturezaConta } from '../../utils/enum';
import { Container } from '../styles';
import { SALDO_MENSAL_CONTA } from '../../components/layout/Tabela/nomesCabecalhos';
import { BotaoPrimario, Header } from '../../components/layout';
import { telaGrande } from '../../utils';
import {
  Modal,
  TabContainer,
  ModalButton,
  TabelaContainer,
  DatePicker,
  Select,
} from './styles';

const colunasDRE = [
  {
    negrito: true,
    colSpan: 2,
    titulo: '',
    variavel: true,
    align: 'left',
    atributo: 0,
  },
  ...TabelaMesesAno.cabecalho.desktop,
];

const periodos = TabelaMesesAno.cabecalho.desktop.map(({ titulo }, index) => ({
  titulo,
  index,
}));

const ordenacao = ['R', 'V', 'C', 'F', 'I', 'D', 'O'].map(
  letra => NaturezaConta[letra],
);

const { Option } = Select;

const { CriarLancamentoForm } = LancamentoForm;
const DRE = ({ history }) => {
  const { pathname } = history.location;
  const { buscar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const [contaUid, setContaUid] = useState(null);
  const [dadosDre, setDadosDre] = useState([]);
  const [calculoMensal, setCalculoMensal] = useState([]);
  const [modalVisivel, setModalVisivel] = useState(false);
  const [tipoLancamento, setTipoLancamento] = useState('entrada');
  const [ano, setAno] = useState(moment().format('YYYY'));
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const mostrarCadastro = existeQuery(CADASTRAR, LANCAMENTO);
  const [mesSelecionado, setMesSelecionado] = useState(
    telaGrande() ? null : moment().month() + 1,
  );
  const getValorMes = nome => {
    switch (nome) {
      case 'Janeiro':
        return '01';
      case 'Fevereiro':
        return '02';
      case 'Março':
        return '03';
      case 'Abril':
        return '04';
      case 'Maio':
        return '05';
      case 'Junho':
        return '06';
      case 'Julho':
        return '07';
      case 'Agosto':
        return '08';
      case 'Setembro':
        return '09';
      case 'Outubro':
        return '10';
      case 'Novembro':
        return '11';
      case 'Dezembro':
        return '12';
      default:
        return null;
    }
  };

  const carregarDadosDRE = useCallback(async () => {
    const { data } = await buscar(`/dre/${ano}`);
    setDadosDre(
      data?.map(({ conta, ...resto }) => {
        conta.natureza = NaturezaConta[conta.natureza];
        return { ...resto, conta };
      }),
    );
  }, [buscar, ano]);

  useEffect(() => {
    const calcularTotalMes = () => {
      const total = { 0: 'Total' };
      const resultadoFinal = { 0: 'Resultado' };
      let somaResultado = 0;
      for (let index = 1; index <= 12; index++) {
        const valorMes = dadosDre?.map(({ meses }) => meses[index]);
        const totalMes = valorMes?.reduce((soma, valor) => {
          if (valor) {
            const resultado = soma + Number(valor);
            return resultado;
          }
          return soma;
        }, 0);
        total[index] = totalMes !== 0 ? totalMes : undefined;
        if (total[index]) {
          somaResultado = total[index] + somaResultado;
        }
        resultadoFinal[index] = somaResultado;
      }

      setCalculoMensal([total, resultadoFinal]);
    };
    calcularTotalMes();
  }, [dadosDre]);

  useEffect(() => {
    carregarDadosDRE();
  }, [carregarDadosDRE]);

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        titulo="Demonstrativo de Resultado do Exercício"
        extra={[
          <BotaoPrimario
            disabled={loading}
            key="1"
            type="primary"
            htmlType="button"
            danger
            onClick={() => {
              setTipoLancamento('saida');
              adicionarQuery(CADASTRAR, LANCAMENTO);
            }}
          >
            Nova saída
          </BotaoPrimario>,
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => {
              setTipoLancamento('entrada');
              adicionarQuery(CADASTRAR, LANCAMENTO);
            }}
          >
            Nova entrada
          </BotaoPrimario>,
          <DatePicker
            disabled={loading}
            key="3"
            onChange={(date, anoSelecionado) => {
              setAno(anoSelecionado);
              history.replace(`${pathname}?ano=${anoSelecionado}`);
            }}
            picker="year"
            placeholder="Selecione o ano"
            defaultValue={moment()}
            allowClear={false}
          />,

          !telaGrande() && (
            <Select
              placeholder="Selecione um Mês"
              onSelect={value => setMesSelecionado(Number(value) + 1)}
            >
              {periodos?.map(({ titulo, index }) => (
                <Option key={index} value={index}>
                  {titulo}
                </Option>
              ))}
            </Select>
          ),
        ]}
      />
      <TabelaContainer>
        <Tabela
          mesSelecionado={mesSelecionado}
          ordenarPor="natureza"
          ordenacao={ordenacao}
          loading={loading}
          dados={dadosDre}
          cabecalho={SALDO_MENSAL_CONTA}
          footer={{
            dados: calculoMensal,
            cabecalho: telaGrande()
              ? colunasDRE
              : colunasDRE.filter(
                  ({ atributo }) => !atributo || atributo === mesSelecionado,
                ),
          }}
          celulaSelecionada={(texto, { conta }, titulo) => {
            history.replace(
              `${pathname}?mes=${getValorMes(titulo)}&ano=${ano}`,
            );
            setContaUid(conta.uid);
            setModalVisivel(true);
          }}
        />
      </TabelaContainer>

      <Modal
        visible={modalVisivel}
        afterClose={() => {
          adicionarQuery('ano', ano);
        }}
        onCancel={() => setModalVisivel(false)}
        footer={null}
        centered
      >
        <TabContainer>
          <ContasTab
            history={history}
            contaUid={contaUid}
            callback={carregarDadosDRE}
          />
          <ModalButton onClick={() => setModalVisivel(false)} type="primary">
            Fechar
          </ModalButton>
        </TabContainer>
      </Modal>

      <CriarLancamentoForm
        aoFechar={() => removerQuery(CADASTRAR, LANCAMENTO)}
        mostrar={mostrarCadastro}
        tipoLancamento={tipoLancamento}
        afterSubmit={() => {
          carregarDadosDRE();
          removerQuery(CADASTRAR, LANCAMENTO);
        }}
      />
    </Container>
  );
};

DRE.propTypes = {
  history: objectOf(any).isRequired,
};

export default DRE;

import TempoConfig from './TempoConfig';
import ValorConfig from './ValorConfig';
import OrdemConfig from './OrdemConfig';
import PremioConfig from './PremioConfig';
import ApontamentoConfig from './ApontamentoConfig';

export default {
  TempoConfig,
  ApontamentoConfig,
  ValorConfig,
  OrdemConfig,
  PremioConfig,
};

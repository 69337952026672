import styled from 'styled-components';

export const Eficiencia = styled.span`
  color: rgb(197, 168, 22);
  font-size: 20px;
`;

export const TempoApontado = styled.span`
  color: rgb(18, 161, 42);
  margin: 0px 10px;
  font-size: 20px;
`;

export const TituloContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    display: grid;
  }
`;

export const SubTituloContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    display: grid;
    text-align: center;
    ${TempoApontado} {
      margin: 0px;
    }
  }
`;

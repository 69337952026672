import moment from 'moment';
import { transform, isEqual, isObject, isArray } from 'lodash';
import { MesesAno } from './enum';

export const formatar = (tipo, valor, enumerador) => {
  switch (tipo) {
    case 'data':
      return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY');
    case 'mes-dia':
      const data = moment(valor);
      const mes = MesesAno[data.month() + 1];
      const dia = data.date() < 10 ? `0${data.date()}` : data.date();
      return `${dia}/${mes.substr(0, 3)}`;
    case 'mes-ano':
      return `${MesesAno[moment(valor).month() + 1]}/${moment(valor).year()}`;
    case 'mes':
      return MesesAno[moment(valor).month() + 1];
    case 'dia':
      const dataValor = moment(valor);
      return dataValor.date() < 10
        ? `0${dataValor.date()}`
        : String(dataValor.date());
    case 'moeda':
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    case 'moeda-compacta':
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
        .format(valor)
        .replace('R$', '');
    case 'decimal':
      return Math.round(Number(Number(valor) + Number.EPSILON) * 100) / 100;
    case 'n-decimal':
      let decimal;
      if (!enumerador || enumerador === 0) {
        decimal = String(valor).replace('.', ',');
        const index = decimal.lastIndexOf(',');
        return decimal
          .slice(0, index >= 0 ? index : decimal.length)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      }
      decimal = valor.toFixed(enumerador).replace('.', ',');
      const index = decimal.lastIndexOf(',');
      const quebrado = decimal.slice(index, decimal.length);
      decimal =
        decimal.slice(0, index).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +
        quebrado;
      if (Number(quebrado.slice(1, quebrado.length)) === 0) {
        return decimal.substr(0, decimal.lastIndexOf(',')).trim();
      }
      return decimal;
    case 'inteiro':
      return Math.round(Number(valor));
    case 'valor-numerico':
      return Math.round(
        Number(formatar('moeda-compacta', valor).replace(',', '.')),
      );
    case 'minutos':
      return `${
        Math.round(Number(Number(valor) + Number.EPSILON) * 100) / 100
      } min`;
    case 'enum':
      return enumerador?.[valor];
    case 'minutosCheio':
      return `${String(valor).replaceAll('.', ',')} min`;
    default:
      return valor;
  }
};

export const formatarData = (data, formato) => {
  if (data && formato) {
    return data.format(formato);
  }
  return undefined;
};

export const telaGrande = () => window.screen.width >= 640;

export const dataNoIntervalo = (objeto, key, data) => {
  const comparador = (inicio, final) =>
    moment(objeto[key]).isBetween(inicio, final) ||
    (moment(objeto[key]).isSame(final) && moment(objeto[key]).isSame(inicio));

  if (data) {
    if (isArray(data)) {
      const [dataFinal, dataInicio] = data;
      return comparador(dataFinal, dataInicio);
    }
    const { dataFinal, dataInicio } = data;
    return comparador(dataFinal, dataInicio);
  }
  return false;
};

export const removerValorFalso = obj => {
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getDiferencaObjeto = (objetoNovo, objetoAntigo) => {
  let arrayIndexCounter = 0;
  return transform(objetoNovo, (result, value, key) => {
    if (!isEqual(value, objetoAntigo?.[key])) {
      const resultKey = isArray(objetoAntigo) ? arrayIndexCounter++ : key;
      result[resultKey] =
        isObject(value) && isObject(objetoAntigo?.[key])
          ? getDiferencaObjeto(value, objetoAntigo?.[key])
          : value;
    }
  });
};

import moment from 'moment';

const fundo = ({ dataFimExperiencia1, dataFimExperiencia2 }) => {
  const inicioSemana = moment().startOf('week');
  const fimSemana = moment().endOf('week');
  return moment(dataFimExperiencia1).isBetween(inicioSemana, fimSemana) ||
    moment(dataFimExperiencia2).isBetween(inicioSemana, fimSemana)
    ? '#fff1f0'
    : null;
};

export default {
  titulo: 'Funcionários em Experiência',
  cabecalho: {
    desktop: [
      {
        variavel: true,
        titulo: 'Nome',
        fundo,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
          },
          {
            atributo: 'cpf',
          },
        ],
      },
      {
        titulo: 'Fim da 1ª Experiência',
        atributo: 'dataFimExperiencia1',
        align: 'center',
        tipo: 'data',
        fundo,
      },
      {
        titulo: 'Fim da 2ª Experiência',
        atributo: 'dataFimExperiencia2',
        align: 'center',
        tipo: 'data',
        fundo,
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Funcionário',
        fundo,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
          },
          {
            atributo: 'cpf',
          },
          {
            tipo: 'data',
            custom: ({ dataFimExperiencia1 }, format) =>
              `1ª Exp: ${format(dataFimExperiencia1)}`,
          },
          {
            tipo: 'data',
            custom: ({ dataFimExperiencia2 }, format) =>
              `2ª Exp: ${format(dataFimExperiencia2)}`,
          },
        ],
      },
    ],
  },
};

import { Button, Popconfirm } from 'antd';
import { func, elementType, string } from 'prop-types';
import React from 'react';

const BotaoConfirmar = ({
  children,
  titulo,
  textoOk,
  textoCancelar,
  onConfirmar,
  ...props
}) => {
  return (
    <Popconfirm
      title={titulo}
      onConfirm={onConfirmar}
      okText={textoOk}
      cancelText={textoCancelar}
    >
      <Button {...props}>{children}</Button>
    </Popconfirm>
  );
};

BotaoConfirmar.propTypes = {
  onConfirmar: func,
  children: elementType,
  titulo: string,
  textoOk: string,
  textoCancelar: string,
};

BotaoConfirmar.defaultProps = {
  onConfirmar: null,
  children: null,
  titulo: 'Confirmar Ação',
  textoOk: 'Sim',
  textoCancelar: 'Não',
};

export default BotaoConfirmar;

import styled from 'styled-components';

import { Select as AntSelect } from 'antd';

export const TituloEstatistica = styled.span`
  font-weight: 600;
`;

export const Select = styled(AntSelect)`
  height: 32px;
  width: 100%;
`;

export const SelectContainer = styled.div`
  margin-top: 10px;
`;

export const OptionsContainer = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: flex;
    justify-content: right;
    ${SelectContainer}:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const DataContainer = styled.div`
  margin: 20px;
  @media only screen and (min-device-width: 640px) {
    display: flex;
    justify-content: space-between;
    ${Select} ${SelectContainer} {
      width: 120px;
      margin-top: 0px;
    }
  }
`;

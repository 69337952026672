import React from 'react';
import { objectOf, any } from 'prop-types';
import { useUrl } from '../../hooks';
import { Container } from '../styles';
import { ApontamentosTab } from '../../components/tabs';
import { BotaoPrimario, Header } from '../../components/layout';
import {
  APONTAMENTO,
  CADASTRAR,
  FUNCIONARIO,
  TAB,
} from '../../hooks/useUrl/acoes';

const Apontamentos = ({ history }) => {
  const { adicionarQuery, pegarValorQuery } = useUrl(history);
  const tabAtiva = pegarValorQuery(TAB) ?? FUNCIONARIO;
  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          className="site-page-header"
          titulo={`Apontamentos por ${
            tabAtiva === FUNCIONARIO ? 'Funcionário' : 'Célula'
          }`}
          extra={[
            <BotaoPrimario
              key="1"
              type="primary"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, APONTAMENTO)}
            >
              Novo Apontamento
            </BotaoPrimario>,
          ]}
        />
      </Container>
      <ApontamentosTab history={history} />
    </>
  );
};

Apontamentos.propTypes = {
  history: objectOf(any).isRequired,
};

export default Apontamentos;

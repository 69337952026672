export default {
  titulo: 'Pontos do funcionário',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'center',
      },
      {
        titulo: 'Previsto',
        atributo: 'previsto',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
      },
      {
        titulo: 'Faltas',
        custom: ({ falta }, formatar) => (falta !== 0 ? formatar(falta) : null),
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        atributo: 'falta',
      },
      {
        titulo: 'Presente',
        atributo: 'presenca',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
      },
      {
        titulo: 'Paradas',
        custom: ({ paradaApontada }, formatar) =>
          paradaApontada !== 0 ? formatar(paradaApontada) : null,
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        atributo: 'paradaApontada',
      },
      {
        titulo: 'Trabalhado',
        atributo: 'trabalhado',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        fundo: 'rgb(237, 246, 255)',
        cor: 'rgb(24, 144, 255)',
      },
      {
        titulo: 'Produzido',
        atributo: 'tempoApontado',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        fundo: 'rgb(246, 255, 237)',
        cor: 'rgb(18, 161, 42)',
      },
      {
        titulo: 'Eficiência',
        atributo: 'eficiencia',
        custom: ({ eficiencia }, formatar) =>
          eficiencia ? `${formatar(eficiencia)}%` : '',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        fundo: 'rgb(254, 255, 230)',
        cor: 'rgb(197, 168, 22)',
      },
    ].map(props => ({ ...props, width: '12.5%' })),
    mobile: [
      {
        variavel: true,
        titulo: 'Nome',
        agrupar: [
          { atributo: 'data', tipo: 'data' },
          {
            custom: ({ trabalhado }, formatar) =>
              trabalhado !== 0 ? `Trabalhado: ${formatar(trabalhado)}` : null,
            tipo: 'n-decimal',
            enumerador: 0,
          },
          {
            custom: ({ paradaApontada }, formatar) =>
              paradaApontada && paradaApontada !== 0
                ? `Paradas: ${formatar(paradaApontada)}`
                : null,
            tipo: 'n-decimal',
            enumerador: 0,
          },
          {
            custom: ({ tempoApontado }, formatar) =>
              tempoApontado && tempoApontado !== 0
                ? `Produzido: ${formatar(tempoApontado)}`
                : null,
            tipo: 'n-decimal',
            enumerador: 0,
          },
          {
            custom: ({ eficiencia }, formatar) =>
              eficiencia && eficiencia !== 0
                ? `Eficiência: ${formatar(eficiencia)}%`
                : null,
            tipo: 'n-decimal',
            enumerador: 0,
          },
        ],
      },
    ],
  },
};

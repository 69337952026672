import React, { useState, useEffect } from 'react';
import { Form, Spin, message, Drawer } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
// import MaskedInput from 'antd-mask-input';
import { func, objectOf, any, bool } from 'prop-types';
import { onFinish, lerObjectProp } from '../commonFunctions';
import {
  Grupo,
  CloseButtonContainer,
  Input,
  MaskedInputs as MaskedInput,
  TextArea,
} from '../commonStyles';
import { Sessao, Campo, BotaoPrimario } from '../../layout';
import { useApi } from '../../../hooks';
import { setLoading } from '../../../store/modules/sistema/actions';
import { telaGrande } from '../../../utils';

const regexRetirarCaracteresNaoNumericos = /[^\d]+/g;

const ClienteForm = ({ afterSubmit, cliente, aoVoltar, mostrar, aoFechar }) => {
  const { criar, alterar, buscar } = useApi();
  const [cnpj, setCnpj] = useState(null);
  const [cep, setCep] = useState(null);
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(cliente, form.setFieldsValue, form.resetFields);
  }, [cliente, form.resetFields, form.setFieldsValue]);

  useEffect(() => {
    const buscarCnpj = async () => {
      const cnpjFormatado = cnpj?.replace(
        regexRetirarCaracteresNaoNumericos,
        '',
      );
      if (cnpjFormatado?.length >= 14) {
        const { data } = await buscar(`/cnpjs/${cnpjFormatado}`);
        const { message: mensagem } = data;
        if (mensagem) {
          return message.error(mensagem);
        }
        const {
          telefone,
          email,
          nome,
          fantasia,
          cep: retornoCep,
          complemento: enderecoComplemento,
          numero: enderecoNumero,
        } = data;
        const enderecoCep = retornoCep.replace(
          regexRetirarCaracteresNaoNumericos,
          '',
        );
        form.setFieldsValue({
          telefone,
          email,
          razaoSocial: nome,
          nomeFantasia: fantasia || nome,
          enderecoCep,
          enderecoComplemento,
          enderecoNumero,
        });
        setCep(enderecoCep);
      }
      return null;
    };
    const timer = setTimeout(() => {
      buscarCnpj();
    }, 500);

    return () => clearTimeout(timer);
  }, [cnpj, form, buscar]);

  useEffect(() => {
    const buscarCep = async () => {
      const cepFormatado = cep?.replace(regexRetirarCaracteresNaoNumericos, '');
      if (cepFormatado?.length >= 8) {
        const { data } = await buscar(`/ceps/${cepFormatado}`);
        const { logradouro, bairro, localidade } = data;
        if (data?.logradouro) {
          form.setFieldsValue({
            enderecoLogradouro: logradouro,
          });
        }
        if (data?.bairro) {
          form.setFieldsValue({
            enderecoBairro: bairro,
          });
        }
        if (data?.localidade) {
          form.setFieldsValue({
            municipio: localidade,
          });
        }
      }
    };
    const timer = setTimeout(() => {
      buscarCep();
    }, 500);
    return () => clearTimeout(timer);
  }, [cep, form, buscar]);

  const cadastrarCliente = async values => {
    const { data } = await criar(
      '/clientes',
      values,
      `O cliente ${values?.nomeFantasia} foi criado`,
    );
    return data;
  };

  const editarCliente = async (values, id) => {
    setLoading(true);
    const { data } = await alterar(
      `/clientes/${id}`,
      values,
      cliente,
      `O cliente ${values?.nomeFantasia} foi alterado`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      closable={!aoVoltar}
      width={telaGrande() ? 420 : '100%'}
      title={cliente ? 'Alterar Cliente' : 'Novo Cliente'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        {aoVoltar && (
          <CloseButtonContainer>
            <button onClick={aoVoltar} type="button">
              Voltar
            </button>
          </CloseButtonContainer>
        )}
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              {
                ...values,
                objectUid: cliente?.uid,
              },
              cadastrarCliente,
              editarCliente,
              novoCliente => {
                if (afterSubmit && novoCliente) afterSubmit(novoCliente);
                if (aoVoltar && novoCliente) aoVoltar();
              },
              form.resetFields,
            );
          }}
        >
          <Sessao titulo="Dados de Identificação">
            <Grupo>
              <Campo
                fluido
                name="apelido"
                label="Apelido"
                obrigatorio
                comPopover
                tituloPopover="Apelido do Cliente"
                textoPopover="Informe aqui como o cliente é conhecido dentro da empresa. Mais tarde você poderá encontrar o cadastro do cliente procurando pelo apelido."
                mostrarFoco={
                  mostrar &&
                  !form.getFieldValue('cnpj') &&
                  !form.getFieldValue('cep')
                }
              >
                <Input
                  type="text"
                  placeholder="Digite um apelido"
                  maxLength={30}
                />
              </Campo>

              <Campo
                fluido
                name="cnpj"
                label="CNPJ"
                obrigatorio
                comPopover
                tituloPopover="CNPJ"
                textoPopover="Informe o CNPJ e o sistema fará a consulta dos dados automaticamente."
              >
                <MaskedInput
                  mask="11.111.111/1111-11"
                  placeholder="Digite um CNPJ"
                  onChange={({ target }) => {
                    setCnpj(target.value);
                  }}
                />
              </Campo>
            </Grupo>
            <Campo
              fluido
              name="razaoSocial"
              label="Razão Social"
              obrigatorio
              comPopover
              tituloPopover="Razão Social"
              textoPopover="Informe a Razão Social, e os outros campos abaixo do cadastro do cliente. Ao concluir, clique no botão abaixo Salvar."
            >
              <Input
                type="text"
                placeholder="Digite a razão social"
                maxLength={100}
              />
            </Campo>
            <Campo fluido name="nomeFantasia" label="Nome Fantasia" obrigatorio>
              <Input
                type="text"
                placeholder="Digite o nome fantasia"
                maxLength={100}
              />
            </Campo>
          </Sessao>

          <Sessao titulo="Contatos">
            <Grupo>
              <Campo fluido name="celular" label="Celular">
                <Input
                  type="text"
                  inputMode="numeric"
                  placeholder="Digite um celular"
                  maxLength={50}
                />
              </Campo>

              <Campo fluido name="telefone" label="Telefone">
                <Input
                  type="text"
                  inputMode="numeric"
                  placeholder="Digite um telefone"
                  maxLength={50}
                />
              </Campo>
            </Grupo>

            <Grupo>
              <Campo fluido name="email" label="E-mail" obrigatorio>
                <Input
                  type="email"
                  inputMode="email"
                  placeholder="Digite um email"
                  maxLength={255}
                />
              </Campo>
            </Grupo>
          </Sessao>

          <Sessao titulo="Endereço">
            <Campo name="enderecoCep" label="CEP" obrigatorio>
              <MaskedInput
                value={cep}
                mask="11111-111"
                onChange={({ target }) => setCep(target.value)}
                placeholder="Digite o CEP"
              />
            </Campo>

            <Campo
              fluido
              name="enderecoLogradouro"
              label="Logradouro"
              obrigatorio
            >
              <Input
                type="text"
                placeholder="Digite o logradouro"
                maxLength={255}
              />
            </Campo>
            <Grupo>
              <Campo name="enderecoNumero" label="Número" fluido>
                <Input
                  type="number"
                  placeholder="Digite o número"
                  maxLength={10}
                />
              </Campo>
              <Campo name="enderecoComplemento" label="Complemento" fluido>
                <Input
                  type="text"
                  placeholder="Digite o complemento"
                  maxLength={255}
                />
              </Campo>
            </Grupo>
            <Campo fluido name="enderecoBairro" label="Bairro" obrigatorio>
              <Input type="text" placeholder="Digite o bairro" maxLength={60} />
            </Campo>
            <Campo fluido name="municipio" label="Município" obrigatorio>
              <Input
                type="text"
                placeholder="Digite o município"
                maxLength={100}
              />
            </Campo>
          </Sessao>

          <Sessao titulo="Observação">
            <Campo fluido name="observacao">
              <TextArea rows={4} placeholder="Digite as observações" />
            </Campo>
          </Sessao>

          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

ClienteForm.propTypes = {
  afterSubmit: func,
  aoVoltar: func,
  aoFechar: func,
  cliente: objectOf(any),
  mostrar: bool,
};

ClienteForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  cliente: null,
  afterSubmit: null,
  aoVoltar: null,
};

export default ClienteForm;

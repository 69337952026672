export default {
  titulo: 'Metas de Produção',
  cabecalho: {
    desktop: [
      {
        titulo: 'Metas de Produção',
        custom: ({ produtividade }) => `${produtividade}% de Produção`,
        variavel: true,
      },
      {
        titulo: 'Peças/Dia',
        atributo: 'pecasDia',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
      },
      {
        titulo: 'Peças/Hora',
        atributo: 'pecasHora',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
      },
      {
        titulo: 'Peças/Funcionário',
        atributo: 'pecasFuncionario',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Metas de Produção',
        agrupar: [
          {
            custom: ({ produtividade }) => `${produtividade}% de Produção`,
            variavel: true,
          },
          {
            custom: ({ pecasDia }, format) => `Peças/Dia: ${format(pecasDia)}`,
            align: 'right',
            tipo: 'n-decimal',
            enumerador: 0,
          },
          {
            custom: ({ pecasHora }, format) =>
              `Peças/Hora: ${format(pecasHora)}`,
            align: 'right',
            tipo: 'n-decimal',
            enumerador: 0,
          },
          {
            custom: ({ pecasFuncionario }, format) =>
              `Peças/Funcionário: ${format(pecasFuncionario)}`,
            align: 'right',
            tipo: 'n-decimal',
            enumerador: 0,
          },
        ],
      },
    ],
  },
};

import React, { useEffect, useRef, useState } from 'react';
import { bool, string, objectOf, any } from 'prop-types';
import { Popover, Tooltip } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Item } from '../../forms/commonStyles';
import { telaGrande } from '../../../utils';
import { TextoPopover } from './styles';

const Campo = ({
  fluido,
  name,
  label,
  obrigatorio,
  children,
  mostrarFoco,
  comPopover,
  tituloPopover,
  textoPopover,
  hidden,
  block,
}) => {
  const inputFoco = useRef(null);
  const [mostrarPopover, setMostrarPopover] = useState(false);
  const { mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  useEffect(() => {
    if (inputFoco.current) {
      if (mostrarFoco) {
        // Coloquei um timeout senão o drawer remove o focus no primeiro render (bug do ant)
        setTimeout(() => inputFoco.current.focus(), 0);
      } else {
        inputFoco.current.blur();
      }
    }
  }, [mostrarFoco, inputFoco]);

  const renderItem = () => (
    <Item
      onFocus={() => setMostrarPopover(true)}
      onBlur={() => setMostrarPopover(false)}
      block={`${block ?? false}`}
      hidden={hidden}
      fluido={`${fluido ?? false}`}
      name={name}
      label={label}
      rules={[{ required: obrigatorio, message: 'Campo obrigatório' }]}
    >
      {React.cloneElement(children, {
        ...children.props,
        comtutorial: true,
        autoComplete:
          comPopover && mostrarTutorial && mostrarPopover ? 'off' : 'on',
        ref: inputFoco,
      })}
    </Item>
  );

  return telaGrande() ? (
    <Popover
      width="300px"
      visible={comPopover && mostrarPopover && mostrarTutorial}
      content={textoPopover && <TextoPopover>{textoPopover}</TextoPopover>}
      title={tituloPopover}
      placement="rightTop"
      overlayStyle={{ zIndex: 1200 }}
    >
      {renderItem()}
    </Popover>
  ) : (
    <Tooltip placement="top" title={textoPopover}>
      {renderItem()}
    </Tooltip>
  );
};

Campo.propTypes = {
  fluido: bool,
  hidden: bool,
  comPopover: bool,
  mostrarFoco: bool,
  obrigatorio: bool,
  block: bool,
  name: string,
  label: string,
  tituloPopover: string,
  textoPopover: string,
  children: objectOf(any),
};

Campo.defaultProps = {
  fluido: false,
  comPopover: false,
  hidden: false,
  mostrarFoco: false,
  obrigatorio: false,
  block: false,
  name: '',
  label: '',
  tituloPopover: null,
  textoPopover: null,
  children: null,
};

export default Campo;

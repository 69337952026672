import styled from 'styled-components';
import {
  Checkbox as AntCheckbox,
  Input as AntInput,
  Select as AntSelect,
  Button as AntButton,
  DatePicker as AntDatePicker,
  Radio as AntRadio,
  TimePicker as AntTimePicker,
} from 'antd';
import colors from '../../../../styles/colors';

export const Group = styled(AntRadio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;

  .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.primaria};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgb(236 212 5 / 8%);
  }

  .ant-radio-inner::after {
    background-color: ${colors.primaria_fundo_claro};
  }
`;

export const DatePicker = styled(AntDatePicker)`
  padding: 0px 11px;
  @media only screen and (max-device-width: 640px) {
    height: 32px;
    ${({ extra }) =>
      !extra &&
      `
      margin-top: 10px;
      margin-left: 35px !important;
    `}
  }
`;

export const TimePicker = styled(AntTimePicker)`
  padding: 0px 11px;
  @media only screen and (max-device-width: 640px) {
    height: 32px;
    ${({ extra }) =>
      !extra &&
      `
      margin-top: 10px;
      margin-left: 35px !important;
    `}
  }
`;

export const Checkbox = styled(AntCheckbox)`
  margin-right: 10px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.primaria};
    border-color: ${colors.primaria};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.primaria};
  }
`;

export const Input = styled(AntInput)`
  margin-left: 10px;
  @media only screen and (max-device-width: 640px) {
    ${({ extra }) =>
      !extra &&
      `
      margin-top: 10px;
      margin-left: 35px !important;
    `}
  }

  .ant-input {
    &:hover {
      border-color: ${colors.primaria};
    }

    &:focus {
      border-color: ${colors.primaria};
      box-shadow: 0 0 0 2px ${colors.primaria};
    }
  }
  &:hover {
    border-color: ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }
`;

export const Select = styled(AntSelect)`
  width: ${({ operacao }) => (operacao === '[between]' ? 'auto' : '193px')};
  @media only screen and (max-device-width: 640px) {
    width: 60vw;
    ${({ operacao }) =>
      operacao === '[between]' &&
      `
      margin-top: 10px;
      margin-left: 35px !important;
      `}
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  ${Input},${DatePicker},${TimePicker} {
    width: ${({ operacao }) => (operacao === '[between]' ? '142px' : '176px')};
    @media only screen and (max-device-width: 640px) {
      width: 60vw;
    }
  }
  @media only screen and (max-device-width: 640px) {
    flex-wrap: wrap;
    justify-content: end;
  }

  ${Select}:not(:first-child),${DatePicker}:not(:first-child),${TimePicker}:not(:first-child) {
    margin-left: 10px;
    margin-right: 0px;
  }
`;

export const ListContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  max-height: 300px;
  padding: 10px;
`;

export const Button = styled(AntButton)`
  margin-top: 20px;

  background-color: ${colors.primaria};
  border-color: ${colors.primaria};
  color: ${colors.texto};

  &:hover {
    background-color: ${colors.secundaria};
    border-color: ${colors.secundaria};
  }
`;

export const IconButton = styled(AntButton)`
  margin-left: 10px;
  color: #1890ff;
  border: 0;
`;

export const Radio = styled(AntRadio)``;

import React, { useCallback, useEffect, useState } from 'react';
import { any, func, objectOf, string } from 'prop-types';
import { isArray } from 'lodash';
import { Tabela, Eficiencia } from '../../layout';
import { TabPeriodos } from '..';
import { PONTOS_FUNCIONARIOS } from '../../layout/Tabela/nomesCabecalhos';
import { EXCLUIR, FALTAR } from '../../layout/Tabela/nomesAcoes';
import { dataNoIntervalo, formatar, telaGrande } from '../../../utils';
import { useApi, useUrl } from '../../../hooks';
import { PontoFuncionarioForm } from '../../forms';
import { PontoContainer } from './styles';
import {
  BUSCAR,
  CADASTRAR,
  GERAR,
  PERIODO,
  PONTO,
  ORDENACAO,
  EFICIENCIA,
  ALFABETICA,
  CELULA,
} from '../../../hooks/useUrl/acoes';

const { PontoForm, GerarPontoForm } = PontoFuncionarioForm;

const PontosTab = ({ history, filtro, setNumeroPontos }) => {
  const { buscar, deletar, criar } = useApi();
  const [pontosFuncionarios, setPontosFuncionarios] = useState([]);
  const [eficiencia, setEficiencia] = useState([]);
  const { existeQuery, removerQuery, pegarValorQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, PONTO);
  const mostrarGeracao = existeQuery(CADASTRAR, GERAR);
  const celula = pegarValorQuery(CELULA);
  const ordenacao = pegarValorQuery(ORDENACAO) ?? EFICIENCIA;
  const periodo = pegarValorQuery(PERIODO);
  const busca = pegarValorQuery(BUSCAR);

  const carregarEficiencia = useCallback(
    async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/eficiencia/diaria?${
          celula ? `celulaUid=${celula}` : ''
        }&data[between]=${dataInicio}&data[between]=${dataFinal}`,
      );
      setEficiencia(data);
    },
    [buscar, celula],
  );

  const carregarListaPontosFuncionarios = useCallback(
    async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/registros-ponto/?${filtro}&$rel[celula]=nome&$rel[funcionario]=nomeCompleto,situacao,cpf,foto40,foto40Url&$ordem=celulaUid,-data,${
          ordenacao === ALFABETICA
            ? 'funcionario.nomeCompleto'
            : '-eficiencia,funcionario.nomeCompleto'
        }${busca ? `&$buscar=${busca}` : ''}${
          celula ? `&celulaUid[eq]=${celula}` : ''
        }&data[between]=${dataInicio}&data[between]=${dataFinal}&$limite=3000`,
      );

      const celulasUids = [];
      if (data) {
        data.forEach(({ celulaUid }) => {
          if (!celulasUids.find(value => value === celulaUid)) {
            celulasUids.push(celulaUid);
          }
        });
      }

      setPontosFuncionarios(
        celulasUids.map(uid => {
          return data.filter(({ celulaUid }) => celulaUid === uid);
        }),
      );

      return setNumeroPontos?.(data?.length);
    },
    [busca, buscar, celula, filtro, ordenacao, setNumeroPontos],
  );

  useEffect(() => {
    if (periodo) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarListaPontosFuncionarios(dataInicio, dataFinal);
      carregarEficiencia(dataInicio, dataFinal);
    }
  }, [carregarEficiencia, carregarListaPontosFuncionarios, periodo]);

  const removerPontos = ({ celulaUid, uid }) => {
    const index = pontosFuncionarios.findIndex(
      arrCelulas => arrCelulas[0]?.celulaUid === celulaUid,
    );
    pontosFuncionarios[index] = [
      ...pontosFuncionarios[index]?.filter(
        ({ uid: pontoId }) => pontoId !== uid,
      ),
    ];
    const [dataInicio, dataFinal] = periodo?.split('/');
    carregarEficiencia(dataInicio, dataFinal);
    if (pontosFuncionarios[index]?.length === 0) {
      setPontosFuncionarios([...pontosFuncionarios.splice(index, 1)]);
    }
    return setPontosFuncionarios([...pontosFuncionarios]);
  };

  const adicionarPontos = ponto => {
    if (dataNoIntervalo(ponto, 'data', periodo?.split('/'))) {
      const index = pontosFuncionarios.findIndex(
        arr => arr[0]?.celulaUid === ponto.celulaUid,
      );
      if (index >= 0) {
        if (isArray(pontosFuncionarios[index])) {
          pontosFuncionarios[index] = [...pontosFuncionarios[index], ponto];
          setPontosFuncionarios([...pontosFuncionarios]);
        }
      } else {
        setPontosFuncionarios([...pontosFuncionarios, [ponto]]);
      }
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarEficiencia(dataInicio, dataFinal);
    }
  };

  const deletarPonto = async ponto => {
    const { uid, data, funcionario } = ponto;
    await deletar(
      `/registros-ponto/${uid}`,
      `O ponto do dia ${formatar('data', data)} de ${
        funcionario?.nomeCompleto
      } foi excluído`,
      () => removerPontos(ponto),
    );
  };

  const faltarPonto = async ({ uid, data: dia, funcionario }) => {
    const { data } = await criar(
      `/registros-ponto/${uid}/faltar`,
      {},
      `O ponto do dia ${formatar('data', dia)} de ${
        funcionario.nomeCompleto
      } foi lançado como falta`,
    );
    if (data) {
      removerPontos(data);
      adicionarPontos(data);
    }
  };

  const gerarCallback = pontos => {
    if (pontos) {
      if (dataNoIntervalo(pontos[0], 'data', periodo?.split('/'))) {
        const arr = [...pontosFuncionarios];
        pontos.forEach(ponto => {
          const { celulaUid: uid } = ponto;
          if (arr.length > 0) {
            const index = arr.findIndex(lista => lista[0].celulaUid === uid);
            if (index < 0) {
              arr.push([ponto]);
            } else {
              arr[index] = [...arr[index], ponto];
            }
          } else {
            arr.push([ponto]);
          }
        });
        setPontosFuncionarios([...arr]);
      }
      removerQuery(CADASTRAR, GERAR);
    }
  };
  return (
    <>
      <Eficiencia eficienciaDiaria={eficiencia} history={history} />
      <TabPeriodos history={history} transparente>
        <div>
          {pontosFuncionarios?.length > 0 ? (
            pontosFuncionarios.map((pontosCelula, index) => (
              <PontoContainer key={index}>
                <h2>{pontosCelula[0]?.celula?.nome}</h2>
                <Tabela
                  cabecalho={PONTOS_FUNCIONARIOS}
                  dados={pontosCelula}
                  paginacao
                  celulaSelecionada={({ uid }) =>
                    history.push(`/registros-ponto/${uid}`)
                  }
                  acoes={
                    telaGrande()
                      ? [
                          {
                            titulo: FALTAR,
                            onClick: faltarPonto,
                            naoMostrarSe: ({ previsto, falta }) =>
                              previsto === falta,
                            confirmacao: true,
                          },
                          {
                            titulo: EXCLUIR,
                            onClick: deletarPonto,
                            confirmacao: true,
                          },
                        ]
                      : null
                  }
                />
              </PontoContainer>
            ))
          ) : (
            <Tabela cabecalho={PONTOS_FUNCIONARIOS} dados={[]} />
          )}
        </div>
      </TabPeriodos>
      <PontoForm
        aoFechar={() => removerQuery(CADASTRAR, PONTO)}
        mostrar={mostrarCadastro}
        afterSubmit={ponto => {
          if (ponto) {
            adicionarPontos(ponto);
            removerQuery(CADASTRAR, PONTO);
            return setNumeroPontos?.(1);
          }
          return null;
        }}
      />
      <GerarPontoForm
        aoFechar={() => removerQuery(CADASTRAR, GERAR)}
        mostrar={mostrarGeracao}
        afterSubmit={gerarCallback}
      />
      <div id="final" />
    </>
  );
};

PontosTab.propTypes = {
  history: objectOf(any).isRequired,
  setNumeroPontos: func,
  filtro: string,
};

PontosTab.defaultProps = {
  setNumeroPontos: null,
  filtro: '',
};

export default PontosTab;

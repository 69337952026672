export const APONTAMENTOS = 'APONTAMENTOS';
export const COMPARAR_APONTAMENTOS = 'COMPARAR_APONTAMENTOS';
export const APURACOES = 'APURACOES';
export const OBSERVACOES = 'OBSERVACOES';
export const PREMIOS = 'PREMIOS';
export const PONTOS_FUNCIONARIOS = 'PONTOS_FUNCIONARIOS';
export const PARADAS = 'PARADAS';
export const MOTIVOS = 'MOTIVOS';
export const CELULAS = 'CELULAS';
export const CLIENTES = 'CLIENTES';
export const CONTAS = 'CONTAS';
export const TURNOS = 'TURNOS';
export const ORDENS_PLANEJADAS = 'ORDENS_PLANEJADAS';
export const ORDENS_NAO_PLANEJADAS = 'ORDENS_NAO_PLANEJADAS';
export const METAS_PREMIOS = 'METAS_PREMIOS';
export const PREMIO_PREVISAO = 'PREMIO_PREVISAO';
export const DIAS_NAO_TRABALHADOS = 'DIAS_NAO_TRABALHADOS';
export const APONTAMENTOS_ORDEM_PRODUCAO = 'APONTAMENTOS_ORDEM_PRODUCAO';
export const FUNCIONARIOS = 'FUNCIONARIOS';
export const PONTOS_DO_FUNCIONARIO = 'PONTOS_DO_FUNCIONARIO';
export const PREMIOS_CELULAS = 'PREMIOS_CELULAS';
export const LANCAMENTOS = 'LANCAMENTOS';
export const MAQUINAS = 'MAQUINAS';
export const OPERACOES_BALANCEADAS = 'OPERACOES_BALANCEADAS';
export const OPERACOES_ORDEM_PRODUCAO = 'OPERACOES_ORDEM_PRODUCAO';
export const OPERACOES_FUNCIONARIO = 'OPERACOES_FUNCIONARIO';
export const ORDENS_PRODUCAO = 'ORDENS_PRODUCAO';
export const ORDENS_PRODUCAO_SALDO = 'ORDENS_PRODUCAO_SALDO';
export const APONTAMENTOS_CELULA_ORDEM = 'APONTAMENTOS_CELULA_ORDEM';
export const ORDENS_CLIENTE = 'ORDENS_CLIENTE';
export const ORDENS_CLIENTE_SALDO = 'ORDENS_CLIENTE_SALDO';
export const SALDO_MENSAL_CONTA = 'SALDO_MENSAL_CONTA';
export const OPERACOES_TEMPO = 'OPERACOES_TEMPO';
export const PACOTES_ORDEM_PRODUCAO = 'PACOTES_ORDEM_PRODUCAO';
export const OPERACOES_PACOTE = 'OPERACOES_PACOTE';
export const CELULA_TURNO = 'CELULA_TURNO';
export const FUNCIONARIO_CELULA = 'FUNCIONARIO_CELULA';
export const FUNCIONARIO_APONTAMENTO = 'FUNCIONARIO_APONTAMENTO';
export const MOTIVOS_PARADAS = 'MOTIVOS_PARADAS';
export const PARADAS_MAQUINA = 'PARADAS_MAQUINA';
export const METAS_PRODUCAO = 'METAS_PRODUCAO';
export const FUNCIONARIO_APONTAMENTO_DATA = 'FUNCIONARIO_APONTAMENTO_DATA';
export const FUNCIONARIO_EXPERIENCIA = 'FUNCIONARIO_EXPERIENCIA';
export const ANIVERSARIANTES = 'ANIVERSARIANTES';
export const APONTAMENTOS_CELULA = 'APONTAMENTOS_CELULA';
export const APONTAMENTOS_CELULAS = 'APONTAMENTOS_CELULAS';

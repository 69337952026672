import {
  Dashboard,
  DRE,
  Funcionarios,
  DetalhesFuncionario,
  Clientes,
  DetalhesCliente,
  Contas,
  DetalhesConta,
  PontosFuncionarios,
  DetalhesPontosFuncionarios,
  LancamentosContas,
  Maquinas,
  KanbanProducao,
  DetalhesMaquina,
  OrdensProducao,
  DetalhesOrdemProducao,
  Apontamentos,
  Celulas,
  DetalhesCelula,
  Turnos,
  DetalhesTurno,
  DetalhesMotivos,
  Motivos,
  MinhaConta,
  Premios,
  DetalhesPremios,
  DiasNaoTrabalhados,
  Planejamento,
  DashboardPremios,
  CompararApontamentos,
} from '../pages';
import ApontamentoConfig from '../pages/MinhaConta/ApontamentoConfig';

const { TempoConfig, OrdemConfig, ValorConfig, PremioConfig } = MinhaConta;

export default [
  { exact: true, path: '/', component: Dashboard },
  { exact: true, path: '/dre', component: DRE },
  { exact: true, path: '/kanban-producao', component: KanbanProducao },
  { exact: true, path: '/apontamentos', component: Apontamentos },
  {
    exact: true,
    path: '/compararApontamentos',
    component: CompararApontamentos,
  },
  { exact: true, path: '/clientes', component: Clientes },
  { exact: true, path: '/planejamento', component: Planejamento },
  { exact: true, path: '/contas', component: Contas },
  { exact: true, path: '/ordens-producao', component: OrdensProducao },
  { exact: true, path: '/lancamentos-contas', component: LancamentosContas },
  { exact: true, path: '/funcionarios', component: Funcionarios },
  { exact: true, path: '/celulas', component: Celulas },
  { exact: true, path: '/turnos', component: Turnos },
  { exact: true, path: '/maquinas', component: Maquinas },
  { exact: true, path: '/registros-ponto', component: PontosFuncionarios },
  { exact: true, path: '/dias-nao-trabalhados', component: DiasNaoTrabalhados },
  { exact: true, path: '/motivos', component: Motivos },
  { exact: true, path: '/config-tempo', component: TempoConfig },
  { exact: true, path: '/config-ordem', component: OrdemConfig },
  { exact: true, path: '/config-valor', component: ValorConfig },
  { exact: true, path: '/config-premio', component: PremioConfig },
  { exact: true, path: '/config-apontamento', component: ApontamentoConfig },
  { exact: true, path: '/premios', component: DashboardPremios },
  { exact: true, path: '/apuracoes', component: Premios },
  { exact: false, path: '/apuracoes/:id', component: DetalhesPremios },
  { exact: false, path: '/motivos/:id', component: DetalhesMotivos },
  { exact: false, path: '/turnos/:id', component: DetalhesTurno },
  { exact: false, path: '/celulas/:id', component: DetalhesCelula },
  { exact: false, path: '/funcionarios/:id', component: DetalhesFuncionario },
  { exact: false, path: '/contas/:id', component: DetalhesConta },
  { exact: false, path: '/maquinas/:id', component: DetalhesMaquina },
  { exact: false, path: '/clientes/:id', component: DetalhesCliente },
  {
    exact: false,
    path: '/registros-ponto/:id',
    component: DetalhesPontosFuncionarios,
  },
  {
    exact: false,
    path: '/ordens-producao/:id',
    component: DetalhesOrdemProducao,
  },
];

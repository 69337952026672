import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import {
  Tabela,
  Filtros,
  Header,
  BotaoPrimario,
  BotaoDefault,
} from '../../components/layout';
import { CelulaForm } from '../../components/forms';
import { useApi, useUrl } from '../../hooks';
import { Container } from '../styles';
import Tags from '../../components/layout/Filtros/Tags';
import { ModalBusca } from '../../components/modais';
import { CELULA_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { CELULAS } from '../../components/layout/Tabela/nomesCabecalhos';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  CADASTRAR,
  BUSCAR,
  FILTRAR,
  ATIVAR_FILTRO,
  CELULA,
} from '../../hooks/useUrl/acoes';

const { TituloComFiltro } = Filtros;

const CelulasTrabalho = ({ history }) => {
  const { adicionarQuery, existeQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const [filtro, setFiltro] = useState('');
  const { buscar, deletar } = useApi();
  const [celulas, setCelulas] = useState([]);
  const mostrarCadastro = existeQuery(CADASTRAR, CELULA);
  const busca = pegarValorQuery(BUSCAR);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    const carregarCelulas = async () => {
      if (pagina === 1) {
        setCelulas([]);
      }

      if (filtro === '') {
        const { data } = await buscar(
          `/celulas?${filtro}&$rel[turno]=nome${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&$limite=10&$pagina=${pagina}`,
        );
        setCelulas(cel => [...cel, ...data]);
      }

      if (filtro !== '') {
        const { data } = await buscar(
          `/celulas?${filtro}&$rel[turno]=nome${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&`,
        );
        setCelulas(data);
        // setCelulas(cel => [...cel, ...data]);
        setPagina(1);
      }
    };
    carregarCelulas();
  }, [buscar, busca, filtro, pagina]);

  useEffect(() => {
    if (filtro === '') {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setPagina(page => page + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#final'));
      return () => intersectionObserver.disconnect();
    }

    return undefined;
  }, [filtro]);

  const deletarCelula = async ({ uid, nome }) => {
    await deletar(`/celulas/${uid}`, `A célula ${nome} foi excluída`, () =>
      setCelulas(celulas.filter(({ uid: celulaUid }) => celulaUid !== uid)),
    );
  };

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro
            titulo="Células de Trabalho"
            history={history}
            busca={busca}
            semUrl
          />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, CELULA)}
          >
            Nova Célula
          </BotaoPrimario>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabela
        loading={loading}
        cabecalho={CELULAS}
        dados={celulas}
        celulaSelecionada={({ uid }) => history.push(`/celulas/${uid}`)}
        acoes={
          telaGrande()
            ? [{ titulo: EXCLUIR, onClick: deletarCelula, confirmacao: true }]
            : null
        }
      />
      <CelulaForm
        aoFechar={() => removerQuery(CADASTRAR, CELULA)}
        mostrar={mostrarCadastro}
        afterSubmit={celula => {
          if (celula) {
            setCelulas([...celulas, celula]);
            removerQuery(CADASTRAR, CELULA);
          }
        }}
      />
      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={CELULA_LAYOUT}
      />
      {filtro === '' ? <div id="final" /> : null}
    </Container>
  );
};

CelulasTrabalho.propTypes = {
  history: objectOf(any).isRequired,
};

export default CelulasTrabalho;

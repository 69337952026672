import styled from 'styled-components';

export const EnderecoContainer = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: flex;
  }
  h4 {
    margin-right: 10px;
    width: fit-content;
  }
  img {
    margin-left: 5px;
    width: 15px;
    height: 20px;
  }
`;

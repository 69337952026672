import { parse } from 'query-string';
import {
  SET_LOADING,
  SET_TOKEN,
  SET_TOKEN_VALIDO,
  SET_CONFIGURACOES,
  SET_COLAPSAR_MENU,
  SET_MOSTRAR_TUTORIAL,
} from './operacoes';

const initialState = {
  loading: false,
  token: parse(window.location.search).token,
  tokenValido: true,
  configuracoes: null,
  mostrarTutorial: false,
  colapsarMenu: localStorage.getItem('colapsar-menu') === 'true',
};

const reducer = (state = initialState, action) => {
  const { type, valor } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: valor,
      };
    case SET_COLAPSAR_MENU:
      return { ...state, colapsarMenu: valor };
    case SET_TOKEN:
      return { ...state, token: valor };
    case SET_TOKEN_VALIDO:
      return { ...state, tokenValido: valor };
    case SET_CONFIGURACOES:
      return { ...state, configuracoes: valor };
    case SET_MOSTRAR_TUTORIAL:
      return { ...state, mostrarTutorial: valor };
    default:
      return state;
  }
};

export default reducer;

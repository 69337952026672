export default {
  titulo: 'Ordens',
  cabecalho: {
    desktop: [
      {
        titulo: 'Nome',
        atributo: 'nome',
      },
      {
        titulo: 'Quantidade',
        atributo: 'quantidadeOrdemProducao',
        tipo: 'n-decimal',
        enumerador: 2,
      },
      {
        titulo: 'Apontamentos Célula',
        atributo: 'quantiodadeApontadaCelula',
        tipo: 'n-decimal',
        enumerador: 2,
      },
      {
        titulo: 'Apontamentos Funcionários',
        atributo: 'quantidadeApontadaFuncionario',
        tipo: 'n-decimal',
        enumerador: 2,
      },
      {
        titulo: 'Tempo Esperado',
        atributo: 'tempoTotalEsperado',
        tipo: 'n-decimal',
        enumerador: 2,
      },
    ],
    mobile: [
      {
        titulo: 'Ordens',
        variavel: true,
        agrupar: [
          {
            titulo: 'Nome',
            atributo: 'nome',
          },
          // {
          //   titulo: 'Quantidade',
          //   atributo: 'quantidadeOrdemProducao',
          //   tipo: 'n-decimal',
          //   enumerador: 2,
          // },
          {
            titulo: 'Apontamentos Célula',
            atributo: 'quantiodadeApontadaCelula',
            tipo: 'n-decimal',
            enumerador: 2,
          },
          {
            titulo: 'Apontamentos Funcionários',
            atributo: 'quantidadeApontadaFuncionario',
            tipo: 'n-decimal',
            enumerador: 2,
          },
          {
            titulo: 'Tempo Esperado',
            atributo: 'tempoTotalEsperado',
            tipo: 'n-decimal',
            enumerador: 2,
          },
        ],
      },
    ],
  },
};

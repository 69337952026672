import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { objectOf, any, func } from 'prop-types';
import { Tag } from './styles';
import { verificarUrl } from '../functions';
import { montarNome } from '../operacoes';

const Tags = ({ history, retornarQuery }) => {
  const { search, pathname } = history.location;
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const objeto = parse(search);
    let query = '';
    setTags([]);
    Object.keys(objeto)
      .filter(key => key.includes('[') && key.includes(']'))
      .forEach((key, index) => {
        if (typeof objeto[key] === 'object') {
          setTags(oldTags => [
            ...oldTags,
            {
              texto: montarNome(objeto[key][0], objeto[key][1], key),
              url: `${key}=${objeto[key][0]}&${key}=${objeto[key][1]}`,
            },
          ]);
          query = query.concat(
            index === 0 ? '' : '&',
            `${key}=${objeto[key][0]}&${key}=${objeto[key][1]}`,
          );
        } else {
          const url = `${key}=${objeto[key]}`;
          setTags(oldTags => [
            ...oldTags,
            { texto: montarNome(objeto[key], null, key), url },
          ]);
          query = query.concat(index === 0 ? '' : '&', url);
        }
      });
    retornarQuery(query);
  }, [search, retornarQuery]);

  return (
    <>
      {tags.map(({ texto, url }, index) => (
        <Tag
          key={index + texto}
          closable
          onClose={() => {
            setTags(tags.filter(tag => tag.texto !== texto));
            const oldSearch = verificarUrl(url, search);
            history.replace(pathname + search.replace(oldSearch, ''));
          }}
        >
          {texto}
        </Tag>
      ))}
    </>
  );
};

Tags.propTypes = {
  history: objectOf(any).isRequired,
  retornarQuery: func.isRequired,
};

export default Tags;

export default {
  titulo: 'Células',
  cabecalho: {
    desktop: [
      { titulo: 'Nome', atributo: 'nome', variavel: true },
      {
        titulo: 'Turno',
        atributo: 'nome',
        aninhamento: 'turno',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Célula',
        agrupar: [
          { atributo: 'nome' },
          { atributo: 'nome', aninhamento: 'turno' },
        ],
      },
    ],
  },
};

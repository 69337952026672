import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Radio, Select, Spin } from 'antd';
import { func, any, objectOf, bool } from 'prop-types';
import { onFinish, lerObjectProp } from '../../commonFunctions';
import { BotaoPrimario, Campo, SelectDinamico, Sessao } from '../../../layout';
import { telaGrande } from '../../../../utils';
import { useApi } from '../../../../hooks';
import { CelulaForm, TurnoForm } from '../..';
import {
  Grupo,
  DatePicker,
  CloseButtonContainer,
  RadioGroup,
  Input,
} from '../../commonStyles';

const { Option } = Select;
const NAO_INFORMADO = 'null';
const MASCULINO = 'M';
const FEMININO = 'F';

const FuncionarioForm = ({
  afterSubmit,
  funcionario,
  aoVoltar,
  aoFechar,
  mostrar,
}) => {
  const { criar, alterar, buscar, existeNoLog } = useApi();
  const [form] = Form.useForm();
  const [celulas, setCelulas] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [mostrarFormCelula, setMostrarCelula] = useState(false);
  const [mostrarFormTurno, setMostrarTurno] = useState(false);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        ...funcionario,
        dataAdmissao: funcionario?.dataAdmissao
          ? moment(funcionario?.dataAdmissao, 'yyyy-MM-DD')
          : moment(),
        dataNascimento:
          funcionario?.dataNascimento &&
          moment(funcionario?.dataNascimento, 'yyyy-MM-DD'),
        dataFimExperiencia1: funcionario?.dataFimExperiencia1
          ? moment(funcionario?.dataFimExperiencia1, 'yyyy-MM-DD')
          : moment().add(45, 'd'),
        dataFimExperiencia2: funcionario?.dataFimExperiencia2
          ? moment(funcionario?.dataFimExperiencia2, 'yyyy-MM-DD')
          : moment().add(90, 'd'),
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [funcionario, form.resetFields, form.setFieldsValue]);

  useEffect(() => {
    const buscarCelulas = async () => {
      const url = '/celulas?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setCelulas(data);
      }
    };

    const buscarTurnos = async () => {
      const url = '/turnos?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setTurnos(data);
      }
    };

    if (mostrar) {
      buscarTurnos();
      buscarCelulas();
    }
  }, [buscar, existeNoLog, mostrar]);

  const cadastrarFuncionario = async ({
    codigo,
    nomeCompleto,
    dataAdmissao,
    dataNascimento,
    sexo,
    dataFimExperiencia1,
    dataFimExperiencia2,
    ...values
  }) => {
    const { data } = await criar(
      '/funcionarios',
      {
        codigo,
        nomeCompleto,
        sexo: sexo === NAO_INFORMADO ? null : sexo,
        dataAdmissao: dataAdmissao?.format('YYYY-MM-DD'),
        dataNascimento: dataNascimento?.format('YYYY-MM-DD'),
        dataFimExperiencia1: dataFimExperiencia1?.format('YYYY-MM-DD'),
        dataFimExperiencia2: dataFimExperiencia2?.format('YYYY-MM-DD'),
        ...values,
      },
      `${
        sexo === 'F' ? 'A funcionária' : 'O funcionário'
      } ${codigo} - ${nomeCompleto} foi ${sexo === 'F' ? 'criada' : 'criado'}`,
    );
    return data;
  };

  const editarFuncionario = async (
    {
      sexo,
      codigo,
      nomeCompleto,
      dataAdmissao,
      dataNascimento,
      dataFimExperiencia1,
      dataFimExperiencia2,
      ...values
    },
    uid,
  ) => {
    const { data } = await alterar(
      `/funcionarios/${uid}`,
      {
        codigo,
        nomeCompleto,
        sexo: sexo === NAO_INFORMADO ? null : sexo,
        dataAdmissao: dataAdmissao?.format('YYYY-MM-DD'),
        dataNascimento: dataNascimento?.format('YYYY-MM-DD'),
        dataFimExperiencia1: dataFimExperiencia1?.format('YYYY-MM-DD'),
        dataFimExperiencia2: dataFimExperiencia2?.format('YYYY-MM-DD'),
        ...values,
      },
      funcionario,
      `${
        sexo === 'F' ? 'A funcionária' : 'O funcionário'
      } ${codigo} - ${nomeCompleto} foi ${
        sexo === 'F' ? 'alterada' : 'alterado'
      }`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      closable={!aoVoltar}
      width={telaGrande() ? 420 : '100%'}
      title={funcionario ? 'Alterar Funcionário' : 'Novo Funcionário'}
      onClose={aoFechar}
    >
      {mostrarFormTurno && (
        <TurnoForm
          mostrar={mostrarFormTurno}
          aoVoltar={() => setMostrarTurno(false)}
          afterSubmit={turno => {
            setTurnos([turno, ...turnos]);
            form.setFieldsValue({
              turnoUid: turno?.uid,
            });
          }}
        />
      )}

      {mostrarFormCelula && (
        <CelulaForm
          mostrar={mostrarFormCelula}
          aoVoltar={() => setMostrarCelula(false)}
          afterSubmit={celula => {
            if (celula) {
              const { turnoUidPadrao, turno, uid } = celula;
              setCelulas([celula, ...celulas]);
              setTurnos([{ uid: turnoUidPadrao, nome: turno.nome }, ...turnos]);
              form.setFieldsValue({
                celulaUid: uid,
                turnoUid: turnoUidPadrao,
              });
            }
          }}
        />
      )}

      {!mostrarFormCelula && !mostrarFormTurno && (
        <Spin spinning={loading}>
          {aoVoltar && (
            <CloseButtonContainer>
              <button onClick={aoVoltar} type="button">
                Voltar
              </button>
            </CloseButtonContainer>
          )}
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onFinish={values => {
              onFinish(
                { ...values, objectUid: funcionario?.uid },
                cadastrarFuncionario,
                editarFuncionario,
                novoFuncionario => {
                  if (afterSubmit && novoFuncionario)
                    afterSubmit(novoFuncionario);
                  if (aoVoltar && novoFuncionario) aoVoltar();
                },
                () => {
                  form.resetFields();
                  form.setFieldsValue({
                    dataAdmissao: moment(),
                  });
                },
              );
            }}
          >
            <Sessao titulo="Dados Pessoais">
              <Grupo>
                <Campo
                  name="nomeCompleto"
                  label="Nome Completo"
                  obrigatorio
                  mostrarFoco={mostrar}
                  comPopover
                  tituloPopover="Nome Completo"
                  textoPopover="Informe aqui o nome do seu funcionário."
                >
                  <Input
                    type="text"
                    placeholder="Digite um nome"
                    maxLength={255}
                  />
                </Campo>
                <Campo
                  name="codigo"
                  label="Código"
                  obrigatorio
                  comPopover
                  tituloPopover="Código"
                  textoPopover="Informe aqui como o funcionário é conhecido dentro do sistema. Ao concluir os demais campos de cadastro, clique no botão abaixo Salvar."
                >
                  <Input
                    type="text"
                    placeholder="Digite um código"
                    maxLength={255}
                  />
                </Campo>
              </Grupo>

              <Campo name="sexo" label="Sexo">
                <RadioGroup value={funcionario?.sexo} inline="true">
                  <Radio value={FEMININO}>Feminino</Radio>
                  <Radio value={MASCULINO}>Masculino</Radio>
                  <Radio value={NAO_INFORMADO}>Não informado</Radio>
                </RadioGroup>
              </Campo>

              <Grupo>
                <Campo
                  name="dataNascimento"
                  label="Data de Nascimento"
                  obrigatorio
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                  />
                </Campo>

                <Campo name="cpf" label="CPF">
                  <Input
                    type="text"
                    placeholder="Digite um cpf"
                    maxLength={14}
                  />
                </Campo>
              </Grupo>
            </Sessao>

            <Sessao titulo="Dados do Trabalho">
              <Grupo>
                <Campo name="dataAdmissao" label="Data de Admissão" obrigatorio>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                    onChange={(data, str) => {
                      if (data) {
                        form.setFieldsValue({
                          dataFimExperiencia1: moment(str, 'DD/MM/YYYY').add(
                            45,
                            'd',
                          ),
                          dataFimExperiencia2: moment(str, 'DD/MM/YYYY').add(
                            90,
                            'd',
                          ),
                        });
                      }
                    }}
                  />
                </Campo>
                <Campo name="salario" label="Salário" obrigatorio>
                  <Input
                    type="number"
                    addonBefore="R$"
                    placeholder="Digite um valor"
                    maxLength={15}
                  />
                </Campo>
              </Grupo>

              <Campo name="cargo" label="Cargo" obrigatorio fluido>
                <Input
                  type="text"
                  placeholder="Digite um cargo"
                  maxLength={100}
                />
              </Campo>

              <Grupo>
                <Campo name="dataFimExperiencia1" label="Fim Experiência 1">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                  />
                </Campo>

                <Campo name="dataFimExperiencia2" label="Fim Experiência 2">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                  />
                </Campo>
              </Grupo>
              <Grupo>
                <Campo name="celulaUid" label="Célula" obrigatorio>
                  <SelectDinamico
                    form={form}
                    nomeCampo="celulaUid"
                    onSelect={(value, { turno }) => {
                      form.setFieldsValue({
                        turnoUid: turno,
                      });
                    }}
                    placeholder="Selecione uma célula"
                    aoAdicionar={() => setMostrarCelula(true)}
                  >
                    {celulas?.map(({ uid, nome, turnoUidPadrao }) => (
                      <Option key={uid} value={uid} turno={turnoUidPadrao}>
                        {nome}
                      </Option>
                    ))}
                  </SelectDinamico>
                </Campo>

                <Campo name="turnoUid" label="Turno" obrigatorio>
                  <SelectDinamico
                    form={form}
                    nomeCampo="turnoUid"
                    placeholder="Selecione uma turno"
                    aoAdicionar={() => setMostrarTurno(true)}
                  >
                    {turnos?.map(({ uid, nome }) => (
                      <Option key={uid} value={uid}>
                        {nome}
                      </Option>
                    ))}
                  </SelectDinamico>
                </Campo>
              </Grupo>
            </Sessao>

            <Sessao titulo="Contatos">
              <Campo name="email" label="E-mail" fluido>
                <Input
                  type="text"
                  placeholder="Digite um email"
                  maxLength={100}
                />
              </Campo>

              <Grupo>
                <Campo name="telefone" label="Telefone">
                  <Input
                    type="text"
                    placeholder="Digite um telefone"
                    maxLength={20}
                  />
                </Campo>

                <Campo name="celular" label="Celular">
                  <Input
                    type="text"
                    placeholder="Digite um celular"
                    maxLength={20}
                  />
                </Campo>
              </Grupo>
            </Sessao>

            <Campo fluido>
              <BotaoPrimario htmlType="submit" type="primary">
                Salvar
              </BotaoPrimario>
            </Campo>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

FuncionarioForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  aoVoltar: func,
  funcionario: objectOf(any),
  mostrar: bool,
};

FuncionarioForm.defaultProps = {
  aoFechar: null,
  mostrar: false,
  funcionario: null,
  aoVoltar: null,
  afterSubmit: null,
};

export default FuncionarioForm;

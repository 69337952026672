import React from 'react';
import { Popconfirm } from 'antd';
import { any, func, objectOf } from 'prop-types';
import { ExcluirButton } from './styles';

const BotaoExcluir = ({ onConfirm, children }) => {
  return (
    <Popconfirm
      title="Confirmar exclusão"
      onConfirm={onConfirm}
      okText="Sim"
      cancelText="Não"
    >
      <ExcluirButton>{children}</ExcluirButton>
    </Popconfirm>
  );
};

BotaoExcluir.propTypes = {
  onConfirm: func.isRequired,
  children: objectOf(any).isRequired,
};

export default BotaoExcluir;

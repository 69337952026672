import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Drawer, Form, Select, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, bool, number } from 'prop-types';
import { onFinish } from '../../commonFunctions';
import { DatePicker, Input } from '../../commonStyles';
import { BotaoPrimario, Campo, SelectDinamico } from '../../../layout';
import { useApi } from '../../../../hooks';
import { FuncionarioForm as FuncionarioForms } from '../..';
import { formatar, telaGrande } from '../../../../utils';

const { Option } = Select;
const { FuncionarioForm } = FuncionarioForms;

const PremiacaoForm = ({ mostrar, aoFechar, afterSubmit, apuracaoUid }) => {
  const { criar, buscar, existeNoLog } = useApi();
  const [form] = Form.useForm();
  const [funcionarios, setFuncionarios] = useState([]);
  const [mostrarFuncionario, setMostrarFuncionario] = useState(false);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const buscarFuncionarios = async () => {
      const url = '/funcionarios';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setFuncionarios(data);
      }
    };
    if (mostrar) {
      buscarFuncionarios();
    }
  }, [buscar, existeNoLog, mostrar]);

  const cadastrarPremiacao = async values => {
    const { dataPagamento, valorOriginal } = values;
    const { data } = await criar(
      `/apuracoes/${apuracaoUid}/premios`,
      {
        ...values,
        dataPagamento: moment(dataPagamento).format('YYYY-MM-DD'),
      },
      `Premiação de valor ${formatar(
        'moeda',
        valorOriginal,
      )} para o dia ${moment(dataPagamento).format('DD/MM/YYYY')} foi criada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title="Nova Premiação"
      onClose={aoFechar}
    >
      {mostrarFuncionario && (
        <FuncionarioForm
          mostrar={mostrarFuncionario}
          aoVoltar={() => setMostrarFuncionario(false)}
          afterSubmit={funcionario => {
            setFuncionarios([funcionario, ...funcionarios]);
            form.setFieldsValue({
              funcionarioUid: funcionario?.uid,
            });
          }}
        />
      )}

      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              values,
              cadastrarPremiacao,
              null,
              premiacao => {
                if (afterSubmit && premiacao) afterSubmit(premiacao);
              },
              form.resetFields,
            );
          }}
        >
          <Campo name="funcionarioUid" label="Funcionário" obrigatorio fluido>
            <SelectDinamico
              form={form}
              nomeCampo="funcionarioUid"
              placeholder="Selecione um funcionário"
              aoAdicionar={() => setMostrarFuncionario(true)}
              onSelect={funcionarioUid => {
                form.setFieldsValue({
                  funcionarioUid,
                });
              }}
            >
              {funcionarios?.map(({ uid, nomeCompleto }) => (
                <Option key={uid} value={uid}>
                  {nomeCompleto}
                </Option>
              ))}
            </SelectDinamico>
          </Campo>

          <Campo
            fluido
            name="eficiencia"
            label="Eficiência"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <Input type="text" placeholder="Digite um nome" maxLength={255} />
          </Campo>

          <Campo
            fluido
            name="valorOriginal"
            label="Valor do Prêmio"
            obrigatorio
          >
            <Input type="number" placeholder="Digite o valor" maxLength={255} />
          </Campo>

          <Campo
            fluido
            name="dataPagamento"
            label="Data do Pagamento"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <DatePicker
              placeholder="Selecione uma data"
              picker="day"
              format="DD/MM/YYYY"
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

PremiacaoForm.propTypes = {
  aoFechar: func,
  mostrar: bool,
  afterSubmit: func,
  apuracaoUid: number,
};

PremiacaoForm.defaultProps = {
  apuracaoUid: null,
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default PremiacaoForm;

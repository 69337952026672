import React from 'react';
import { any, func, objectOf } from 'prop-types';

import { Botao } from './styles';

const BotaoPrimario = ({ children, onClick, ...props }) => {
  return (
    <Botao type="primary" htmlType="button" onClick={onClick} {...props}>
      {children}
    </Botao>
  );
};

BotaoPrimario.propTypes = {
  onClick: func,
  children: objectOf(any),
};

BotaoPrimario.defaultProps = {
  onClick: null,
  children: null,
};

export default BotaoPrimario;

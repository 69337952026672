/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { Chart } from 'react-google-charts';
import { number } from 'prop-types';
import { Spin } from 'antd';
import { formatar, telaGrande } from '../../../utils';
import { GraficoHeader } from '../../layout';
import { ChartContainer, LoadingContainer } from '../styles';
import { renderItemLegenda } from '../functions';
import colors from '../../../styles/colors';

const opacidadeCor = 0.5;
const tooltipConfig = { role: 'tooltip', type: 'string', p: { html: true } };
const styleConfig = { role: 'style' };
const corEficiencia = colors.primaria;
const corTrabalhado = '#a0f582';
const corParada = '#ffed87';
const corFalta = '#ff9b94';
const options = {
  titleTextStyle: {
    color: '#607d8b',
    fontName: 'Fira Sans',
    fontSize: 14,
    bold: false,
    italic: false,
  },
  hAxis: {
    title: 'Data',
    titleTextStyle: { color: '#607d8b' },
    gridlines: { count: 0 },
    textStyle: {
      color: '#000000D9',
      fontName: 'Fira Sans',
      fontSize: '13',
    },
  },
  vAxis: {
    minValue: 0,
    gridlines: { count: 0 },
    title: 'Minutos',
    titleTextStyle: { color: '#607d8b' },
    gridlineColor: '#fff',
  },
  legend: {
    position: 'top',
    alignment: 'center',
    textStyle: { color: '#607d8b', fontName: 'Fira Sans', fontSize: '12' },
  },
  areaOpacity: 0.24,
  lineWidth: 1,
  backgroundColor: 'transparent',
  chartArea: {
    backgroundColor: 'transparent',
    height: '70%',
  },
  displayMode: 'regions',
  colors: [corTrabalhado, corParada, corFalta, corEficiencia],
  title: 'Gráfico da Eficiência',
  tooltip: { isHtml: true, trigger: 'visible' },
};

const GraficoEficiencia = ({ celulaUid, funcionarioUid }) => {
  const [dadosGrafico, setDadosGrafico] = useState(['']);

  const renderTooltip = props => {
    const {
      trabalhado,
      parada,
      falta,
      eficiencia,
      tempoApontado,
      quantidadeApontada,
    } = props;
    return `
      <div>
        ${renderItemLegenda(
          `Eficiência: ${formatar('n-decimal', eficiencia ?? 0, 2)}%`,
        )}
        ${renderItemLegenda(
          `Faltas: ${falta ? formatar('n-decimal', falta, 2) : '0'} min`,
        )}
        ${renderItemLegenda(
          `Paradas: ${parada ? formatar('n-decimal', parada, 2) : '0'} min`,
        )}
        ${renderItemLegenda(
          `Trabalhado: ${
            trabalhado ? formatar('n-decimal', trabalhado, 2) : '0'
          } min`,
        )}
        ${renderItemLegenda(
          `Apontado: ${
            tempoApontado ? formatar('n-decimal', tempoApontado, 2) : '0'
          } min`,
        )}
        ${renderItemLegenda(`Execuções: ${quantidadeApontada ?? 0}`)}
      </div>
    `;
  };

  const carregarProps = useCallback((propriedades, formato) => {
    const { data, trabalhado, parada, falta, eficiencia } = propriedades;
    const tooltip = renderTooltip(propriedades);
    return [
      formatar(formato, data),
      trabalhado ?? 0,
      tooltip,
      `color: ${corTrabalhado}; opacity: ${opacidadeCor}`,
      parada ?? 0,
      tooltip,
      `color: ${corParada}; opacity: ${opacidadeCor}`,
      falta ?? 0,
      tooltip,
      `color: ${corFalta}; opacity: ${opacidadeCor}`,
      eficiencia ?? 0,
      tooltip,
      `color: ${corEficiencia}; opacity: ${opacidadeCor}`,
    ];
  }, []);

  const data = [
    [
      'Data',
      'Trabalhado',
      tooltipConfig,
      styleConfig,
      'Paradas',
      tooltipConfig,
      styleConfig,
      'Faltas',
      tooltipConfig,
      styleConfig,
      'Eficiência',
      tooltipConfig,
      styleConfig,
    ],
    ...dadosGrafico,
  ];

  const loader = (
    <LoadingContainer>
      <Spin spinning />
    </LoadingContainer>
  );

  return (
    <div>
      <GraficoHeader
        funcionarioUid={funcionarioUid}
        celulaUid={celulaUid}
        carregarProps={carregarProps}
        setDadosGrafico={setDadosGrafico}
      />
      <ChartContainer>
        {telaGrande() ? (
          <Chart
            width="100%"
            height="500px"
            chartType="ComboChart"
            rootProps={{ 'data-testid': '1' }}
            options={{
              ...options,
              bar: { groupWidth: '40' },
              seriesType: 'bars',
              isStacked: true,
              series: {
                0: { targetAxisIndex: 1 },
                1: { targetAxisIndex: 1 },
                2: { targetAxisIndex: 1 },
                3: {
                  type: 'area',
                  targetAxisIndex: 0,
                },
              },
              vAxes: {
                0: { title: 'Eficiência' },
                1: { title: 'Minutos' },
              },
            }}
            data={data}
            loader={loader}
          />
        ) : (
          <>
            <Chart
              width="100%"
              height="250px"
              chartType="AreaChart"
              loader={loader}
              data={[
                ...data.map(arr => [arr[0], ...arr.slice(10, arr.length)]),
              ]}
              options={{
                ...options,
                colors: [corEficiencia],
              }}
              rootProps={{ 'data-testid': '1' }}
            />

            <Chart
              width="100%"
              height="250px"
              chartType="ComboChart"
              loader={loader}
              data={[...data.map(arr => [...arr.slice(0, 10)])]}
              options={{
                ...options,
                seriesType: 'bars',
                isStacked: true,
                colors: [corTrabalhado, corParada, corFalta],
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </>
        )}
      </ChartContainer>
    </div>
  );
};

GraficoEficiencia.propTypes = {
  celulaUid: number,
  funcionarioUid: number,
};

GraficoEficiencia.defaultProps = {
  celulaUid: null,
  funcionarioUid: null,
};

export default GraficoEficiencia;

import { Tag } from 'antd';
import styled from 'styled-components';
import colors from '../../../styles/colors';

export const TituloContainer = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: inline-flex;
    align-items: baseline;
    margin-left: 5px;
  }
`;

export const TituloTag = styled(Tag)`
  cursor: ${({ onClick }) => onClick && 'pointer'};
  font-weight: 400;
  max-height: 32px;
  margin-left: 5px;
  margin-right: 0px;
  font-size: 14px;
  background: transparent;
  border: ${({ semborda }) => semborda === 'true' && 'none'};
  ${({ color }) =>
    !color &&
    `
  color: ${colors.titulo_claro};
  border-color:  ${colors.titulo_claro};
  `};
`;

export const SituacaoContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    display: grid;
  }
`;

import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

export const OptionButton = styled.button`
  background: transparent;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
`;

export const Select = styled(AntSelect)``;

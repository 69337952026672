import { Spin } from 'antd';
import moment from 'moment';
import { any, objectOf } from 'prop-types';
import React from 'react';
import { Chart } from 'react-google-charts';
import colors from '../../../styles/colors';
import { formatar } from '../../../utils';
import { ChartContainer } from '../../layout/GraficoHeader/styles';
import { renderItemLegenda } from '../functions';
import { LoadingContainer } from '../styles';

const tooltipConfig = { role: 'tooltip', type: 'string', p: { html: true } };
const options = {
  tooltip: { isHtml: true, trigger: 'visible', ignoreBounds: true },
  chartArea: {
    backgroundColor: 'transparent',
    height: '70%',
  },
  titleTextStyle: {
    color: '#607d8b',
    fontName: 'Fira Sans',
    fontSize: 14,
    bold: false,
    italic: false,
  },
  vAxis: {
    minValue: 0,
    gridlines: { count: 0 },
    title: 'Valor',
    titleTextStyle: { color: '#607d8b' },
    gridlineColor: '#fff',
  },
  hAxis: {
    title: 'Data',
    titleTextStyle: { color: '#607d8b' },
    gridlines: { count: 0 },
    textStyle: {
      color: '#000000D9',
      fontName: 'Fira Sans',
      fontSize: '13',
    },
  },
  legend: {
    position: 'top',
    alignment: 'center',
    textStyle: {
      color: '#607d8b',
      fontName: 'Fira Sans',
      fontSize: '12',
    },
  },
  series: {
    0: { lineWidth: 5, zIndex: 10, color: colors.primaria },
    1: { lineDashStyle: [4, 4], color: colors.secundaria },
  },
  chart: {
    title: 'Average Temperatures and Daylight in Iceland Throughout the Year',
  },
  height: 500,
};

const GraficoFaturamento = ({ data }) => {
  const renderTooltip = (dataMes, faturado, estimativa) => {
    return `
      <div>
        ${renderItemLegenda(`Data: ${dataMes}`)}
        ${renderItemLegenda(
          `Valor Faturado: R$ ${
            faturado ? formatar('n-decimal', faturado, 2) : '0'
          }`,
        )}
        ${renderItemLegenda(
          `Estimativa de Faturamento: R$ ${
            estimativa ? formatar('n-decimal', estimativa, 2) : '0'
          }`,
        )}
      </div>
    `;
  };

  const renderData = () => {
    const hoje = moment().format('DD/MM/YYYY');
    return data?.length > 0
      ? data.map(({ valorEstimado, valorFaturado, data }) => {
          const dataMes = moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
          return [
            dataMes,
            valorFaturado ?? 0,
            renderTooltip(dataMes, valorFaturado ?? 0, valorEstimado ?? 0),
            valorEstimado ?? 0,
            renderTooltip(dataMes, valorFaturado ?? 0, valorEstimado ?? 0),
          ];
        })
      : [[hoje, 0, renderTooltip(hoje, 0, 0), 0, renderTooltip(hoje, 0, 0)]];
  };

  return (
    <ChartContainer>
      <Chart
        width="100%"
        height="500"
        chartType="AreaChart"
        rootProps={{ 'data-testid': '4' }}
        options={options}
        loader={
          <LoadingContainer>
            <Spin spinning />
          </LoadingContainer>
        }
        data={[
          [
            'Data',
            'Valor Faturado',
            tooltipConfig,
            'Estimativa de Faturamento',
            tooltipConfig,
          ],
          ...renderData(),
        ]}
      />
    </ChartContainer>
  );
};

GraficoFaturamento.propTypes = {
  data: objectOf(any).isRequired,
};

export default GraficoFaturamento;

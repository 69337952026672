import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { ClienteForm } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import { CADASTRAR, CLIENTE, ORDEM } from '../../../hooks/useUrl/acoes';
import { OrdensTab } from '../../../components/tabs';
import maps from '../../../assets/icones/google-maps.png';
import { EnderecoContainer } from './styles';
import { telaGrande } from '../../../utils';
import {
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
  UploadImagem,
} from '../../../components/layout';

const DetalhesCliente = ({ history, match }) => {
  const { id } = match.params;
  const [cliente, setCliente] = useState({});
  const { buscar, alterar, deletar } = useApi();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { adicionarQuery, existeQuery, removerQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, CLIENTE);

  const editarImagem = async ({ file }) => {
    const formData = new FormData();
    formData.append('logo', file);
    const { data } = await alterar(
      `/clientes/${id}/logo`,
      formData,
      null,
      null,
      true,
    );
    if (data) {
      setCliente(data);
    }
  };

  const montarHref = texto => {
    if (texto && String(texto).length > 0) {
      return `${String(texto).replaceAll(' ', '+')},`;
    }
    return '';
  };

  const deletarImagem = async () => {
    const { data } = await deletar(
      `/clientes/${id}/logo`,
      'Foto do cliente removida com sucesso',
    );
    if (data) {
      setCliente(data);
    }
  };

  /* eslint-disable react/prop-types */
  const descricao = [
    {
      titulo: 'Dados de Identificação',
      itens: [
        { nome: 'CNPJ', propriedade: 'cnpj' },
        { nome: 'Razão Social', propriedade: 'razaoSocial' },
        { nome: 'Nome Fantasia', propriedade: 'nomeFantasia' },
      ],
    },

    {
      titulo: 'Contatos',
      naoTemSe: ({ celular, telefone, email }) =>
        !celular && !telefone && !email,
      itens: [
        {
          nome: 'Celular',
          propriedade: 'celular',
        },
        {
          nome: 'Telefone',
          propriedade: 'telefone',
        },
        {
          nome: 'E-mail',
          custom: ({ email }) =>
            email && <a href={`mailto:${email}`}>{email}</a>,
        },
      ],
    },

    {
      titulo: 'Endereço',
      itens: [
        { nome: 'Município', propriedade: 'municipio' },
        {
          nome: ({
            enderecoLogradouro: logradouro,
            enderecoNumero: numero,
            enderecoComplemento: complemento,
            enderecoBairro: bairro,
            enderecoCep: cep,
            municipio,
          }) => (
            <EnderecoContainer>
              <h4>Endereço</h4>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.google.com/maps/search/${montarHref(
                  logradouro,
                )}${montarHref(numero)}${montarHref(complemento)}${montarHref(
                  bairro,
                )}${montarHref(municipio)}${montarHref(cep)}`}
              >
                Abrir no Google Maps
              </a>
              <img src={maps} alt="Google Maps" />
            </EnderecoContainer>
          ),
          custom: ({
            enderecoLogradouro: logradouro,
            enderecoNumero: numero,
            enderecoComplemento: complemento,
            enderecoBairro: bairro,
            enderecoCep: cep,
          }) => (
            <>
              {`${logradouro}, ${numero || ''}, ${complemento || ''} ${bairro}`}
              <br />
              CEP {cep}
            </>
          ),
        },
      ],
    },

    {
      titulo: 'Logo',
      custom: () => (
        <UploadImagem
          editarImagem={editarImagem}
          deletarImagem={deletarImagem}
          imagem={{
            imagemUrl: cliente?.logoUrl,
            imagem40Url: cliente?.logo40Url,
            imagem100Url: cliente?.logo100Url,
          }}
        />
      ),
    },
  ];
  /* eslint-enable react/prop-types */

  useEffect(() => {
    const carregarDadosCliente = async () => {
      const { data } = await buscar(`/clientes/${id}`);
      setCliente(data);
    };
    carregarDadosCliente();
  }, [id, buscar]);

  const deletarCliente = async () => {
    const { uid, nomeFantasia } = cliente;
    await deletar(
      `/clientes/${uid}`,
      `O cliente ${nomeFantasia} foi excluído`,
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        onBack={() => history.goBack()}
        titulo={cliente?.apelido ?? ''}
        classe="Cliente"
        subTitulo={`de ${cliente?.municipio ?? ''}`}
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
            key="1"
            type="primary"
          >
            Nova Ordem de Produção
          </BotaoPrimario>,
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, CLIENTE)}
            key="2"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        <Descritor objeto={cliente} propriedades={descricao} />
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarCliente}>
            Excluir Cliente
          </BotaoExcluir>
        )}
      </Header>

      <OrdensTab history={history} clienteUid={id} />

      <ClienteForm
        aoFechar={() => removerQuery(CADASTRAR, CLIENTE)}
        mostrar={mostrarCadastro}
        cliente={cliente}
        afterSubmit={novoCliente => {
          if (novoCliente) {
            setCliente(novoCliente);
            removerQuery(CADASTRAR, CLIENTE);
          }
        }}
      />
    </>
  );
};

DetalhesCliente.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesCliente;

export default {
  titulo: 'Apontamentos',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'center',
        width: '110px',
      },
      {
        titulo: 'Funcionário',
        atributo: 'nomeCompleto',
        aninhamento: 'funcionario',
        variavel: true,
      },
      {
        titulo: 'Ordem',
        atributo: 'numero',
        aninhamento: 'operacao',
        subAninhamento: 'ordemProducao',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Produto',
        atributo: 'nome',
        aninhamento: 'operacao',
        subAninhamento: 'ordemProducao',
        variavel: true,
      },
      {
        titulo: 'Operação',
        atributo: 'nome',
        aninhamento: 'operacao',
      },
      {
        titulo: 'Quantidade',
        atributo: 'quantidade',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        width: '100px',
      },
      {
        titulo: 'Tempo Unitário',
        atributo: 'tempoUnitario',
        tipo: 'n-decimal',
        enumerador: 4,
        custom: ({ tempoUnitario }, format) => `${format(tempoUnitario)} min`,
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Tempo Total',
        atributo: 'tempoTotal',
        enumerador: 4,
        tipo: 'n-decimal',
        custom: ({ tempoTotal }, format) => `${format(tempoTotal)} min`,
        align: 'right',
        width: '100px',
      },
    ],
    mobile: [
      {
        titulo: 'Apontamentos',
        variavel: true,
        agrupar: [
          { atributo: 'data', tipo: 'data' },
          { atributo: 'nomeCompleto', aninhamento: 'funcionario' },
          { atributo: 'nome', aninhamento: 'operacao' },
          {
            custom: ({ operacao }) =>
              `Ordem: ${operacao?.ordemProducao?.numero}`,
          },
          {
            tipo: 'n-decimal',
            custom: ({ quantidade }, format) =>
              `Quantidade: ${format(quantidade)}`,
          },
        ],
      },
    ],
  },
};

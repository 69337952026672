import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { TurnoForm } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import {
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
} from '../../../components/layout';
import { CADASTRAR, TURNO } from '../../../hooks/useUrl/acoes';
import { DetalhesTurnoTab } from '../../../components/tabs';
import { telaGrande } from '../../../utils';

const descritores = [
  // {
  //   titulo: { nome: 'Dia da semana', propriedade: 'nome' },
  // },
  {
    titulo: 'Primeiro Horário',
    itens: [
      { nome: 'Entrada', propriedade: 'entrada1' },
      {
        nome: 'Saída',
        propriedade: 'saida1',
      },
    ],
  },
  {
    titulo: 'Segundo Horário',
    naoTemSe: ({ saida2, entrada2 }) => !saida2 && !entrada2,
    itens: [
      { nome: 'Entrada', propriedade: 'entrada2' },
      {
        nome: 'Saída',
        propriedade: 'saida2',
      },
    ],
  },
  {
    titulo: 'Terceiro Horário',
    naoTemSe: ({ saida3, entrada3 }) => !saida3 && !entrada3,
    itens: [
      { nome: 'Entrada', propriedade: 'entrada3' },
      {
        nome: 'Saída',
        propriedade: 'saida3',
        naoMostrarSe: ({ saida3 }) => !saida3,
      },
    ],
  },
];

const DetalhesCelula = ({ history, match }) => {
  const { buscar, deletar } = useApi();
  const { id } = match.params;
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, TURNO);
  const [turno, setTurno] = useState();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarDetalhesTurno = async () => {
      const { data } = await buscar(`/turnos/${id}`);
      setTurno(data);
    };
    carregarDetalhesTurno();
  }, [id, buscar]);

  const deletarTurno = async () => {
    const { uid, nome } = turno;
    await deletar(`/turnos/${uid}`, `O turno ${nome} foi excluído`, () =>
      history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        onBack={() => history.goBack()}
        titulo={turno?.nome ?? ''}
        subTitulo={`${turno?.tempoPrevisto ?? '0'} minutos`}
        classe="Turno"
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, TURNO)}
            key="1"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        {turno?.detalharDia ? (
          turno?.turnoDiaSemana.map(turno => (
            <div style={{ marginBottom: '20px' }}>
              <h2>{turno.nome}</h2>
              <Descritor objeto={turno} propriedades={descritores} />
            </div>
          ))
        ) : (
          <Descritor objeto={turno} propriedades={descritores} />
        )}

        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarTurno}>Excluir Turno</BotaoExcluir>
        )}
      </Header>

      <DetalhesTurnoTab turnoUid={turno?.uid} history={history} />

      <TurnoForm
        aoFechar={() => removerQuery(CADASTRAR, TURNO)}
        mostrar={mostrarCadastro}
        turno={turno}
        afterSubmit={novoTurno => {
          if (novoTurno) {
            setTurno(novoTurno);
            removerQuery(CADASTRAR, TURNO);
          }
        }}
      />
    </>
  );
};

DetalhesCelula.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesCelula;

export default [
  { naoNulo: true, tipo: 'string', atributo: 'nome', nome: 'Nome' },
  { naoNulo: true, tipo: 'string', atributo: 'maquina', nome: 'Maquina' },
  { naoNulo: true, tipo: 'numero', atributo: 'tempoCalcular', nome: 'Tempo' },
  {
    naoNulo: true,
    tipo: 'numero',
    atributo: 'quantidadeCalcular',
    nome: 'Quantidade',
  },
  { naoNulo: true, tipo: 'numero', atributo: 'tempo', nome: 'Tempo Unitário' },
];

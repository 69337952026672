export default {
  titulo: 'Apontamentos por Célula',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'center',
        width: '110px',
      },
      {
        titulo: 'Célula',
        atributo: 'nome',
        aninhamento: 'celula',
        width: '150px',
      },
      {
        titulo: 'Ordem',
        atributo: 'numero',
        aninhamento: 'ordemProducao',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Referência',
        atributo: 'referencia',
        aninhamento: 'ordemProducao',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Produto',
        atributo: 'nome',
        aninhamento: 'ordemProducao',
        variavel: true,
      },
      {
        titulo: 'Quantidade',
        atributo: 'quantidade',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        width: '100px',
      },
      {
        titulo: 'Tempo Unitário',
        atributo: 'tempoUnitario',
        tipo: 'n-decimal',
        enumerador: 4,
        custom: ({ tempoUnitario }, formatar) =>
          `${formatar(tempoUnitario)} min`,
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Tempo Total',
        atributo: 'tempoTotal',
        custom: ({ tempoTotal }, formatar) => `${formatar(tempoTotal)} min`,
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 4,
        width: '100px',
      },
      {
        titulo: 'Preço Unitário',
        atributo: 'precoUnitario',
        tipo: 'n-decimal',
        align: 'right',
        enumerador: 4,
        width: '120px',
      },
      {
        titulo: 'Valor Total',
        atributo: 'valorTotal',
        tipo: 'n-decimal',
        align: 'right',
        enumerador: 2,
        width: '100px',
      },
    ],
    mobile: [
      {
        titulo: 'Apontamentos',
        variavel: true,
        agrupar: [
          { atributo: 'data', tipo: 'data' },
          { atributo: 'nome', aninhamento: 'celula' },
          {
            custom: ({ ordemProducao }) => `Ordem: ${ordemProducao?.numero}`,
          },
          {
            tipo: 'n-decimal',
            custom: ({ quantidade }, format) =>
              `Quantidade: ${format(quantidade)}`,
          },
        ],
      },
    ],
  },
};

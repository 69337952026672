import { Spin, Form, Radio, Drawer } from 'antd';
import moment from 'moment';
import { any, bool, func, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { SituacaoFuncionario } from '../../../../utils/enum';
import { BotaoPrimario, Campo } from '../../../layout';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { DatePicker, RadioGroup } from '../../commonStyles';

// const { Group } = Radio;

const SituacaoForm = ({ afterSubmit, funcionario, mostrar, aoFechar }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const [situacaoSelecionada, setSituacao] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const { dataSaida, dataRetorno, situacao } = funcionario ?? {};
    lerObjectProp(
      {
        dataSaida: dataSaida && moment(dataSaida),
        dataRetorno: dataRetorno && moment(dataRetorno),
        situacao,
      },
      form.setFieldsValue,
      form.resetFields,
    );
    setSituacao(situacao);
  }, [form.resetFields, form.setFieldsValue, funcionario]);

  const funcionarioAtivo = (situacao, retorno, saida) => {
    switch (true) {
      case situacao === 'A':
        return true;
      case situacao === 'F' || situacao === 'B' || situacao === 'O':
        return retorno === funcionario?.dataRetorno || null;
      case situacao === 'I':
        return saida === funcionario?.dataSaida || null;
      default:
        return null;
    }
  };

  const alterarSituacao = async ({ situacao, dataRetorno, dataSaida }) => {
    const { nomeCompleto, uid } = funcionario ?? {};
    const { data } = await alterar(
      `/funcionarios/${uid}`,
      {
        situacao,
        dataRetorno,
        dataSaida,
        ativo: funcionarioAtivo(situacao, dataRetorno, dataSaida),
      },
      funcionario,
      `A situação de ${nomeCompleto} foi alterada para ${SituacaoFuncionario[situacao]}`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 'auto' : '100%'}
      title="Alterar Situação"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          name="tempo-ajuste"
          onFinish={values =>
            onFinish(
              { ...values, objectUid: funcionario?.uid },
              null,
              alterarSituacao,
              afterSubmit,
            )
          }
        >
          <Campo label="Nova Situação" name="situacao">
            <RadioGroup
              value={situacaoSelecionada}
              onChange={({ target }) => {
                setSituacao(target.value);
              }}
            >
              {Object.keys(SituacaoFuncionario).map(
                (key, index) =>
                  key !== 'D' && (
                    <div key={index}>
                      <Radio value={key}>{SituacaoFuncionario[key]}</Radio>
                    </div>
                  ),
              )}
            </RadioGroup>
          </Campo>

          <Campo
            hidden={
              situacaoSelecionada !== 'O' &&
              situacaoSelecionada !== 'B' &&
              situacaoSelecionada !== 'F'
            }
            mostrarFoco={
              mostrar &&
              (situacaoSelecionada === 'O' ||
                situacaoSelecionada === 'B' ||
                situacaoSelecionada === 'F')
            }
            name="dataRetorno"
            label="Data de retorno"
          >
            <DatePicker placeholder="Selecione uma data" format="DD/MM/YYYY" />
          </Campo>

          <Campo
            mostrarFoco={mostrar && situacaoSelecionada === 'I'}
            hidden={situacaoSelecionada !== 'I'}
            name="dataSaida"
            label="Data de saída"
          >
            <DatePicker placeholder="Selecione uma data" format="DD/MM/YYYY" />
          </Campo>

          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

SituacaoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
  funcionario: objectOf(any).isRequired,
};

SituacaoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default SituacaoForm;

import { Drawer, Form, Spin } from 'antd';
import moment from 'moment';
import { any, bool, func, objectOf } from 'prop-types';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../hooks';
import { telaGrande } from '../../../utils';
import { BotaoPrimario, Campo } from '../../layout';
import { lerObjectProp, onFinish } from '../commonFunctions';
import { DatePicker, TextArea } from '../commonStyles';

const DiasNaoTrabalhadoForm = ({ mostrar, aoFechar, afterSubmit, dia }) => {
  const [form] = Form.useForm();
  const { criar, alterar } = useApi();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      dia ? { ...dia, data: moment(dia?.data) } : null,
      form.setFieldsValue,
      form.resetFields,
    );
  }, [dia, form.setFieldsValue, form.resetFields]);

  const cadastrarDia = async values => {
    const { data } = await criar(
      '/dias-nao-trabalhados',
      values,
      `O dia não trabalhado ${values?.data.format('DD/MM/YYYY')} foi criado`,
    );
    return data;
  };

  const editarDia = async values => {
    const { data } = await alterar(
      `/dias-nao-trabalhados/${dia?.uid}`,
      values,
      dia,
      `O dia não trabalhado ${moment(dia?.data).format(
        'DD/MM/YYYY',
      )} foi alterado`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title={`${dia?.uid ? 'Alterar' : 'Novo'} Dia`}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, objectUid: dia?.uid },
              cadastrarDia,
              editarDia,
              afterSubmit,
              () =>
                form.setFieldsValue({
                  data: null,
                  motivo: null,
                }),
            );
          }}
        >
          <Campo name="data" label="Data" obrigatorio fluido>
            <DatePicker format="DD/MM/YYYY" placeholder="Selecione uma data" />
          </Campo>

          <Campo name="motivo" label="Motivo" fluido obrigatorio>
            <TextArea rows={4} placeholder="Preencha o motivo" />
          </Campo>

          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

DiasNaoTrabalhadoForm.propTypes = {
  mostrar: bool,
  afterSubmit: func,
  aoFechar: func,
  dia: objectOf(any),
};

DiasNaoTrabalhadoForm.defaultProps = {
  dia: null,
  mostrar: null,
  afterSubmit: null,
  aoFechar: null,
};

export default DiasNaoTrabalhadoForm;

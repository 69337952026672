import React, { Fragment } from 'react';
import {
  string,
  object,
  arrayOf,
  bool,
  func,
  objectOf,
  any,
  number,
} from 'prop-types';
import { Spin } from 'antd';
import { formatar, telaGrande } from '../../../utils';
import { getTabela } from '../../../components/layout/Tabela/functions';
import {
  Cell,
  Table,
  Row,
  HeaderCell,
  CellButton,
  TableTitle,
  SubTitle,
} from './styles';

const Tabela = props => {
  const {
    dados,
    cabecalho,
    mesSelecionado,
    celulaSelecionada,
    colunas,
    ordenarPor,
    ordenacao,
    loading,
    footer,
    titulo,
  } = props;

  const { titulo: tituloTabela, cabecalho: cabecalhoConfig } = cabecalho
    ? getTabela(cabecalho)
    : colunas;
  const cabecalhoTabelaLayout = telaGrande()
    ? cabecalhoConfig.desktop
    : cabecalhoConfig.mobile;

  const cabecalhoTabela = mesSelecionado
    ? cabecalhoTabelaLayout.filter(
        ({ atributo }) =>
          atributo === 'codigo' ||
          atributo === 'nome' ||
          atributo === mesSelecionado,
      )
    : cabecalhoTabelaLayout;

  let dadosOrdenados = mesSelecionado
    ? dados.map(item => {
        return {
          ...item,
          meses: { [mesSelecionado]: item.meses?.[mesSelecionado] },
        };
      })
    : dados;

  if (ordenarPor && ordenacao) {
    const arrays = ordenacao.map(valor => {
      return dados?.filter(({ conta }) => conta?.[ordenarPor] === valor);
    });
    let novoArr = [];
    arrays.forEach(arr => {
      if (arr?.length > 0) {
        novoArr = novoArr.concat([
          {
            texto: arr[0].conta.natureza,
            quebrarLinha: true,
            colSpan: cabecalhoTabela.length,
          },
          ...arr,
        ]);
      }
    });
    dadosOrdenados = novoArr;
  }

  const renderizarCelula = (
    {
      atributo,
      aninhamento,
      tipo,
      valorMonetario,
      clickable,
      align,
      titulo: tituloCelula,
      colSpan,
      negrito,
    },
    cabIndex,
    dado,
  ) => {
    const valor = aninhamento ? dado[aninhamento]?.[atributo] : dado[atributo];
    const textoFinal = tipo ? formatar(tipo, valor ?? '') : valor;
    return (
      <Cell key={cabIndex} align={align} colSpan={colSpan} negrito={negrito}>
        {valor && (
          <CellButton
            clickable={clickable}
            valorMonetario={valorMonetario}
            texto={textoFinal}
            onClick={() =>
              clickable && celulaSelecionada?.(textoFinal, dado, tituloCelula)
            }
          >
            {textoFinal}
          </CellButton>
        )}
      </Cell>
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        {titulo && <TableTitle>{tituloTabela}</TableTitle>}
        <Table>
          <thead>
            <Row alta="true" cor="rgb(245,245,245)">
              {cabecalhoTabela.map(({ titulo: tituloCelula, align }, index) => {
                return (
                  <HeaderCell key={index} align={align}>
                    {tituloCelula}
                  </HeaderCell>
                );
              })}
            </Row>
          </thead>
          <tbody>
            {dadosOrdenados.map(
              ({ quebrarLinha, texto, colSpan, ...dado }, dadoIndex) => {
                return (
                  <Fragment key={dadoIndex}>
                    {quebrarLinha ? (
                      <Row cor="rgb(252,252,252)">
                        <SubTitle align="left" colSpan={colSpan}>
                          {texto}
                        </SubTitle>
                      </Row>
                    ) : (
                      <Row>
                        {cabecalhoTabela.map((cab, index) => {
                          return renderizarCelula(cab, index, dado);
                        })}
                      </Row>
                    )}
                  </Fragment>
                );
              },
            )}
          </tbody>
          <tfoot>
            {footer && (
              <>
                {footer.dados.map((dado, dadoIndex) => {
                  return (
                    <Row
                      key={dadoIndex}
                      alta={`${dado[0] === 'Resultado'}`}
                      cor={
                        dado[0] === 'Resultado'
                          ? 'rgb(245,245,245)'
                          : 'rgb(252,252,252)'
                      }
                    >
                      {footer.cabecalho.map((cab, cabIndex) => {
                        return renderizarCelula(cab, cabIndex, dado);
                      })}
                    </Row>
                  );
                })}
              </>
            )}
          </tfoot>
        </Table>
      </Spin>
    </>
  );
};

Tabela.propTypes = {
  colunas: objectOf(any),
  loading: bool,
  titulo: bool,
  mesSelecionado: number,
  dados: arrayOf(object).isRequired,
  cabecalho: string,
  ordenarPor: string,
  ordenacao: arrayOf(string),
  celulaSelecionada: func,
  footer: objectOf(any),
};

Tabela.defaultProps = {
  titulo: null,
  colunas: null,
  cabecalho: null,
  footer: null,
  loading: false,
  ordenarPor: null,
  ordenacao: null,
  mesSelecionado: null,
  celulaSelecionada: null,
};

export default Tabela;

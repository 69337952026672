import React, { useEffect, useState } from 'react';
import { Image, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, shallowEqual } from 'react-redux';
import { any, func, objectOf } from 'prop-types';
import { Upload, PlaceholderImage } from './styles';

const UploadImagem = ({ editarImagem, deletarImagem, imagem }) => {
  const [lista, setLista] = useState([]);
  const [mostrarPreview, setMostrarPreview] = useState(false);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const validarUpload = ({ type, size }) => {
    if (!(type === 'image/jpeg' || type === 'image/png')) {
      message.error('Imagem deve ser no formato PNG ou JPEG!');
      return false;
    }
    if (!(size / 1024 / 1024 < 2)) {
      message.error('Imagem deve ser menor que 2MB!');
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { imagem100Url } = imagem ?? {};
    if (imagem100Url) {
      return setLista([
        {
          thumbUrl: imagem100Url,
          uid: 1,
          type: 'image/png',
          name: 'imagem.png',
        },
      ]);
    }
    return setLista([]);
  }, [imagem]);

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={lista}
        onPreview={() => setMostrarPreview(true)}
        onRemove={file => deletarImagem?.(file)}
        beforeUpload={validarUpload}
        customRequest={editarImagem}
        onChange={({ fileList, file }) =>
          file?.status === 'done' && setLista(fileList)
        }
      >
        {lista.length === 0 && (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>{loading ? 'Carregando...' : 'Enviar'}</div>
          </div>
        )}
      </Upload>

      <Modal
        visible={mostrarPreview}
        title="Imagem"
        centered
        footer={null}
        onCancel={() => setMostrarPreview(false)}
      >
        <Image
          src={imagem?.imagemUrl}
          placeholder={
            <PlaceholderImage preview={false} src={imagem?.imagem100Url} />
          }
        />
      </Modal>
    </>
  );
};

UploadImagem.propTypes = {
  editarImagem: func,
  deletarImagem: func,
  imagem: objectOf(any),
};

UploadImagem.defaultProps = {
  deletarImagem: null,
  editarImagem: null,
  imagem: null,
};

export default UploadImagem;

export default {
  titulo: 'Previsão prêmios',
  cabecalho: {
    desktop: [
      {
        titulo: 'Nome',
        atributo: 'funcionarioNome',
        // tipo: 'string',
      },
      {
        titulo: 'Eficiência',
        atributo: 'eficiencia',
        tipo: 'n-decimal',
        enumerador: 2,
      },
      {
        titulo: 'Valor Previsto',
        atributo: 'valorPrevisto',
        tipo: 'n-decimal',
        enumerador: 2,
      },
    ],
    mobile: [
      {
        titulo: 'Prêmios',
        variavel: true,
        agrupar: [
          {
            tipo: 'data',
            custom: ({ data }, format) => `Data: ${format(data)}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorFaturado }, format) =>
              `Estimado: R$${format(valorFaturado)}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorPremio }, format) =>
              `Prêmio: R$${format(valorPremio)}`,
          },
        ],
      },
    ],
  },
};

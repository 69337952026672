import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { any, objectOf } from 'prop-types';
import {
  BotaoPrimario,
  Filtros,
  Header,
  Tabela,
} from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { Container } from '../styles';
import { PremiacaoForm } from '../../components/forms';
import { APURACOES } from '../../components/layout/Tabela/nomesCabecalhos';
import { CADASTRAR, APURACAO } from '../../hooks/useUrl/acoes';

const { ApuracaoForm } = PremiacaoForm;
const { Tags } = Filtros;

const Premios = ({ history }) => {
  const { buscar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const [filtro, setFiltro] = useState('');
  const [apuracoes, setApuracoes] = useState([]);
  const mostrarCadastro = existeQuery(CADASTRAR, APURACAO);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    const buscarApuracoes = async () => {
      const { data } = await buscar(`/apuracoes?&$limite=10&$pagina=${pagina}`);
      setApuracoes(apuracao => [...apuracao, ...data]);
    };
    buscarApuracoes();
  }, [buscar, pagina]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setPagina(page => page + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#final'));
    return () => intersectionObserver.disconnect();
  }, []);

  const retornarQuery = query => filtro !== query && setFiltro(query);
  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        titulo="Apurações"
        extra={[
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, APURACAO)}
          >
            Nova apuração
          </BotaoPrimario>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabela
        cabecalho={APURACOES}
        dados={apuracoes}
        loading={loading}
        celulaSelecionada={({ uid }) => history.push(`/apuracoes/${uid}`)}
      />
      <div id="final" />

      <ApuracaoForm
        mostrar={mostrarCadastro}
        aoFechar={() => removerQuery(CADASTRAR, APURACAO)}
        afterSubmit={apuracao => {
          if (apuracao?.uid) {
            removerQuery(CADASTRAR, APURACAO);
            history.push(`/apuracoes/${apuracao?.uid}`);
          }
        }}
      />
    </Container>
  );
};

Premios.propTypes = {
  history: objectOf(any).isRequired,
};

export default Premios;

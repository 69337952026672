import { any, number, objectOf } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi, useUrl } from '../../../hooks';
import { OPERACAO, PARADA, TAB } from '../../../hooks/useUrl/acoes';
import { Tabs } from '../../../styles/global';
import { Tabela } from '../../layout';
import {
  OPERACOES_ORDEM_PRODUCAO,
  PARADAS_MAQUINA,
} from '../../layout/Tabela/nomesCabecalhos';

const { TabPane } = Tabs;

const DetalhesMaquinaTab = ({ history, maquinaUid }) => {
  const { buscar } = useApi();
  const [operacoes, setOperacoes] = useState([]);
  const [paradas, setParadas] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { pegarValorQuery, adicionarQuery } = useUrl(history);
  const tabAtiva = pegarValorQuery(TAB) ?? OPERACAO;

  useEffect(() => {
    const carregarOperacoes = async () => {
      const { data } = await buscar(
        `/ordens-producao/operacoes?maquinaUid[eq]=${maquinaUid}`,
      );
      setOperacoes(data);
    };
    const carregarParadas = async () => {
      const { data } = await buscar(
        // `/registros-ponto/paradas?maquinaUid[eq]=${maquinaUid}&$rel[motivoParada]=nome&$rel[registroPonto]=data&$rel[registroPonto>celula]=nome&$rel[registroPonto>funcionario]=nomeCompleto`,
        `/registros-ponto-paradas?maquinaUid[eq]=${maquinaUid}&$rel[motivoParada]=nome&$rel[registroPonto]=data&$rel[registroPonto>celula]=nome&$rel[registroPonto>funcionario]=nomeCompleto`,
      );
      setParadas(data);
    };
    if (maquinaUid) {
      carregarOperacoes();
      carregarParadas();
    }
  }, [buscar, maquinaUid]);

  return (
    <Tabs
      defaultActiveKey={tabAtiva}
      activeKey={tabAtiva}
      onChange={key => adicionarQuery(TAB, key)}
    >
      <TabPane tab="Operações" key={OPERACAO}>
        <Tabela
          loading={loading}
          cabecalho={OPERACOES_ORDEM_PRODUCAO}
          dados={operacoes}
          celulaSelecionada={({ ordemProducaoUid }) =>
            history.push(`/ordens-producao/${ordemProducaoUid}`)
          }
        />
      </TabPane>
      <TabPane tab="Paradas" key={PARADA}>
        <Tabela
          loading={loading}
          cabecalho={PARADAS_MAQUINA}
          dados={paradas}
          celulaSelecionada={({ registroPontoUid }) =>
            history.push(`/registros-ponto/${registroPontoUid}`)
          }
        />
      </TabPane>
    </Tabs>
  );
};

DetalhesMaquinaTab.propTypes = {
  history: objectOf(any).isRequired,
  maquinaUid: number,
};

DetalhesMaquinaTab.defaultProps = {
  maquinaUid: null,
};

export default DetalhesMaquinaTab;

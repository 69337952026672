import styled from 'styled-components';
import { Row as AntRow, Card as AntCard } from 'antd';

export const Card = styled(AntCard)`
  width: 100%;
  @media only screen and (max-device-width: 640px) {
    .ant-card-body {
      padding: 5px !important;
    }
  }
`;

export const LinkButton = styled.button`
  color: #40a9ff;
  border: none;
  outline: none;
  :hover {
    cursor: pointer;
  }
`;

export const Lista = styled.ul`
  margin-left: 17px;
`;

export const Row = styled(AntRow)`
  margin-bottom: 10px;
  padding: 0px 10px;
`;

export const CardContainer = styled.div`
  margin-bottom: 10px;
  ${Card}:not(:last-child) {
    margin-bottom: 10px;
  }
  @media only screen and (min-device-width: 640px) {
    display: flex;
    ${Card}:not(:last-child) {
      margin: 0px 10px 0px 0px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { Tag, Spin, Empty } from 'antd';
import { objectOf, any } from 'prop-types';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { useApi, useUrl } from '../../hooks';
import {
  Filtros,
  Descritor,
  Header,
  BotaoPrimario,
} from '../../components/layout';
import { Container } from '../styles';
import { Icone } from '../../styles/global';
import { telaGrande } from '../../utils';
import {
  OrdemProducaoForm,
  ApontamentoForm,
  LancamentoForm,
} from '../../components/forms';
import {
  CADASTRAR,
  BUSCAR,
  ORDEM,
  APONTAMENTO,
  RECEBIMENTO,
  TAB,
  ORDEM_CONCLUIDA,
  FUNCIONARIO,
} from '../../hooks/useUrl/acoes';
import {
  List,
  Item,
  Card,
  CardContainer,
  TransparentButton,
  ItemContainer,
  CardHeaderContainer,
  CardHeader,
  Link,
  Badge,
  FlexContainer,
  Row,
  Col,
  RowContainer,
} from './styles';

const { TituloComFiltro } = Filtros;
const { OrdemForm } = OrdemProducaoForm;
const { CriarLancamentoForm } = LancamentoForm;
/* eslint-disable react/prop-types */
const descritores = [
  {
    itens: [
      {
        nome: 'Produto',
        naoMostrarSe: () => telaGrande(),
        propriedade: 'nome',
      },
      {
        nome: 'Peças Completas',
        semMargem: !telaGrande(),
        custom: ({
          quantidadeApontada,
          quantidade,
          dataInicio,
          dataConclusao,
          quantidadeApontadaCelula,
        }) =>
          dataInicio && !dataConclusao
            ? `${
                quantidadeApontada ?? quantidadeApontadaCelula
              } de ${quantidade}`
            : quantidade,
        naoMostrarSe: ({ dataInicio, dataConclusao }) =>
          !(dataInicio && !dataConclusao),
      },
      {
        nome: 'Quantidade',
        semMargem: !telaGrande(),
        custom: ({ quantidade }) => quantidade,
        naoMostrarSe: ({ dataInicio, dataConclusao }) =>
          dataInicio && !dataConclusao,
      },
      {
        semMargem: true,
        naoMostrarSe: ({ numeroCliente, cliente }) =>
          !numeroCliente && !cliente,
        nome: ({ cliente, numeroCliente }) =>
          !cliente && numeroCliente ? (
            <h4>Cliente Não Informado</h4>
          ) : (
            <FlexContainer>
              <h4>{cliente?.apelido}</h4>
              {cliente?.logo40Url && (
                <Icone
                  imagem={cliente?.logo40Url}
                  width="20px"
                  height="20px"
                  margin="0px 0px 0px 10px"
                />
              )}
            </FlexContainer>
          ),
        custom: ({ numeroCliente }) =>
          numeroCliente ? `N° ${numeroCliente}` : '',
      },
    ],
  },
];
/* eslint-enable react/prop-types */
const locale = {
  emptyText: (
    <Empty description="Nada encontrado" image={Empty.PRESENTED_IMAGE_SIMPLE} />
  ),
};
const KanbanProducao = ({ history }) => {
  const { adicionarQuery, removerQuery, existeQuery, pegarValorQuery } = useUrl(
    history,
  );
  const { buscar } = useApi();
  const busca = pegarValorQuery(BUSCAR);
  const [apontamento, setApontamento] = useState({});
  const [ordemSelecionada, setOrdemSelecionada] = useState({});
  const [ordensIniciadas, setOrdensIniciadas] = useState([]);
  const [ordensConcluidas, setOrdensConcluidas] = useState([]);
  const [ordensNaoIniciadas, setOrdensNaoIniciadas] = useState([]);
  const [ordensNaoRecebidas, setOrdensNaoRecebidas] = useState([]);
  const mostrarCadastroApontamento = existeQuery(CADASTRAR, APONTAMENTO);
  const mostrarCadastroOrdem = existeQuery(CADASTRAR, ORDEM);
  const mostrarCadastroRecebimento = existeQuery(CADASTRAR, RECEBIMENTO);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarOrdens = async () => {
      const { data } = await buscar(
        `/ordens-producao/?$limite=999999&$rel[cliente]=apelido,logo40,logo40Url${
          busca ? `&$buscar=${busca}` : ''
        }`,
      );
      // const naoIniciadas = data?.filter(({ dataInicio }) => !dataInicio);
      const naoIniciadas = data?.filter(({ dataInicio }) => !dataInicio);
      const iniciadas = data?.filter(
        ({ dataInicio, dataConclusao, dataConclusaoCelula }) =>
          (dataInicio && !dataConclusao && !dataConclusaoCelula) ||
          (dataInicio && dataConclusao && !dataConclusaoCelula) ||
          (dataInicio && !dataConclusao && dataConclusaoCelula),
      );
      const concluidas = data?.filter(
        ({ dataConclusao, dataConclusaoCelula }) => {
          return (
            dataConclusao &&
            dataConclusaoCelula &&
            moment().diff(moment(dataConclusao, 'yyyy-MM-DD'), 'days') <= 10
          );
        },
      );

      setOrdensNaoRecebidas(
        concluidas?.filter(({ dataQuitacao }) => !dataQuitacao),
      );
      setOrdensConcluidas(
        concluidas?.filter(({ dataQuitacao }) => dataQuitacao),
      );
      setOrdensIniciadas(iniciadas);
      setOrdensNaoIniciadas(naoIniciadas);
    };
    carregarOrdens();
  }, [buscar, busca]);

  const aplicarCor = ({
    dataCombinadoCliente: dataCombinado,
    dataConclusao,
  }) => {
    if (dataCombinado && !dataConclusao) {
      const [year, month, day] = moment().toArray();
      const diff = moment(dataCombinado).diff(
        moment({ year, month, day }),
        'days',
      );
      switch (true) {
        case diff < 5 && diff > 0:
          return 'yellow';
        case diff === 0:
          return 'orange';
        case diff < 0:
          return 'red';
        default:
          return null;
      }
    }
    return null;
  };

  const renderLista = dados => {
    return (
      <Spin spinning={loading}>
        <List
          locale={locale}
          bordered
          dataSource={dados}
          renderItem={(ordem, index) => {
            const cor = aplicarCor(ordem);
            return (
              <ItemContainer
                borda={cor}
                key={index}
                onClick={
                  !ordem.dataQuitacao
                    ? () => {
                        if (ordem.dataConclusao && ordem.dataConclusaoCelula) {
                          setOrdemSelecionada(ordem);
                          adicionarQuery(CADASTRAR, RECEBIMENTO);
                        } else {
                          setApontamento({ ordemProducaoUid: ordem.uid });
                          adicionarQuery(CADASTRAR, APONTAMENTO);
                        }
                      }
                    : null
                }
              >
                <CardHeader cor={cor}>
                  <CardHeaderContainer>
                    <Link
                      to={`/ordens-producao/${ordem.uid}${
                        ordem.dataInicio ? `?${TAB}=${FUNCIONARIO}` : ''
                      }`}
                      onClick={event => event.stopPropagation()}
                    >
                      {ordem.numero}
                    </Link>
                    {telaGrande() && (
                      <span>{`${ordem.nome} ${ordem.referencia}`}</span>
                    )}
                    <Tag
                      visible={cor === 'red'}
                      title="Esta Ordem Está Atrasada!"
                    >
                      Atrasada
                    </Tag>
                    <Tag
                      visible={cor === 'yellow'}
                      title="A Data Limite Está Próxima!"
                    >
                      {moment(ordem.dataCombinadoCliente, 'yyyy-MM-DD').format(
                        'DD/MM',
                      )}
                    </Tag>
                    <Tag
                      visible={cor === 'orange'}
                      title="A Data Limite é Hoje!"
                    >
                      Hoje
                    </Tag>
                  </CardHeaderContainer>
                </CardHeader>
                <Item>
                  <Descritor
                    inline
                    semSection
                    objeto={ordem}
                    propriedades={descritores}
                  />
                </Item>
              </ItemContainer>
            );
          }}
        />
      </Spin>
    );
  };

  const callback = data => {
    if (data) {
      const { operacao, ordemProducao } = data;
      let ordem = ordemProducao;
      if (!ordemProducao) {
        ordem = operacao.ordemProducao;
      }
      const {
        dataConclusao,
        dataConclusaoCelula,
        dataInicio,
        dataQuitacao,
        uid: ordemUid,
      } = ordem;
      if (dataConclusao && dataConclusaoCelula && !dataQuitacao) {
        setOrdensIniciadas(
          ordensIniciadas.filter(({ uid }) => uid !== ordemUid),
        );
        setOrdensNaoIniciadas(
          ordensNaoIniciadas.filter(({ uid }) => uid !== ordemUid),
        );
        return setOrdensNaoRecebidas([ordem, ...ordensNaoRecebidas]);
      }
      if (dataInicio && !dataQuitacao) {
        setOrdensNaoIniciadas(
          ordensNaoIniciadas.filter(({ uid }) => uid !== ordemUid),
        );
        return setOrdensIniciadas([
          ordem,
          ...ordensIniciadas.filter(({ uid }) => uid !== ordemUid),
        ]);
      }
    }
    return null;
  };

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro
            titulo="Kanban de Produção"
            history={history}
            busca={busca}
          />
        }
        extra={[
          <BotaoPrimario
            key="1"
            onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
            title="Cadastrar Nova Ordem"
            type="primary"
          >
            Nova Ordem de Produção
          </BotaoPrimario>,
        ]}
      />
      <RowContainer>
        <Row gutter={10}>
          <Col span={6}>
            <Card
              bordered={false}
              title={
                <CardContainer>
                  <span>
                    A Produzir
                    <Badge count={ordensNaoIniciadas?.length || 0} />
                  </span>
                  <TransparentButton
                    onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
                    title="Cadastrar Nova Ordem"
                  >
                    {telaGrande() ? 'Nova Ordem' : 'Nova'}
                  </TransparentButton>
                </CardContainer>
              }
            >
              {renderLista(ordensNaoIniciadas)}
            </Card>
          </Col>
          <Col span={6}>
            <Card
              bordered={false}
              title={
                <span>
                  Em Produção
                  <Badge
                    count={ordensIniciadas?.length || 0}
                    transparente="true"
                  />
                </span>
              }
            >
              {renderLista(ordensIniciadas)}
            </Card>
          </Col>

          <Col span={6}>
            <Card
              bordered={false}
              title={
                <span>
                  A Receber <Badge count={ordensNaoRecebidas?.length || 0} />
                </span>
              }
            >
              {renderLista(ordensNaoRecebidas)}
            </Card>
          </Col>

          <Col span={6}>
            <Card
              bordered={false}
              title={
                <CardContainer>
                  <span>
                    Concluídas
                    <Badge
                      count={ordensConcluidas?.length || 0}
                      transparente="true"
                    />
                  </span>
                  <TransparentButton
                    title="Visualizar Todas as Ordens de Produção"
                    onClick={() =>
                      history.push(
                        `/ordens-producao?${ORDEM}=-dataConclusao&${TAB}=${ORDEM_CONCLUIDA}`,
                      )
                    }
                  >
                    {telaGrande() ? 'Ver Todas' : 'Todas'}
                  </TransparentButton>
                </CardContainer>
              }
            >
              {renderLista(ordensConcluidas)}
            </Card>
          </Col>
        </Row>
      </RowContainer>
      <OrdemForm
        aoFechar={() => removerQuery(CADASTRAR, ORDEM)}
        mostrar={mostrarCadastroOrdem}
        afterSubmit={ordem => {
          if (ordem) {
            history.push(`/ordens-producao/${ordem.uid}`);
          }
        }}
      />

      <ApontamentoForm
        history={history}
        aoFechar={() => removerQuery(CADASTRAR, APONTAMENTO)}
        mostrar={mostrarCadastroApontamento}
        apontamento={apontamento}
        afterSubmit={callback}
      />

      <CriarLancamentoForm
        tipoLancamento="entrada"
        mostrar={mostrarCadastroRecebimento}
        ordem={ordemSelecionada}
        aoFechar={() => removerQuery(CADASTRAR, RECEBIMENTO)}
        afterSubmit={data => {
          if (data?.ordem) {
            const { ordem } = data;
            setOrdemSelecionada({});
            if (ordem?.dataQuitacao) {
              setOrdensNaoRecebidas(
                ordensNaoRecebidas.filter(({ uid }) => uid !== ordem?.uid),
              );
              setOrdensConcluidas([ordem, ...ordensConcluidas]);
            } else {
              setOrdensNaoRecebidas([
                ordem,
                ...ordensNaoRecebidas.filter(({ uid }) => uid !== ordem?.uid),
              ]);
            }
            removerQuery(CADASTRAR, RECEBIMENTO);
          }
        }}
      />
    </Container>
  );
};

KanbanProducao.propTypes = {
  history: objectOf(any).isRequired,
};

export default KanbanProducao;

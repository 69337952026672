import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { Link } from 'react-router-dom';
import { CelulaForm } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import {
  BotaoExcluir,
  BotaoPrimario,
  Header,
} from '../../../components/layout';
import { CADASTRAR, CELULA } from '../../../hooks/useUrl/acoes';
import DetalhesCelulaTab from '../../../components/tabs/DetalhesCelulaTab';
import { telaGrande } from '../../../utils';

const DetalhesCelula = ({ history, match }) => {
  const { buscar, deletar } = useApi();
  const { id } = match.params;
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, CELULA);
  const [celula, setCelula] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarDetalhesCelula = async () => {
      const { data } = await buscar(`/celulas/${id}?$rel[turno]=nome`);
      setCelula(data);
    };
    carregarDetalhesCelula();
  }, [id, buscar]);

  const deletarCelula = async () => {
    const { uid, nome } = celula;
    await deletar(`/celulas/${uid}`, `A célula ${nome} foi excluída`, () =>
      history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        classe="Célula"
        onBack={() => history.goBack()}
        titulo={`${celula?.nome ?? ''}`}
        subTitulo={() => (
          <>
            Turno{' '}
            <Link to={`/turnos/${celula?.turnoUidPadrao}`}>
              {celula?.turno?.nome}
            </Link>
          </>
        )}
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, CELULA)}
            key="1"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarCelula}>Excluir Célula</BotaoExcluir>
        )}
      </Header>

      <DetalhesCelulaTab history={history} celula={celula} />

      <CelulaForm
        aoFechar={() => removerQuery(CADASTRAR, CELULA)}
        mostrar={mostrarCadastro}
        celula={celula}
        afterSubmit={novaCelula => {
          if (novaCelula) {
            setCelula(novaCelula);
            removerQuery(CADASTRAR, CELULA);
          }
        }}
      />
    </>
  );
};

DetalhesCelula.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesCelula;

export default {
  titulo: 'Tabela de clientes',
  cabecalho: {
    desktop: [
      {
        titulo: 'Nome',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'logo40Url',
              alt: ['apelido', 'razaoSocial'],
            },
          },
          { atributo: 'apelido' },
          { atributo: 'nomeFantasia' },
        ],
      },
      {
        titulo: 'CNPJ',
        atributo: 'cnpj',
      },
      {
        titulo: 'Município',
        atributo: 'municipio',
      },
      {
        titulo: 'E-mail',
        atributo: 'email',
      },
      {
        titulo: 'Celular',
        atributo: 'celular',
      },
    ],
    mobile: [
      {
        titulo: 'Contas',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'logo40Url',
              alt: ['apelido', 'razaoSocial'],
            },
          },
          { atributo: 'apelido' },
          { atributo: 'nomeFantasia' },
        ],
      },
    ],
  },
};

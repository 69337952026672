import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { any, objectOf } from 'prop-types';
import {
  BotaoPrimario,
  Estatistica,
  Header,
  Tabela,
} from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { OrdemProducaoForm } from '../../components/forms';
import TituloComFiltro from '../../components/layout/Filtros/TituloComFiltro';
import { CADASTRAR, ORDEM } from '../../hooks/useUrl/acoes';
import { formatar } from '../../utils';
import { Container } from '../styles';
import {
  ORDENS_NAO_PLANEJADAS,
  ORDENS_PLANEJADAS,
} from '../../components/layout/Tabela/nomesCabecalhos';
import {
  PRIORIZAR,
  REMOVER_PRIORIDADE,
} from '../../components/layout/Tabela/nomesAcoes';
import {
  TableContainer,
  FlexContainer,
  TituloContainer,
  EstatisticaContainer,
  CategoriaEstatistica,
  Select,
} from './styles';

const { Option } = Select;
const { OrdemForm } = OrdemProducaoForm;
const urlBase = `/ordens-producao?$campos=uid,tempoFinal,dataPrevisaoInicio,dataPrevisaoConclusao,prioridade,numero,foto40,foto40Url,referencia,nome,quantidade,quantidadeApontada,valorTotal,dataPrevisaoInicio&$rel[cliente]=logo40Url,logo40,apelido,nomeFantasia,razaoSocial&dataConclusao[is]=null
`;
const periodos = [
  { label: 'Mês Atual', value: 0 },
  { label: 'Mês Anterior', value: 1 },
  { label: formatar('mes-ano', moment().subtract('2', 'month')), value: 2 },
  { label: formatar('mes-ano', moment().subtract('3', 'month')), value: 3 },
  { label: 'Ignorar', value: null },
];

const Planejamento = ({ history }) => {
  const { buscar, alterar, criar } = useApi();
  const [buscaPlanejadas, setBuscaPlanejadas] = useState('');
  const [buscaNaoPlanejadas, setBuscaNaoPlanejadas] = useState('');
  const [periodoSelecionado, setPeriodoSelecionado] = useState(0);
  const [dadosPlanejamento, setDadosPlanejamento] = useState([]);
  const [ordensPlanejadas, setOrdensPlanejadas] = useState([]);
  const [ordensNaoPlanejadas, setOrdensNaoPlanejadas] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { adicionarQuery, removerQuery, existeQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, ORDEM);

  const buscarPlanejamento = useCallback(
    async (dataInicial, dataFinal) => {
      const { data } = await buscar(
        `/planejamento/geral${
          dataFinal && dataInicial
            ? `?dataInicial=${dataInicial}&dataFinal=${dataFinal}`
            : ''
        }`,
      );
      setDadosPlanejamento(data);
    },
    [buscar],
  );

  const ativarBuscaPlanejamento = useCallback(
    value => {
      setPeriodoSelecionado(value);
      if (value === null) return buscarPlanejamento();
      const mes = moment().subtract(value, 'month');
      return buscarPlanejamento(
        mes.startOf('month').format('YYYY-MM-DD'),
        mes.endOf('month').format('YYYY-MM-DD'),
      );
    },
    [buscarPlanejamento],
  );

  useEffect(() => {
    ativarBuscaPlanejamento(0);
  }, [ativarBuscaPlanejamento]);

  useEffect(() => {
    const carregarOrdensPlanejadas = async () => {
      const { data } = await buscar(
        `${urlBase}&$ordem=prioridade&prioridade[not]=null${
          buscaPlanejadas ? `&$buscar=${buscaPlanejadas}` : ''
        }`,
      );
      setOrdensPlanejadas(data);
    };

    carregarOrdensPlanejadas();
  }, [buscar, buscaPlanejadas]);

  useEffect(() => {
    const carregarOrdensNaoPlanejadas = async () => {
      const { data } = await buscar(
        `${urlBase}&prioridade[is]=null&$ordem=uid${
          buscaNaoPlanejadas ? `&$buscar=${buscaNaoPlanejadas}` : ''
        }`,
      );
      setOrdensNaoPlanejadas(data);
    };

    carregarOrdensNaoPlanejadas();
  }, [buscar, buscaNaoPlanejadas]);

  const priorizarOrdem = async ({ uid, numero, nome }) => {
    const { data } = await criar(
      `/ordens-producao/${uid}/priorizar`,
      {},
      `Ordem ${numero} - ${nome} priorizada com sucesso!`,
    );
    setOrdensNaoPlanejadas(
      ordensNaoPlanejadas.filter(ordem => ordem.uid !== uid),
    );
    setOrdensPlanejadas([...ordensPlanejadas, data]);
    await ativarBuscaPlanejamento(periodoSelecionado);
  };

  const removerPrioridadeOrdem = async ({ uid, numero, nome, prioridade }) => {
    const ordensComMaisPrioridade = ordensPlanejadas.filter(
      ordem => ordem.prioridade < prioridade,
    );
    const ordensParaAjustar = ordensPlanejadas
      .filter(ordem => ordem.prioridade > prioridade)
      .map(async ({ uid, prioridade }) => {
        const { data } = await alterar(
          `/ordens-producao/${uid}`,
          { prioridade: prioridade - 1 },
          {},
          '',
        );
        return data;
      });

    const resultadoAjuste = await Promise.all(ordensParaAjustar);
    const { data } = await alterar(
      `/ordens-producao/${uid}`,
      { prioridade: null },
      {},
      `A prioridade da ordem ${numero} - ${nome} foi removida com sucesso!`,
    );
    setOrdensPlanejadas([...ordensComMaisPrioridade, ...resultadoAjuste]);
    setOrdensNaoPlanejadas([...ordensNaoPlanejadas, data]);
    await ativarBuscaPlanejamento(periodoSelecionado);
  };

  const onDrag = async (
    { prioridade, uid, numero, nome },
    sourceIndex,
    targetIndex,
  ) => {
    if (sourceIndex === targetIndex) return;
    const prioridadeAlvo = ordensPlanejadas[targetIndex].prioridade;
    let ordensParaAjustar = [];
    if (sourceIndex < targetIndex) {
      ordensParaAjustar = ordensPlanejadas
        .filter(
          ordem =>
            ordem.prioridade > prioridade && ordem.prioridade <= prioridadeAlvo,
        )
        .map(async ({ uid, prioridade }) => {
          const { data } = await alterar(
            `/ordens-producao/${uid}`,
            { prioridade: prioridade - 1 },
            {},
            '',
          );
          return data;
        });
    } else {
      ordensParaAjustar = ordensPlanejadas
        .filter(
          ordem =>
            ordem.prioridade < prioridade && ordem.prioridade >= prioridadeAlvo,
        )
        .map(async ({ uid, prioridade }) => {
          const { data } = await alterar(
            `/ordens-producao/${uid}`,
            { prioridade: prioridade + 1 },
            {},
            '',
          );
          return data;
        });
    }
    const resultadoAjuste = await Promise.all(ordensParaAjustar);
    const { data } = await alterar(
      `/ordens-producao/${uid}`,
      { prioridade: prioridadeAlvo },
      {},
      `A prioridade da ordem ${numero} - ${nome} foi alterada com sucesso!`,
    );
    if (sourceIndex < targetIndex) {
      resultadoAjuste.push(data);
      ordensPlanejadas.splice(
        sourceIndex,
        ordensParaAjustar.length + 1,
        ...resultadoAjuste,
      );
    } else {
      resultadoAjuste.unshift(data);
      ordensPlanejadas.splice(
        targetIndex,
        ordensParaAjustar.length + 1,
        ...resultadoAjuste,
      );
    }
    setOrdensPlanejadas([...ordensPlanejadas]);
  };

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title="Planejamento da Produção"
        extra={[
          <BotaoPrimario
            disabled={loading}
            key="1"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
          >
            Nova Ordem
          </BotaoPrimario>,
        ]}
      >
        <EstatisticaContainer>
          <CategoriaEstatistica>
            <h2>Planejado</h2>
            <EstatisticaContainer>
              <Estatistica titulo="Tempo Acumulado">
                {formatar(
                  'n-decimal',
                  dadosPlanejamento?.planejado?.tempoAcumulado ?? 0,
                  0,
                )}
                min
              </Estatistica>
              <Estatistica titulo="Previsão para Conclusão">
                {formatar(
                  'mes-dia',
                  dadosPlanejamento?.planejado?.dataPrevisaoConclusao,
                )}
              </Estatistica>
            </EstatisticaContainer>
          </CategoriaEstatistica>
          <CategoriaEstatistica>
            <h2>Cálculo do Planejamento</h2>
            <EstatisticaContainer min="650px">
              <Estatistica titulo="Capacidade Diária">
                {formatar(
                  'n-decimal',
                  dadosPlanejamento?.capacidadeDia ?? 0,
                  0,
                )}
                min
              </Estatistica>
              <Estatistica titulo="Eficiência">
                {dadosPlanejamento?.eficiencia
                  ? `${dadosPlanejamento?.eficiencia}%`
                  : '---'}
              </Estatistica>
              <Estatistica titulo="Absenteísmo">
                {dadosPlanejamento?.absenteismo
                  ? `${dadosPlanejamento?.absenteismo}%`
                  : '---'}
              </Estatistica>
              <div>
                <span className="ant-statistic-title">Período</span>
                <Select
                  placeholder="Todas"
                  value={periodoSelecionado}
                  bordered
                  showSearch
                  onSelect={ativarBuscaPlanejamento}
                >
                  {periodos.map(({ label, value }, index) => (
                    <Option key={index} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </div>
            </EstatisticaContainer>
          </CategoriaEstatistica>
          <CategoriaEstatistica>
            <h2>Não Planejado</h2>
            <EstatisticaContainer>
              <Estatistica titulo="Tempo Acumulado">
                {formatar(
                  'n-decimal',
                  dadosPlanejamento?.naoPlanejado?.tempoAcumulado ?? 0,
                  0,
                )}
                min
              </Estatistica>
              <Estatistica titulo="Previsão para Conclusão">
                {formatar(
                  'mes-dia',
                  dadosPlanejamento?.naoPlanejado?.dataPrevisaoConclusao,
                )}
              </Estatistica>
            </EstatisticaContainer>
          </CategoriaEstatistica>
        </EstatisticaContainer>
      </Header>

      <FlexContainer>
        <TableContainer>
          <TituloContainer>
            <TituloComFiltro
              semUrl
              titulo={<h4>Ordens já Planejadas</h4>}
              busca={buscaPlanejadas}
              history={history}
              onChange={busca => setBuscaPlanejadas(busca)}
            />
          </TituloContainer>
          <Tabela
            onDrag={onDrag}
            draggable={ordensPlanejadas?.length > 0}
            cabecalho={ORDENS_PLANEJADAS}
            dados={ordensPlanejadas}
            loading={loading}
            acoes={[
              { titulo: REMOVER_PRIORIDADE, onClick: removerPrioridadeOrdem },
            ]}
          />
        </TableContainer>
        <TableContainer>
          <TituloContainer>
            <TituloComFiltro
              semUrl
              busca={buscaNaoPlanejadas}
              titulo={<h4>Ordens não Planejadas</h4>}
              history={history}
              onChange={busca => setBuscaNaoPlanejadas(busca)}
            />
          </TituloContainer>
          <Tabela
            cabecalho={ORDENS_NAO_PLANEJADAS}
            dados={ordensNaoPlanejadas}
            loading={loading}
            acoes={[{ titulo: PRIORIZAR, onClick: priorizarOrdem }]}
          />
        </TableContainer>
      </FlexContainer>

      <OrdemForm
        aoFechar={() => removerQuery(CADASTRAR, ORDEM)}
        mostrar={mostrarCadastro}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            removerQuery(CADASTRAR, ORDEM);
            setOrdensNaoPlanejadas([...ordensNaoPlanejadas, novaOrdem]);
          }
        }}
      />
    </Container>
  );
};

Planejamento.propTypes = {
  history: objectOf(any).isRequired,
};

export default Planejamento;

export default {
  titulo: 'Operações dos Pacotes',
  cabecalho: {
    desktop: [
      {
        titulo: '#',
        aninhamento: 'operacao',
        atributo: 'sequencia',
        align: 'right',
      },
      {
        titulo: 'Operação',
        atributo: 'nome',
        aninhamento: 'operacao',
        variavel: true,
      },
      {
        titulo: 'Código de Barras',
        atributo: 'codigoBarras',
        align: 'right',
        width: '150px',
      },
      {
        titulo: 'Produção Apontada',
        atributo: 'quantidadeApontada',
        align: 'right',
        width: '150px',
      },
      {
        titulo: 'Saldo a Apontar',
        custom: ({ pacote, quantidadeApontada }) =>
          pacote.quantidade - (quantidadeApontada ?? 0),
        align: 'right',
        width: '120px',
      },
    ],
    mobile: [
      {
        titulo: 'Pacote',
        variavel: true,
        agrupar: [
          {
            custom: ({ operacao }) =>
              `${operacao?.sequencia} - ${operacao?.nome}`,
          },
          { custom: ({ codigoBarras }) => `Cód: ${codigoBarras} ` },
          {
            custom: ({ quantidadeApontada }) =>
              `Qtde Apontada: ${quantidadeApontada ?? 0}`,
          },
          {
            custom: ({ pacote, quantidadeApontada }) =>
              `Saldo a Apontar: ${
                pacote.quantidade - (quantidadeApontada ?? 0)
              }`,
          },
        ],
      },
    ],
  },
};

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { MaquinaForm } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import {
  BotaoExcluir,
  BotaoPrimario,
  Header,
} from '../../../components/layout';
import { CADASTRAR, MAQUINA } from '../../../hooks/useUrl/acoes';
import { DetalhesMaquinaTab } from '../../../components/tabs';
import { telaGrande } from '../../../utils';

const DetalhesMaquina = ({ history, match }) => {
  const { buscar, deletar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const [maquina, setMaquina] = useState({});
  const mostrarCadastro = existeQuery(CADASTRAR, MAQUINA);
  const { id } = match.params;
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarDadosMaquina = async () => {
      const { data } = await buscar(`/maquinas/${id}`);
      setMaquina(data);
    };

    carregarDadosMaquina();
  }, [id, buscar]);

  const deletarMaquina = async () => {
    const { uid, nome, codigo } = maquina;
    await deletar(
      `/maquinas/${uid}`,
      `Máquina ${codigo} - ${nome} excluída com sucesso!`,
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        onBack={() => history.goBack()}
        titulo={`${maquina?.codigo ?? ''} ${maquina?.nome ?? ''}`}
        classe="Máquina"
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, MAQUINA)}
            key="1"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarMaquina}>
            Excluir Máquina
          </BotaoExcluir>
        )}
      </Header>
      <DetalhesMaquinaTab history={history} maquinaUid={maquina?.uid} />

      <MaquinaForm
        mostrar={mostrarCadastro}
        maquina={maquina}
        aoFechar={() => removerQuery(CADASTRAR, MAQUINA)}
        afterSubmit={novaMaquina => {
          if (novaMaquina) {
            setMaquina(novaMaquina);
            removerQuery(CADASTRAR, MAQUINA);
          }
        }}
      />
    </>
  );
};

DetalhesMaquina.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesMaquina;

export default [
  {
    tipo: 'boolean',
    atributo: 'ativo',
    nome: 'Status',
    valorTrue: 'Ativo',
    valorFalse: 'Inativo',
  },
  { naoNulo: true, tipo: 'string', atributo: 'nome', nome: 'Nome' },
  { naoNulo: true, tipo: 'string', atributo: 'codigo', nome: 'Código' },
  { naoNulo: true, tipo: 'string', atributo: 'natureza', nome: 'Natureza' },
];

import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { string, any, objectOf, func, oneOfType, bool } from 'prop-types';
import { ContainerBuscar, Button, Input } from './styles';
import { BUSCAR } from '../../../../hooks/useUrl/acoes';
import { useUrl } from '../../../../hooks';

const TituloComFiltro = ({ titulo, busca, onChange, history, semUrl }) => {
  const [buscaValue, setBuscaValue] = useState(busca);
  const { adicionarQuery, removerQuery } = useUrl(history);

  const mostrar = false;

  useEffect(() => {
    setBuscaValue(busca);
  }, [busca, onChange]);
  /**
   * Necessário verificar como iremos usar a barra de pesquisa futuramente
   * O  mesmo pode ser usado para realizar  a pesquisa por nome dos usuários
   * O mesmo foi desativado temporariamente
   */
  return (
    <ContainerBuscar>
      {typeof titulo === 'function' ? titulo() : <span>{titulo}</span>}
      {mostrar ? (
        <Input
          value={buscaValue}
          suffix={
            <Button
              title="Limpar campo de busca"
              onClick={() => {
                setBuscaValue(null);
                return !semUrl && removerQuery(BUSCAR, busca);
              }}
            >
              <CloseOutlined />
            </Button>
          }
          placeholder="Digite Enter para buscar"
          onChange={({ target }) => setBuscaValue(target.value)}
          onKeyUp={({ key, target }) => {
            if (key === 'Enter') {
              if (onChange) onChange(target.value);
              if (target.value.length === 0) {
                return !semUrl && removerQuery(BUSCAR);
              }
              return !semUrl && adicionarQuery(BUSCAR, target.value);
            }
            return null;
          }}
        />
      ) : null}
    </ContainerBuscar>
  );
};

TituloComFiltro.propTypes = {
  titulo: oneOfType([string, func, objectOf(any)]),
  history: objectOf(any).isRequired,
  onChange: func,
  semUrl: bool,
  busca: string,
};

TituloComFiltro.defaultProps = {
  semUrl: false,
  busca: undefined,
  titulo: '',
  onChange: null,
};

export default TituloComFiltro;

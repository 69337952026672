import React, { useEffect, useState } from 'react';
import { any, arrayOf, bool, objectOf, string } from 'prop-types';
import { formatar } from '../../../utils';
import { useApi, useUrl } from '../../../hooks';
import { Estatistica } from '..';
import {
  ALFABETICA,
  CELULA,
  EFICIENCIA,
  ORDENACAO,
} from '../../../hooks/useUrl/acoes';
import {
  DataContainer,
  Select,
  SelectContainer,
  OptionsContainer,
} from './styles';

const { Option } = Select;

const Eficiencia = ({
  eficienciaDiaria,
  eficienciaMensal,
  tituloDiaria,
  tituloMensal,
  history,
  esconderDiaria,
  esconderMensal,
  apontamento,
}) => {
  const { buscar } = useApi();
  const [celulas, setCelulas] = useState([]);
  const [eficienciaFormatadaDia, setEficienciaFormatadaDia] = useState({});
  const [eficienciaFormatadaMes, setEficienciaFormatadaMes] = useState({});
  const { adicionarQuery, removerQuery, pegarValorQuery } = useUrl(history);
  const celula = pegarValorQuery(CELULA);
  const ordenacao = pegarValorQuery(ORDENACAO) ?? EFICIENCIA;

  useEffect(() => {
    const carregarCelulas = async () => {
      const { data } = await buscar(`/celulas`);
      setCelulas(data);
    };
    carregarCelulas();
  }, [buscar]);

  useEffect(() => {
    const formatarData = data => {
      if (data) {
        const somarItens = item =>
          formatar(
            'n-decimal',
            data.reduce((somado, objeto) => {
              if (objeto[item]) {
                somado += Number(objeto[item]);
              }
              return somado;
            }, 0),
            2,
          );

        const somatorio = {};
        if (!apontamento) {
          somatorio.Previsto = {
            color: '#555555',
            value: `${somarItens('previsto')}`,
          };
          somatorio.Faltas = {
            color: '#cf1322',
            value: `${somarItens('falta')}`,
          };
          somatorio['Presença'] = {
            color: '#555555',
            value: `${somarItens('presenca')}`,
          };
          somatorio.Paradas = {
            color: '#cf1322',
            value: `${somarItens('parada')}`,
          };
        }

        const apontado = data.reduce((somado, objeto) => {
          if (objeto?.tempoApontado) {
            somado += Number(objeto?.tempoApontado);
          }
          return somado;
        }, 0);

        const trabalhado = data.reduce((somado, objeto) => {
          if (objeto?.trabalhado) {
            somado += Number(objeto?.trabalhado);
          }
          return somado;
        }, 0);

        const falta = data.reduce((soma, objeto) => {
          if (objeto?.falta) {
            soma += Number(objeto?.falta);
          }

          return soma;
        }, 0);

        const parada = data.reduce((soma, objeto) => {
          if (objeto?.parada) {
            soma += Number(objeto?.parada);
          }

          return soma;
        }, 0);

        somatorio.Trabalhado = {
          color: '#1890ff',
          value: formatar('n-decimal', trabalhado, 2),
        };
        somatorio.Produzido = {
          color: 'rgb(18, 161, 42)',
          value: formatar('n-decimal', apontado, 2),
        };

        somatorio['Eficiência'] = {
          color: 'rgb(197, 168, 22)',
          value:
            apontado && trabalhado
              ? `${formatar(
                  'decimal',
                  (apontado * 1000) / (trabalhado + (falta + parada)) / 10,
                )}%`
              : '0%',
        };
        return somatorio;
      }
      return null;
    };
    if (!esconderDiaria) {
      setEficienciaFormatadaDia(formatarData(eficienciaDiaria));
    }
    setEficienciaFormatadaMes(formatarData(eficienciaMensal));
  }, [eficienciaDiaria, buscar, apontamento, eficienciaMensal, esconderDiaria]);

  return (
    <>
      <DataContainer apontamento={apontamento}>
        {!esconderDiaria && (
          <Estatistica
            titulo={apontamento ? tituloDiaria : null}
            configuracao={eficienciaFormatadaDia}
          />
        )}
        {apontamento && !esconderMensal && (
          <Estatistica
            titulo={tituloMensal}
            configuracao={eficienciaFormatadaMes}
          />
        )}
        <OptionsContainer>
          <SelectContainer>
            <span className="ant-statistic-title">Filtrar por Célula</span>
            <Select
              placeholder="Todas"
              value={celulas.find(({ uid }) => uid === Number(celula))?.nome}
              bordered
              showSearch
              onSelect={value =>
                value ? adicionarQuery(CELULA, value) : removerQuery(CELULA)
              }
            >
              <Option key="todas" value={null}>
                Todas
              </Option>
              {celulas?.map(({ uid, nome }) => (
                <Option key={uid} value={uid}>
                  {nome}
                </Option>
              ))}
            </Select>
          </SelectContainer>
          <SelectContainer>
            <span className="ant-statistic-title">Ordenar</span>
            <Select
              placeholder="Eficiência"
              value={ordenacao}
              bordered
              showSearch
              onSelect={value => adicionarQuery(ORDENACAO, value)}
            >
              <Option key={EFICIENCIA} value={EFICIENCIA}>
                Eficiência
              </Option>
              <Option key={ALFABETICA} value={ALFABETICA}>
                Alfabético
              </Option>
            </Select>
          </SelectContainer>
        </OptionsContainer>
      </DataContainer>
    </>
  );
};

Eficiencia.propTypes = {
  eficienciaDiaria: arrayOf(any),
  eficienciaMensal: arrayOf(any),
  history: objectOf(any).isRequired,
  tituloDiaria: string,
  tituloMensal: string,
  apontamento: bool,
  esconderDiaria: bool,
  esconderMensal: bool,
};

Eficiencia.defaultProps = {
  esconderDiaria: false,
  esconderMensal: false,
  tituloDiaria: null,
  tituloMensal: null,
  eficienciaDiaria: [],
  eficienciaMensal: [],
  apontamento: null,
};

export default Eficiencia;

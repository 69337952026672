import { getOperacoes } from '../operacoes';
import cliente from './cliente';
import pontoFuncionario from './pontoFuncionario';
import conta from './conta';
import funcionario from './funcionario';
import maquina from './maquina';
import ordemProducao from './ordemProducao';
import operacao from './operacao';
import lancamento from './lancamento';
import diaNaoTrabalhado from './diaNaoTrabalhado';
import celula from './celula';
import turno from './turno';
import motivo from './motivo';
import {
  CELULA_LAYOUT,
  CLIENTE_LAYOUT,
  CONTA_LAYOUT,
  DIA_NAO_TRABALHADO_LAYOUT,
  FUNCIONARIO_LAYOUT,
  LANCAMENTO_LAYOUT,
  MAQUINA_LAYOUT,
  MOTIVO_LAYOUT,
  OPERACAO_LAYOUT,
  ORDEM_PRODUCAO_LAYOUT,
  PONTO_FUNCIONARIO_LAYOUT,
  TURNO_LAYOUT,
} from '../nomeFiltros';

const mapearLista = array =>
  array.map(item => ({
    ...item,
    ativo: false,
    operacoes: item.naoNulo
      ? getOperacoes(item.tipo).filter(
          ({ operador }) => operador !== '[is]' && operador !== '[not]',
        )
      : getOperacoes(item.tipo),
    valor: null,
    operacaoSelecionada: null,
    valorExtra: null,
  }));

export const getLayout = nome => {
  switch (nome) {
    case DIA_NAO_TRABALHADO_LAYOUT:
      return mapearLista([...diaNaoTrabalhado]);
    case CLIENTE_LAYOUT:
      return mapearLista([...cliente]);
    case CELULA_LAYOUT:
      return mapearLista([...celula]);
    case TURNO_LAYOUT:
      return mapearLista([...turno]);
    case MOTIVO_LAYOUT:
      return mapearLista([...motivo]);
    case PONTO_FUNCIONARIO_LAYOUT:
      return mapearLista([...pontoFuncionario]);
    case CONTA_LAYOUT:
      return mapearLista([...conta]);
    case FUNCIONARIO_LAYOUT:
      return mapearLista([...funcionario]);
    case MAQUINA_LAYOUT:
      return mapearLista([...maquina]);
    case ORDEM_PRODUCAO_LAYOUT:
      return mapearLista([...ordemProducao]);
    case OPERACAO_LAYOUT:
      return mapearLista([...operacao]);
    case LANCAMENTO_LAYOUT:
      return mapearLista([...lancamento]);
    default:
      return [];
  }
};

import moment from 'moment';

export const emExperiencia = ({ dataFimExperiencia1, dataFimExperiencia2 }) => {
  const hoje = moment();
  const experiencia1 = moment(dataFimExperiencia1);
  const experiencia2 = moment(dataFimExperiencia2);
  return (
    hoje.diff(experiencia1) < 0 ||
    (dataFimExperiencia2 && hoje.diff(experiencia2) < 0)
  );
};

import TabelaOrdensCliente from './TabelaOrdensCliente';

export default {
  titulo: 'Ordens de Produção',
  cabecalho: {
    desktop: [
      ...TabelaOrdensCliente.cabecalho.desktop,
      {
        titulo: 'Saldo',
        tipo: 'moeda',
        align: 'right',
        atributo: 'valorRecebido',
        custom: ({ valorTotal, valorRecebido }, formatar) =>
          `${formatar(Number(valorTotal) - Number(valorRecebido))}`,
      },
    ],
    mobile: [],
  },
};

import { SituacaoFuncionario } from '../../../../utils/enum';
import TabelaPontosDoFuncionario from './TabelaPontosDoFuncionario';
import CoresSituacaoFuncionario from '../../../../utils/enum/SituacaoFuncionario/cores';

export default {
  titulo: 'Últimos pontos dos funcionários',
  cabecalho: {
    desktop: [
      {
        aninhamento: 'funcionario',
        titulo: 'Funcionário',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
            tag: {
              atributo: 'situacao',
              tipo: 'enum',
              enumerador: SituacaoFuncionario,
              color: ({ situacao }) => CoresSituacaoFuncionario[situacao],
            },
          },
          {
            atributo: 'cpf',
          },
        ],
      },
      ...TabelaPontosDoFuncionario.cabecalho.desktop.map(cab => ({
        ...cab,
        width: null,
      })),
    ],
    mobile: [
      {
        titulo: 'Pontos',
        variavel: true,
        agrupar: [
          {
            aninhamento: 'funcionario',
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            aninhamento: 'funcionario',
            atributo: 'nomeCompleto',
            tag: {
              atributo: 'situacao',
              tipo: 'enum',
              enumerador: SituacaoFuncionario,
              color: ({ situacao }) => CoresSituacaoFuncionario[situacao],
            },
          },
          {
            aninhamento: 'funcionario',
            atributo: 'cpf',
          },
          { atributo: 'data', tipo: 'data' },
        ],
      },
    ],
  },
};

export default [
  {
    naoNulo: true,
    tipo: 'string',
    atributo: 'funcionario.nomeCompleto',
    nome: 'Funcionario',
  },
  { naoNulo: true, tipo: 'string', atributo: 'celula.nome', nome: 'Célula' },
  { naoNulo: true, tipo: 'data', atributo: 'data', nome: 'Data' },
  { naoNulo: true, tipo: 'numero', atributo: 'previsto', nome: 'Previsto' },
  { tipo: 'numero', atributo: 'total', nome: 'Presente' },
  { tipo: 'numero', atributo: 'falta', nome: 'Faltas' },
  { tipo: 'numero', atributo: 'paradaApontada', nome: 'Paradas' },
  { tipo: 'numero', atributo: 'trabalhado', nome: 'Trabalhado' },
];

import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { parse } from 'query-string';
import { MenuPrincipal } from '../components/menus';
import { apiServidor } from '../services/api';
import rotas from './rotas';
import { useApi } from '../hooks';
import {
  setConfiguracoes,
  setToken,
  setTokenValido,
} from '../store/modules/sistema/actions';

const Routes = () => {
  const { buscar } = useApi();
  const dispatch = useDispatch();
  const { token, tokenValido, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );
  const localToken =
    localStorage.getItem('token') === 'undefined' ||
    localStorage.getItem('token') === 'null'
      ? undefined
      : localStorage.getItem('token');

  useEffect(() => {
    const getConfiguracoes = async () => {
      const { data } = await buscar('/configuracoes');
      dispatch(setConfiguracoes(data));
    };
    if (!configuracoes) {
      getConfiguracoes();
    }
  }, [buscar, configuracoes, dispatch]);

  useEffect(() => {
    if (!localToken && !token) {
      const { token: urlToken } = parse(window.location.search);
      localStorage.setItem('token', urlToken);
      dispatch(setToken(urlToken));
      dispatch(setTokenValido(true));
      apiServidor.setToken(urlToken);
    } else if (!localToken && token) {
      localStorage.setItem('token', token);
      apiServidor.setToken(token);
    } else if (localToken && !token) {
      dispatch(setToken(localToken));
      dispatch(setTokenValido(true));
      apiServidor.setToken(localToken);
    } else if (localToken && token) {
      localStorage.setItem('token', token);
      apiServidor.setToken(token);
    }
  }, [token, dispatch, localToken]);

  return (
    <Router>
      <Switch>
        <>
          {tokenValido ? (
            <MenuPrincipal>
              {rotas.map(({ exact, path, component }, index) => (
                <Route
                  exact={exact}
                  path={path}
                  component={component}
                  key={index}
                />
              ))}
            </MenuPrincipal>
          ) : (
            <Route
              path="/"
              component={() => {
                window.location.href = `${
                  process.env.REACT_APP_AUTH_URL
                }/dashboard?redirect=${window.location.href.replace('?', '&')}`;
                return null;
              }}
            />
          )}
        </>
      </Switch>
    </Router>
  );
};

export default Routes;

import TabelaOrdensProducao from './TabelaOrdensProducao';

export default {
  titulo: 'Ordens de Produção',
  cabecalho: {
    desktop: [
      ...TabelaOrdensProducao.cabecalho.desktop,
      {
        titulo: 'Saldo',
        tipo: 'moeda',
        align: 'right',
        atributo: 'valorRecebido',
        custom: ({ valorTotal, valorRecebido }, formatar) =>
          `${formatar(Number(valorTotal) - Number(valorRecebido))}`,
      },
    ],
    mobile: [
      {
        titulo: 'Produto',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['referencia', 'nome'],
            },
          },
          { atributo: 'referencia' },
          { atributo: 'nome' },
          {
            tipo: 'moeda',
            custom: ({ valorTotal, valorRecebido }, formatar) =>
              `Saldo: ${formatar(Number(valorTotal) - Number(valorRecebido))}`,
          },
        ],
      },
    ],
  },
};

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { func, objectOf, any, bool } from 'prop-types';
import { Drawer, Form, Input, Radio, Spin } from 'antd';
import { onFinish, lerObjectProp } from '../../commonFunctions';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { RadioGroup } from '../../commonStyles';

// const { Group } = Radio;
const PORCENTAGEM = 'Porcentagem';
const MINUTOS = 'Minutos';
const NENHUM = 'Nenhum';

const TempoAjusteForm = ({ afterSubmit, ordem, mostrar, aoFechar }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const [metodoAjuste, setMetodoAjuste] = useState(PORCENTAGEM);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        percentualAjuste: ordem?.percentualAjuste,
        tempoAjuste: ordem?.tempoAjuste,
        metodo: ordem?.percentualAjuste ? PORCENTAGEM : MINUTOS,
      },
      form.setFieldsValue,
      form.resetFields,
    );
    setMetodoAjuste(ordem?.percentualAjuste ? PORCENTAGEM : MINUTOS);
  }, [form.resetFields, form.setFieldsValue, ordem]);

  useEffect(() => {
    if (metodoAjuste === NENHUM) {
      form.setFieldsValue({
        tempoAjuste: null,
      });
    }
    if (metodoAjuste === NENHUM || metodoAjuste === MINUTOS) {
      form.setFieldsValue({
        percentualAjuste: null,
      });
    }
  }, [metodoAjuste, form]);

  const montarMensagem = ({ numero, nome }, tempoAjuste, percentualAjuste) => {
    switch (metodoAjuste) {
      case NENHUM:
        return `O ajuste de tempo da ordem ${numero} - ${nome} foi eliminado`;
      case PORCENTAGEM:
        return `A ordem ${numero} - ${nome} sofreu um ajuste de tempo de ${
          percentualAjuste ?? 0
        } por cento`;
      case MINUTOS:
        return `A ordem ${numero} - ${nome} sofreu um ajuste de tempo de ${
          tempoAjuste ?? 0
        } minuto(s)`;
      default:
        return null;
    }
  };

  const adicionarTempoAjuste = async ({ tempoAjuste, percentualAjuste }) => {
    const { data } = await alterar(
      `/ordens-producao/${ordem?.uid}`,
      {
        tempoAjuste: Number(tempoAjuste),
        percentualAjuste: percentualAjuste ? Number(percentualAjuste) : null,
      },
      ordem,
      montarMensagem(ordem, tempoAjuste, percentualAjuste),
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title="Alterar Tempo de Ajuste"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          name="tempo-ajuste"
          onFinish={values =>
            onFinish(values, adicionarTempoAjuste, null, afterSubmit)
          }
        >
          <Campo label="Método de ajuste" name="metodo">
            <RadioGroup
              value={metodoAjuste}
              onChange={({ target }) => {
                setMetodoAjuste(target.value);
              }}
            >
              <Radio value={NENHUM}>Nenhum Ajuste</Radio>
              <Radio value={PORCENTAGEM}>Porcentagem</Radio>
              <Radio value={MINUTOS}>Minutos</Radio>
            </RadioGroup>
          </Campo>
          {metodoAjuste !== NENHUM && (
            <>
              <Campo
                fluido
                name="percentualAjuste"
                label={PORCENTAGEM}
                initialValue={null}
                obrigatorio={metodoAjuste === PORCENTAGEM}
              >
                <Input
                  onChange={({ target }) => {
                    const { value } = target;
                    const percentualAjuste =
                      value < 0 ? Math.abs(value) : value;
                    const valor = ordem
                      ? (ordem?.tempoOperacoes * percentualAjuste) / 100
                      : 0;
                    form.setFieldsValue({
                      tempoAjuste: Math.round(valor),
                      percentualAjuste,
                    });
                  }}
                  type="number"
                  maxLength={15}
                  placeholder={PORCENTAGEM}
                  disabled={metodoAjuste !== PORCENTAGEM}
                />
              </Campo>

              <Campo
                fluido
                name="tempoAjuste"
                label="Minutos"
                initialValue={0}
                obrigatorio={metodoAjuste === MINUTOS}
                mostrarFoco={mostrar}
              >
                <Input
                  onChange={({ target }) => {
                    const { value } = target;
                    form.setFieldsValue({
                      tempoAjuste: value < 0 ? Math.abs(value) : value,
                    });
                  }}
                  maxLength={15}
                  type="number"
                  placeholder="Minutos"
                  disabled={metodoAjuste !== MINUTOS}
                />
              </Campo>
            </>
          )}
          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

TempoAjusteForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  ordem: objectOf(any),
  mostrar: bool,
};

TempoAjusteForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  ordem: null,
};

export default TempoAjusteForm;

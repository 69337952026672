import React, { useEffect } from 'react';
import moment from 'moment';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, bool } from 'prop-types';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { DatePicker } from '../../commonStyles';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';

const ApuracaoForm = ({ mostrar, aoFechar, afterSubmit }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        dataInicial: moment().subtract(1, 'month').startOf('month'),
        dataFinal: moment().subtract(1, 'month').endOf('month'),
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [form.resetFields, form.setFieldsValue]);

  const cadastrarApuracao = async values => {
    const { data } = await criar(
      '/apuracoes',
      {
        dataInicial: moment(values?.dataInicial).format('YYYY-MM-DD'),
        dataFinal: moment(values?.dataFinal).format('YYYY-MM-DD'),
      },
      `Apuração entre ${moment(values?.dataInicial).format(
        'DD/MM/YYYY',
      )} e ${moment(values?.dataFinal).format(
        'DD/MM/YYYY',
      )} foi criada com sucesso!`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title="Nova Apuração"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              values,
              cadastrarApuracao,
              null,
              apuracao => {
                if (afterSubmit && apuracao) afterSubmit(apuracao);
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="dataInicial"
            label="Data Inicial"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <DatePicker
              placeholder="Selecione uma data"
              picker="day"
              format="DD/MM/YYYY"
            />
          </Campo>
          <Campo
            fluido
            name="dataFinal"
            label="Data Final"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <DatePicker
              placeholder="Selecione uma data"
              picker="day"
              format="DD/MM/YYYY"
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

ApuracaoForm.propTypes = {
  aoFechar: func,
  mostrar: bool,
  afterSubmit: func,
};

ApuracaoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default ApuracaoForm;

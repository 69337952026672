import React, { useEffect, useState } from 'react';
import { Drawer, Form, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, bool, func, objectOf } from 'prop-types';
import { BotaoPrimario, Campo } from '../../layout';
import { lerObjectProp, onFinish } from '../commonFunctions';
import { CloseButtonContainer, Input, Checkbox } from '../commonStyles';
import { useApi } from '../../../hooks';
import { telaGrande } from '../../../utils';

const MotivoForm = ({ afterSubmit, motivo, aoVoltar, mostrar, aoFechar }) => {
  const { alterar, criar } = useApi();
  const [form] = Form.useForm();
  const [falta, setFalta] = useState(false);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(motivo, form.setFieldsValue, form.resetFields);
    setFalta(motivo?.falta ?? false);
  }, [form.resetFields, form.setFieldsValue, motivo]);

  const criarMotivo = async values => {
    const { data } = await criar(
      `/motivos-parada`,
      values,
      `O motivo ${values?.nome} foi criado`,
    );
    return data;
  };

  const alterarMotivo = async (values, id) => {
    const { data } = await alterar(
      `/motivos-parada/${id}`,
      values,
      motivo,
      `O motivo ${values?.nome} foi alterado`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      closable={!aoVoltar}
      title={motivo ? 'Alterar Motivo' : 'Novo Motivo'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        {aoVoltar && (
          <CloseButtonContainer>
            <button onClick={aoVoltar} type="button">
              Voltar
            </button>
          </CloseButtonContainer>
        )}
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="criar-motivo"
          onFinish={values => {
            onFinish(
              { ...values, falta, objectUid: motivo?.uid },
              criarMotivo,
              alterarMotivo,
              novoMotivo => {
                if (afterSubmit && novoMotivo) afterSubmit(novoMotivo);
                if (aoVoltar && novoMotivo) aoVoltar();
              },
              () => {
                form.resetFields();
                setFalta(false);
              },
            );
          }}
        >
          <Campo
            name="nome"
            label="Nome"
            obrigatorio
            mostrarFoco={mostrar}
            fluido
          >
            <Input type="text" placeholder="Digite o nome" maxLength={100} />
          </Campo>
          <Campo name="falta" label="">
            <Checkbox
              value="true"
              checked={falta}
              onChange={() => setFalta(!falta)}
            >
              Considerar como falta
            </Checkbox>
          </Campo>

          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

MotivoForm.propTypes = {
  afterSubmit: func,
  motivo: objectOf(any),
  aoVoltar: func,
  aoFechar: func,
  mostrar: bool,
};

MotivoForm.defaultProps = {
  aoFechar: null,
  mostrar: false,
  afterSubmit: null,
  motivo: null,
  aoVoltar: null,
};

export default MotivoForm;

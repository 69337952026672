import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Spin } from 'antd';
import moment from 'moment';
import { any, bool, func, objectOf } from 'prop-types';
import { onFinish } from '../../commonFunctions';
import { DatePicker } from '../../commonStyles';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { formatar, telaGrande } from '../../../../utils';
import { SituacaoFuncionario } from '../../../../utils/enum';

const DEMISSAO = 'D';

const DemissaoForm = ({ afterSubmit, funcionario, mostrar, aoFechar }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const demitirFuncionario = async ({ dataSaida }) => {
    const { nomeCompleto, uid } = funcionario ?? {};
    const { data } = await alterar(
      `/funcionarios/${uid}`,
      { dataSaida, situacao: DEMISSAO },
      funcionario,
      `A situação de ${nomeCompleto} foi alterada para ${
        SituacaoFuncionario[DEMISSAO]
      } em ${formatar('data', dataSaida)}`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 'auto' : '100%'}
      title="Demissão Funcionário"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          initialValues={{
            dataSaida: moment(),
          }}
          onFinish={values => {
            onFinish(
              { ...values, objectUid: funcionario?.uid },
              null,
              demitirFuncionario,
              afterSubmit,
            );
          }}
        >
          <Campo
            name="dataSaida"
            label="Data de demissão"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <DatePicker placeholder="Selecione uma data" format="DD/MM/YYYY" />
          </Campo>

          <Campo>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

DemissaoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
  funcionario: objectOf(any).isRequired,
};

DemissaoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default DemissaoForm;

import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi, useUrl } from '../../../hooks';
import { Tabs } from '../../../styles/global';
import GraficoEficiencia from '../../graficos/GraficoEficiencia';
import { Tabela } from '../../layout';
import { TabPeriodos } from '..';
import {
  APONTAMENTOS_CELULA,
  FUNCIONARIO_CELULA,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  APONTAMENTO,
  EFICIENCIA,
  FUNCIONARIO,
  PERIODO,
  TAB,
} from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;

const DetalhesCelulaTab = ({ history, celula }) => {
  const { buscar } = useApi();
  const [funcionarios, setFuncionarios] = useState([]);
  const [apontamentos, setApontamentos] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { pegarValorQuery, adicionarQuery } = useUrl(history);
  const tabAtiva = pegarValorQuery(TAB) ?? EFICIENCIA;
  const periodo = pegarValorQuery(PERIODO);

  useEffect(() => {
    const carregarApontamentos = async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/apontamentos-celula?celulaUid=${celula?.uid}&$ordem=-data${
          dataInicio && dataFinal
            ? `&data[between]=${dataInicio}&data[between]=${dataFinal}`
            : ''
        }&$rel[ordemProducao]=nome,numero,referencia`,
      );
      setApontamentos(data);
    };
    if (celula?.uid && periodo) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarApontamentos(dataInicio, dataFinal);
    }
  }, [celula, buscar, periodo]);

  useEffect(() => {
    const carregarFuncionarios = async () => {
      const { data } = await buscar(
        `/funcionarios?$ordem=nomeCompleto,uid&celulaUid[eq]=${celula.uid}`,
      );
      setFuncionarios(data);
    };

    if (celula?.uid) {
      carregarFuncionarios();
    }
  }, [celula, buscar]);

  return (
    <Tabs
      defaultActiveKey={tabAtiva}
      activeKey={tabAtiva}
      onChange={key => adicionarQuery(TAB, key)}
    >
      <TabPane tab="Eficiência" key={EFICIENCIA}>
        <GraficoEficiencia celulaUid={celula?.uid} />
      </TabPane>
      <TabPane tab="Funcionários" key={FUNCIONARIO}>
        <Tabela
          cabecalho={FUNCIONARIO_CELULA}
          dados={funcionarios}
          loading={loading}
          celulaSelecionada={({ uid }) => history.push(`/funcionarios/${uid}`)}
        />
      </TabPane>
      <TabPane tab="Apontamentos da Célula" key={APONTAMENTO}>
        <TabPeriodos history={history} tirarmargem transparente>
          <Tabela
            cabecalho={APONTAMENTOS_CELULA}
            dados={apontamentos}
            loading={loading}
          />
        </TabPeriodos>
      </TabPane>
    </Tabs>
  );
};

DetalhesCelulaTab.propTypes = {
  history: objectOf(any).isRequired,
  celula: objectOf(any),
};

DetalhesCelulaTab.defaultProps = {
  celula: null,
};

export default DetalhesCelulaTab;

import { parse } from 'query-string';
import { useCallback } from 'react';

const useUrl = history => {
  const { search, pathname } = history.location;

  const existeQuery = useCallback(
    (acao, valor) => {
      return parse(search)[acao] === `${valor}`;
    },
    [search],
  );

  const pegarValorQuery = useCallback(
    acao => {
      return parse(search)[acao];
    },
    [search],
  );

  const removerQuery = useCallback(
    (acao, valor = pegarValorQuery(acao)) => {
      return search.replace(
        search.indexOf(`&${acao}=${valor}`) === -1
          ? `${acao}=${valor}`
          : `&${acao}=${valor}`,
        '',
      );
    },
    [pegarValorQuery, search],
  );

  const adicionarQuery = useCallback(
    (acao, valor) => {
      const novoSearch = removerQuery(acao);
      const url =
        novoSearch.trim().length > 0 && novoSearch !== '?'
          ? `${pathname}${novoSearch}&${acao}=${valor}`
          : `${pathname}?${acao}=${valor}`;
      history.replace(url);
    },
    [history, pathname, removerQuery],
  );

  return {
    adicionarQuery,
    existeQuery,
    pegarValorQuery,
    removerQuery: useCallback(
      (acao, valor) => {
        return history.replace(pathname + removerQuery(acao, valor));
      },
      [history, pathname, removerQuery],
    ),
  };
};

export default useUrl;

export default {
  titulo: 'Paradas do Motivo',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'center',
        aninhamento: 'registroPonto',
      },
      {
        titulo: 'Funcionário',
        atributo: 'nomeCompleto',
        aninhamento: 'registroPonto',
        subAninhamento: 'funcionario',
      },
      {
        titulo: 'Célula',
        atributo: 'nome',
        aninhamento: 'registroPonto',
        subAninhamento: 'celula',
      },
      { titulo: 'Tempo', atributo: 'tempo', align: 'right', tipo: 'minutos' },
      {
        titulo: 'Máquina',
        aninhamento: 'maquina',
        atributo: 'nome',
      },
      { titulo: 'Observações', atributo: 'observacao', variavel: true },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Nome',
        agrupar: [
          { atributo: 'data', aninhamento: 'registroPonto', tipo: 'data' },
          {
            atributo: 'nomeCompleto',
            aninhamento: 'registroPonto',
            subAninhamento: 'funcionario',
          },
          {
            atributo: 'nome',
            aninhamento: 'registroPonto',
            subAninhamento: 'celula',
          },
          { aninhamento: 'maquina', atributo: 'nome' },
        ],
      },
    ],
  },
};

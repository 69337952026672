import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Form, Select, List, Typography, Spin, Drawer, Empty } from 'antd';
import { func, bool, number } from 'prop-types';
import { onFinish } from '../../commonFunctions';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';

const { Option } = Select;
const { Item } = List;
const { Text } = Typography;

const ClonarOperacaoForm = ({ afterSubmit, ordemUid, mostrar, aoFechar }) => {
  const { buscar, criar, existeNoLog } = useApi();
  const [ordensProducao, setOrdensProducao] = useState([]);
  const [operacoesClonar, setOperacoesClonar] = useState([]);
  const [uidOrdemASerClonada, setUidOrdemASerClonada] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarOrdensProducaoClonagem = async () => {
      const url = `/ordens-producao/`;
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setOrdensProducao(data.filter(({ uid }) => uid !== ordemUid));
      }
    };
    if (mostrar) {
      carregarOrdensProducaoClonagem();
    }
  }, [ordemUid, buscar, mostrar, existeNoLog]);

  useEffect(() => {
    const carregarOperacoesClonagem = async () => {
      if (uidOrdemASerClonada) {
        const { data } = await buscar(
          `/ordens-producao/${uidOrdemASerClonada}/operacoes`,
        );
        setOperacoesClonar(data);
      }
    };
    if (mostrar) {
      carregarOperacoesClonagem();
    }
  }, [uidOrdemASerClonada, buscar, mostrar]);

  const clonarOperacoes = async () => {
    const { numero, nome } = ordensProducao.find(
      ({ uid }) => uid === uidOrdemASerClonada,
    );
    const { data } = await criar(
      `ordens-producao/${uidOrdemASerClonada}/operacoes/clonar/${ordemUid}`,
      {
        operacoes: operacoesClonar.map(({ uid }) => uid),
      },
      `As operações da ordem ${numero} - ${nome} foram clonadas`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title="Clonar Operações de Outra Ordem"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          hideRequiredMark
          name="clonar-operacoes"
          onFinish={() =>
            onFinish({}, clonarOperacoes, null, afterSubmit, () =>
              setUidOrdemASerClonada(null),
            )
          }
        >
          <Campo
            fluido
            name="nome"
            label="Selecione a ordem a ser clonada"
            mostrarFoco={mostrar}
          >
            <Select
              placeholder="Procurar ordem de produção"
              onChange={uid => setUidOrdemASerClonada(uid)}
              showSearch
              exibicao="numero"
              optionFilterProp="children"
              filterOption={(input, { children }) =>
                String(children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {ordensProducao.map(({ uid, nome, numero }) => (
                <Option key={uid} value={uid}>
                  {numero} - {nome}
                </Option>
              ))}
            </Select>
          </Campo>

          <Campo fluido>
            {operacoesClonar && (
              <List
                header={<strong>Operações</strong>}
                dataSource={operacoesClonar}
                bordered
                locale={{
                  emptyText: (
                    <Empty
                      description="Nada encontrado"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
                renderItem={({ nome, sequencia, uid }) => (
                  <Item key={uid}>
                    <Text>
                      {sequencia} - {nome}
                    </Text>
                  </Item>
                )}
              />
            )}
          </Campo>

          <Campo fluido>
            <BotaoPrimario type="primary" htmlType="submit">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

ClonarOperacaoForm.propTypes = {
  ordemUid: number,
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
};

ClonarOperacaoForm.defaultProps = {
  aoFechar: null,
  mostrar: false,
  afterSubmit: null,
  ordemUid: null,
};

export default ClonarOperacaoForm;

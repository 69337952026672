import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  background: rgb(255, 255, 255);
  @media only screen and (max-device-width: 640px) {
    margin-top: 15px;
  }
`;

export const TableTitle = styled.h3`
  padding: 5px;
`;

export const Cell = styled.td`
  font-weight: ${({ negrito }) => negrito && '700'};
  text-align: ${({ align }) => align || 'center'};
`;

export const SubTitle = styled(Cell)`
  font-weight: 500;
`;

export const HeaderCell = styled.th`
  color: black;
  font-weight: 500;
  text-align: ${({ align }) => align || 'center'};
  position: sticky;
  top: 0;
`;

export const CellButton = styled.button`
  color: ${({ valorMonetario, texto }) => {
    if (valorMonetario) {
      return String(texto).indexOf('-') === -1 ? '#1890ff' : '#cf1322';
    }
    return 'inherit';
  }};
  text-align: inherit;
  white-space: nowrap;
  font-weight: inherit;
  background: transparent;
  width: 100%;
  padding: 0px;
  border: none;
  @media only screen and (max-device-width: 640px) {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  &:focus,
  &:active {
    outline: none;
  }
`;

export const Row = styled.tr`
  background: ${({ cor }) => cor};
  ${Cell}, ${HeaderCell} {
    border: 1px solid #f0f0f0;
    padding: 0px 5px 0px 5px;
    height: ${({ alta }) => (alta === 'true' ? '40px' : '32px')};
    &:hover {
      background: #f0f0f0;
    }
  }
`;

export default {
  titulo: 'Prêmios',
  cabecalho: {
    desktop: [
      {
        titulo: 'Funcionário',
        aninhamento: 'funcionario',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
          },
          {
            atributo: 'cpf',
          },
        ],
      },
      {
        tipo: 'n-decimal',
        enumerador: 2,
        titulo: 'Eficiência',
        atributo: 'eficiencia',
        width: '120px',
        align: 'right',
      },
      {
        titulo: 'Valor Original',
        atributo: 'valorOriginal',
        tipo: 'moeda',
        align: 'right',
        width: '120px',
      },
      {
        tipo: 'n-decimal',
        enumerador: 2,
        titulo: '% de Ajuste',
        atributo: 'percentualAjuste',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Valor Ajustado',
        atributo: 'valorAjustado',
        tipo: 'moeda',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Pago em',
        atributo: 'dataPagamento',
        tipo: 'data',
        align: 'center',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Prêmio',
        agrupar: [
          {
            aninhamento: 'funcionario',
            atributo: 'nomeCompleto',
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ eficiencia }, format) =>
              `Eficiência: ${format(eficiencia ?? 0)}%`,
          },
          {
            tipo: 'moeda',
            custom: ({ valorOriginal }, format) =>
              `Original: ${format(valorOriginal ?? 0)}`,
          },
          {
            tipo: 'moeda',
            custom: ({ valorAjustado }, format) =>
              `Ajustado: ${format(valorAjustado ?? 0)}`,
          },
          {
            atributo: 'dataPagamento',
            tipo: 'data',
            custom: ({ dataPagamento }, format) =>
              `Pagamento: ${format(dataPagamento)}`,
          },
        ],
      },
    ],
  },
};

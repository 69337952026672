export default {
  titulo: 'Prêmios',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'right',
      },
      {
        titulo: 'Minutos Previstos',
        atributo: 'previsto',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Trabalhados',
        atributo: 'trabalhado',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Produzido',
        atributo: 'tempoApontado',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Eficiência',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
        custom: ({ eficiencia }, format) =>
          eficiencia ? `${format(eficiencia)}%` : '0%',
      },
      {
        titulo: 'Absenteísmo',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
        custom: ({ absenteismo }, format) =>
          absenteismo ? `${format(absenteismo)}` : '0',
      },
      {
        titulo: 'Faturamento Meta 100%',
        atributo: 'valorMeta',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Faturamento Estimado',
        atributo: 'valorEstimado',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Valor Faturado',
        atributo: 'valorFaturado',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Faturamento Mínimo',
        atributo: 'valorMinimo',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Lucro Bruto',
        atributo: 'lucroBruto',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
      {
        titulo: 'Prêmio',
        atributo: 'valorPremio',
        tipo: 'n-decimal',
        enumerador: 2,
        align: 'right',
      },
    ],
    mobile: [
      {
        titulo: 'Prêmios',
        variavel: true,
        align: 'right',
        agrupar: [
          {
            tipo: 'data',
            custom: ({ data }, format) => `Data: ${format(data)}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorFaturado }, format) =>
              `Estimado: R$${format(valorFaturado)}`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 2,
            custom: ({ valorPremio }, format) =>
              `Prêmio: R$${format(valorPremio)}`,
          },
        ],
      },
    ],
  },
};

import { Radio, Spin, Form, Drawer } from 'antd';
import { any, bool, func, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { BotaoPrimario, Campo } from '../../../layout';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { Input, RadioGroup } from '../../commonStyles';

// const { Group } = Radio;
const INFORMADO = 'INFORMADO';
const SUGERIDO = 'SUGERIDO';

const AlterarPrecoForm = ({ afterSubmit, ordem, mostrar, aoFechar }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const [metodoPreco, setMetodoPreco] = useState(INFORMADO);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        metodo: INFORMADO,
        preco: ordem?.preco,
      },
      form.setFieldsValue,
      form.resetFields,
    );
    setMetodoPreco(INFORMADO);
  }, [form.resetFields, form.setFieldsValue, ordem]);

  useEffect(() => {
    if (metodoPreco !== INFORMADO) {
      form.setFieldsValue({
        preco: null,
      });
    }
  }, [metodoPreco, form]);

  const adicionarPreco = async ({ preco }) => {
    const { data } = await alterar(
      `/ordens-producao/${ordem?.uid}`,
      {
        preco: preco ? Number(preco) : null,
      },
      ordem,
      `A ordem ${ordem?.numero} - ${ordem?.nome} foi alterada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title="Informar o Preço"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          name="tempo-ajuste"
          onFinish={values =>
            onFinish(
              { ...values, objectUid: 1 },
              null,
              adicionarPreco,
              afterSubmit,
            )
          }
        >
          <Campo label="Escolha de Preços" name="metodo">
            <RadioGroup
              value={metodoPreco}
              onChange={({ target }) => {
                setMetodoPreco(target.value);
              }}
            >
              <Radio value={SUGERIDO}>Usar Preço Sugerido</Radio>
              <Radio value={INFORMADO}>Informar um Preço Manualmente</Radio>
            </RadioGroup>
          </Campo>

          <Campo
            hidden={metodoPreco !== INFORMADO}
            fluido
            name="preco"
            label="Preço"
            mostrarFoco={mostrar}
            initialValue={null}
            obrigatorio={metodoPreco === INFORMADO}
          >
            <Input
              type="number"
              maxLength={15}
              addonBefore="R$"
              placeholder="Digite um preço"
              disabled={metodoPreco !== INFORMADO}
            />
          </Campo>

          <BotaoPrimario htmlType="submit" type="primary">
            Salvar
          </BotaoPrimario>
        </Form>
      </Spin>
    </Drawer>
  );
};

AlterarPrecoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  ordem: objectOf(any),
  mostrar: bool,
};

AlterarPrecoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  ordem: null,
};

export default AlterarPrecoForm;

import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { PremiacaoForm } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import {
  Header,
  Descritor,
  BotaoPrimario,
  BotaoDefault,
} from '../../../components/layout';
import { CADASTRAR, APURACAO } from '../../../hooks/useUrl/acoes';
import { DetalhesApuracaoTab } from '../../../components/tabs';
import { formatar } from '../../../utils';

const descricao = [
  {
    titulo: 'Dados da Apuração',
    itens: [
      { nome: 'Número', propriedade: 'numero' },
      {
        nome: 'Data da Apuração',
        custom: ({ dataApuracao }) => formatar('data', dataApuracao),
      },
      {
        nome: 'Data Inicial',
        custom: ({ dataInicial }) => formatar('data', dataInicial),
      },
      {
        nome: 'Data Final',
        custom: ({ dataFinal }) => formatar('data', dataFinal),
      },
    ],
  },
  {
    titulo: 'Valores Apurados',
    itens: [
      {
        nome: 'Valor Faturado',
        propriedade: 'valorFaturado',
        custom: ({ valorFaturado }) =>
          formatar('n-decimal', Number(valorFaturado), 2),
      },
      { nome: 'Percentual Distribuído', propriedade: 'percentualDistribuido' },
      {
        nome: 'Valor Distribuído',
        propriedade: 'valorDistribuido',
        custom: ({ valorDistribuido }) =>
          formatar('n-decimal', Number(valorDistribuido), 2),
      },
      {
        nome: 'Quantidade de Funcionários',
        propriedade: 'quantidadeFuncionarios',
      },
    ],
  },
  {
    titulo: 'Pagamento',
    itens: [
      {
        nome: 'Data de Pagamento',
        custom: ({ dataPagamento }) =>
          dataPagamento ? formatar('data', dataPagamento) : 'Não informado',
      },
    ],
  },
];

const { ApuracaoForm } = PremiacaoForm;

const DetalhesApuracao = ({ history, match }) => {
  const { id } = match.params;
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, APURACAO);
  const [apuracao, setApuracao] = useState({});
  const [premiosRecalculados, setPremiosRecalculados] = useState(null);
  const [premiosCelulasRecalculados, setPremiosCelulasRecalculados] = useState(
    null,
  );
  const { buscar, criar } = useApi();

  const carregarApuracao = useCallback(async () => {
    const { data } = await buscar(`/apuracoes/${id}`);
    setApuracao(data);
  }, [id, buscar]);

  useEffect(() => {
    carregarApuracao();
  }, [carregarApuracao]);

  const recalcularApuracao = useCallback(async () => {
    const { data } = await criar(
      `/apuracoes/${id}/recalcular`,
      {},
      'Dados recalculados',
    );
    if (data) {
      setPremiosCelulasRecalculados(data?.premiosCelulasRecalculados);
      setPremiosRecalculados(data?.premiosRecalculados);
      carregarApuracao();
    }
  }, [carregarApuracao, criar, id]);

  return (
    <>
      <Header
        descricao
        onBack={() => history.goBack()}
        titulo={`${
          apuracao?.dataApuracao
            ? moment(apuracao?.dataApuracao).format('DD/MM/YYYY')
            : ''
        }`}
        classe="Apuração"
        extra={[
          <BotaoDefault disabled={loading} onClick={recalcularApuracao} key="1">
            Calcular
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, APURACAO)}
            key="2"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        <Descritor objeto={apuracao} propriedades={descricao} />
      </Header>

      <DetalhesApuracaoTab
        history={history}
        premiosCelulasRecalculados={premiosCelulasRecalculados}
        premiosRecalculados={premiosRecalculados}
        carregarApuracao={carregarApuracao}
        apuracaoUid={apuracao?.uid}
      />

      <ApuracaoForm
        mostrar={mostrarCadastro}
        apuracao={apuracao}
        aoFechar={() => removerQuery(CADASTRAR, APURACAO)}
        afterSubmit={novaApuracao => {
          if (novaApuracao) {
            setApuracao(novaApuracao);
            removerQuery(CADASTRAR, APURACAO);
          }
        }}
      />
    </>
  );
};

DetalhesApuracao.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesApuracao;

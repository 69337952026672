import React, { useEffect } from 'react';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, bool, objectOf, any } from 'prop-types';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { Input } from '../../commonStyles';

const PremioCelulaForm = ({ mostrar, aoFechar, afterSubmit, premioCelula }) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      { valorDistribuido: premioCelula?.valorDistribuido },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [form.resetFields, form.setFieldsValue, premioCelula]);

  const alterarPremioCelula = async values => {
    const { data } = await alterar(
      `apuracoes/${premioCelula?.apuracaoUid}/premios-celulas/${premioCelula?.uid}`,
      values,
      premioCelula,
      `Célula-Prêmio alterada com sucesso!`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title="Alterar Célula-Prêmio"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, objectUid: premioCelula?.uid },
              null,
              alterarPremioCelula,
              novoPremioCelula => {
                if (afterSubmit && novoPremioCelula)
                  afterSubmit(novoPremioCelula);
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            obrigatorio
            name="valorDistribuido"
            label="Valor da Premiação"
            mostrarFoco={!premioCelula && mostrar}
          >
            <Input type="number" placeholder="Digite o valor" maxLength={255} />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

PremioCelulaForm.propTypes = {
  premioCelula: objectOf(any),
  aoFechar: func,
  mostrar: bool,
  afterSubmit: func,
};

PremioCelulaForm.defaultProps = {
  premioCelula: null,
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default PremioCelulaForm;

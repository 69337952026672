import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { objectOf, any } from 'prop-types';
import { Table } from 'antd';
import { useApi, useUrl } from '../../../hooks';
import { SummaryCell, Tabs } from '../../../styles/global';
import { ApontamentoForm } from '../../forms';
import { Eficiencia, Tabela } from '../../layout';
import { dataNoIntervalo, formatar, telaGrande } from '../../../utils';
import { EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import { MesesAno } from '../../../utils/enum';
import { TabPeriodos } from '..';
import {
  APONTAMENTOS,
  APONTAMENTOS_CELULAS,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  APONTAMENTO,
  CADASTRAR,
  TAB,
  CELULA,
  FUNCIONARIO,
  PERIODO,
  SEM_TAB,
} from '../../../hooks/useUrl/acoes';

const { Row } = Table.Summary;
const { TabPane } = Tabs;

const ApontamentosTab = ({ history }) => {
  const { buscar, deletar } = useApi();
  const { existeQuery, removerQuery, pegarValorQuery, adicionarQuery } = useUrl(
    history,
  );
  const [eficienciaDiaria, setEficienciaDiaria] = useState([]);
  const [eficienciaMensal, setEficienciaMensal] = useState([]);
  const [apontamentosFunc, setApontamentosFunc] = useState([]);
  const [apontamentosCel, setApontamentosCel] = useState([]);
  const [nomeTabAtiva, setNomeTabAtiva] = useState(null);
  const [mesTabAtiva, setMesTabAtiva] = useState(null);
  const tabAtiva = pegarValorQuery(TAB) ?? FUNCIONARIO;
  const semTabs = pegarValorQuery(SEM_TAB);
  const celula = pegarValorQuery(CELULA);
  const mostrarCadastro = existeQuery(CADASTRAR, APONTAMENTO);
  const periodo = pegarValorQuery(PERIODO);

  const tituloMensal = `Eficiência ${
    mesTabAtiva?.[mesTabAtiva.length - 1] === 'a' ? 'da' : 'de'
  } ${mesTabAtiva}`;
  const tituloDiaria = `Eficiência ${
    nomeTabAtiva?.[nomeTabAtiva.length - 1] === 'a' ? 'da' : 'de'
  } ${
    typeof nomeTabAtiva === 'string'
      ? nomeTabAtiva?.replace('Esta', '')
      : nomeTabAtiva
  }`;
  const mostrarEficienciaAnual =
    moment().year() === nomeTabAtiva ||
    moment().year() - 1 === nomeTabAtiva ||
    nomeTabAtiva === 'Anteriores';

  useEffect(() => {
    const carregarApontamentosFunc = async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/apontamentos?$rel[funcionario]=nomeCompleto&$rel[operacao]=nome&$rel[operacao>ordemProducao]=nome,numero${
          dataInicio && dataFinal
            ? `&data[between]=${dataInicio}&data[between]=${dataFinal}`
            : ''
        }&$limite=1000`,
      );
      setApontamentosFunc(data);
    };
    if (periodo && tabAtiva === FUNCIONARIO) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarApontamentosFunc(dataInicio, dataFinal);
    }
  }, [buscar, periodo, tabAtiva]);

  useEffect(() => {
    const carregarApontamentosCel = async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/apontamentos-celula?$ordem=-data&$rel[ordemProducao]=nome,numero,referencia${
          dataInicio && dataFinal
            ? `&data[between]=${dataInicio}&data[between]=${dataFinal}`
            : ''
        }&$rel[celula]=nome${
          celula ? `&celulaUid=${celula}` : ''
        }&$limite=1000`,
      );
      setApontamentosCel(data);
    };

    const carregarEficienciaCel = async (dataInicio, dataFinal) => {
      if (!mostrarEficienciaAnual) {
        const { data } = await buscar(
          `/eficiencia-celula/diaria?data[gte]=${dataInicio}&data[lte]=${dataFinal}${
            celula ? `&celulaUid=${celula}` : ''
          }`,
        );
        setEficienciaDiaria(data);
      }
    };

    const carregarEficienciaMensalCel = async dataInicio => {
      const periodo = !mostrarEficienciaAnual ? 'month' : 'year';
      const { data } = await buscar(
        `/eficiencia-celula/mensal?data[gte]=${moment(dataInicio)
          .startOf(periodo)
          .format('YYYY-MM-DD')}&data[lte]=${moment(dataInicio)
          .endOf(periodo)
          .format('YYYY-MM-DD')}${celula ? `&celulaUid=${celula}` : ''}`,
      );
      setEficienciaMensal(data);
    };

    if (periodo && tabAtiva === CELULA) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarEficienciaMensalCel(dataInicio);
      carregarEficienciaCel(dataInicio, dataFinal);
      carregarApontamentosCel(dataInicio, dataFinal);
    }
  }, [buscar, celula, mostrarEficienciaAnual, periodo, tabAtiva]);

  const deletarApontamento = async ({
    uid,
    operacao,
    quantidade,
    data,
    funcionario,
    celula,
    ordemProducao,
  }) => {
    const mensagem = `O apontamento de ${quantidade} peça(s) ${
      operacao ? `de ${operacao.nome}` : ''
    } da ordem ${
      operacao?.ordemProducao?.numero ?? ordemProducao?.numero
    } em ${formatar('data', data)} ${
      funcionario
        ? `por ${funcionario.nomeCompleto}`
        : `pela célula ${celula?.nome}`
    } foi excluído`;
    if (funcionario) {
      await deletar(`/apontamentos/${uid}`, mensagem, () =>
        setApontamentosFunc(
          apontamentosFunc.filter(
            ({ uid: apontamentoUid }) => apontamentoUid !== uid,
          ),
        ),
      );
    } else if (celula) {
      await deletar(`/apontamentos-celula/${uid}`, mensagem, () =>
        setApontamentosCel(
          apontamentosCel.filter(
            ({ uid: apontamentoUid }) => apontamentoUid !== uid,
          ),
        ),
      );
    }
  };

  const summaryFuncionarios = () => {
    const tempoTotal = apontamentosFunc.reduce((soma, { tempoTotal }) => {
      soma += tempoTotal ?? 0;
      return soma;
    }, 0);

    return (
      apontamentosFunc?.length > 0 &&
      telaGrande() && (
        <Row>
          <SummaryCell colSpan="6" align="left">
            {formatar('n-decimal', apontamentosFunc.length, 2)} Apontamento
            {apontamentosFunc.length > 1 && 's'}
          </SummaryCell>
          <SummaryCell colSpan="2">
            {formatar('n-decimal', tempoTotal, 2)} min
          </SummaryCell>
          <SummaryCell />
        </Row>
      )
    );
  };

  const summaryCelulas = () => {
    const soma = apontamentosCel.reduce(
      (soma, { quantidade, tempoTotal, valorTotal }) => {
        soma.quantidade += quantidade ?? 0;
        soma.tempoTotal += tempoTotal ?? 0;
        soma.valorTotal += valorTotal ?? 0;
        return soma;
      },
      { quantidade: 0, tempoTotal: 0, valorTotal: 0 },
    );

    return (
      apontamentosCel?.length > 0 &&
      telaGrande() && (
        <Row>
          <SummaryCell colSpan="2" align="left">
            {formatar('n-decimal', apontamentosCel.length, 2)} Apontamento
            {apontamentosCel.length > 1 && 's'}
          </SummaryCell>
          <SummaryCell colSpan="4">
            {formatar('n-decimal', soma.quantidade, 2)}
          </SummaryCell>
          <SummaryCell colSpan="2">
            {formatar('n-decimal', soma.tempoTotal, 2)} min
          </SummaryCell>
          <SummaryCell colSpan="2">
            {formatar('n-decimal', soma.valorTotal, 2)}
          </SummaryCell>
          <SummaryCell />
        </Row>
      )
    );
  };

  return (
    <>
      <Tabs
        tabBarStyle={{ display: semTabs ? `none` : 'flex' }}
        transparente="true"
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => adicionarQuery(TAB, key)}
      >
        <TabPane tab="Apontamentos por Funcionário" key={FUNCIONARIO}>
          <TabPeriodos history={history} tirarmargem transparente>
            <Tabela
              dados={apontamentosFunc}
              cabecalho={APONTAMENTOS}
              summary={summaryFuncionarios}
              acoes={[
                {
                  titulo: EXCLUIR,
                  onClick: deletarApontamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPeriodos>
        </TabPane>
        <TabPane tab="Apontamentos por Célula" key={CELULA}>
          <Eficiencia
            apontamento
            history={history}
            esconderMensal={false}
            eficienciaMensal={eficienciaMensal}
            eficienciaDiaria={eficienciaDiaria}
            tituloDiaria={tituloDiaria}
            esconderDiaria={
              tituloDiaria === tituloMensal || mostrarEficienciaAnual
            }
            tituloMensal={
              !mostrarEficienciaAnual
                ? tituloMensal
                : `Eficiência de ${nomeTabAtiva}`
            }
          />
          <TabPeriodos
            history={history}
            tirarmargem
            transparente
            tabAtiva={tab => {
              setNomeTabAtiva(tab?.nome);
              setMesTabAtiva(
                MesesAno[moment(tab?.key.split('/')[0]).month() + 1],
              );
            }}
          >
            <Tabela
              dados={apontamentosCel}
              cabecalho={APONTAMENTOS_CELULAS}
              summary={summaryCelulas}
              acoes={[
                {
                  titulo: EXCLUIR,
                  onClick: deletarApontamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPeriodos>
        </TabPane>
      </Tabs>

      <ApontamentoForm
        history={history}
        aoFechar={() => removerQuery(CADASTRAR, APONTAMENTO)}
        mostrar={mostrarCadastro}
        afterSubmit={apontamento => {
          if (
            apontamento &&
            dataNoIntervalo(apontamento, 'data', periodo?.split('/'))
          ) {
            if (apontamento.celula) {
              setApontamentosCel([...apontamentosCel, apontamento]);
            } else {
              setApontamentosFunc([...apontamentosFunc, apontamento]);
            }
          }
        }}
      />
      <div id="final" />
    </>
  );
};

ApontamentosTab.propTypes = {
  history: objectOf(any).isRequired,
};

export default ApontamentosTab;

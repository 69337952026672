import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from '../../styles';
import { setConfiguracoes } from '../../../store/modules/sistema/actions';
import { BotaoPrimario, Campo, Header } from '../../../components/layout';
import { useApi } from '../../../hooks';
import { verificarBotoes } from '../functions';
import { FormContainer } from '../styles';
import {
  lerObjectProp,
  onFinish,
} from '../../../components/forms/commonFunctions';
import { Input } from '../../../components/forms/commonStyles';

const Tempos = () => {
  const [sofreuAlteracao, setSofreuAlteracao] = useState({
    percentualConcessao: false,
  });
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const dispatch = useDispatch();
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const resetarCampos = useCallback(() => {
    lerObjectProp(
      { percentualConcessao: configuracoes?.percentualConcessao },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [configuracoes, form.resetFields, form.setFieldsValue]);

  useEffect(() => resetarCampos(), [resetarCampos]);

  const editarConfiguracao = async ({ percentualConcessao }) => {
    const { data } = await alterar(
      '/configuracoes',
      {
        percentualConcessao:
          !percentualConcessao || percentualConcessao === '0'
            ? null
            : percentualConcessao,
      },
      {},
      'Dados atualizados com sucesso!',
    );

    return data;
  };

  const afterSubmit = config => {
    if (config) {
      setSofreuAlteracao(false);
      dispatch(setConfiguracoes(config));
    }
  };

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title="Configurações de Tempos"
        extra={[
          <Button
            disabled={loading}
            key="1"
            htmlType="button"
            hidden={!sofreuAlteracao.percentualConcessao}
            onClick={() => {
              resetarCampos();
              setSofreuAlteracao(false);
            }}
          >
            Descartar a alteração
          </Button>,
          <BotaoPrimario
            disabled={loading}
            hidden={!sofreuAlteracao.percentualConcessao}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => form.submit()}
          >
            Salvar
          </BotaoPrimario>,
        ]}
      />
      <FormContainer>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onChange={({ target }) =>
              verificarBotoes(target, configuracoes, setSofreuAlteracao)
            }
            onFinish={values => {
              onFinish(
                { ...values, objectUid: 1 },
                null,
                editarConfiguracao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="percentualConcessao"
              label="Percentual Padrão de Concessões"
              mostrarFoco
            >
              <Input
                name="percentualConcessao"
                type="number"
                placeholder="Digite o percentual"
                maxLength={255}
                min={0}
                max={100}
              />
            </Campo>
          </Form>
        </Spin>
      </FormContainer>
    </Container>
  );
};

export default Tempos;

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { func, objectOf, any, bool } from 'prop-types';
import { Drawer, Form, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { lerObjectProp, onFinish } from '../commonFunctions';
import { BotaoPrimario, Campo } from '../../layout';
import { useApi } from '../../../hooks';
import { CloseButtonContainer, Grupo, Input, Checkbox } from '../commonStyles';
import { telaGrande } from '../../../utils';
import TurnoFormDiaSemana from '../TurnoDiaSemana';

const TurnoForm = ({ mostrar, aoFechar, afterSubmit, turno, aoVoltar }) => {
  const { criar, alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const [cadDias, setCadDias] = useState(false);

  useEffect(() => {
    if (turno?.detalharDia) {
      setCadDias(true);
    }
    lerObjectProp(turno, form.setFieldsValue, form.resetFields);
  }, [turno, form.setFieldsValue, form.resetFields]);

  const cadastrarTurno = async ({ nome, ...values }) => {
    const { data } = await criar(
      '/turnos',
      { nome, ...values },
      `O turno ${nome} foi criado`,
    );
    return data;
  };

  const editarTurno = async ({ nome, ...values }, id) => {
    const { data } = await alterar(
      `/turnos/${id}`,
      { nome, ...values },
      turno,
      `O turno ${nome} foi alterado`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      closable={!aoVoltar}
      width={telaGrande() ? 300 : '100%'}
      title={turno ? 'Alterar Turno' : 'Novo Turno'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        {aoVoltar && (
          <CloseButtonContainer>
            <button onClick={aoVoltar} type="button">
              Voltar
            </button>
          </CloseButtonContainer>
        )}
        <Campo name="detalharDia">
          <Checkbox
            name="detalharDia"
            checked={cadDias}
            onChange={() => (cadDias ? setCadDias(false) : setCadDias(true))}
          >
            Detalhar dia da semana
          </Checkbox>
        </Campo>

        {cadDias ? (
          <>
            <TurnoFormDiaSemana
              mostrar={cadDias}
              aoVoltar={() => setCadDias(false)}
              aoFechar={() => setCadDias(false)}
              afterSubmitDiaSemana={({ turno }) => {
                afterSubmit(turno);
                aoVoltar();
              }}
              turno={turno}
            />
          </>
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              hideRequiredMark
              name="controlled-form"
              onFinish={values =>
                onFinish(
                  { ...values, objectUid: turno?.uid },
                  cadastrarTurno,
                  editarTurno,
                  novoTurno => {
                    if (afterSubmit && novoTurno) afterSubmit(novoTurno);
                    if (aoVoltar && novoTurno) aoVoltar();
                  },
                  form.resetFields,
                )
              }
            >
              <Campo
                fluido
                name="nome"
                label="Nome"
                obrigatorio
                mostrarFoco={mostrar}
              >
                <Input
                  type="text"
                  placeholder="Digite um nome"
                  maxLength={255}
                />
              </Campo>

              <Grupo>
                <Campo name="entrada1" label="Entrada 1" obrigatorio>
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada1: target.value })
                    }
                  />
                </Campo>

                <Campo name="saida1" label="Saída 1" obrigatorio>
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida1: target.value })
                    }
                  />
                </Campo>
              </Grupo>

              <Grupo>
                <Campo name="entrada2" label="Entrada 2">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada2: target.value })
                    }
                  />
                </Campo>

                <Campo name="saida2" label="Saída 2">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida2: target.value })
                    }
                  />
                </Campo>
              </Grupo>

              <Grupo>
                <Campo name="entrada3" label="Entrada 3">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada3: target.value })
                    }
                  />
                </Campo>

                <Campo name="saida3" label="Saída 3">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida3: target.value })
                    }
                  />
                </Campo>
              </Grupo>

              <Campo fluido>
                <BotaoPrimario htmlType="submit" type="primary">
                  Salvar
                </BotaoPrimario>
              </Campo>
            </Form>
          </>
        )}
      </Spin>
    </Drawer>
  );
};

TurnoForm.propTypes = {
  aoFechar: func,
  afterSubmit: func,
  aoVoltar: func,
  turno: objectOf(any),
  mostrar: bool,
};

TurnoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  aoVoltar: null,
  afterSubmit: null,
  turno: null,
};

export default TurnoForm;

import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)``;

export const TabelaHeader = styled.div`
  @media only screen and (max-device-width: 640px) {
    button {
      width: 100%;
      margin-top: 10px;
    }
  }
  @media only screen and (min-device-width: 640px) {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      button {
        margin-left: 10px;
      }
    }
  }
`;

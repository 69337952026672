import React, { useEffect, useState } from 'react';
import { any, bool, func, number, objectOf } from 'prop-types';
import { Drawer, Form, Select, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi } from '../../../hooks';
import { lerObjectProp, onFinish } from '../commonFunctions';
import { BotaoPrimario, Campo, SelectDinamico } from '../../layout';
import { MotivoForm } from '..';
import MaquinaForm from '../MaquinaForm';
import { telaGrande } from '../../../utils';
import { Input, TextArea } from '../commonStyles';

const { Option } = Select;

const ParadaForm = ({ afterSubmit, parada, pontoUid, mostrar, aoFechar }) => {
  const { buscar, criar, alterar, existeNoLog } = useApi();
  const [form] = Form.useForm();
  const [mostrarMotivoForm, setMostrarMotivo] = useState(false);
  const [mostrarMaquinaForm, setMostrarMaquina] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [maquinas, setMaquinas] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(parada, form.setFieldsValue, form.resetFields);
  }, [form.resetFields, form.setFieldsValue, parada]);

  useEffect(() => {
    const buscarMotivos = async () => {
      const url = '/motivos-parada?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setMotivos(data);
      }
    };

    const buscarMaquinas = async () => {
      const url = '/maquinas?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setMaquinas(data);
      }
    };

    if (mostrar) {
      buscarMaquinas();
      buscarMotivos();
    }
  }, [buscar, existeNoLog, mostrar]);

  const criarParada = async values => {
    const { data } = await criar(
      `/registros-ponto/${pontoUid}/paradas`,
      values,
      `A parada de ${values.tempo} minutos por conta de ${
        motivos.find(({ uid }) => uid === values.motivoParadaUid)?.nome
      } foi criada`,
    );
    return data;
  };

  const alterarParada = async (values, id) => {
    const { data } = await alterar(
      `/registros-ponto/${pontoUid}/paradas/${id}`,
      values,
      parada,
      `A parada de ${values.tempo} minutos por conta de ${
        motivos.find(({ uid }) => uid === values.motivoParadaUid)?.nome
      } foi alterada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title={parada ? 'Alterar Parada' : 'Nova Parada'}
      onClose={aoFechar}
    >
      {mostrarMotivoForm && (
        <MotivoForm
          mostrar={mostrarMotivoForm}
          aoVoltar={() => setMostrarMotivo(false)}
          afterSubmit={motivo => {
            setMotivos([motivo, ...motivos]);
            form.setFieldsValue({
              motivoParadaUid: motivo?.uid,
            });
          }}
        />
      )}

      {mostrarMaquinaForm && (
        <MaquinaForm
          mostrar={mostrarMaquinaForm}
          aoVoltar={() => setMostrarMaquina(false)}
          afterSubmit={maquina => {
            setMaquinas([maquina, ...maquinas]);
            form.setFieldsValue({
              maquinaUid: maquina?.uid,
            });
          }}
        />
      )}

      {!mostrarMaquinaForm && !mostrarMotivoForm && (
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="criar-motivo"
            onFinish={values => {
              onFinish(
                {
                  ...values,
                  objectUid: parada?.uid,
                  tempo: Number(values.tempo),
                },
                criarParada,
                alterarParada,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="motivoParadaUid"
              label="Motivo da parada"
              obrigatorio
              mostrarFoco={mostrar}
              fluido
            >
              <SelectDinamico
                form={form}
                nomeCampo="motivoParadaUid"
                placeholder="Selecione um motivo"
                aoAdicionar={() => setMostrarMotivo(true)}
              >
                {motivos?.map(({ uid, nome }) => (
                  <Option key={uid} value={uid}>
                    {nome}
                  </Option>
                ))}
              </SelectDinamico>
            </Campo>

            <Campo name="maquinaUid" label="Máquina" fluido>
              <SelectDinamico
                form={form}
                nomeCampo="maquinaUid"
                placeholder="Selecione uma máquina"
                aoAdicionar={() => setMostrarMaquina(true)}
              >
                {maquinas?.map(({ uid, nome }) => (
                  <Option key={uid} value={uid}>
                    {nome}
                  </Option>
                ))}
              </SelectDinamico>
            </Campo>

            <Campo name="tempo" label="Tempo" obrigatorio fluido>
              <Input
                type="number"
                placeholder="Digite o nome"
                maxLength={100}
              />
            </Campo>

            <Campo fluido name="observacao" label="Observações">
              <TextArea rows={4} placeholder="" />
            </Campo>

            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

ParadaForm.propTypes = {
  pontoUid: number,
  afterSubmit: func,
  aoFechar: func,
  parada: objectOf(any),
  mostrar: bool,
};

ParadaForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  parada: null,
  pontoUid: null,
};

export default ParadaForm;

export default {
  titulo: 'Dias Não Trabalhados',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
      },
      {
        titulo: 'Motivo do Dia',
        atributo: 'motivo',
        variavel: true,
      },
    ],
    mobile: [
      {
        titulo: 'Dias Não Trabalhados',
        variavel: true,
        tipo: 'data',
        custom: ({ data, motivo }, format) => `${format(data)} - ${motivo}`,
      },
    ],
  },
};

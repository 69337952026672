import styled from 'styled-components';
import { Upload as AntUpload, Image } from 'antd';

export const Upload = styled(AntUpload)`
  overflow: hidden;
`;

export const PlaceholderImage = styled(Image)`
  width: 100%;
`;

import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import {
  BotaoDefault,
  BotaoTutorial,
  Filtros,
  Header,
} from '../../components/layout';
import { useUrl } from '../../hooks';
import { ModalBusca } from '../../components/modais';
import { OrdensTab } from '../../components/tabs';
import { Container } from '../styles';
import { ORDEM_PRODUCAO_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import {
  CADASTRAR,
  FILTRAR,
  ATIVAR_FILTRO,
  ORDEM,
  BUSCAR,
} from '../../hooks/useUrl/acoes';

const { Tags, TituloComFiltro } = Filtros;

const OrdensProducao = ({ history }) => {
  const [filtro, setFiltro] = useState('');
  const { adicionarQuery, pegarValorQuery, existeQuery } = useUrl(history);
  const busca = pegarValorQuery(BUSCAR);
  const [numerOrdens, setNumeroOrdens] = useState(0);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          title={
            <TituloComFiltro
              titulo="Ordens de produção"
              history={history}
              busca={busca}
            />
          }
          extra={[
            <BotaoDefault
              disabled={loading}
              key="1"
              htmlType="button"
              onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
            >
              Filtrar
            </BotaoDefault>,
            <BotaoTutorial
              key="2"
              disabled={loading}
              esconder={existeQuery(CADASTRAR, ORDEM)}
              titulo="Nova Ordem de Produção"
              type="primary"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, ORDEM)}
              content={
                <h5>
                  {numerOrdens === 0 ? (
                    'Vamos cadastrar uma nova ordem de produção. Para isso, clique no botão Nova Ordem de Produção.'
                  ) : (
                    <>
                      <span>
                        Se você já acabou de cadastrar suas ordens e deseja
                        retornar ao Dashboard clique
                      </span>{' '}
                      <a href="/">aqui</a>
                    </>
                  )}
                </h5>
              }
            >
              Nova Ordem de Produção
            </BotaoTutorial>,
          ]}
        />
        <Tags retornarQuery={retornarQuery} history={history} />
      </Container>

      <OrdensTab
        history={history}
        busca={busca}
        filtro={filtro}
        setNumeroOrdens={setNumeroOrdens}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={ORDEM_PRODUCAO_LAYOUT}
      />
    </>
  );
};

OrdensProducao.propTypes = {
  history: objectOf(any).isRequired,
};

export default OrdensProducao;

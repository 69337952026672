import React, { useEffect } from 'react';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, objectOf, any, bool } from 'prop-types';
import { onFinish, lerObjectProp } from '../commonFunctions';
import { CloseButtonContainer, Input } from '../commonStyles';
import { BotaoPrimario, Campo } from '../../layout';
import { useApi } from '../../../hooks';
import { telaGrande } from '../../../utils';

const MaquinaForm = ({ mostrar, aoFechar, afterSubmit, maquina, aoVoltar }) => {
  const { criar, alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(maquina, form.setFieldsValue, form.resetFields);
  }, [form.resetFields, form.setFieldsValue, maquina]);

  const cadastrarMaquina = async values => {
    const { data } = await criar(
      '/maquinas',
      values,
      `Máquina ${values.codigo} - ${values.nome} criada com sucesso!`,
    );
    return data;
  };

  const editarMaquina = async (values, id) => {
    const { data } = await alterar(
      `/maquinas/${id}`,
      values,
      maquina,
      `Máquina ${values.codigo} - ${values.nome} alterada com sucesso!`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      closable={!aoVoltar}
      title={maquina ? 'Alterar Máquina' : 'Nova Máquina'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        {aoVoltar && (
          <CloseButtonContainer>
            <button onClick={aoVoltar} type="button">
              Voltar
            </button>
          </CloseButtonContainer>
        )}
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, objectUid: maquina?.uid },
              cadastrarMaquina,
              editarMaquina,
              novaMaquina => {
                if (afterSubmit && novaMaquina) afterSubmit(novaMaquina);
                if (aoVoltar && novaMaquina) aoVoltar();
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="nome"
            label="Nome da máquina"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <Input type="text" placeholder="Digite um nome" maxLength={255} />
          </Campo>
          <Campo fluido name="codigo" label="Código" obrigatorio>
            <Input type="text" placeholder="Digite o código" maxLength={10} />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

MaquinaForm.propTypes = {
  aoFechar: func,
  maquina: objectOf(any),
  mostrar: bool,
  afterSubmit: func,
  aoVoltar: func,
};

MaquinaForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  maquina: null,
  aoVoltar: null,
  afterSubmit: null,
};

export default MaquinaForm;

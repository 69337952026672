export default {
  titulo: 'Máquinas',
  cabecalho: {
    desktop: [
      {
        titulo: 'Código',
        atributo: 'codigo',
      },
      {
        titulo: 'Nome',
        atributo: 'nome',
        variavel: true,
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Máquina',
        custom: ({ nome, codigo }) => `${codigo} - ${nome}`,
      },
    ],
  },
};

export default {
  titulo: 'Pacotes',
  cabecalho: {
    desktop: [
      {
        titulo: 'Número',
        atributo: 'numero',
        align: 'right',
      },
      {
        titulo: 'Cor',
        atributo: 'cor',
        variavel: true,
      },
      {
        titulo: 'Tamanho',
        atributo: 'tamanho',
      },
      {
        titulo: 'Quantidade',
        atributo: 'quantidade',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
      },
      {
        titulo: 'Produção Apontada',
        atributo: 'quantidadeApontada',
        align: 'right',
      },
    ],
    mobile: [
      {
        titulo: 'Pacote',
        variavel: true,
        agrupar: [
          { custom: ({ numero }) => `Número: ${numero}` },
          { custom: ({ cor }) => `Cor: ${cor}` },
          { custom: ({ tamanho }) => `Tamanho: ${tamanho}` },
          {
            tipo: 'n-decimal',
            enumerador: 0,
            custom: ({ quantidade }, format) =>
              `Quantidade: ${format(quantidade)}`,
          },
        ],
      },
    ],
  },
};

import styled from 'styled-components';

export const StatisticContainer = styled.div`
  margin-right: 25px;
  @media only screen and (max-device-width: 640px) {
    margin-right: 5px;
    margin-bottom: 15px;
    width: 75px;
  }
  .ant-statistic-title {
    display: block;
  }
`;

export const TituloEstatistica = styled.span`
  font-weight: 600;
`;

export const Statistic = styled.span`
  color: ${({ cor }) => cor};
  font-size: 24px;
  @media only screen and (max-device-width: 640px) {
    font-size: 18px;
  }
`;

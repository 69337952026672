import styled from 'styled-components';
import {
  Statistic as AntStatistic,
  Button as AntButton,
  Tabs as AntTabs,
  Card as AntCard,
  Row as AntRow,
} from 'antd';

export const Button = styled(AntButton)`
  float: right;
`;
export const TabContainer = styled.div`
  background: ${({ transparente }) => transparente !== 'true' && '#fcfbfa'};
  padding: 16px 24px;
  @media only screen and (max-device-width: 640px) {
    display: grid;
    ${Button}:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const Row = styled(AntRow)`
  margin-bottom: 10px;
`;

export const Card = styled(AntCard)`
  .ant-card-body {
    padding: 0px;
    padding-left: 0.5em;
    border: none;
    background-color: ${({ transparente }) =>
      transparente !== 'true' ? '#f0f2f5' : '#eeecea'};
  }
`;

export const Tabs = styled(AntTabs)`
  margin-top: 20px;
`;

export const Statistic = styled(AntStatistic)`
  p,
  span {
    color: ${({ valor }) => (Math.sign(valor) === 1 ? '#1890ff' : '#cf1322')};
  }
`;

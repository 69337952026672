import ClonarOperacaoForm from './ClonarOperacaoForm';
import BalanceamentoForm from './BalanceamentoForm';
import AlterarPrecoForm from './AlterarPrecoForm';
import ClonarOrdemForm from './ClonarOrdemForm';
import TempoAjusteForm from './TempoAjusteForm';
import OperacaoForm from './OperacaoForm';
import PacoteForm from './PacoteForm';
import OrdemForm from './OrdemForm';

export default {
  ClonarOperacaoForm,
  BalanceamentoForm,
  AlterarPrecoForm,
  TempoAjusteForm,
  ClonarOrdemForm,
  OperacaoForm,
  PacoteForm,
  OrdemForm,
};

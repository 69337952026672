export default {
  titulo: 'Turnos',
  cabecalho: {
    desktop: [
      { titulo: 'Nome', atributo: 'nome', variavel: true },
      { titulo: 'Primeira Entrada', atributo: 'entrada1', align: 'center' },
      { titulo: 'Primeira Saída', atributo: 'saida1', align: 'center' },
      { titulo: 'Segunda Entrada', atributo: 'entrada2', align: 'center' },
      { titulo: 'Segunda Saída', atributo: 'saida2', align: 'center' },
      { titulo: 'Terceira Entrada', atributo: 'entrada3', align: 'center' },
      { titulo: 'Terceira Saída', atributo: 'saida3', align: 'center' },
      { titulo: 'Minutos', atributo: 'tempoPrevisto', align: 'right' },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Turno',
        agrupar: [
          { atributo: 'nome' },
          {
            custom: ({ tempoPrevisto }) => `Previsto: ${tempoPrevisto} min`,
          },
        ],
      },
    ],
  },
};

import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import { parse } from 'query-string';
import moment from 'moment';
import { func, objectOf, any, string } from 'prop-types';
import { getLayout } from '../filtroLayout';
import { verificarUrl } from '../functions';
import {
  Checkbox,
  Input,
  Select,
  DatePicker,
  ListContainer,
  Button,
  ItemContainer,
  Group,
  TimePicker,
  Radio,
} from './styles';

const { Item } = List;
const { Option } = Select;

const Busca = ({ retornarQuery, history, nomeFiltro }) => {
  const { search, pathname } = history.location;
  const [layout, setLayout] = useState(getLayout(nomeFiltro));

  const gerarBusca = () => {
    let query = '';
    layout
      .filter(({ ativo, operacaoSelecionada }) => ativo && operacaoSelecionada)
      .forEach(
        (
          { atributo: atributoItem, valor, operacaoSelecionada, valorExtra },
          index,
        ) => {
          if (atributoItem === 'ativo' && valor === 'true') {
            return;
          }
          let valorFormatado = valor;
          if (operacaoSelecionada === '[like]') {
            valorFormatado = valor?.replaceAll('%', '*');
            if (valorFormatado.indexOf('*') < 0) {
              valorFormatado = valorFormatado ? `*${valorFormatado}*` : 'null';
            }
          }
          if (operacaoSelecionada === '[between]' && valorExtra) {
            query = query.concat(
              index === 0 && query.length < 1 ? '' : '&',
              `${atributoItem}${operacaoSelecionada}=${valorExtra}`,
            );
          }
          query = query.concat(
            index === 0 && query.length < 1 ? '' : '&',
            `${atributoItem}${operacaoSelecionada}=${valorFormatado}`,
          );
        },
      );
    history.replace(`${pathname}?${query}`);
    retornarQuery(query);
  };

  useEffect(() => {
    const atribuirValor = (item, key, objeto) => {
      const itemFiltro = {
        ...item,
        ativo: true,
        operacaoSelecionada: key.substr(key.indexOf('['), key.indexOf(']')),
        atributo: key.slice(0, key.indexOf('[')),
      };
      if (typeof objeto[key] === 'object') {
        return {
          ...itemFiltro,
          valor: objeto[key][0],
          valorExtra: objeto[key][1],
        };
      }
      return {
        ...itemFiltro,
        valor: objeto[key],
        valorExtra: null,
      };
    };

    const gerarLayout = () => {
      setLayout(getLayout(nomeFiltro));
      const objetoSearch = parse(search);
      Object.keys(objetoSearch)
        .filter(key => key.includes('[') && key.includes(']'))
        .forEach(key => {
          setLayout(oldLayoult => {
            const index = oldLayoult.findIndex(
              ({ atributo }) => key.slice(0, key.indexOf('[')) === atributo,
            );
            oldLayoult[index] = atribuirValor(
              { ...oldLayoult[index] },
              key,
              objetoSearch,
            );
            return oldLayoult;
          });
        });
    };
    gerarLayout();
  }, [nomeFiltro, search]);

  const removerDaUrl = texto => {
    const searchAntigo = verificarUrl(texto, search);
    history.replace(`${pathname}${search.replace(searchAntigo, '')}`);
  };

  return (
    <>
      <ListContainer>
        <List
          dataSource={layout}
          renderItem={(item, index) => {
            const {
              nome,
              operacoes,
              ativo,
              valor,
              operacaoSelecionada,
              atributo,
              valorExtra,
              tipo,
              valorTrue,
              valorFalse,
            } = item;
            return (
              <>
                {tipo === 'boolean' ? (
                  <>
                    {nome}
                    <Group
                      value={valor}
                      onChange={({ target }) => {
                        layout[index] = {
                          ...item,
                          valor: target.value,
                          ativo: true,
                          operacaoSelecionada: '[eq]',
                        };
                        setLayout([...layout]);
                      }}
                    >
                      <Radio value="true">{valorTrue}</Radio>
                      <Radio value="false">{valorFalse}</Radio>
                    </Group>
                  </>
                ) : (
                  <div>
                    {tipo !== 'boolean' && nome}
                    <Item>
                      <ItemContainer operacao={operacaoSelecionada}>
                        <Checkbox
                          checked={ativo}
                          onChange={({ target }) => {
                            if (!target.checked) {
                              let texto = `${atributo}${operacaoSelecionada}=${valor}`;
                              if (valorExtra) {
                                texto = texto.concat(
                                  '',
                                  `&${atributo}${operacaoSelecionada}=${valorExtra}`,
                                );
                              }
                              removerDaUrl(texto);
                            }
                            layout[index] = {
                              ...item,
                              ativo: target.checked,
                              valor: null,
                              operacaoSelecionada: null,
                              valorExtra: null,
                            };
                            setLayout([...layout]);
                          }}
                        />
                        {operacaoSelecionada === '[between]' && (
                          <>
                            {tipo === 'data' && (
                              <DatePicker
                                operacao={operacaoSelecionada}
                                disabled={!ativo}
                                extra="true"
                                value={
                                  valorExtra ? moment(valorExtra) : valorExtra
                                }
                                format="DD-MM-YYYY"
                                placeholder="Selecione uma data"
                                onChange={data => {
                                  layout[index] = {
                                    ...item,
                                    valorExtra: data?.format('YYYY-MM-DD'),
                                  };
                                  setLayout([...layout]);
                                }}
                              />
                            )}

                            {tipo === 'hora' && (
                              <TimePicker
                                operacao={operacaoSelecionada}
                                disabled={!ativo}
                                extra="true"
                                value={
                                  valorExtra
                                    ? moment(valorExtra, 'HH:mm')
                                    : valorExtra
                                }
                                onChange={(value, hora) => {
                                  layout[index] = {
                                    ...item,
                                    valorExtra: hora,
                                  };
                                  setLayout([...layout]);
                                }}
                                placeholder="Selecione um horário"
                                format="HH:mm"
                              />
                            )}

                            {tipo !== 'data' && tipo !== 'hora' && (
                              <Input
                                operacao={operacaoSelecionada}
                                disabled={!ativo}
                                type="text"
                                extra="true"
                                placeholder="Valor"
                                value={valorExtra}
                                onChange={({ target }) => {
                                  layout[index] = {
                                    ...item,
                                    valorExtra: target.value,
                                  };
                                  setLayout([...layout]);
                                }}
                              />
                            )}
                          </>
                        )}
                        <Select
                          operacao={operacaoSelecionada}
                          value={operacaoSelecionada}
                          disabled={!ativo}
                          placeholder="Selecione uma operação"
                          bordered
                          onChange={operacao => {
                            layout[index] = {
                              ...item,
                              valorExtra: null,
                              operacaoSelecionada: operacao,
                            };
                            setLayout([...layout]);
                          }}
                        >
                          {operacoes?.map(
                            ({ nome: label, operador }, opIndex) => (
                              <Option key={opIndex} value={operador}>
                                {label}
                              </Option>
                            ),
                          )}
                        </Select>
                        {tipo === 'data' && (
                          <DatePicker
                            operacao={operacaoSelecionada}
                            disabled={!ativo}
                            value={valor ? moment(valor) : valor}
                            format="DD-MM-YYYY"
                            placeholder="Selecione uma data"
                            onChange={data => {
                              layout[index] = {
                                ...item,
                                valor: data?.format('YYYY-MM-DD'),
                              };
                              setLayout([...layout]);
                            }}
                          />
                        )}
                        {tipo === 'hora' && (
                          <TimePicker
                            operacao={operacaoSelecionada}
                            disabled={!ativo}
                            value={valor ? moment(valor, 'HH:mm') : valor}
                            onChange={(time, hora) => {
                              layout[index] = {
                                ...item,
                                valor: hora,
                              };
                              setLayout([...layout]);
                            }}
                            placeholder="Selecione um horário"
                            format="HH:mm"
                          />
                        )}
                        {tipo !== 'data' && tipo !== 'hora' && (
                          <>
                            {operacaoSelecionada !== '[is]' &&
                              operacaoSelecionada !== '[not]' && (
                                <Input
                                  operacao={operacaoSelecionada}
                                  disabled={!ativo}
                                  type="text"
                                  placeholder="Valor"
                                  value={valor}
                                  onChange={({ target }) => {
                                    layout[index] = {
                                      ...item,
                                      valor: target.value,
                                    };
                                    setLayout([...layout]);
                                  }}
                                />
                              )}
                          </>
                        )}
                      </ItemContainer>
                    </Item>
                  </div>
                )}
              </>
            );
          }}
        />
      </ListContainer>
      <Button onClick={gerarBusca} type="primary">
        Salvar
      </Button>
    </>
  );
};

Busca.propTypes = {
  history: objectOf(any).isRequired,
  retornarQuery: func.isRequired,
  nomeFiltro: string.isRequired,
};

export default Busca;

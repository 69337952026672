import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Spin } from 'antd';
import { any, bool, func, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { DiaSemana, MesesAno } from '../../../utils/enum';
import { Tabs } from '../../../styles/global';
// import DiasSemana from '../../../utils/enum/DiasSemana';
import { useUrl } from '../../../hooks';
import { PERIODO } from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;

const TabPeriodos = ({
  children,
  transparente,
  tirarmargem,
  history,
  tabAtiva,
}) => {
  const formato = 'YYYY-MM-DD';
  const [tabs, setTabs] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { pegarValorQuery, adicionarQuery } = useUrl(history);
  const periodoAtivo = pegarValorQuery(PERIODO);

  useEffect(() => {
    if (!periodoAtivo) {
      adicionarQuery(
        PERIODO,
        `${moment().format(formato)}/${moment().format(formato)}`,
      );
    }
  }, [adicionarQuery, periodoAtivo]);

  useEffect(() => {
    return tabAtiva?.(tabs.find(tab => tab.key === periodoAtivo));
  }, [tabAtiva, periodoAtivo, tabs]);

  useEffect(() => {
    const mes = moment().month() + 1;
    const ano = moment().year();
    const diaSemana = moment().day();
    const tabArr = [];

    const adicionarTab = (nomeDia, dataI, dataF) => {
      const dataInicio = dataI;
      const dataFinal = dataF;
      const nome = nomeDia;
      // if (nome === DiasSemana[0]) {
      //   dataInicio = dataInicio.subtract(2, 'days');
      //   dataFinal = dataFinal.subtract(2, 'days');
      //   nome = DiaSemana[5];
      // }
      // if (nome === DiasSemana[6]) {
      //   dataInicio = dataInicio.subtract(2, 'days');
      //   dataFinal = dataFinal.subtract(2, 'days');
      //   nome = DiaSemana[4];
      // }
      return tabArr.push({
        nome,
        key: `${dataInicio.format(formato)}/${dataFinal.format(formato)}`,
      });
    };

    adicionarTab(`${DiaSemana[diaSemana]} (Hoje)`, moment(), moment());
    adicionarTab(
      DiaSemana[moment().subtract(1, 'days').days()],
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
    );
    adicionarTab(
      DiaSemana[moment().subtract(2, 'days').days()],
      moment().subtract(2, 'days'),
      moment().subtract(2, 'days'),
    );
    adicionarTab(
      'Esta Semana',
      moment().startOf('week'),
      moment().endOf('week'),
    );
    adicionarTab(
      'Semana Passada',
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    );
    adicionarTab(
      MesesAno[mes],
      moment().startOf('month'),
      moment().endOf('month'),
    );
    adicionarTab(
      MesesAno[moment().subtract(1, 'month').month() + 1],
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    );
    adicionarTab(
      MesesAno[moment().subtract(2, 'month').month() + 1],
      moment().subtract(2, 'month').startOf('month'),
      moment().subtract(2, 'month').endOf('month'),
    );
    adicionarTab(ano, moment().startOf('year'), moment().endOf('year'));
    adicionarTab(
      ano - 1,
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    );
    adicionarTab(
      'Anteriores',
      moment('2000-01-01'),
      moment().subtract(2, 'year').endOf('year'),
    );
    setTabs(tabArr);
  }, []);

  return (
    <Tabs
      tirarmargem={tirarmargem ? 'true' : null}
      transparente={`${transparente}`}
      defaultActiveKey={periodoAtivo}
      activeKey={periodoAtivo}
      onChange={key => {
        adicionarQuery(PERIODO, key);
        return tabAtiva?.(tabs.find(tab => tab.key === key));
      }}
    >
      {tabs.map(({ nome, key }) => (
        <TabPane tab={nome} key={key} disabled={loading}>
          <Spin spinning={loading}>{children}</Spin>
        </TabPane>
      ))}
    </Tabs>
  );
};

TabPeriodos.propTypes = {
  history: objectOf(any).isRequired,
  children: objectOf(any),
  tabAtiva: func,
  tirarmargem: bool,
  transparente: bool,
};

TabPeriodos.defaultProps = {
  children: null,
  tabAtiva: null,
  tirarmargem: false,
  transparente: false,
};

export default TabPeriodos;

export default {
  titulo: 'Lançamentos',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Valor',
        atributo: 'valor',
        tipo: 'moeda',
        align: 'right',
        valorMonetario: true,
      },
      {
        titulo: 'Histórico',
        atributo: 'historico',
        variavel: true,
        tag: [
          {
            atributo: 'nome',
            aninhamento: 'conta',
          },
          {
            texto: 'Quitar',
            atributo: 'nome',
            aninhamento: 'conta',
            color: 'green',
            naoMostrarSe: ({ quitar }) => !quitar,
          },
        ],
      },
    ],
    mobile: [
      {
        titulo: 'Lançamentos',
        variavel: true,
        agrupar: [
          {
            atributo: 'data',
            tipo: 'data',
          },
          {
            tipo: 'moeda',
            atributo: 'valor',
            valorMonetario: true,
          },
          {
            atributo: 'nome',
            aninhamento: 'conta',
          },
        ],
      },
    ],
  },
};

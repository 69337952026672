import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { any, func, number, objectOf, oneOfType, string } from 'prop-types';
import { Badge } from 'antd';
import { Tabs } from '../../../styles/global';
import { Tabela } from '../../layout';
import { useApi, useUrl } from '../../../hooks';
import { LancamentoForm, OrdemProducaoForm } from '../../forms';
import { TituloComBadge } from './styles';
import { EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import { telaGrande } from '../../../utils';
import {
  ORDENS_CLIENTE,
  ORDENS_CLIENTE_SALDO,
  ORDENS_PRODUCAO,
  ORDENS_PRODUCAO_SALDO,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  CADASTRAR,
  FUNCIONARIO,
  ORDEM,
  ORDEM_CONCLUIDA,
  ORDEM_RECEBER,
  RECEBIMENTO,
  TAB,
} from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;
const { CriarLancamentoForm } = LancamentoForm;
const { OrdemForm } = OrdemProducaoForm;

const OrdensTab = ({ history, clienteUid, busca, filtro, setNumeroOrdens }) => {
  const { buscar, deletar } = useApi();
  const [ordensNaoConcluidas, setOrdensNaoConcluidas] = useState([]);
  const [ordensConcluidas, setOrdensConcluidas] = useState([]);
  const [ordensNaoRecebidas, setOrdensNaoRecebidas] = useState([]);
  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { removerQuery, existeQuery, adicionarQuery, pegarValorQuery } = useUrl(
    history,
  );
  const mostrarRecebimento = existeQuery(CADASTRAR, RECEBIMENTO);
  const mostrarCadastro = existeQuery(CADASTRAR, ORDEM);
  const tabAtiva = pegarValorQuery(TAB) ?? ORDEM;
  const ordem = pegarValorQuery(ORDEM);

  const carregarOrdensNaoRecebidas = useCallback(async () => {
    const { data } = await buscar(
      `/ordens-producao/?${
        filtro ?? ''
      }&$rel[cliente]=logo40Url,logo40,apelido,nomeFantasia,razaoSocial${
        clienteUid ? `&clienteUid[eq]=${clienteUid}` : ''
      }&$ordem=${ordem || '-uid'}${
        busca ? `&$buscar=${busca}` : ''
      }&dataConclusao[not]=null&dataQuitacao[is]=null&$limite=1000
      `,
    );
    setOrdensNaoRecebidas(data);
  }, [busca, buscar, clienteUid, ordem, filtro]);

  const deletarOrdem = async ({ uid, numero, nome }) => {
    await deletar(
      `/ordens-producao/${uid}`,
      `A ordem de produção ${numero} - ${nome} foi excluída`,
      () => {
        setOrdensNaoConcluidas(
          ordensNaoConcluidas.filter(({ uid: ordemUid }) => ordemUid !== uid),
        );
        setOrdensConcluidas(
          ordensConcluidas.filter(({ uid: ordemUid }) => ordemUid !== uid),
        );
        setOrdensNaoRecebidas(
          ordensNaoRecebidas.filter(({ uid: ordemUid }) => ordemUid !== uid),
        );
      },
    );
  };

  useEffect(() => {
    const carregarOrdensNaoConcluidas = async () => {
      const { data } = await buscar(
        `/ordens-producao/?${
          filtro ?? ''
        }&$rel[cliente]=logo40Url,logo40,apelido,nomeFantasia,razaoSocial${
          clienteUid ? `&clienteUid[eq]=${clienteUid}` : ''
        }&$ordem=${ordem || '-uid'}${
          busca ? `&$buscar=${busca}` : ''
        }&dataConclusao[is]=null&$limite=1000
        `,
      );
      setOrdensNaoConcluidas(data);
      return setNumeroOrdens?.(data?.length);
    };

    const carregarOrdensConcluidas = async () => {
      const { data } = await buscar(
        `/ordens-producao/?${
          filtro ?? ''
        }&$rel[cliente]=logo40Url,logo40,apelido,nomeFantasia,razaoSocial${
          clienteUid ? `&clienteUid[eq]=${clienteUid}` : ''
        }&$ordem=${ordem || '-uid'}${
          busca ? `&$buscar=${busca}` : ''
        }&dataQuitacao[not]=null&$limite=10000
        `,
      );
      setOrdensConcluidas(data);
    };

    carregarOrdensConcluidas();
    carregarOrdensNaoConcluidas();
    carregarOrdensNaoRecebidas();
  }, [
    busca,
    buscar,
    carregarOrdensNaoRecebidas,
    clienteUid,
    ordem,
    filtro,
    setNumeroOrdens,
  ]);

  const acoes = () =>
    !clienteUid && telaGrande()
      ? [
          {
            titulo: EXCLUIR,
            onClick: deletarOrdem,
            confirmacao: true,
          },
        ]
      : null;

  return (
    <>
      <Tabs
        transparente={`${!clienteUid}`}
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => adicionarQuery(TAB, key)}
      >
        <TabPane tab="Ordens em Produção e a Produzir" key={ORDEM}>
          <Tabela
            loading={loading}
            cabecalho={clienteUid ? ORDENS_PRODUCAO : ORDENS_CLIENTE}
            dados={ordensNaoConcluidas}
            acoes={acoes()}
            paginacao
            celulaSelecionada={({ uid, dataInicio }) =>
              history.push(
                `/ordens-producao/${uid}${
                  dataInicio ? `?${TAB}=${FUNCIONARIO}` : ''
                }`,
              )
            }
          />
        </TabPane>
        <TabPane
          key={ORDEM_RECEBER}
          tab={
            <Badge count={ordensNaoRecebidas?.length}>
              <TituloComBadge>Ordens a Receber</TituloComBadge>
            </Badge>
          }
        >
          <Tabela
            loading={loading}
            cabecalho={
              clienteUid ? ORDENS_PRODUCAO_SALDO : ORDENS_CLIENTE_SALDO
            }
            dados={ordensNaoRecebidas}
            acoes={acoes()}
            paginacao
            celulaSelecionada={(ordem, texto, titulo) => {
              if (clienteUid && titulo !== 'Ordem') {
                setOrdemSelecionada(ordem);
                return adicionarQuery(CADASTRAR, RECEBIMENTO);
              }
              return history.push(
                `/ordens-producao/${ordem.uid}${
                  ordem.dataInicio ? `?${TAB}=${FUNCIONARIO}` : ''
                }`,
              );
            }}
          />
        </TabPane>

        <TabPane tab="Ordens Recebidas" key={ORDEM_CONCLUIDA}>
          <Tabela
            loading={loading}
            cabecalho={clienteUid ? ORDENS_PRODUCAO : ORDENS_CLIENTE}
            dados={ordensConcluidas}
            paginacao
            acoes={acoes()}
            celulaSelecionada={({ uid, dataInicio }) =>
              history.push(
                `/ordens-producao/${uid}${
                  dataInicio ? `?${TAB}=${FUNCIONARIO}` : ''
                }`,
              )
            }
          />
        </TabPane>
      </Tabs>

      <CriarLancamentoForm
        aoFechar={() => removerQuery(CADASTRAR, RECEBIMENTO)}
        mostrar={mostrarRecebimento}
        ordem={ordemSelecionada}
        afterSubmit={data => {
          if (data?.ordem) {
            const { ordem } = data;
            const arr = [
              ...ordensNaoRecebidas.filter(({ uid }) => uid !== ordem?.uid),
            ];
            if (ordem?.dataQuitacao) {
              setOrdensNaoRecebidas([...arr]);
            } else {
              setOrdensNaoRecebidas([ordem, ...arr]);
            }
            removerQuery(CADASTRAR, RECEBIMENTO);
          }
        }}
      />

      <OrdemForm
        aoFechar={() => removerQuery(CADASTRAR, ORDEM)}
        mostrar={mostrarCadastro}
        ordem={{ clienteUid: clienteUid ? Number(clienteUid) : null }}
        afterSubmit={novaOrdem => {
          if (novaOrdem) {
            removerQuery(CADASTRAR, ORDEM);
            if (clienteUid) {
              setOrdensNaoConcluidas([novaOrdem, ...ordensNaoConcluidas]);
            } else {
              history.push(`/ordens-producao/${novaOrdem.uid}`);
            }
          }
        }}
      />
    </>
  );
};

OrdensTab.propTypes = {
  history: objectOf(any).isRequired,
  clienteUid: oneOfType([string, number]),
  setNumeroOrdens: func,
  busca: string,
  filtro: string,
};

OrdensTab.defaultProps = {
  setNumeroOrdens: null,
  clienteUid: null,
  busca: null,
  filtro: null,
};

export default OrdensTab;

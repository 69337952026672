import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { Select, Form, Spin, Drawer } from 'antd';
import { func, objectOf, any, bool } from 'prop-types';
import { Grupo, DatePicker, Input } from '../../commonStyles';
import { onFinish, lerObjectProp } from '../../commonFunctions';
import { Campo, Sessao, SelectDinamico, BotaoPrimario } from '../../../layout';
import { useApi } from '../../../../hooks';
import { formatar, formatarData, telaGrande } from '../../../../utils';
import { ClienteForm } from '../..';

const { Option } = Select;

const OrdemProducao = ({ afterSubmit, ordem, mostrar, aoFechar }) => {
  const { buscar, criar, alterar } = useApi();
  const [clientes, setClientes] = useState([]);
  const [foco, setFoco] = useState(mostrar);
  const [mostrarFormCliente, setMostrarCliente] = useState(false);
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        ...ordem,
        dataCombinadoCliente: ordem?.dataCombinadoCliente
          ? moment(ordem?.dataCombinadoCliente)
          : null,
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [ordem, form.resetFields, form.setFieldsValue]);

  useEffect(() => {
    setFoco(mostrar);
  }, [mostrar]);

  const carregarClientes = useCallback(
    async str => {
      const { data } = await buscar(
        `/clientes?${ordem?.clienteUid ? `uid[eq]=${ordem?.clienteUid}` : ''}${
          str ? `&$buscar=${str}` : ''
        }`,
        null,
        true,
      );
      setClientes(data);
    },
    [buscar, ordem],
  );

  useEffect(() => {
    if (mostrar) {
      carregarClientes();
    }
  }, [carregarClientes, mostrar]);

  const cadastrarOrdemProducao = async ({
    numero,
    nome,
    dataCombinadoCliente,
    quantidade,
    ...values
  }) => {
    const { data } = await criar(
      '/ordens-producao',
      {
        numero,
        nome,
        quantidade: Number(formatar('inteiro', quantidade)),
        dataCombinadoCliente: formatarData(dataCombinadoCliente, 'YYYY-MM-DD'),
        ...values,
      },
      novaOrdem =>
        `A ordem de produção ${novaOrdem?.numero} - ${novaOrdem?.nome} foi criada`,
    );
    return data;
  };

  const editarOrdemProducao = async (
    { numero, nome, quantidade, ...values },
    id,
  ) => {
    const { data } = await alterar(
      `/ordens-producao/${id}`,
      {
        numero,
        nome,
        quantidade: Number(formatar('inteiro', quantidade)),
        ...values,
      },
      ordem,
      novaOrdem =>
        `A ordem de produção ${novaOrdem?.numero} - ${novaOrdem?.nome} foi alterada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 400 : '100%'}
      title={ordem?.uid ? 'Alterar Ordem' : 'Nova Ordem'}
      onClose={aoFechar}
    >
      {mostrarFormCliente && (
        <ClienteForm
          mostrar={mostrarFormCliente}
          aoVoltar={() => setMostrarCliente(false)}
          afterSubmit={cliente => {
            setClientes([cliente, ...clientes]);
            form.setFieldsValue({
              clienteUid: cliente.uid,
            });
          }}
        />
      )}

      {!mostrarFormCliente && (
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onChange={() => foco && setFoco(false)}
            onFinish={values => {
              onFinish(
                { ...values, objectUid: ordem?.uid },
                cadastrarOrdemProducao,
                editarOrdemProducao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Grupo>
              <Campo
                name="numero"
                label="Número"
                mostrarFoco={foco}
                comPopover
                tituloPopover="Número"
                textoPopover="Número da Ordem de Produção, campo opcional. Caso não preenchido o sistema atribuirá um número de forma sequencial."
              >
                <Input
                  type="text"
                  placeholder="Digite um número"
                  maxLength={10}
                />
              </Campo>
              <Campo
                name="referencia"
                label="Referência"
                obrigatorio
                comPopover
                tituloPopover="Referência do Produto"
                textoPopover="Código de referência do produto, como ele será apresentado dentro do sistema. Ao concluir os demais campos de cadastro, clique no botão abaixo Salvar."
              >
                <Input
                  type="text"
                  placeholder="Digite a referência"
                  maxLength={30}
                />
              </Campo>
            </Grupo>

            <Campo fluido name="nome" label="Nome do Produto" obrigatorio>
              <Input type="text" placeholder="Digite o nome" maxLength={255} />
            </Campo>

            <Grupo>
              <Campo name="quantidade" label="Quantidade" obrigatorio>
                <Input
                  type="number"
                  placeholder="Digite uma quantidade"
                  maxLength={15}
                />
              </Campo>

              <Campo
                name="preco"
                label="Preço Unitário"
                comPopover
                tituloPopover="Preço Unitário"
                textoPopover="Campo opcional, caso não preenchido o sistema irá calcular o preço unitário com base no custo médio do produto."
              >
                <Input
                  type="number"
                  addonBefore="R$"
                  placeholder="Digite um preço"
                  maxLength={15}
                />
              </Campo>
            </Grupo>

            <Sessao titulo="Cliente">
              <Campo name="clienteUid" label="Cliente" fluido>
                <SelectDinamico
                  form={form}
                  aoFiltrar={carregarClientes}
                  nomeCampo="clienteUid"
                  placeholder="Digite para procurar um cliente"
                  aoAdicionar={() => setMostrarCliente(true)}
                >
                  {clientes?.map(({ uid, nomeFantasia }) => (
                    <Option key={uid} value={uid}>
                      {nomeFantasia}
                    </Option>
                  ))}
                </SelectDinamico>
              </Campo>

              <Grupo>
                <Campo name="numeroCliente" label="Número do Cliente">
                  <Input
                    type="text"
                    placeholder="Digite um número"
                    maxLength={30}
                  />
                </Campo>
                <Campo name="dataCombinadoCliente" label="Data Combinada">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Selecione uma data"
                  />
                </Campo>
              </Grupo>
            </Sessao>

            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

OrdemProducao.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  ordem: objectOf(any),
  mostrar: bool,
};

OrdemProducao.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  ordem: null,
};

export default OrdemProducao;

import React, { useState, useEffect, useCallback } from 'react';
import { Select, Table } from 'antd';
import { any, arrayOf, func, number, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { TabelaHeader } from '../styles';
import { useApi, useUrl } from '../../../hooks';
import { Tabs, SummaryCell } from '../../../styles/global';
import { PremiosContainer, SelectFiltro } from './styles';
import { PremiacaoForm as PremiacoesForms } from '../../forms';
import { EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import { formatar, telaGrande } from '../../../utils';
import { BotaoPrimario, Tabela } from '../../layout';
import {
  OBSERVACOES,
  PREMIOS,
  PREMIOS_CELULAS,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  OBSERVACAO,
  PREMIO,
  TAB,
  CADASTRAR,
  PREMIACAO,
  PREMIO_CELULA,
} from '../../../hooks/useUrl/acoes';

const { Row } = Table.Summary;
const { TabPane } = Tabs;
const { Option } = Select;
const {
  ObservacaoForm,
  PremiacaoForm,
  PremioForm,
  PremioCelulaForm,
} = PremiacoesForms;
const ORDEM_ALFABETICA = 'funcionario.celula.nome,funcionario.nomeCompleto';
const ORDEM_PREMIO = 'funcionario.celula.nome,valorAjustado';

const DetalhesApuracaoTab = ({
  history,
  apuracaoUid,
  carregarApuracao,
  premiosCelulasRecalculados,
  premiosRecalculados,
}) => {
  const { buscar, deletar } = useApi();
  const [observacoes, setObservacoes] = useState([]);
  const [premiosCelulas, setPremiosCelulas] = useState([]);
  const [premios, setPremios] = useState([]);
  const [premioCelulaSelecionado, setPremioCelulaSelecionado] = useState(null);
  const [observacaoSelecionada, setObservacaoSelecionada] = useState(null);
  const [premioSelecionado, setPremioSelecionado] = useState(null);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { pegarValorQuery, adicionarQuery, existeQuery, removerQuery } = useUrl(
    history,
  );
  const mostrarCadastroPremioCelula = existeQuery(CADASTRAR, PREMIO_CELULA);
  const mostrarCadastroObservacao = existeQuery(CADASTRAR, OBSERVACAO);
  const mostrarCadastroPremiacao = existeQuery(CADASTRAR, PREMIACAO);
  const mostrarCadastroPremio = existeQuery(CADASTRAR, PREMIO);
  const tabAtiva = pegarValorQuery(TAB) ?? PREMIO_CELULA;
  const [ordem, setOrdem] = useState(ORDEM_ALFABETICA);

  useEffect(() => {
    const carregarObservacoes = async () => {
      const { data } = await buscar(
        `/apuracoes/${apuracaoUid}/observacoes?$ordem=sequencia`,
      );
      setObservacoes(data);
    };

    if (apuracaoUid) {
      carregarObservacoes();
    }
  }, [buscar, apuracaoUid]);

  const adicionarPremios = useCallback(data => {
    const premiosUids = [];
    if (data) {
      data.forEach(({ funcionario }) => {
        const { uid: celulaUid } = funcionario.celula;
        if (!premiosUids.find(value => value === celulaUid)) {
          premiosUids.push(celulaUid);
        }
      });
    }
    setPremios(
      premiosUids.map(uid => {
        return data.filter(({ funcionario }) => funcionario.celula.uid === uid);
      }),
    );
  }, []);

  useEffect(() => {
    const carregarPremios = async () => {
      const { data } = await buscar(
        `/apuracoes/${apuracaoUid}/premios?${
          ordem ? `$ordem=${ordem}` : ''
        }&$rel[funcionario]=nomeCompleto,foto40,foto40Url,cpf&$rel[funcionario>celula]=nome,uid`,
      );
      adicionarPremios(data);
    };
    if (apuracaoUid && !premiosRecalculados) {
      carregarPremios();
    }
  }, [apuracaoUid, buscar, ordem, adicionarPremios, premiosRecalculados]);

  useEffect(() => {
    adicionarPremios(premiosRecalculados);
  }, [adicionarPremios, premiosRecalculados]);

  useEffect(() => {
    const carregarPremiosCelulas = async () => {
      const { data } = await buscar(
        `/apuracoes/${apuracaoUid}/premios-celulas`,
      );
      setPremiosCelulas(data);
    };
    if (apuracaoUid && !premiosCelulasRecalculados) {
      carregarPremiosCelulas();
    }
  }, [apuracaoUid, premiosCelulasRecalculados, buscar]);

  const premioCallback = premio => {
    if (premio) {
      const { uid } = premio?.funcionario?.celula;
      const arr = premios;
      premios.forEach((lista, index) => {
        if (lista[0].funcionario?.celula?.uid === uid) {
          arr[index] = [
            premio,
            ...lista.filter(({ uid }) => uid !== premio.uid),
          ];
        }
      });
      setPremios(arr);
      setPremioSelecionado(null);
      carregarApuracao();
    }
  };

  const premiacaoCallBack = data => {
    if (data) {
      if (premios.length > 0) {
        const { uid } = data?.funcionario?.celula;
        const arr = [...premios];
        const index = premios.findIndex(
          lista => lista[0].funcionario?.celula?.uid === uid,
        );
        if (index < 0) {
          setPremios([...premios, [data]]);
        } else {
          arr[index] = [...arr[index], data];
          setPremios([...arr]);
        }
        setPremioSelecionado(null);
        carregarApuracao();
      } else {
        adicionarPremios([data]);
        carregarApuracao();
      }
    }
  };

  const renderSummaryPremios = lista => {
    if (lista?.length === 0) {
      return null;
    }

    let funcionarios = lista
      .map(({ funcionario }) => funcionario.nomeCompleto)
      .filter((nome, index, self) => self.indexOf(nome) === index).length;
    funcionarios =
      funcionarios > 1
        ? `${funcionarios} Funcionários`
        : `${funcionarios} Funcionário`;
    const valorOriginal = formatar(
      'moeda',
      lista.reduce((acc, { valorOriginal }) => {
        acc += valorOriginal;
        return acc;
      }, 0),
    );
    const valorAjustado = formatar(
      'moeda',
      lista.reduce((acc, { valorAjustado }) => {
        acc += valorAjustado;
        return acc;
      }, 0),
    );
    if (!telaGrande()) {
      return (
        <>
          <Row>
            <SummaryCell colSpan="2">{funcionarios}</SummaryCell>
          </Row>
          <Row>
            <SummaryCell colSpan="2">
              Valor Original: {valorOriginal}
            </SummaryCell>
          </Row>
          <Row>
            <SummaryCell colSpan="2">
              Valor Ajustado {valorAjustado}
            </SummaryCell>
          </Row>
        </>
      );
    }

    return (
      <Row>
        <SummaryCell align="left" colSpan="2">
          {funcionarios}
        </SummaryCell>
        <SummaryCell>{valorOriginal}</SummaryCell>
        <SummaryCell colSpan="2">{valorAjustado}</SummaryCell>
        <SummaryCell colSpan="2" />
      </Row>
    );
  };

  const renderTitulo = () => (
    <TabelaHeader>
      <h4>Prêmios</h4>
      <div>
        <SelectFiltro
          placeholder="Ordenação"
          bordered
          showSearch
          value={ordem}
          onSelect={value => setOrdem(value)}
        >
          <Option key="alfabetica" value={ORDEM_ALFABETICA}>
            Alfabética
          </Option>
          <Option key="premio" value={ORDEM_PREMIO}>
            Prêmio
          </Option>
        </SelectFiltro>
        <BotaoPrimario
          disabled={loading}
          tamanho="pequeno"
          type="primary"
          onClick={() => adicionarQuery(CADASTRAR, PREMIACAO)}
        >
          Nova premiação
        </BotaoPrimario>
      </div>
    </TabelaHeader>
  );

  const deletarPremio = async ({ uid: premioUid, funcionario }) => {
    await deletar(
      `/apuracoes/${apuracaoUid}/premios/${premioUid}`,
      'Prêmio deletado',
      () => {
        const { uid: celulaUid } = funcionario?.celula;
        let arr = premios;
        premios.forEach((lista, index) => {
          if (lista[0].funcionario?.celula?.uid === celulaUid) {
            arr[index] = [...lista.filter(({ uid }) => uid !== premioUid)];
            if (arr[index].length === 0) {
              arr = arr.splice(index, 1);
            }
          }
        });
        carregarApuracao();
      },
    );
  };

  const deletarObservacao = async ({ uid }) => {
    await deletar(
      `/apuracoes/${apuracaoUid}/observacoes/${uid}`,
      'Observação excluída',
      () => setObservacoes([...observacoes.filter(obs => obs.uid !== uid)]),
    );
  };

  const deletarCelulaPremio = async ({ uid: premioCelulaUid }) => {
    await deletar(
      `apuracoes/${apuracaoUid}/premios-celulas/${premioCelulaUid}`,
      'Prêmio-Célula excluído',
      () =>
        setPremiosCelulas([
          ...premiosCelulas.filter(({ uid }) => uid !== premioCelulaUid),
        ]),
    );
  };

  const renderSummaryPremiacaoCelula = lista => {
    const { faturamento, premio } = lista?.reduce(
      (soma, { valorDistribuido, valorFaturado }) => {
        soma.premio += valorDistribuido ?? 0;
        soma.faturamento += valorFaturado ?? 0;
        return soma;
      },
      { premio: 0, faturamento: 0 },
    );
    const celulas = `${formatar('n-decimal', lista.length, 2)}
    ${lista.length > 1 ? 'Células' : 'Célula'}`;
    return (
      lista?.length > 0 && (
        <>
          <Row>
            <SummaryCell colSpan="1" align="left">
              {celulas}
            </SummaryCell>
            <SummaryCell colSpan="1">
              {formatar('n-decimal', faturamento, 2)}
            </SummaryCell>
            <SummaryCell colSpan="2">
              {formatar('n-decimal', premio, 2)}
            </SummaryCell>
            <SummaryCell />
          </Row>
        </>
      )
    );
  };

  const premioCelulaCallback = premioCelula => {
    if (premioCelula?.uid) {
      setPremiosCelulas([
        premioCelula,
        ...premiosCelulas.filter(({ uid }) => uid !== premioCelula?.uid),
      ]);
      removerQuery(CADASTRAR, PREMIO_CELULA);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => adicionarQuery(TAB, key)}
      >
        <TabPane tab="Premiação por Célula" key={PREMIO_CELULA}>
          <Tabela
            loading={loading}
            cabecalho={PREMIOS_CELULAS}
            dados={premiosCelulasRecalculados ?? premiosCelulas}
            summary={() =>
              renderSummaryPremiacaoCelula(
                premiosCelulasRecalculados ?? premiosCelulas,
              )
            }
            celulaSelecionada={item => {
              setPremioCelulaSelecionado(item);
              adicionarQuery(CADASTRAR, PREMIO_CELULA);
            }}
            acoes={[
              {
                titulo: EXCLUIR,
                onClick: deletarCelulaPremio,
                confirmacao: true,
              },
            ]}
          />
        </TabPane>
        <TabPane tab="Prêmios" key={PREMIO}>
          {premios.length > 0 ? (
            premios.map((lista, index) => (
              <PremiosContainer key={index} index={index}>
                <h2>{lista[0].funcionario?.celula?.nome}</h2>
                <Tabela
                  loading={loading}
                  cabecalho={PREMIOS}
                  dados={lista}
                  titulo={index === 0 ? () => renderTitulo() : null}
                  summary={() => renderSummaryPremios(lista)}
                  celulaSelecionada={premio => {
                    setPremioSelecionado(premio);
                    adicionarQuery(CADASTRAR, PREMIO);
                  }}
                  acoes={[
                    {
                      titulo: EXCLUIR,
                      onClick: deletarPremio,
                      confirmacao: true,
                    },
                  ]}
                />
              </PremiosContainer>
            ))
          ) : (
            <Tabela
              loading={loading}
              cabecalho={PREMIOS}
              dados={[]}
              titulo={() => renderTitulo()}
            />
          )}
        </TabPane>
        <TabPane tab="Observações" key={OBSERVACAO}>
          <Tabela
            loading={loading}
            cabecalho={OBSERVACOES}
            dados={observacoes}
            celulaSelecionada={obs => {
              setObservacaoSelecionada(obs);
              adicionarQuery(CADASTRAR, OBSERVACAO);
            }}
            acoes={[
              {
                titulo: EXCLUIR,
                onClick: deletarObservacao,
                confirmacao: true,
              },
            ]}
            titulo={() => (
              <TabelaHeader>
                <h4>Observações</h4>
                <BotaoPrimario
                  disabled={loading}
                  tamanho="pequeno"
                  type="primary"
                  onClick={() => adicionarQuery(CADASTRAR, OBSERVACAO)}
                >
                  Nova observação
                </BotaoPrimario>
              </TabelaHeader>
            )}
          />
        </TabPane>
      </Tabs>

      <PremioCelulaForm
        mostrar={mostrarCadastroPremioCelula}
        aoFechar={() => removerQuery(CADASTRAR, PREMIO_CELULA)}
        premioCelula={premioCelulaSelecionado}
        afterSubmit={premioCelulaCallback}
      />

      <PremiacaoForm
        mostrar={mostrarCadastroPremiacao}
        aoFechar={() => removerQuery(CADASTRAR, PREMIACAO)}
        apuracaoUid={apuracaoUid}
        afterSubmit={premiacaoCallBack}
      />

      <PremioForm
        mostrar={mostrarCadastroPremio}
        premio={premioSelecionado}
        apuracaoUid={apuracaoUid}
        afterSubmit={premioCallback}
        aoFechar={() => {
          removerQuery(CADASTRAR, PREMIO);
          setPremioSelecionado(null);
        }}
      />

      <ObservacaoForm
        observacao={observacaoSelecionada}
        apuracaoUid={apuracaoUid}
        mostrar={mostrarCadastroObservacao}
        aoFechar={() => {
          setObservacaoSelecionada(null);
          removerQuery(CADASTRAR, OBSERVACAO);
        }}
        afterSubmit={observacao => {
          if (observacao?.uid) {
            removerQuery(CADASTRAR, OBSERVACAO);
            setObservacoes(
              [
                ...observacoes.filter(({ uid }) => uid !== observacao.uid),
                observacao,
              ].sort((a, b) => a.sequencia - b.sequencia),
            );
            setObservacaoSelecionada(null);
          }
        }}
      />
    </>
  );
};

DetalhesApuracaoTab.propTypes = {
  history: objectOf(any).isRequired,
  carregarApuracao: func.isRequired,
  premiosRecalculados: arrayOf(any),
  premiosCelulasRecalculados: arrayOf(any),
  apuracaoUid: number,
};

DetalhesApuracaoTab.defaultProps = {
  apuracaoUid: null,
  premiosRecalculados: null,
  premiosCelulasRecalculados: null,
};

export default DetalhesApuracaoTab;

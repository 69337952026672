export default [
  {
    tipo: 'boolean',
    atributo: 'ativo',
    nome: 'Status',
    valorTrue: 'Ativo',
    valorFalse: 'Inativo',
  },
  { naoNulo: true, tipo: 'string', atributo: 'apelido', nome: 'Apelido' },
  { naoNulo: true, tipo: 'string', atributo: 'cnpj', nome: 'CNPJ' },
  {
    naoNulo: true,
    tipo: 'string',
    atributo: 'nomeFantasia',
    nome: 'Nome Fantasia',
  },
  {
    naoNulo: true,
    tipo: 'string',
    atributo: 'razaoSocial',
    nome: 'Razão Social',
  },
  { tipo: 'string', atributo: 'celular', nome: 'Celular' },
  { naoNulo: true, tipo: 'string', atributo: 'email', nome: 'Email' },
  { tipo: 'string', atributo: 'enderecoMunicipio', nome: 'Município' },
];

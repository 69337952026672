import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Select, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from '../../styles';
import { ContaForm } from '../../../components/forms';
import { setConfiguracoes } from '../../../store/modules/sistema/actions';
import {
  BotaoPrimario,
  Campo,
  Header,
  SelectDinamico,
} from '../../../components/layout';
import { useApi } from '../../../hooks';
import { verificarBotoes } from '../functions';
import { FormContainer } from '../styles';
import {
  lerObjectProp,
  onFinish,
} from '../../../components/forms/commonFunctions';

const { Option } = Select;

const OrdensProducao = () => {
  const [sofreuAlteracao, setSofreuAlteracao] = useState({
    contaUidRecebimento: false,
  });
  const dispatch = useDispatch();
  const { buscar, alterar } = useApi();
  const [form] = Form.useForm();
  const [contas, setContas] = useState([]);
  const [mostrarFormConta, setMostrarConta] = useState(false);
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const resetarCampos = useCallback(() => {
    lerObjectProp(
      { contaUidRecebimento: configuracoes?.contaUidRecebimento },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [configuracoes, form.resetFields, form.setFieldsValue]);

  useEffect(() => resetarCampos(), [resetarCampos]);

  useEffect(() => {
    const carregarContas = async () => {
      const { data } = await buscar('/contas?natureza[eq]=R');
      setContas(data);
    };
    carregarContas();
  }, [buscar]);

  const afterSubmit = config => {
    if (config) {
      setSofreuAlteracao(false);
      dispatch(setConfiguracoes(config));
    }
  };

  const editarConfiguracao = async values => {
    const { data } = await alterar(
      '/configuracoes',
      values,
      {},
      'Dados atualizados com sucesso!',
    );
    return data;
  };

  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          title="Configurações das Ordens de Produção"
          extra={[
            <Button
              disabled={loading}
              key="1"
              htmlType="button"
              hidden={!sofreuAlteracao.contaUidRecebimento}
              onClick={() => {
                resetarCampos();
                setSofreuAlteracao(false);
              }}
            >
              Descartar a alteração
            </Button>,
            <BotaoPrimario
              disabled={loading}
              key="2"
              type="primary"
              htmlType="button"
              hidden={mostrarFormConta || !sofreuAlteracao.contaUidRecebimento}
              onClick={() => form.submit()}
            >
              Salvar
            </BotaoPrimario>,
          ]}
        />
        <FormContainer>
          <Spin spinning={loading}>
            <Form
              form={form}
              layout="vertical"
              hideRequiredMark
              name="controlled-form"
              onFinish={values => {
                onFinish(
                  { ...values, objectUid: 1 },
                  null,
                  editarConfiguracao,
                  afterSubmit,
                  form.resetFields,
                );
              }}
            >
              <Campo
                mostrarFoco
                name="contaUidRecebimento"
                label="Plano de Contas Padrão nos Recebimentos"
              >
                <SelectDinamico
                  form={form}
                  nomeCampo="contaUidRecebimento"
                  placeholder="Selecione a conta"
                  aoAdicionar={() => setMostrarConta(true)}
                  onChange={value =>
                    verificarBotoes(
                      { value, name: 'contaUidRecebimento' },
                      configuracoes,
                      setSofreuAlteracao,
                    )
                  }
                >
                  {contas?.map(({ uid, nome }) => (
                    <Option key={uid} value={uid}>
                      {nome}
                    </Option>
                  ))}
                </SelectDinamico>
              </Campo>
            </Form>
          </Spin>
        </FormContainer>
      </Container>

      <ContaForm
        mostrar={mostrarFormConta}
        mostrarFoco={mostrarFormConta}
        aoVoltar={() => setMostrarConta(false)}
        afterSubmit={conta => {
          if (conta?.natureza === 'R') {
            setContas([conta, ...contas]);
            form.setFieldsValue({
              contaUidRecebimento: conta.uid,
            });
          }
        }}
      />
    </>
  );
};

export default OrdensProducao;

import TabelaOrdensProducao from './TabelaOrdensProducao';

export default {
  titulo: 'Ordens de Produção',
  cabecalho: {
    desktop: [
      {
        titulo: 'Cliente',
        aninhamento: 'cliente',
        agrupar: [
          {
            icone: {
              atributo: 'logo40Url',
              alt: ['apelido', 'razaoSocial'],
            },
          },
          { atributo: 'apelido' },
          { atributo: 'nomeFantasia' },
        ],
      },
      {
        titulo: 'N° do Cliente',
        atributo: 'numeroCliente',
        width: '105px',
        align: 'right',
      },
      ...TabelaOrdensProducao.cabecalho.desktop.filter(
        ({ atributo }) => atributo !== 'numeroCliente',
      ),
    ],
    mobile: TabelaOrdensProducao.cabecalho.mobile,
  },
};

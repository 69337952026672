import React, { useEffect } from 'react';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, bool, number, any, objectOf } from 'prop-types';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { TextArea } from '../../commonStyles';

const ObservacaoForm = ({
  observacao,
  mostrar,
  aoFechar,
  afterSubmit,
  apuracaoUid,
}) => {
  const { criar, alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(observacao, form.setFieldsValue, form.resetFields);
  }, [form.resetFields, form.setFieldsValue, observacao]);

  const cadastrarObservacao = async values => {
    const { data } = await criar(
      `apuracoes/${apuracaoUid}/observacoes`,
      values,
      `Observação criada com sucesso!`,
    );
    return data;
  };

  const editarObservacao = async (values, id) => {
    const { data } = await alterar(
      `apuracoes/${apuracaoUid}/observacoes/${id}`,
      values,
      observacao,
      `Observacao alterada com sucesso!`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title={observacao ? 'Alterar Observação' : 'Nova Observação'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, objectUid: observacao?.uid },
              cadastrarObservacao,
              editarObservacao,
              novaObservacao => {
                if (afterSubmit && novaObservacao) afterSubmit(novaObservacao);
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="texto"
            label="Observação"
            obrigatorio
            mostrarFoco={mostrar}
          >
            <TextArea
              rows={4}
              type="text"
              placeholder="Digite suas observações..."
              maxLength={255}
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

ObservacaoForm.propTypes = {
  observacao: objectOf(any),
  aoFechar: func,
  apuracaoUid: number,
  mostrar: bool,
  afterSubmit: func,
};

ObservacaoForm.defaultProps = {
  apuracaoUid: null,
  observacao: null,
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default ObservacaoForm;

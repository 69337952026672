export default {
  titulo: 'Apurações',
  cabecalho: {
    desktop: [
      {
        titulo: 'Número',
        atributo: 'numero',
        align: 'right',
      },
      {
        titulo: 'Data',
        atributo: 'dataApuracao',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Data Inicial',
        atributo: 'dataInicial',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Data Final',
        atributo: 'dataFinal',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Funcionários',
        atributo: 'quantidadeFuncionarios',
        align: 'right',
      },
      {
        titulo: 'Valor Total',
        atributo: 'valorTotal',
        tipo: 'moeda',
        align: 'right',
      },
      {
        titulo: '%',
        atributo: 'percentualDistribuido',
        custom: ({ percentualDistribuido }) =>
          percentualDistribuido ? `${percentualDistribuido}%` : '',
      },
      {
        titulo: 'Valor dos Prêmios',
        atributo: 'valorDistribuido',
        tipo: 'moeda',
        align: 'right',
      },
      {
        titulo: 'Pago em',
        atributo: 'dataPagamento',
        align: 'center',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Célula',
        agrupar: [
          {
            tipo: 'data',
            custom: ({ dataInicial, dataFinal }, format) =>
              `${format(dataInicial)} até ${format(dataFinal)}`,
          },
          {
            tipo: 'data',
            custom: ({ dataApuracao }, format) =>
              `Apuração: ${format(dataApuracao)}`,
          },
          {
            custom: ({ quantidadeFuncionarios }) =>
              `Funcionários: ${quantidadeFuncionarios ?? 0}`,
          },
          {
            tipo: 'moeda',
            custom: ({ valorDistribuido }, format) =>
              `Prêmio: ${format(valorDistribuido ?? 0)}`,
          },
          { atributo: 'nome', aninhamento: 'turno' },
        ],
      },
    ],
  },
};

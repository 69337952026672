import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { FuncionarioForm as Funcionario } from '../../components/forms';
import { useApi, useUrl } from '../../hooks';
import { ModalBusca } from '../../components/modais';
import { Container, Link } from '../styles';
import { FUNCIONARIOS } from '../../components/layout/Tabela/nomesCabecalhos';
import { FUNCIONARIO_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  Tabela,
  Filtros,
  Header,
  BotaoTutorial,
  AlertaTutorial,
  BotaoDefault,
} from '../../components/layout';
import {
  FILTRAR,
  ATIVAR_FILTRO,
  CADASTRAR,
  BUSCAR,
  FUNCIONARIO,
} from '../../hooks/useUrl/acoes';

const { FuncionarioForm } = Funcionario;
const { Tags, TituloComFiltro } = Filtros;

const Funcionarios = ({ history }) => {
  const { buscar, deletar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const mostrarCadastro = existeQuery(CADASTRAR, FUNCIONARIO);
  const [filtro, setFiltro] = useState('');
  const [funcionarios, setFuncionarios] = useState([]);
  const busca = pegarValorQuery(BUSCAR);
  const desktop = telaGrande();
  const { loading, mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    const carregarFuncionarios = async () => {
      if (pagina === 1) {
        setFuncionarios([]);
      }

      if (filtro === '') {
        const { data } = await buscar(
          `/funcionarios?${filtro}&$rel[celula]=nome${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nomeCompleto,uid&$limite=10&$pagina=${pagina}`,
        );
        setFuncionarios(func => [...func, ...data]);
      }

      if (filtro !== '') {
        const { data } = await buscar(
          `/funcionarios?${filtro}&$rel[celula]=nome${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nomeCompleto,uid&`,
        );
        setFuncionarios(data);
        setPagina(1);
      }
    };
    carregarFuncionarios();
  }, [buscar, filtro, busca, pagina]);

  useEffect(() => {
    if (filtro === '') {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setPagina(page => page + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#final'));
      return () => intersectionObserver.disconnect();
    }

    return undefined;
  }, [filtro]);

  const deletarFuncionario = async ({ uid, codigo, nomeCompleto, sexo }) => {
    await deletar(
      `/funcionarios/${uid}`,
      `${
        sexo === 'F' ? 'A funcionária' : 'O funcionário'
      } ${codigo} - ${nomeCompleto} foi ${
        sexo === 'F' ? 'excluída' : 'excluído'
      }`,
      () =>
        setFuncionarios(
          funcionarios.filter(({ uid: funcUid }) => funcUid !== uid),
        ),
    );
  };

  const renderAlert = () =>
    funcionarios?.length !== 0 &&
    mostrarTutorial && (
      <AlertaTutorial>
        <span>
          Parabéns! Você cadastrou{' '}
          {funcionarios?.length > 1
            ? 'seus funcionários'
            : 'um novo funcionário'}
          . Você pode continuar cadastrando, ou{' '}
          <Link to="/">clicar aqui para voltar ao seu dashboard</Link> de boas
          vindas e ver as próximas etapas.
        </span>
      </AlertaTutorial>
    );

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      {desktop && renderAlert()}
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro
            titulo="Funcionários"
            history={history}
            busca={busca}
          />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoTutorial
            key="2"
            esconder={mostrarCadastro || funcionarios?.length !== 0}
            disabled={loading}
            titulo="Novo Funcionário"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, FUNCIONARIO)}
            content={
              <h5>
                Vamos cadastrar um novo funcionário. Para isso, clique no botão
                Novo Funcionário.
              </h5>
            }
          >
            Novo Funcionário
          </BotaoTutorial>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      {!desktop && renderAlert()}
      <Tabela
        dados={funcionarios}
        cabecalho={FUNCIONARIOS}
        loading={loading}
        celulaSelecionada={({ uid }) => history.push(`/funcionarios/${uid}`)}
        acoes={
          desktop
            ? [
                {
                  titulo: EXCLUIR,
                  onClick: deletarFuncionario,
                  confirmacao: true,
                },
              ]
            : null
        }
      />

      <FuncionarioForm
        aoFechar={() => removerQuery(CADASTRAR, FUNCIONARIO)}
        mostrar={mostrarCadastro}
        afterSubmit={funcionario => {
          if (funcionario) {
            setFuncionarios([...funcionarios, funcionario]);
            removerQuery(CADASTRAR, FUNCIONARIO);
          }
        }}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={FUNCIONARIO_LAYOUT}
      />
      {filtro === '' ? <div id="final" /> : null}
      {/* <div id="final" /> */}
    </Container>
  );
};

Funcionarios.propTypes = {
  history: objectOf(any).isRequired,
};

export default Funcionarios;

export default [
  {
    tipo: 'boolean',
    atributo: 'ativo',
    nome: 'Status',
    valorTrue: 'Ativo',
    valorFalse: 'Inativo',
  },
  { naoNulo: true, tipo: 'string', atributo: 'nomeCompleto', nome: 'Nome' },
  { naoNulo: true, tipo: 'string', atributo: 'codigo', nome: 'Código' },
  { naoNulo: true, tipo: 'string', atributo: 'celula.nome', nome: 'Célula' },
  { tipo: 'string', atributo: 'cpf', nome: 'CPF' },
  { tipo: 'string', atributo: 'celular', nome: 'Celular' },
  {
    naoNulo: true,
    tipo: 'data',
    atributo: 'dataNascimento',
    nome: 'Data de Nascimento',
  },
  {
    naoNulo: true,
    tipo: 'data',
    atributo: 'dataAdmissao',
    nome: 'Data de Admissao',
  },
];

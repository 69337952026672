import styled from 'styled-components';
import { Button } from 'antd';
import colors from '../../../styles/colors';

export const Botao = styled(Button)`
  background-color: ${colors.primaria};
  border-color: ${colors.primaria};
  color: ${colors.texto};

  &:hover {
    background-color: ${colors.secundaria};
    border-color: ${colors.secundaria};
  }

  &:active {
    background-color: ${colors.secundaria};
    border-color: ${colors.secundaria};
  }

  &:focus {
    background-color: ${colors.secundaria};
    border-color: ${colors.secundaria};
  }
`;

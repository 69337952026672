import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import colors from '../../../styles/colors';

export const Botao = styled(AntButton)`
  &:hover {
    border-color: ${colors.primaria};
    color: ${colors.texto};
  }

  &:focus {
    border-color: ${colors.primaria};
    color: ${colors.texto};
  }
`;

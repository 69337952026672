import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { objectOf, any, func, bool } from 'prop-types';
import { Drawer, Form, Select, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { formatar, telaGrande } from '../../../../utils';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { DatePicker, Grupo } from '../../commonStyles';
import { Campo, Sessao, SelectDinamico, BotaoPrimario } from '../../../layout';
import { useApi } from '../../../../hooks';
import { CelulaForm, FuncionarioForm as FuncForms } from '../..';

const { Option } = Select;
const { FuncionarioForm } = FuncForms;

const PontoForm = ({ afterSubmit, ponto, mostrar, aoFechar }) => {
  const { criar, alterar, buscar, existeNoLog } = useApi();
  const [funcionarios, setFuncionarios] = useState([]);
  const [celulas, setCelulas] = useState([]);
  const [mostrarFormFuncionario, setMostrarFuncionario] = useState(false);
  const [mostrarFormCelula, setMostrarCelula] = useState(false);
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    if (ponto) {
      const { data, ...resto } = ponto;
      lerObjectProp(
        { data: moment(data), ...resto },
        form.setFieldsValue,
        form.resetFields,
      );
    }
  }, [ponto, form.setFieldsValue, form.resetFields]);

  useEffect(() => {
    const carregarFuncionarios = async () => {
      const url = '/funcionarios?$ordem=nomeCompleto,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setFuncionarios(data);
      }
    };

    const carregarCelulas = async () => {
      const url = '/celulas?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setCelulas(data);
      }
    };

    if (mostrar) {
      carregarCelulas();
      carregarFuncionarios();
    }
  }, [buscar, existeNoLog, mostrar]);

  const cadastrarPonto = async ({ data: dia, funcionarioUid, ...values }) => {
    const { data } = await criar(
      `/registros-ponto`,
      {
        data: dia.format('YYYY-MM-DD'),
        funcionarioUid,
        ...values,
      },
      `O ponto do dia ${formatar('data', dia)} de ${
        funcionarios.find(({ uid }) => uid === funcionarioUid).nomeCompleto
      } foi criado`,
    );
    return data;
  };

  const editarPonto = async ({ data: dia, funcionarioUid, ...values }, id) => {
    const { data } = await alterar(
      `/registros-ponto/${id}`,
      { data: dia, funcionarioUid, ...values },
      ponto,
      `O ponto do dia ${formatar('data', dia)} de ${
        funcionarios.find(({ uid }) => uid === funcionarioUid).nomeCompleto
      } foi alterado`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 420 : '100%'}
      title={ponto ? 'Alterar Ponto' : 'Novo Ponto'}
      onClose={aoFechar}
    >
      {mostrarFormCelula && (
        <CelulaForm
          mostrar={mostrarFormCelula}
          aoVoltar={() => setMostrarCelula(false)}
          afterSubmit={celula => {
            setCelulas([celula, ...celulas]);
            form.setFieldsValue({
              celulaUid: celula.uid,
            });
          }}
        />
      )}

      {mostrarFormFuncionario && (
        <FuncionarioForm
          mostrar={mostrarFormFuncionario}
          aoVoltar={() => setMostrarFuncionario(false)}
          afterSubmit={funcionario => {
            setFuncionarios([funcionario, ...funcionarios]);
            form.setFieldsValue({
              funcionarioUid: funcionario.uid,
            });
          }}
        />
      )}

      {!mostrarFormCelula && !mostrarFormFuncionario && (
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onFinish={values => {
              onFinish(
                { ...values, objectUid: ponto?.uid },
                cadastrarPonto,
                editarPonto,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="data"
              label="Data do Ponto"
              obrigatorio
              mostrarFoco={mostrar}
              comPopover
              tituloPopover="Data do Ponto"
              textoPopover="Informe a data que será lançada para este ponto."
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Selecione uma data"
              />
            </Campo>
            <Grupo>
              <Campo
                name="funcionarioUid"
                label="Funcionário"
                obrigatorio
                comPopover
                tituloPopover="Funcionário"
                textoPopover="Informe o funcionário do ponto. Você também pode criar um novo funcionário clicando em Novo ao final da lista."
              >
                <SelectDinamico
                  form={form}
                  placeholder="Selecione o funcionário"
                  nomeCampo="funcionarioUid"
                  aoAdicionar={() => setMostrarFuncionario(true)}
                >
                  {funcionarios?.map(({ uid, nomeCompleto }) => (
                    <Option key={uid} value={uid}>
                      {nomeCompleto}
                    </Option>
                  ))}
                </SelectDinamico>
              </Campo>
              <Campo
                name="celulaUid"
                label="Célula"
                obrigatorio
                comPopover
                tituloPopover="Célula"
                textoPopover="Informe a célula do ponto. Você também pode criar uma nova célula clicando em Novo ao final da lista."
              >
                <SelectDinamico
                  form={form}
                  placeholder="Selecione a célula"
                  nomeCampo="celulaUid"
                  aoAdicionar={() => setMostrarCelula(true)}
                >
                  {celulas?.map(({ uid, nome }) => (
                    <Option key={uid} value={uid}>
                      {nome}
                    </Option>
                  ))}
                </SelectDinamico>
              </Campo>
            </Grupo>
            <Sessao titulo="Primeiro Horário">
              <Grupo>
                <Campo
                  name="entrada1"
                  label="Entrada 1"
                  obrigatorio
                  comPopover
                  tituloPopover="Primeira Entrada"
                  textoPopover="Informe o horário da primeira entrada do funcionário. Apenas a primeira entrada é obrigatória."
                >
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada1: target.value })
                    }
                  />
                </Campo>

                <Campo
                  name="saida1"
                  label="Saída 1"
                  obrigatorio
                  comPopover
                  tituloPopover="Primeira Saída"
                  textoPopover="Informe o horário da primeira saída do funcionário. Apenas a primeira saída é obrigatória. Não esqueça de clicar em salvar após termina de preencher os campos."
                >
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida1: target.value })
                    }
                  />
                </Campo>
              </Grupo>
            </Sessao>

            <Sessao titulo="Segundo Horário">
              <Grupo>
                <Campo name="entrada2" label="Entrada 2">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada2: target.value })
                    }
                  />
                </Campo>

                <Campo name="saida2" label="Saída 2">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida2: target.value })
                    }
                  />
                </Campo>
              </Grupo>
            </Sessao>
            <Sessao titulo="Terceiro Horário">
              <Grupo>
                <Campo name="entrada3" label="Entrada 3">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ entrada3: target.value })
                    }
                  />
                </Campo>

                <Campo name="saida3" label="Saída 3">
                  <MaskedInput
                    mask="11:11"
                    placeholder="Selecione um horário"
                    onChange={({ target }) =>
                      form.setFieldsValue({ saida3: target.value })
                    }
                  />
                </Campo>
              </Grupo>
            </Sessao>

            <Campo fluido>
              <BotaoPrimario htmlType="submit" type="primary">
                Salvar
              </BotaoPrimario>
            </Campo>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

PontoForm.propTypes = {
  ponto: objectOf(any),
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
};

PontoForm.defaultProps = {
  ponto: null,
  aoFechar: null,
  mostrar: false,
  afterSubmit: null,
};

export default PontoForm;

import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { func, number } from 'prop-types';
import { Form } from 'antd';
import { MesesAno } from '../../../utils/enum';
import Campo from '../Campo';
import { Select, SelectContainer } from './styles';
import { useApi } from '../../../hooks';

const { Option } = Select;

const periodos = [
  { value: 0, text: `${MesesAno[moment().month() + 1]}/${moment().year()}` },
  {
    value: 1,
    text: `${
      MesesAno[moment().subtract(1, 'month').month() + 1]
    }/${moment().subtract(1, 'month').year()}`,
  },
  { value: 3, text: 'Últimos 3 Meses' },
  { value: 6, text: 'Últimos 6 Meses' },
  { value: 12, text: 'Últimos 12 Meses' },
];

const GraficoHeader = ({
  celulaUid,
  funcionarioUid,
  carregarProps,
  setDadosGrafico,
}) => {
  const { buscar } = useApi();
  const [periodo, setPeriodo] = useState(0);
  const [celulaAtiva, setCelulaAtiva] = useState(true);
  const [funcionarioAtivo, setFuncionarioAtivo] = useState(true);
  const [celulaSelecionada, setCelulaSelecionada] = useState(null);
  const [funcSelecionado, setFuncSelecionado] = useState(null);
  const [celulas, setCelulas] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);

  useEffect(() => {
    const carregarCelulas = async () => {
      const { data } = await buscar('/celulas');
      setCelulas(data);
    };
    if (!celulaUid) carregarCelulas();
    setCelulaSelecionada(celulaUid);
  }, [buscar, celulaUid]);

  useEffect(() => {
    const carregarFuncionarios = async () => {
      const { data } = await buscar('/funcionarios');
      setFuncionarios(data);
    };
    if (!funcionarioUid) carregarFuncionarios();
    setFuncSelecionado(funcionarioUid);
  }, [buscar, funcionarioUid]);

  const buscarEficiencia = useCallback(
    async (dataInicial, dataFinal, intervalo) => {
      const novoPeriodo = intervalo > 1 ? 'mensal' : 'diaria';
      if (novoPeriodo) {
        const { data: values } = await buscar(
          `/eficiencia/${novoPeriodo}?${
            dataInicial && dataFinal
              ? `data[between]=${dataInicial}&data[between]=${dataFinal}`
              : ''
          }${celulaSelecionada ? `&celulaUid[eq]=${celulaSelecionada}` : ''}${
            funcSelecionado ? `&funcionarioUid[eq]=${funcSelecionado}` : ''
          }`,
        );

        let formato = 'mes';
        if (novoPeriodo === 'diaria') {
          formato = 'dia';
        }
        if (intervalo === 3) {
          formato = 'mes-dia';
        }

        if (values?.length > 0) {
          setDadosGrafico(values.map(props => carregarProps(props, formato)));
        } else {
          setDadosGrafico([
            carregarProps(
              [moment().format('YYYY-MM-DD'), 0, 0, 0, 0, 0],
              formato,
            ),
          ]);
        }
      }
    },
    [
      buscar,
      carregarProps,
      celulaSelecionada,
      funcSelecionado,
      setDadosGrafico,
    ],
  );

  const calcularData = useCallback(
    intervalo => {
      const dataInicial = moment()
        .subtract(intervalo, 'months')
        .startOf('month');
      let dataFinal;
      if (intervalo > 1) {
        dataFinal = moment().endOf('month');
      } else {
        dataFinal = moment().subtract(intervalo, 'months').endOf('month');
      }
      buscarEficiencia(
        dataInicial.format('YYYY-MM-DD'),
        dataFinal.format('YYYY-MM-DD'),
        intervalo,
      );
    },
    [buscarEficiencia],
  );

  useEffect(() => {
    calcularData(periodo);
  }, [calcularData, celulaSelecionada, funcSelecionado, periodo]);

  return (
    <Form>
      <SelectContainer>
        <Campo label="Período" block name="periodo">
          <Select
            value={periodo}
            placeholder={periodos[0].text}
            bordered
            showSearch
            onSelect={value => setPeriodo(value)}
          >
            {periodos.map(({ text, value }, index) => (
              <Option key={index} value={value}>
                {text}
              </Option>
            ))}
          </Select>
        </Campo>
        <SelectContainer
          width={
            (!celulaUid && funcionarioUid) || (!funcionarioUid && celulaUid)
              ? 'auto'
              : '380px'
          }
        >
          {!celulaUid && (
            <Campo label="Célula" block name="celula">
              <Select
                placeholder="Todas"
                disabled={!celulaAtiva}
                value={celulaSelecionada}
                bordered
                showSearch
                onSelect={value => {
                  setFuncionarioAtivo(value === null);
                  setCelulaSelecionada(value);
                }}
              >
                <Option key="todas" value={null}>
                  Todas
                </Option>
                {celulas?.map(({ uid, nome }) => (
                  <Option key={uid} value={uid}>
                    {nome}
                  </Option>
                ))}
              </Select>
            </Campo>
          )}
          {!funcionarioUid && (
            <Campo label="Funcionário" block name="funcionario">
              <Select
                disabled={!funcionarioAtivo}
                value={funcSelecionado}
                placeholder="Todos"
                bordered
                showSearch
                onSelect={value => {
                  setCelulaAtiva(value === null);
                  setFuncSelecionado(value);
                }}
              >
                <Option key="todas" value={null}>
                  Todos
                </Option>
                {funcionarios?.map(({ nomeCompleto, uid }) => (
                  <Option key={uid} value={uid}>
                    {nomeCompleto}
                  </Option>
                ))}
              </Select>
            </Campo>
          )}
        </SelectContainer>
      </SelectContainer>
    </Form>
  );
};

GraficoHeader.propTypes = {
  celulaUid: number,
  funcionarioUid: number,
  carregarProps: func.isRequired,
  setDadosGrafico: func.isRequired,
};

GraficoHeader.defaultProps = {
  celulaUid: null,
  funcionarioUid: null,
};

export default GraficoHeader;

import React, { useEffect } from 'react';
import moment from 'moment';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, bool, number, objectOf, any } from 'prop-types';
import { lerObjectProp, onFinish } from '../../commonFunctions';
import { DatePicker, Input, TextArea } from '../../commonStyles';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';

const PremioForm = ({
  mostrar,
  aoFechar,
  afterSubmit,
  apuracaoUid,
  premio,
}) => {
  const { alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  useEffect(() => {
    lerObjectProp(
      { ...premio, dataPagamento: moment(premio?.dataPagamento ?? undefined) },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [form.resetFields, form.setFieldsValue, premio]);

  const cadastrarPremio = async values => {
    const { data } = await alterar(
      `apuracoes/${apuracaoUid}/premios/${premio?.uid}`,
      {
        ...values,
        dataPagamento: moment(values?.dataPagamento).format('YYYY-MM-DD'),
      },
      premio,
      `Prêmio alterado com sucesso!`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 300 : '100%'}
      title="Alterar Prêmio"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              values,
              cadastrarPremio,
              null,
              novoPremio => {
                if (afterSubmit && novoPremio) afterSubmit(novoPremio);
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="percentualAjuste"
            label="Percentual de Ajuste"
            obrigatorio
            mostrarFoco={!premio && mostrar}
          >
            <Input
              type="number"
              placeholder="Digite o percentual"
              maxLength={255}
            />
          </Campo>
          <Campo
            fluido
            name="dataPagamento"
            label="Data do Pagamento"
            obrigatorio
            mostrarFoco={mostrar && premio}
          >
            <DatePicker
              placeholder="Selecione uma data"
              picker="day"
              format="DD/MM/YYYY"
            />
          </Campo>
          <Campo fluido name="observacao" label="Observação" obrigatorio>
            <TextArea
              rows={4}
              type="text"
              placeholder="Digite suas observações..."
              maxLength={255}
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

PremioForm.propTypes = {
  premio: objectOf(any),
  aoFechar: func,
  apuracaoUid: number,
  mostrar: bool,
  afterSubmit: func,
};

PremioForm.defaultProps = {
  premio: null,
  apuracaoUid: null,
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
};

export default PremioForm;

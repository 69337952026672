import { MesesAno } from '../../../../utils/enum';

const tabela = {
  titulo: '',
  cabecalho: {
    desktop: [],
    mobile: [],
  },
};

for (let i = 1; i <= 12; i++) {
  tabela.cabecalho.desktop.push({
    titulo: MesesAno[i],
    align: 'right',
    atributo: i,
    tipo: 'moeda-compacta',
    valorMonetario: true,
  });
}

export default tabela;

import TabelaApontamentos from './TabelaApontamentos';

export default {
  titulo: 'Apontamentos da Ordem de Produção',
  cabecalho: {
    desktop: [
      ...TabelaApontamentos.cabecalho.desktop.filter(
        ({ titulo }) =>
          titulo !== 'Produto' && titulo !== 'Operação' && titulo !== 'Ordem',
      ),
    ],
    mobile: [
      {
        titulo: 'Apontamentos',
        variavel: true,
        agrupar: [
          { atributo: 'data', tipo: 'data' },
          { atributo: 'nomeCompleto', aninhamento: 'funcionario' },
          { atributo: 'nome', aninhamento: 'operacao' },
          {
            tipo: 'n-decimal',
            custom: ({ quantidade }, format) =>
              `Quantidade: ${format(quantidade)}`,
          },
        ],
      },
    ],
  },
};

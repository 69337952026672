/** Pega o objeto repassado no parâmetro e aplica no formulário
 *  do componente com todas as suas propriedades */
export const lerObjectProp = (objeto, setarCampo, limparCampos) => {
  const chaves = objeto ? Object.keys(objeto) : [];

  if (chaves.length === 0) {
    return limparCampos?.();
  }
  return chaves.forEach(key => {
    setarCampo({ [key]: objeto[key] });
  });
};

export const onFinish = async (
  values,
  cadastrarFunction,
  editarFunction,
  afterSubmit,
  limparCampos,
) => {
  const { objectUid: uid } = values;
  delete values.objectUid;
  let newValues = null;
  if (uid) {
    newValues = await editarFunction(values, uid);
  } else {
    newValues = await cadastrarFunction(values);
  }
  if (afterSubmit && newValues) afterSubmit(newValues);
  if (limparCampos && newValues && !uid) limparCampos();
};

export default {
  titulo: 'Observações',
  cabecalho: {
    desktop: [
      {
        titulo: '#',
        atributo: 'sequencia',
        align: 'right',
      },
      {
        titulo: 'Observação',
        atributo: 'texto',
        variavel: true,
      },
    ],
    mobile: [
      {
        titulo: 'Observação',
        custom: ({ sequencia, texto }) => `${sequencia} - ${texto}`,
      },
    ],
  },
};

/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { func, objectOf, any, bool } from 'prop-types';
import { Form } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { BotaoPrimario, Campo } from '../../layout';
import { useApi } from '../../../hooks';
import { Grupo, Input, Checkbox } from '../commonStyles';
import { lerObjectProp } from '../commonFunctions';

const TurnoFormDiaSemana = ({ mostrar, afterSubmitDiaSemana, turno }) => {
  const { criar, alterar } = useApi();
  const [form] = Form.useForm();

  const [domingo, setDomingo] = useState(false);
  const [segunda, setSegunda] = useState(false);
  const [terca, setTerca] = useState(false);
  const [quarta, setQuarta] = useState(false);
  const [quinta, setQuinta] = useState(false);
  const [sexta, setSexta] = useState(false);
  const [sabado, setSabado] = useState(false);

  const dias = [
    'domingo',
    'segunda',
    'terca',
    'quarta',
    'quinta',
    'sexta',
    'sabado',
  ];

  const tratarDadosEdicao = useCallback(turno => {
    if (!turno) {
      return null;
    }
    const { turnoDiaSemana } = turno;

    const domingo = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'domingo',
    );
    domingo ? setDomingo(true) : null;

    const segunda = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'segunda',
    );
    segunda ? setSegunda(true) : null;

    const terca = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'terça',
    );
    terca ? setTerca(true) : null;

    const quarta = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'quarta',
    );
    quarta ? setQuarta(true) : null;

    const quinta = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'quinta',
    );
    quinta ? setQuinta(true) : null;

    const sexta = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'sexta',
    );
    sexta ? setSexta(true) : null;

    const sabado = turnoDiaSemana.find(
      dia => dia.nome.toLowerCase() === 'sábado',
    );
    sabado ? setSabado(true) : null;

    const novoTurno = {
      nome: turno.nome,

      domingoEntrada1: domingo ? domingo.entrada1 : null,
      domingoSaida1: domingo ? domingo.saida1 : null,
      domingoEntrada2: domingo ? domingo.entrada2 : null,
      domingoSaida2: domingo ? domingo.saida2 : null,
      domingoEntrada3: domingo ? domingo.entrada3 : null,
      domingoSaida3: domingo ? domingo.saida3 : null,

      segundaEntrada1: segunda ? segunda.entrada1 : null,
      segundaSaida1: segunda ? segunda.saida1 : null,
      segundaEntrada2: segunda ? segunda.entrada2 : null,
      segundaSaida2: segunda ? segunda.saida2 : null,
      segundaEntrada3: segunda ? segunda.entrada3 : null,
      segundaSaida3: segunda ? segunda.saida3 : null,

      tercaEntrada1: terca ? terca.entrada1 : null,
      tercaSaida1: terca ? terca.saida1 : null,
      tercaEntrada2: terca ? terca.entrada2 : null,
      tercaSaida2: terca ? terca.saida2 : null,
      tercaEntrada3: terca ? terca.entrada3 : null,
      tercaSaida3: terca ? terca.saida3 : null,

      quartaEntrada1: quarta ? quarta.entrada1 : null,
      quartaSaida1: quarta ? quarta.saida1 : null,
      quartaEntrada2: quarta ? quarta.entrada2 : null,
      quartaSaida2: quarta ? quarta.saida2 : null,
      quartaEntrada3: quarta ? quarta.entrada3 : null,
      quartaSaida3: quarta ? quarta.saida3 : null,

      quintaEntrada1: quinta ? quinta.entrada1 : null,
      quintaSaida1: quinta ? quinta.saida1 : null,
      quintaEntrada2: quinta ? quinta.entrada2 : null,
      quintaSaida2: quinta ? quinta.saida2 : null,
      quintaEntrada3: quinta ? quinta.entrada3 : null,
      quintaSaida3: quinta ? quinta.saida3 : null,

      sextaEntrada1: sexta ? sexta.entrada1 : null,
      sextaSaida1: sexta ? sexta.saida1 : null,
      sextaEntrada2: sexta ? sexta.entrada2 : null,
      sextaSaida2: sexta ? sexta.saida2 : null,
      sextaEntrada3: sexta ? sexta.entrada3 : null,
      sextaSaida: sexta ? sexta.saida3 : null,

      sabadoEntrada1: sabado ? sabado.entrada1 : null,
      sabadoSaida1: sabado ? sabado.saida1 : null,
      sabadoEntrada2: sabado ? sabado.entrada2 : null,
      sabadoSaida2: sabado ? sabado.saida2 : null,
      sabadoEntrada3: sabado ? sabado.entrada3 : null,
      sabadoSaida3: sabado ? sabado.saida3 : null,
    };

    return novoTurno;
  }, []);

  useEffect(() => {
    const turnoDiaSemanaFormatado = tratarDadosEdicao(turno);

    lerObjectProp(
      turnoDiaSemanaFormatado,
      form.setFieldsValue,
      form.resetFields,
    );
  }, [turno, form.setFieldsValue, form.resetFields, tratarDadosEdicao]);

  const tratarDados = useCallback(
    dados => {
      const carregarEstrutura = ({
        nome,
        ativo,
        entrada1,
        saida1,
        entrada2,
        saida2,
        entrada3,
        saida3,
        diaSemana,
      }) => {
        const estrutura = {
          ativo: false,
          nome: '',
          entrada1: '00:00',
          saida1: '00:00',
          entrada2: '00:00',
          saida2: '00:00',
          entrada3: '00:00',
          saida3: '00:00',
          diaSemana: Number(),
        };
        estrutura.nome = nome;
        estrutura.ativo = ativo;
        estrutura.entrada1 = entrada1 === undefined ? null : entrada1;
        estrutura.saida1 = saida1 === undefined ? null : saida1;
        estrutura.entrada2 = entrada2 === undefined ? null : entrada2;
        estrutura.saida2 = saida2 === undefined ? null : saida2;
        estrutura.entrada3 = entrada3 === undefined ? null : entrada3;
        estrutura.saida3 = saida3 === undefined ? null : saida3;
        estrutura.diaSemana = diaSemana;

        return estrutura;
      };

      const data = {};
      const diasSemana = [];

      diasSemana.push(
        carregarEstrutura({
          nome: 'Domingo',
          ativo: domingo,
          entrada1: dados.domingoEntrada1,
          saida1: dados.domingoSaida1,
          entrada2: dados.domingoEntrada2,
          saida2: dados.domingoSaida2,
          entrada3: dados.domingoEntrada3,
          saida3: dados.domingoSaida3,
          diaSemana: 0,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Segunda',
          ativo: segunda,
          entrada1: dados.segundaEntrada1,
          saida1: dados.segundaSaida1,
          entrada2: dados.segundaEntrada2,
          saida2: dados.segundaSaida2,
          entrada3: dados.segundaEntrada3,
          saida3: dados.segundaSaida3,
          diaSemana: 1,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Terça',
          ativo: terca,
          entrada1: dados.tercaEntrada1,
          saida1: dados.tercaSaida1,
          entrada2: dados.tercaEntrada2,
          saida2: dados.tercaSaida2,
          entrada3: dados.tercaEntrada3,
          saida3: dados.tercaSaida3,
          diaSemana: 2,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Quarta',
          ativo: quarta,
          entrada1: dados.quartaEntrada1,
          saida1: dados.quartaSaida1,
          entrada2: dados.quartaEntrada2,
          saida2: dados.quartaSaida2,
          entrada3: dados.quartaEntrada3,
          saida3: dados.quartaSaida3,
          diaSemana: 3,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Quinta',
          ativo: quinta,
          entrada1: dados.quintaEntrada1,
          saida1: dados.quintaSaida1,
          entrada2: dados.quintaEntrada2,
          saida2: dados.quintaSaida2,
          entrada3: dados.quintaEntrada3,
          saida3: dados.quintaSaida3,
          diaSemana: 4,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Sexta',
          ativo: sexta,
          entrada1: dados.sextaEntrada1,
          saida1: dados.sextaSaida1,
          entrada2: dados.sextaEntrada2,
          saida2: dados.sextaSaida2,
          entrada3: dados.sextaEntrada3,
          saida3: dados.sextaSaida3,
          diaSemana: 5,
        }),
      );

      diasSemana.push(
        carregarEstrutura({
          nome: 'Sábado',
          ativo: sabado,
          entrada1: dados.sabadoEntrada1,
          saida1: dados.sabadoSaida1,
          entrada2: dados.sabadoEntrada2,
          saida2: dados.sabadoSaida2,
          entrada3: dados.sabadoaEntrada3,
          saida3: dados.sabadoSaida3,
          diaSemana: 6,
        }),
      );

      data.nome = dados.nome;
      data.diasSemana = diasSemana;

      return data;
    },
    [domingo, segunda, terca, quarta, quinta, sexta, sabado],
  );

  const selecionarDiaSemana = useCallback(
    dia => {
      switch (dia) {
        case 'domingo':
          domingo ? setDomingo(false) : setDomingo(true);
          break;

        case 'segunda':
          segunda ? setSegunda(false) : setSegunda(true);
          break;

        case 'terca':
          terca ? setTerca(false) : setTerca(true);
          break;

        case 'quarta':
          quarta ? setQuarta(false) : setQuarta(true);
          break;

        case 'quinta':
          quinta ? setQuinta(false) : setQuinta(true);
          break;

        case 'sexta':
          sexta ? setSexta(false) : setSexta(true);
          break;

        case 'sabado':
          sabado ? setSabado(false) : setSabado(true);
          break;

        default:
          break;
      }
    },
    [domingo, quarta, quinta, sabado, segunda, sexta, terca],
  );

  const retornarDiaSemana = useCallback(
    // eslint-disable-next-line consistent-return
    dia => {
      switch (dia) {
        case 'domingo':
          return domingo;
        // break;

        case 'segunda':
          return segunda;
        // break;

        case 'terca':
          return terca;
        // break;

        case 'quarta':
          return quarta;
        // break;

        case 'quinta':
          return quinta;
        // break;

        case 'sexta':
          return sexta;
        // break;

        case 'sabado':
          return sabado;
        // break;

        default:
          break;
      }
    },
    [domingo, quarta, quinta, sabado, segunda, sexta, terca],
  );

  const cadastrarDados = useCallback(
    async dados => {
      const { nome, diasSemana } = dados;
      const { data } = await criar(
        '/TurnoDiaSemana/',
        { nome, diasSemana },
        `Turno ${dados.nome} cadastrado com sucesso`,
      );
      return data;
    },
    [criar],
  );

  const editarDados = useCallback(
    async ({ dados, id }) => {
      const { data } = await alterar(
        `/TurnoDiaSemana/${id}`,
        dados,
        `Turno ${dados.nome} foi alterado com sucesso`,
      );

      return data;
    },
    [alterar],
  );

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      name="controlled-form"
      onFinish={async values => {
        const dados = tratarDados(values);
        try {
          if (turno) {
            const { turno: turnoEditado } = await editarDados({
              dados,
              id: turno.uid,
            });
            afterSubmitDiaSemana({ turno: turnoEditado });
          } else {
            const { turno } = await cadastrarDados(dados);
            afterSubmitDiaSemana({ turno });
          }
        } catch (e) {
          console.log(e);
        }
      }}
    >
      <Campo fluido name="nome" label="Nome" obrigatorio mostrarFoco={mostrar}>
        <Input type="text" placeholder="Digite um nome" maxLength={255} />
      </Campo>

      {dias.map(dia => {
        let nomeDia = dia;

        if (dia === 'terca') {
          nomeDia = 'terça';
        }

        if (dia === 'sabado') {
          nomeDia = 'Sábado';
        }

        const entrada1 = `${dia}Entrada1`;
        const saida1 = `${dia}Saida1`;

        const entrada2 = `${dia}Entrada2`;
        const saida2 = `${dia}Saida2`;

        const entrada3 = `${dia}Entrada3`;
        const saida3 = `${dia}Saida3`;

        return (
          <>
            <Grupo>
              <h2 style={{ marginRight: '10px' }}>{nomeDia.toUpperCase()}</h2>
              <Campo>
                <Checkbox
                  data-checkBox={dia}
                  checked={retornarDiaSemana(dia)}
                  onChange={() => selecionarDiaSemana(dia)}
                />
              </Campo>
            </Grupo>

            <Grupo>
              <Campo
                name={entrada1}
                label="Entrada 1"
                obrigatorio={retornarDiaSemana(dia)}
              >
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ entrada1: target.value })
                  }
                />
              </Campo>

              <Campo
                name={saida1}
                label="Saída 1"
                obrigatorio={retornarDiaSemana(dia)}
              >
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ saida1: target.value })
                  }
                />
              </Campo>
            </Grupo>

            <Grupo>
              <Campo name={entrada2} label="Entrada 2">
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ entrada2: target.value })
                  }
                />
              </Campo>

              <Campo name={saida2} label="Saída 2">
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ saida2: target.value })
                  }
                />
              </Campo>
            </Grupo>

            <Grupo>
              <Campo name={entrada3} label="Entrada 3">
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ entrada3: target.value })
                  }
                />
              </Campo>

              <Campo name={saida3} label="Saída 3">
                <MaskedInput
                  disabled={!retornarDiaSemana(dia)}
                  mask="11:11"
                  placeholder="Selecione um horário"
                  onChange={({ target }) =>
                    form.setFieldsValue({ saida3: target.value })
                  }
                />
              </Campo>
            </Grupo>
          </>
        );
      })}

      <Campo fluido>
        <BotaoPrimario htmlType="submit" type="primary">
          Salvar
        </BotaoPrimario>
      </Campo>
    </Form>
  );
};

TurnoFormDiaSemana.propTypes = {
  afterSubmitDiaSemana: func,
  turno: objectOf(any),
  mostrar: bool,
};

TurnoFormDiaSemana.defaultProps = {
  mostrar: false,
  afterSubmitDiaSemana: null,
  turno: null,
};

export default TurnoFormDiaSemana;

import React, { useState, useEffect, useCallback } from 'react';
import { objectOf, any } from 'prop-types';
import { Container } from '../styles';
import { Header, Estatistica, Tabela } from '../../components/layout';
import { TabPeriodos } from '../../components/tabs';
import { useApi, useUrl } from '../../hooks';
import { DataContainer } from './styles';

import { PERIODO } from '../../hooks/useUrl/acoes';
import { formatar } from '../../utils';
import { COMPARAR_APONTAMENTOS } from '../../components/layout/Tabela/nomesCabecalhos';

// eslint-disable-next-line react/prop-types
const CompararApontamentos = ({ history }) => {
  const { buscar } = useApi();
  const { pegarValorQuery } = useUrl(history);
  const periodo = pegarValorQuery(PERIODO);

  const [dados, setDados] = useState({});

  const carregarDados = useCallback(async () => {
    const [dataInicial, dataFinal] = periodo.split('/');
    const { data } = await buscar(
      `/compararApontamentos?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
    );
    setDados(data);
  }, [buscar, periodo]);

  const montarConfiguracoes = useCallback(data => {
    const config = {};

    config.celula = {
      color: '',
      value: formatar('minutos', data.tempoTotalCelulas),
    };

    config.funcionario = {
      color: '',
      value: formatar('minutos', data.tempoTotalFuncionario),
    };

    config.previsto = {
      color: '',
      value: formatar('minutos', data.tempoTotalPrevisto),
    };

    return config;
  }, []);

  useEffect(() => {
    if (periodo) {
      carregarDados();
    }
  }, [carregarDados, periodo]);

  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          className="site-page-header"
          titulo="Comparar Apontamentos"
        >
          <DataContainer>
            <Estatistica
              titulo="Dados Absolutos"
              configuracao={montarConfiguracoes(dados)}
            />
          </DataContainer>
        </Header>

        <TabPeriodos history={history} transparente>
          <Tabela
            cabecalho={COMPARAR_APONTAMENTOS}
            dados={dados.ordemProducao ? dados.ordemProducao : []}
          />
        </TabPeriodos>
      </Container>
    </>
  );
};

CompararApontamentos.prototype = {
  history: objectOf(any).isRequired,
};

export default CompararApontamentos;

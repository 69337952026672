import ApuracaoForm from './ApuracaoForm';
import ObservacaoForm from './ObservacaoForm';
import PremioCelulaForm from './PremioCelulaForm';
import PremiacaoForm from './PremiacaoForm';
import PremioForm from './PremioForm';

export default {
  PremioCelulaForm,
  ApuracaoForm,
  PremioForm,
  ObservacaoForm,
  PremiacaoForm,
};

import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { any, objectOf } from 'prop-types';
import moment from 'moment';
import {
  BotaoPrimario,
  Estatistica,
  Header,
  Tabela,
} from '../../components/layout';
import { useApi, useUrl } from '../../hooks';
import { formatar } from '../../utils';
import { Container, Select } from '../styles';
import { APURACAO, CADASTRAR, PERIODO } from '../../hooks/useUrl/acoes';
import { TabPeriodos } from '../../components/tabs';
import { METAS_PREMIOS } from '../../components/layout/Tabela/nomesCabecalhos';
import { GraficoFaturamento } from '../../components/graficos';
import { EstatisticaContainer } from './styles';
import ApuracaoForm from '../../components/forms/PremiacaoForm/ApuracaoForm';

const { Option } = Select;

const DashboardPremios = ({ history }) => {
  const { adicionarQuery, removerQuery, pegarValorQuery, existeQuery } = useUrl(
    history,
  );
  const { buscar } = useApi();
  const periodo = pegarValorQuery(PERIODO);
  const [metasPremios, setMetasPremios] = useState(null);
  const [celulas, setCelulas] = useState([]);
  const [celulaSelecionada, setCelulaSelecionada] = useState(null);
  const [minutosConfig, setMinutosConfig] = useState({});
  const [faturamentoConfig, setFaturamentoConfig] = useState({});
  const [resultadoConfig, setResultadoConfig] = useState({});
  const mostrarCadastro = existeQuery(CADASTRAR, APURACAO);
  const { percentualDistribuicao, loading } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const [calcularPremiosNegativo, setCalcularPremiosNegativo] = useState(false);

  const buscarPrevisao = useCallback(async () => {
    const dataAtual = moment(new Date()).format('yyyy-MM-DD');
    if (periodo) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      const { data } = await buscar(
        `/previsao-producao/diaria?dataInicial=${dataInicio}&dataFinal=${dataFinal}&dataAtual=${dataAtual}${
          celulaSelecionada ? `&celulaUid=${celulaSelecionada}` : ''
        }`,
      );
      setMetasPremios(data);
      if (data.length > 0) {
        if (!data[0]?.valorMeta) {
          message.warn(
            'Sem valor de custo ideal configurado nas configurações do sistema',
            15,
          );
        }

        if (!data[0]?.valorMinimo) {
          message.warn(
            'Sem valor de minuto mínimo configurado nas configurações do sistema',
            15,
          );
        }
      }
    }
  }, [buscar, celulaSelecionada, periodo]);

  const buscarDadosEmpresa = useCallback(async () => {
    const { data } = await buscar('/configuracoes');
    setCalcularPremiosNegativo(data?.calcularPremiosNegativo);
  }, [buscar]);

  useEffect(() => {
    buscarPrevisao();
    buscarDadosEmpresa();
  }, [buscarPrevisao, buscarDadosEmpresa]);

  const apresentarLucroBrutoPremioAtual = useCallback(() => {
    const { previsoes } = metasPremios;
    if (previsoes) {
      const { valorFaturado, valorMinimo } = metasPremios?.previsoes.reduce(
        (soma, obj) => {
          soma.valorFaturado += obj.valorFaturado;
          soma.valorMinimo += obj.valorMinimo;
          return soma;
        },
        {
          valorFaturado: 0,
          valorMinimo: 0,
        },
      );
      return valorFaturado - valorMinimo < 0;
    }
    return false;
  }, [metasPremios]);

  useEffect(() => {
    if (metasPremios?.previsoes) {
      const { previsoes } = metasPremios;
      const somatorio = (propriedade, cor) => {
        const soma = previsoes?.reduce((somado, objeto) => {
          if (objeto) {
            somado += objeto?.[propriedade] ? Number(objeto?.[propriedade]) : 0;
          }
          return somado;
        }, 0);
        return {
          color: cor,
          value: formatar('n-decimal', soma ?? 0, 2),
        };
      };
      const absenteismo = somatorio('absenteismo');
      setMinutosConfig({
        Previstos: somatorio('previsto'),
        Trabalhados: somatorio('trabalhado', '#1890ff'),
        Produzidos: somatorio('tempoApontado', 'rgb(18, 161, 42)'),
        Eficiência: {
          cor: 'rgb(197, 168, 22)',
          value: `${formatar(
            'n-decimal',
            metasPremios.geral.eficienciaGeral || 0,
            2,
          )}%`,
        },
        Absenteísmo: {
          ...absenteismo,
          color: '#e63946',
          value: `${formatar(
            'n-decimal',
            metasPremios.geral.absenteismoGeral || 0,
            2,
          )}`,
        },
        Parada: {
          color: '#e63946',
          value: formatar('n-decimal', metasPremios.geral.paradaTotal || 0, 2),
        },
      });

      setFaturamentoConfig({
        'Meta 100%': somatorio('valorMeta'),
        Estimado: somatorio('valorEstimado', '#1890ff'),
        Realizado: somatorio('valorFaturado', 'rgb(18, 161, 42)'),
        Mínimo: somatorio('valorMinimo'),
      });

      if (calcularPremiosNegativo) {
        setResultadoConfig({
          'Lucro Bruto': apresentarLucroBrutoPremioAtual()
            ? somatorio('lucroBruto', '#e63946')
            : somatorio('lucroBruto', 'rgb(18, 161, 42)'),
          'Prêmio Atual': apresentarLucroBrutoPremioAtual()
            ? somatorio('valorPremio', '#e63946')
            : somatorio('valorPremio', 'rgb(197, 168, 22)'),
        });
      }

      if (!calcularPremiosNegativo) {
        setResultadoConfig({
          'Lucro Bruto': apresentarLucroBrutoPremioAtual()
            ? { color: '', value: 0 }
            : somatorio('lucroBruto', 'rgb(18, 161, 42)'),
          'Prêmio Atual': apresentarLucroBrutoPremioAtual()
            ? { color: '', value: 0 }
            : somatorio('valorPremio', 'rgb(197, 168, 22)'),
        });
      }

      // setResultadoConfig({
      //   'Lucro Bruto': somatorio('lucroBruto', 'rgb(18, 161, 42)'),
      //   'Prêmio Atual': somatorio('valorPremio', 'rgb(197, 168, 22)'),
      // });
    }
  }, [
    percentualDistribuicao,
    metasPremios,
    apresentarLucroBrutoPremioAtual,
    calcularPremiosNegativo,
  ]);

  useEffect(() => {
    const buscarCelulas = async () => {
      const { data } = await buscar(`/celulas?$campos=uid,nome`);
      setCelulas(data);
    };

    buscarCelulas();
  }, [buscar]);

  return (
    <>
      <Container>
        <Header
          transparente
          semPadding="true"
          titulo="Dashboard Prêmios"
          extra={[
            <Select
              key="1"
              value={celulaSelecionada}
              placeholder="Todas"
              bordered
              showSearch
              onSelect={value => setCelulaSelecionada(value)}
            >
              {celulas.map(({ nome, uid }, index) => (
                <Option key={index} value={uid}>
                  {nome}
                </Option>
              ))}
              <Option key={celulas?.length + 1} value={null}>
                Todas
              </Option>
            </Select>,
            <BotaoPrimario
              disabled={loading}
              key="2"
              type="primary"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, APURACAO)}
            >
              Nova apuração
            </BotaoPrimario>,
          ]}
        />
        <EstatisticaContainer>
          <Estatistica
            titulo="Minutos de Produção"
            configuracao={minutosConfig}
          />
          <Estatistica titulo="Faturamento" configuracao={faturamentoConfig} />
          <Estatistica titulo="Resultado" configuracao={resultadoConfig} />
        </EstatisticaContainer>
        <GraficoFaturamento data={metasPremios?.previsoes ?? []} />
        <TabPeriodos history={history} tirarmargem transparente>
          <Tabela dados={metasPremios?.previsoes} cabecalho={METAS_PREMIOS} />
        </TabPeriodos>
      </Container>

      <ApuracaoForm
        mostrar={mostrarCadastro}
        aoFechar={() => removerQuery(CADASTRAR, APURACAO)}
        afterSubmit={apuracao => {
          if (apuracao?.uid) {
            removerQuery(CADASTRAR, APURACAO);
            buscarPrevisao();
          }
        }}
      />
    </>
  );
};

DashboardPremios.propTypes = {
  history: objectOf(any).isRequired,
};

export default DashboardPremios;

import TabelaOrdensProducao from './TabelaOrdensProducao';

export default {
  titulo: 'Ordens de Produção',
  cabecalho: {
    desktop: [
      {
        titulo: 'Ordem',
        atributo: 'numero',
        align: 'right',
      },
      {
        titulo: 'Produto',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['referencia', 'nome'],
            },
          },
          { atributo: 'referencia' },
          { atributo: 'nome' },
        ],
      },
      {
        titulo: 'Cliente',
        aninhamento: 'cliente',
        agrupar: [
          {
            icone: {
              atributo: 'logo40Url',
              alt: ['apelido', 'razaoSocial'],
            },
          },
          { atributo: 'apelido' },
          { atributo: 'nomeFantasia' },
        ],
      },
      {
        titulo: 'Saldo',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        custom: ({ quantidadeApontada, quantidade }, format) =>
          format(quantidade - (quantidadeApontada ?? 0)),
      },
      {
        titulo: 'Tempo Total',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
        custom: ({ quantidadeApontada, quantidade, tempoFinal }, format) =>
          format((quantidade - (quantidadeApontada ?? 0)) * tempoFinal),
      },
    ],
    mobile: TabelaOrdensProducao.cabecalho.mobile,
  },
};

import styled from 'styled-components';
import { Card as AntCard, Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  width: 100% !important;
  @media only screen and (min-device-width: 640px) {
    width: 700px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const Card = styled(AntCard)`
  width: 100% !important;
  height: 100%;
  @media only screen and (min-device-width: 640px) {
    width: 700px !important;
    width: 700px;
    height: 300px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  padding: 39px;
  width: auto;
  justify-content: space-around;
  background: #f0f0f0;
`;

import React from 'react';
import { any, func, objectOf } from 'prop-types';
import { Botao } from './styles';

const BotaoDefault = ({ children, onClick, ...props }) => {
  return (
    <Botao title="Confirmar exclusão" onClick={onClick} {...props}>
      {children}
    </Botao>
  );
};

BotaoDefault.propTypes = {
  onClick: func,
  children: objectOf(any),
};

BotaoDefault.defaultProps = {
  onClick: null,
  children: null,
};

export default BotaoDefault;

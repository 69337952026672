import styled from 'styled-components';
import { Button } from 'antd';

export const ExcluirButton = styled(Button)`
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: red;
  border-radius: 5px;
  border: 1px solid red;
  background: transparent;
  box-shadow: none;
`;

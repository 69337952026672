import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

export const CategoriaEstatistica = styled.div``;

export const TituloContainer = styled.div`
  margin-bottom: 15px;
`;

export const Select = styled(AntSelect)`
  width: 200px;
  display: block;
`;

export const TableContainer = styled.div`
  width: -webkit-fill-available;
`;

export const EstatisticaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  div:first-child {
    margin-right: 15px;
  }
  ${({ min }) =>
    min &&
    `
    @media only screen and (min-device-width: 640px) {
      min-width: ${min};
    }
`}
`;

export const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${TableContainer}:first-child {
    margin-right: 10px;
  }
`;

import TabelaOperacoes from './TabelaOperacoes';

export default {
  titulo: 'Operações da Ordem de Produção',
  cabecalho: {
    desktop: [
      ...TabelaOperacoes.cabecalho.desktop.filter(
        ({ atributo }) =>
          atributo !== 'tempoCalcular' && atributo !== 'quantidadeCalcular',
      ),
      {
        width: '120px',
        titulo: 'Qtde Apontada',
        atributo: 'quantidadeApontada',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
      },
      {
        width: '120px',
        atributo: 'saldo',
        tipo: 'n-decimal',
        enumerador: 0,
        titulo: 'Saldo',
        align: 'right',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Apontamentos',
        agrupar: [
          {
            titulo: '#',
            atributo: 'sequencia',
            align: 'right',
          },
          {
            titulo: 'Operação',
            atributo: 'nome',
            tag: {
              atributo: 'nome',
              aninhamento: 'maquina',
            },
          },
        ],
      },
    ],
  },
};

import {
  TabelaApontamentos,
  TabelaCelulas,
  TabelaClientes,
  TabelaContas,
  TabelaFuncionarios,
  TabelaPontosDosFunc,
  TabelaPontosDoFuncionario,
  TabelaLancamentos,
  TabelaMaquinas,
  TabelaTurnos,
  TabelaOperacoes,
  TabelaOrdensProducao,
  TabelaApontamentosOrdemProducao,
  TabelaMotivos,
  TabelaParadas,
  TabelaOperacoesTempo,
  TabelaPacotes,
  TabelaOperacoesPacote,
  TabelaMotivosParadas,
  TabelaMaquinasParadas,
  TabelaFuncionarioExperiencia,
  TabelaSaldoMensalConta,
  TabelaAniversariantes,
  TabelaApontamentosCelulas,
  TabelaOrdensCliente,
  TabelaOperacoesFuncionario,
  TabelaOrdensClienteSaldo,
  TabelaOrdensProducaoSaldo,
  TabelaOperacoesBalanceadas,
  TabelaApuracoes,
  TabelaObservacoes,
  TabelaPremios,
  TabelaMetasProducao,
  TabelaDiasNaoTrabalhados,
  TabelaOrdensPlanejamento,
  TabelaMetasPremios,
  TabelaPremiosCelulas,
  TabelaPrevisaoPremios,
  TabelaCompararApontamentos,
} from './cabecalhos';
import {
  APONTAMENTOS,
  APONTAMENTOS_ORDEM_PRODUCAO,
  CELULAS,
  CLIENTES,
  CONTAS,
  FUNCIONARIOS,
  LANCAMENTOS,
  MAQUINAS,
  MOTIVOS,
  OPERACOES_ORDEM_PRODUCAO,
  ORDENS_PRODUCAO,
  PARADAS,
  PONTOS_DO_FUNCIONARIO,
  PONTOS_FUNCIONARIOS,
  SALDO_MENSAL_CONTA,
  OPERACOES_TEMPO,
  TURNOS,
  PACOTES_ORDEM_PRODUCAO,
  OPERACOES_PACOTE,
  CELULA_TURNO,
  FUNCIONARIO_CELULA,
  FUNCIONARIO_APONTAMENTO,
  MOTIVOS_PARADAS,
  PARADAS_MAQUINA,
  FUNCIONARIO_APONTAMENTO_DATA,
  FUNCIONARIO_EXPERIENCIA,
  APONTAMENTOS_CELULA,
  APONTAMENTOS_CELULAS,
  ANIVERSARIANTES,
  APONTAMENTOS_CELULA_ORDEM,
  ORDENS_CLIENTE,
  OPERACOES_FUNCIONARIO,
  ORDENS_PRODUCAO_SALDO,
  ORDENS_CLIENTE_SALDO,
  OPERACOES_BALANCEADAS,
  APURACOES,
  OBSERVACOES,
  PREMIOS,
  METAS_PRODUCAO,
  DIAS_NAO_TRABALHADOS,
  ORDENS_PLANEJADAS,
  ORDENS_NAO_PLANEJADAS,
  METAS_PREMIOS,
  PREMIOS_CELULAS,
  PREMIO_PREVISAO,
  COMPARAR_APONTAMENTOS,
} from './nomesCabecalhos';

export const getTabela = tipo => {
  switch (tipo) {
    case PREMIOS:
      return TabelaPremios;
    case COMPARAR_APONTAMENTOS:
      return TabelaCompararApontamentos;
    case PREMIOS_CELULAS:
      return TabelaPremiosCelulas;
    case PREMIO_PREVISAO:
      return TabelaPrevisaoPremios;
    case OBSERVACOES:
      return TabelaObservacoes;
    case APURACOES:
      return TabelaApuracoes;
    case METAS_PREMIOS:
      return TabelaMetasPremios;
    case METAS_PRODUCAO:
      return TabelaMetasProducao;
    case DIAS_NAO_TRABALHADOS:
      return TabelaDiasNaoTrabalhados;
    case OPERACOES_BALANCEADAS:
      return TabelaOperacoesBalanceadas;
    case APONTAMENTOS_CELULAS:
      return TabelaApontamentosCelulas;
    case OPERACOES_FUNCIONARIO:
      return TabelaOperacoesFuncionario;
    case APONTAMENTOS:
      return TabelaApontamentos;
    case PONTOS_FUNCIONARIOS:
      return TabelaPontosDosFunc;
    case PARADAS:
      return TabelaParadas;
    case MOTIVOS:
      return TabelaMotivos;
    case OPERACOES_PACOTE:
      return TabelaOperacoesPacote;
    case CELULAS:
      return TabelaCelulas;
    case PACOTES_ORDEM_PRODUCAO:
      return TabelaPacotes;
    case CLIENTES:
      return TabelaClientes;
    case CONTAS:
      return TabelaContas;
    case TURNOS:
      return TabelaTurnos;
    case MOTIVOS_PARADAS:
      return TabelaMotivosParadas;
    case APONTAMENTOS_ORDEM_PRODUCAO:
      return TabelaApontamentosOrdemProducao;
    case FUNCIONARIOS:
      return TabelaFuncionarios;
    case PONTOS_DO_FUNCIONARIO:
      return TabelaPontosDoFuncionario;
    case LANCAMENTOS:
      return TabelaLancamentos;
    case MAQUINAS:
      return TabelaMaquinas;
    case OPERACOES_ORDEM_PRODUCAO:
      return TabelaOperacoes;
    case OPERACOES_TEMPO:
      return TabelaOperacoesTempo;
    case ORDENS_PRODUCAO:
      return TabelaOrdensProducao;
    case ORDENS_PRODUCAO_SALDO:
      return TabelaOrdensProducaoSaldo;
    case ORDENS_CLIENTE:
      return TabelaOrdensCliente;
    case ORDENS_CLIENTE_SALDO:
      return TabelaOrdensClienteSaldo;
    case SALDO_MENSAL_CONTA:
      return TabelaSaldoMensalConta;
    case PARADAS_MAQUINA:
      return TabelaMaquinasParadas;
    case ANIVERSARIANTES:
      return TabelaAniversariantes;
    case FUNCIONARIO_EXPERIENCIA:
      return TabelaFuncionarioExperiencia;
    case ORDENS_PLANEJADAS:
      return TabelaOrdensPlanejamento;
    case ORDENS_NAO_PLANEJADAS:
      return {
        cabecalho: {
          desktop: [
            ...TabelaOrdensPlanejamento.cabecalho.desktop.filter(
              ({ titulo }) => titulo !== 'Previsão de Início',
            ),
          ],
          mobile: [],
        },
      };
    case APONTAMENTOS_CELULA_ORDEM:
      return {
        ...TabelaApontamentosCelulas,
        cabecalho: {
          desktop: [
            ...TabelaApontamentosCelulas.cabecalho.desktop.filter(
              ({ titulo }) =>
                titulo !== 'Produto' &&
                titulo !== 'Referência' &&
                titulo !== 'Ordem',
            ),
          ],
          mobile: [],
        },
      };
    case APONTAMENTOS_CELULA:
      return {
        ...TabelaApontamentosCelulas,
        cabecalho: {
          desktop: TabelaApontamentosCelulas.cabecalho.desktop.filter(
            ({ titulo }) => titulo !== 'Célula',
          ),
          mobile: [...TabelaApontamentosCelulas.cabecalho.mobile],
        },
      };
    case FUNCIONARIO_APONTAMENTO:
      return {
        ...TabelaApontamentos,
        cabecalho: {
          desktop: TabelaApontamentos.cabecalho.desktop.filter(
            ({ titulo }) => titulo !== 'Funcionário',
          ),
          mobile: [
            ...TabelaApontamentos.cabecalho.mobile.map(prop => {
              prop.agrupar = prop.agrupar.filter(
                ({ atributo }) => atributo !== 'nomeCompleto',
              );
              return prop;
            }),
          ],
        },
      };
    case FUNCIONARIO_APONTAMENTO_DATA:
      return {
        ...TabelaApontamentos,
        cabecalho: {
          desktop: TabelaApontamentos.cabecalho.desktop.filter(
            ({ titulo }) => titulo !== 'Funcionário' && titulo !== 'Data',
          ),
          mobile: [
            ...TabelaApontamentos.cabecalho.mobile.map(prop => {
              prop.agrupar = prop.agrupar.filter(
                ({ atributo }) =>
                  atributo !== 'data' && atributo !== 'nomeCompleto',
              );
              return prop;
            }),
          ],
        },
      };
    case FUNCIONARIO_CELULA:
      return {
        ...TabelaFuncionarios,
        cabecalho: {
          desktop: TabelaFuncionarios.cabecalho.desktop.filter(
            ({ titulo }) => titulo !== 'Célula',
          ),
          mobile: [...TabelaFuncionarios.cabecalho.mobile],
        },
      };
    case CELULA_TURNO:
      return {
        ...TabelaCelulas,
        cabecalho: {
          desktop: TabelaCelulas.cabecalho.desktop.filter(
            ({ titulo }) => titulo === 'Nome',
          ),
          mobile: TabelaCelulas.cabecalho.desktop.filter(
            ({ titulo }) => titulo === 'Nome',
          ),
        },
      };
    default:
      return {
        titulo: 'Cabeçalho não informado',
      };
  }
};

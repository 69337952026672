import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Tag } from 'antd';
import { objectOf, any } from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { formatar, telaGrande } from '../../../utils';
import { FuncionarioForm as Funcionario } from '../../../components/forms';
import { useApi, useUrl } from '../../../hooks';
import { Sexo, SituacaoFuncionario } from '../../../utils/enum';
import CoresTagSituacao from '../../../utils/enum/SituacaoFuncionario/cores';
import { SituacaoContainer, TituloContainer, TituloTag } from './styles';
import { DetalhesFuncionarioTab } from '../../../components/tabs';
import { emExperiencia } from '../functions';
import { Link } from '../../styles';
import {
  BotaoDefault,
  BotaoExcluir,
  BotaoPrimario,
  Descritor,
  Header,
  UploadImagem,
} from '../../../components/layout';
import {
  FUNCIONARIO,
  DEMISSAO,
  SITUACAO,
  CADASTRAR,
} from '../../../hooks/useUrl/acoes';

const { FuncionarioForm, DemissaoForm, SituacaoForm } = Funcionario;

const DetalhesFuncionarios = ({ history, match }) => {
  const { id } = match.params;
  const { buscar, alterar, deletar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const mostrarCadastro = existeQuery(CADASTRAR, FUNCIONARIO);
  const mostrarDemissao = existeQuery(CADASTRAR, DEMISSAO);
  const mostrarSituacao = existeQuery(CADASTRAR, SITUACAO);
  const [funcionario, setFuncionario] = useState({});
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const editarImagem = async ({ file }) => {
    const formData = new FormData();
    formData.append('foto', file);
    const { data } = await alterar(
      `/funcionarios/${id}/foto`,
      formData,
      null,
      null,
      true,
    );
    if (data) {
      setFuncionario(data);
    }
  };

  const deletarImagem = async () => {
    const { data } = await deletar(
      `/funcionarios/${id}/foto`,
      'Foto do funcionário removida com sucesso',
    );
    if (data) {
      setFuncionario(data);
    }
  };

  /* eslint-disable react/prop-types */
  const descritores = [
    {
      titulo: 'Dados Pessoais',
      itens: [
        { nome: 'CPF', propriedade: 'cpf' },
        {
          nome: 'Data de Nascimento',
          custom: ({ dataNascimento }) => formatar('data', dataNascimento),
        },
        { nome: 'Sexo', custom: ({ sexo }) => Sexo[sexo] },
      ],
    },
    {
      titulo: 'Dados do Registro',
      itens: [
        {
          nome: 'Data de Admissão',
          custom: ({ dataAdmissao }) => formatar('data', dataAdmissao),
        },
        {
          nome: 'Salário',
          custom: ({ salario }) => formatar('moeda', salario),
        },
        { nome: 'Cargo', propriedade: 'cargo' },
      ],
    },
    {
      titulo: 'Experiência',
      naoMostrarSe: func => !emExperiencia(func),
      itens: [
        {
          nome: 'Fim da 1ª Experiência',
          custom: ({ dataFimExperiencia1 }) =>
            formatar('data', dataFimExperiencia1),
        },
        {
          nome: 'Fim da 2ª Experiência',
          custom: ({ dataFimExperiencia2 }) =>
            formatar('data', dataFimExperiencia2),
        },
      ],
    },
    {
      titulo: 'Dados Adicionais',
      itens: [
        {
          nome: 'Célula de Trabalho',
          custom: ({ celula, celulaUid }) => (
            <Link to={`/celulas/${celulaUid}`}>{celula?.nome}</Link>
          ),
        },
        {
          nome: 'Turno',
          custom: ({ turno, turnoUid }) => (
            <Link to={`/turnos/${turnoUid}`}>{turno?.nome}</Link>
          ),
        },
      ],
    },
    {
      titulo: 'Contatos',
      naoTemSe: ({ celular, email, telefone }) =>
        !celular && !email && !telefone,
      itens: [
        {
          nome: 'E-mail',
          custom: ({ email }) => <a href={`mailto:${email}`}>{email}</a>,
        },
        {
          nome: 'Telefone',
          propriedade: 'telefone',
        },
        {
          nome: 'Celular',
          propriedade: 'celular',
        },
      ],
    },
    {
      titulo: 'Foto',
      custom: () => (
        <UploadImagem
          editarImagem={editarImagem}
          deletarImagem={deletarImagem}
          imagem={{
            imagemUrl: funcionario?.fotoUrl,
            imagem40Url: funcionario?.foto40Url,
            imagem100Url: funcionario?.foto100Url,
          }}
        />
      ),
    },
  ];
  /* eslint-enable react/prop-types */
  useEffect(() => {
    const carregarDadosFuncionario = async () => {
      const { data } = await buscar(
        `/funcionarios/${id}/?$rel[celula]=nome&$rel[turno]=nome`,
      );
      setFuncionario(data);
    };
    carregarDadosFuncionario();
  }, [id, buscar]);

  const renderTitulo = () => {
    const { situacao, dataRetorno, dataSaida } = funcionario ?? {};
    const nomeSituacao = SituacaoFuncionario[situacao];
    const montarTexto = () => {
      switch (true) {
        case situacao === 'O' || situacao === 'F' || situacao === 'B':
          return `${nomeSituacao} ${
            dataRetorno ? `até ${formatar('data', dataRetorno)}` : ''
          }`;
        case situacao === 'D' || situacao === 'I':
          return `${nomeSituacao} ${
            dataSaida ? `em ${formatar('data', dataSaida)}` : ''
          }`;
        case situacao === 'A':
          return nomeSituacao;
        default:
          return null;
      }
    };
    return (
      <TituloContainer>
        <h4>{`${funcionario?.codigo} ${funcionario?.nomeCompleto}`}</h4>
        <SituacaoContainer>
          {situacao === 'A' && emExperiencia(funcionario) && (
            <Tag color={CoresTagSituacao.E}>Em Experiência</Tag>
          )}
          <Tag color={CoresTagSituacao[situacao]}>{montarTexto()}</Tag>
          <TituloTag
            semborda="true"
            situacao="Alterar"
            onClick={() => adicionarQuery(CADASTRAR, SITUACAO)}
          >
            Alterar Situação <EditOutlined />
          </TituloTag>
        </SituacaoContainer>
      </TituloContainer>
    );
  };

  const deletarFuncionario = async () => {
    const { uid, codigo, nomeCompleto, sexo } = funcionario;
    await deletar(
      `/funcionarios/${uid}`,
      `${
        sexo === 'F' ? 'A funcionária' : 'O funcionário'
      } ${codigo} - ${nomeCompleto} foi ${
        sexo === 'F' ? 'excluída' : 'excluído'
      }`,
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        classe={funcionario?.sexo === 'F' ? 'Funcionária' : 'Funcionário'}
        onBack={() => history.goBack()}
        titulo={renderTitulo}
        extra={[
          <BotaoDefault
            key="1"
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, DEMISSAO)}
            hidden={
              funcionario?.situacao === 'D' || funcionario?.situacao === 'I'
            }
          >
            Demissão
          </BotaoDefault>,
          <BotaoPrimario
            key="2"
            type="primary"
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, FUNCIONARIO)}
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        <Descritor objeto={funcionario} propriedades={descritores} />
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarFuncionario}>
            Excluir {funcionario?.sexo === 'F' ? 'Funcionária' : 'Funcionário'}
          </BotaoExcluir>
        )}
      </Header>

      <DetalhesFuncionarioTab history={history} funcionario={funcionario} />

      <SituacaoForm
        aoFechar={() => removerQuery(CADASTRAR, SITUACAO)}
        funcionario={funcionario}
        mostrar={mostrarSituacao}
        afterSubmit={funcAtualizado => {
          if (funcAtualizado) {
            setFuncionario(funcAtualizado);
            removerQuery(CADASTRAR, SITUACAO);
          }
        }}
      />

      <DemissaoForm
        aoFechar={() => removerQuery(CADASTRAR, DEMISSAO)}
        funcionario={funcionario}
        mostrar={mostrarDemissao}
        afterSubmit={funcDemitido => {
          if (funcDemitido) {
            setFuncionario(funcDemitido);
            removerQuery(CADASTRAR, DEMISSAO);
          }
        }}
      />

      <FuncionarioForm
        history={history}
        mostrar={mostrarCadastro}
        funcionario={funcionario}
        aoFechar={() => removerQuery(CADASTRAR, FUNCIONARIO)}
        afterSubmit={novoFuncionario => {
          if (novoFuncionario) {
            setFuncionario(novoFuncionario);
            removerQuery(CADASTRAR, FUNCIONARIO);
          }
        }}
      />
    </>
  );
};

DetalhesFuncionarios.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesFuncionarios;

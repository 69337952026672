import { Select } from 'antd';
import styled from 'styled-components';

export const PremiosContainer = styled.div`
  margin-top: ${({ index }) => index !== 0 && '10px'};
`;

export const SelectFiltro = styled(Select)`
  margin-right: 10px;
  width: 120px;
  @media only screen and (max-device-width: 640px) {
    width: 100%;
    margin: 0px;
  }
`;

export default {
  titulo: 'Ordens de Produção',
  cabecalho: {
    desktop: [
      {
        titulo: 'Ordem',
        atributo: 'numero',
        align: 'right',
      },
      {
        titulo: 'Produto',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['referencia', 'nome'],
            },
          },
          { atributo: 'referencia' },
          { atributo: 'nome' },
        ],
      },
      {
        titulo: 'N° do Cliente',
        atributo: 'numeroCliente',
        width: '105px',
        align: 'right',
      },
      {
        titulo: 'Quantidade',
        atributo: 'quantidade',
        tipo: 'n-decimal',
        enumerador: 0,
        align: 'right',
      },
      {
        titulo: 'Preço',
        atributo: 'preco',
        tipo: 'moeda',
        align: 'right',
      },
      {
        titulo: 'Total',
        atributo: 'valorTotal',
        tipo: 'moeda',
        align: 'right',
      },
    ],
    mobile: [
      {
        titulo: 'Produto',
        variavel: true,
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['referencia', 'nome'],
            },
          },
          { atributo: 'referencia' },
          { atributo: 'nome' },
        ],
      },
    ],
  },
};

import React, { useEffect } from 'react';
import { Drawer, Form, Spin } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import { func, objectOf, bool, any, number } from 'prop-types';
import { onFinish, lerObjectProp } from '../../commonFunctions';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';
import { Input } from '../../commonStyles';

const PacoteForm = ({ afterSubmit, pacote, ordemUid, aoFechar, mostrar }) => {
  const { criar, alterar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp({ ...pacote }, form.setFieldsValue, form.resetFields);
    form.setFieldsValue({
      quantidadePacotes: 1,
    });
  }, [form, pacote]);

  const cadastrarPacote = async ({ quantidadePacotes, ...rest }) => {
    const dataList = [];
    for (let i = 0; i < quantidadePacotes; i++) {
      const { data } = await criar(
        `/ordens-producao/${ordemUid}/pacotes`,
        rest,
        i >= quantidadePacotes ? 'Pacote(s) criado(s) com sucesso' : null,
      );
      if (data) {
        dataList.push(data);
      }
    }
    return dataList;
  };

  const editarPacote = async (values, id) => {
    delete values.quantidadePacotes;
    const { quantidade, tamanho, cor } = values;
    const { data } = await alterar(
      `/ordens-producao/${ordemUid}/pacotes/${id}`,
      values,
      pacote,
      `Pacote ${quantidade}x ${tamanho ?? ''} - ${
        cor ?? ''
      } alterado com sucesso!`,
    );

    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 'auto' : '100%'}
      title={pacote ? 'Alterar Pacote' : 'Novo Pacote'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              {
                ...values,
                objectUid: pacote?.uid,
                quantidadePacotes: Number(values.quantidadePacotes),
                quantidade: Number(values.quantidade),
              },
              cadastrarPacote,
              editarPacote,
              afterSubmit,
              () => {
                form.resetFields();
                form.setFieldsValue({
                  quantidadePacotes: 1,
                });
              },
            );
          }}
        >
          <Campo
            hidden={!!pacote}
            fluido
            name="quantidadePacotes"
            label="Quantidade de pacotes"
            obrigatorio
            mostrarFoco={mostrar && !pacote}
          >
            <Input
              type="number"
              placeholder="Digite a quantidade"
              maxLength={255}
            />
          </Campo>

          <Campo
            fluido
            name="cor"
            label="Cor"
            mostrarFoco={!!(mostrar && pacote)}
          >
            <Input
              type="text"
              placeholder="Digite a cor (opcional)"
              maxLength={255}
            />
          </Campo>

          <Campo fluido name="tamanho" label="Tamanho">
            <Input
              type="text"
              placeholder="Digite o tamanho (opcional)"
              maxLength={255}
            />
          </Campo>

          <Campo
            fluido
            name="quantidade"
            label="Quantidade de Peças"
            obrigatorio
          >
            <Input
              type="number"
              placeholder="Digite a quantidade"
              maxLength={255}
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

PacoteForm.propTypes = {
  pacote: objectOf(any),
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
  ordemUid: number,
};

PacoteForm.defaultProps = {
  pacote: null,
  aoFechar: null,
  mostrar: false,
  afterSubmit: null,
  ordemUid: null,
};

export default PacoteForm;

import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { func, objectOf, any, bool } from 'prop-types';
import { Drawer, Form, Select, Spin } from 'antd';
import { CloseButtonContainer, Input } from '../commonStyles';
import { lerObjectProp, onFinish } from '../commonFunctions';
import { BotaoPrimario, Campo, SelectDinamico } from '../../layout';
import { useApi } from '../../../hooks';
import TurnoForm from '../TurnoForm';
import { telaGrande } from '../../../utils';

const { Option } = Select;

const CelulaForm = ({ mostrar, afterSubmit, celula, aoVoltar, aoFechar }) => {
  const { buscar, criar, alterar, existeNoLog } = useApi();
  const [form] = Form.useForm();
  const [mostrarTurnoForm, setMostrarTurno] = useState(false);
  const [turnos, setTurnos] = useState([]);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarTurnos = async () => {
      const url = '/turnos?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setTurnos(data);
      }
    };
    if (mostrar) {
      carregarTurnos();
    }
  }, [buscar, existeNoLog, mostrar]);

  useEffect(() => {
    lerObjectProp(celula, form.setFieldsValue, form.resetFields);
  }, [celula, form.setFieldsValue, form.resetFields]);

  const cadastrarCelula = async ({ nome, ...values }) => {
    const { data } = await criar(
      '/celulas',
      { nome, ...values },
      `A célula ${nome} foi criada`,
    );
    return data;
  };

  const editarCelula = async ({ nome, ...values }, id) => {
    const { data } = await alterar(
      `/celulas/${id}`,
      { nome, ...values },
      celula,
      `A célula ${nome} foi alterada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      closable={!aoVoltar}
      title={celula ? 'Alterar Célula' : 'Nova Célula'}
      width={telaGrande() ? 300 : '100%'}
      onClose={aoFechar}
    >
      {mostrarTurnoForm ? (
        <TurnoForm
          mostrar={mostrarTurnoForm}
          aoVoltar={() => setMostrarTurno(false)}
          afterSubmit={turno => {
            setTurnos([turno, ...turnos]);
            form.setFieldsValue({
              turnoUidPadrao: turno?.uid,
            });
          }}
        />
      ) : (
        <Spin spinning={loading}>
          {aoVoltar && (
            <CloseButtonContainer>
              <button onClick={aoVoltar} type="button">
                Voltar
              </button>
            </CloseButtonContainer>
          )}
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onFinish={values =>
              onFinish(
                { ...values, objectUid: celula?.uid },
                cadastrarCelula,
                editarCelula,
                novaCelula => {
                  if (afterSubmit && novaCelula) afterSubmit(novaCelula);
                  if (aoVoltar && novaCelula) aoVoltar();
                },
                form.resetFields,
              )
            }
          >
            <Campo
              fluido
              name="nome"
              label="Nome"
              obrigatorio
              mostrarFoco={mostrar}
            >
              <Input type="text" placeholder="Digite um nome" maxLength={255} />
            </Campo>

            <Campo
              fluido
              name="turnoUidPadrao"
              label="Turno Padrão"
              obrigatorio
            >
              <SelectDinamico
                form={form}
                nomeCampo="turnoUidPadrao"
                placeholder="Selecione um turno"
                aoAdicionar={() => setMostrarTurno(true)}
              >
                {turnos?.map(turno => (
                  <Option key={turno.uid} value={turno.uid}>
                    {turno.nome}
                  </Option>
                ))}
              </SelectDinamico>
            </Campo>

            <Campo fluido>
              <BotaoPrimario htmlType="submit" type="primary">
                Salvar
              </BotaoPrimario>
            </Campo>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

CelulaForm.propTypes = {
  aoFechar: func,
  afterSubmit: func,
  aoVoltar: func,
  celula: objectOf(any),
  mostrar: bool,
};

CelulaForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  aoVoltar: null,
  afterSubmit: null,
  celula: null,
};

export default CelulaForm;

import { SituacaoFuncionario } from '../../../../utils/enum';
import CoresSituacaoFuncionario from '../../../../utils/enum/SituacaoFuncionario/cores';

export default {
  titulo: 'Funcionários',
  cabecalho: {
    desktop: [
      {
        titulo: 'Código',
        atributo: 'codigo',
      },
      {
        variavel: true,
        titulo: 'Nome',
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
            tag: {
              atributo: 'situacao',
              tipo: 'enum',
              enumerador: SituacaoFuncionario,
              color: ({ situacao }) => CoresSituacaoFuncionario[situacao],
            },
          },
          {
            atributo: 'cpf',
          },
        ],
      },
      {
        titulo: 'Celular',
        atributo: 'celular',
      },
      {
        titulo: 'Data de Nascimento',
        atributo: 'dataNascimento',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Data de Admissão',
        atributo: 'dataAdmissao',
        align: 'center',
        tipo: 'data',
      },
      {
        titulo: 'Célula',
        aninhamento: 'celula',
        atributo: 'nome',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Nome',
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
            tag: {
              atributo: 'situacao',
              tipo: 'enum',
              enumerador: SituacaoFuncionario,
              color: ({ situacao }) => CoresSituacaoFuncionario[situacao],
            },
          },
          {
            atributo: 'cpf',
          },
        ],
      },
    ],
  },
};

import styled from 'styled-components';
import { Link as DomLink } from 'react-router-dom';
import { Select as AntSelect } from 'antd';
import colors from '../styles/colors';

export const Select = styled(AntSelect)`
  width: 150px;
`;

export const DetalhesContainer = styled.div`
  margin: 1.5em;
`;

export const Container = styled.div`
  padding: 0px 15px 15px;
`;

export const Link = styled(DomLink)`
  text-decoration: underline ${colors.titulo_claro};
  color: ${colors.titulo_claro};
`;

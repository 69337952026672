import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Select, Spin } from 'antd';
import { func, string, objectOf, oneOfType, number, bool } from 'prop-types';
import { removerValorFalso, telaGrande } from '../../../utils';
import { CloseButtonContainer, Grupo, Input, TextArea } from '../commonStyles';
import { onFinish, lerObjectProp } from '../commonFunctions';
import { BotaoPrimario, Campo, Sessao } from '../../layout';
import { useApi } from '../../../hooks';
import { NaturezaConta } from '../../../utils/enum';

const { Option } = Select;

const naturezasPossiveis = ['R', 'V', 'C', 'F', 'I', 'D', 'O'].map(letra => ({
  uid: letra,
  nome: NaturezaConta[letra],
}));

const ContaForm = ({ afterSubmit, conta, aoVoltar, mostrar, aoFechar }) => {
  const { criar, alterar } = useApi();
  const [recorrenciaObrigatorio, setRecorrenciaObrigatorio] = useState({
    valorPrevisto: conta?.valorPrevisto?.length > 0,
    diaPrevisto: conta?.diaPrevisto?.length > 0,
  });
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    lerObjectProp(
      {
        ...conta,
        valorPrevisto:
          conta?.valorPrevisto && String(Math.abs(conta?.valorPrevisto)),
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [conta, form.resetFields, form.setFieldsValue]);

  const validarData = data => {
    const dados = removerValorFalso(data);
    const { diaPrevisto, valorPrevisto: valor } = recorrenciaObrigatorio;
    if (diaPrevisto && valor) {
      const { natureza, valorPrevisto } = dados;
      if (natureza === 'R' && valorPrevisto) {
        dados.valorPrevisto = +Math.abs(valorPrevisto);
      } else if (valorPrevisto) {
        dados.valorPrevisto = -Math.abs(valorPrevisto);
      }
    }
    return dados;
  };

  const cadastrarConta = async ({ codigo, nome, ...values }) => {
    const dados = validarData({ codigo, nome, ...values });
    const { data } = await criar(
      '/contas',
      dados,
      `A conta ${codigo} - ${nome} foi criada`,
    );
    return data;
  };

  const editarConta = async ({ nome, codigo, ...values }, uid) => {
    const dados = validarData({ nome, codigo, ...values });
    const { data } = await alterar(
      `/contas/${uid}`,
      dados,
      conta,
      `A conta ${codigo} - ${nome} foi alterada`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      closable={!aoVoltar}
      width={telaGrande() ? 420 : '100%'}
      title={conta ? 'Alterar Conta' : 'Nova Conta'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        {aoVoltar && (
          <CloseButtonContainer>
            <button onClick={aoVoltar} type="button">
              Voltar
            </button>
          </CloseButtonContainer>
        )}
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={({ valorPrevisto, ...values }) => {
            onFinish(
              {
                ...values,
                objectUid: conta?.uid,
                valorPrevisto:
                  values.natureza === 'R' ? valorPrevisto : `-${valorPrevisto}`,
              },
              cadastrarConta,
              editarConta,
              novaConta => {
                if (afterSubmit && novaConta) afterSubmit(novaConta);
                if (aoVoltar && novaConta) aoVoltar();
              },
              form.resetFields,
            );
          }}
        >
          <Campo
            fluido
            name="natureza"
            label="Natureza"
            obrigatorio
            mostrarFoco={
              mostrar &&
              !(
                recorrenciaObrigatorio.diaPrevisto ||
                recorrenciaObrigatorio.valorPrevisto
              )
            }
          >
            <Select placeholder="Selecione uma natureza" bordered showSearch>
              {naturezasPossiveis.map(natureza => (
                <Option key={natureza.uid} value={natureza.uid}>
                  {natureza.nome}
                </Option>
              ))}
            </Select>
          </Campo>
          <Campo fluido name="nome" label="Nome" obrigatorio>
            <Input type="text" placeholder="Digite um nome" maxLength={30} />
          </Campo>
          <Campo fluido name="codigo" label="Código" obrigatorio>
            <Input type="text" placeholder="Digite um código" maxLength={10} />
          </Campo>
          <Sessao titulo="Recorrência Mensal">
            <Grupo>
              <Campo
                name="valorPrevisto"
                label="Valor Previsto"
                obrigatorio={recorrenciaObrigatorio.diaPrevisto}
              >
                <Input
                  type="number"
                  addonBefore="R$"
                  placeholder="Digite um valor"
                  maxLength={15}
                  min={0}
                  onChange={({ target }) =>
                    setRecorrenciaObrigatorio({
                      ...recorrenciaObrigatorio,
                      valorPrevisto: target.value.length > 0,
                    })
                  }
                />
              </Campo>
              <Campo
                name="diaPrevisto"
                label="Dia Previsto (1-31)"
                obrigatorio={recorrenciaObrigatorio.valorPrevisto}
              >
                <Input
                  type="number"
                  placeholder="Digite um dia"
                  min="1"
                  max="31"
                  onChange={({ target }) => {
                    setRecorrenciaObrigatorio({
                      ...recorrenciaObrigatorio,
                      diaPrevisto: target.value.length > 0,
                    });
                  }}
                />
              </Campo>
            </Grupo>
            <Campo
              fluido
              name="historicoPadrao"
              label="Histórico Padrão"
              obrigatorio={
                recorrenciaObrigatorio.diaPrevisto ||
                recorrenciaObrigatorio.valorPrevisto
              }
            >
              <TextArea rows={4} placeholder="Preencha o histórico" />
            </Campo>
          </Sessao>
          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

ContaForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  aoVoltar: func,
  conta: objectOf(oneOfType([string, number, bool])),
  mostrar: bool,
};

ContaForm.defaultProps = {
  mostrar: false,
  conta: null,
  aoFechar: null,
  afterSubmit: null,
  aoVoltar: null,
};

export default ContaForm;

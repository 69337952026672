import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, any } from 'prop-types';
import { Layout } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  MenuUnfoldOutlined,
  IdcardOutlined,
  CloseOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { apiServidorAuth } from '../../../services/api';
import { ModalDadosUsuario } from '../../modais';
import { Icone } from '../../../styles/global';
import { telaGrande } from '../../../utils';
import itensDesktop from './itensDesktop';
import itensMobile from './itensMobile';
import {
  setToken,
  setTokenValido,
  setColapsarMenu,
} from '../../../store/modules/sistema/actions';
import {
  Menu,
  MenuInterno,
  Content,
  Item,
  Link,
  Avatar,
  HeaderMenu,
  Header,
  LogoContainer,
  Sider,
  MenuContainer,
  FooterItem,
  SubMenu,
  CloseButton,
  Hr,
} from '../styles';

const itensProducao = telaGrande()
  ? itensDesktop.find(({ label }) => label === 'Produção')?.itensMenu
  : itensMobile.find(({ submenu }) => submenu.title === 'Produção')?.submenu
      .itens;
const MenuAplicacao = ({ children }) => {
  const dispatch = useDispatch();
  const telaDesktop = telaGrande();
  const [usuario, setUsuario] = useState(null);
  const [mostrarSubmenu, setMostrarSubmenu] = useState(false);
  const [modalVisivel, setModalVisivel] = useState(false);
  const { colapsarMenu } = useSelector(({ reducer }) => reducer, shallowEqual);
  const [menuItems, setMenuItems] = useState(
    window.location.pathname === '/' ? itensProducao : [],
  );

  const meusDados = useMemo(
    () => ({
      linkTo: '#',
      onClick: () => setModalVisivel(true),
      label: 'Meus Dados',
      icon: <IdcardOutlined />,
    }),
    [],
  );

  useEffect(() => {
    if (!telaDesktop) {
      setMenuItems([...itensMobile, meusDados]);
    }
  }, [meusDados, telaDesktop]);

  useEffect(() => {
    const buscarFoto = async () => {
      const { data } = await apiServidorAuth.get('/sessoes?$campos=usuario');
      const { usuario: user } = data ?? {};
      setUsuario(user);
      setMostrarSubmenu(!(user?.nome || user?.avatarUrl));
    };
    buscarFoto();
  }, []);

  const gerarIcone = () => {
    if (usuario) {
      const { avatarUrl, nome } = usuario;
      return (
        <Icone
          imagem={avatarUrl}
          margin="12px 0px 0px 0px"
          backgroundColor="burlywood"
        >
          {!avatarUrl && nome ? nome.substr(0, 2).toUpperCase() : ''}
        </Icone>
      );
    }
    return null;
  };

  const renderAvatar = () => (
    <Avatar
      onBlur={({ currentTarget, relatedTarget }) =>
        !currentTarget.contains(relatedTarget) &&
        telaDesktop &&
        setMostrarSubmenu(false)
      }
    >
      {(usuario?.nome || usuario?.avatarUrl) && (
        <div>
          <button
            onClick={() => setMostrarSubmenu(valor => !valor)}
            type="button"
          >
            {gerarIcone()}
          </button>
        </div>
      )}
      <MenuInterno
        esconder={`${mostrarSubmenu}`}
        mode="vertical"
        theme="dark"
        selectable={false}
      >
        {telaDesktop && (
          <Item colapsar={`${colapsarMenu}`} icon={meusDados.icon}>
            <Link to={meusDados.linkTo} onClick={meusDados.onClick}>
              {meusDados.label}
            </Link>
          </Item>
        )}
        <Item colapsar={`${colapsarMenu}`} icon={<LogoutOutlined />}>
          <Link
            to="#"
            onClick={() => {
              dispatch(setToken(null));
              dispatch(setTokenValido(false));
              localStorage.setItem('token', null);
              window.location.href = process.env.REACT_APP_AUTH_URL;
            }}
          >
            Logout
          </Link>
        </Item>
      </MenuInterno>
    </Avatar>
  );

  const renderMenu = (itens, indexP = '') =>
    itens.map(({ linkTo, label, icon, onClick, submenu, hr }, index) => (
      <>
        {submenu ? (
          <SubMenu
            key={`${indexP}${index}`}
            icon={icon}
            mode="inline"
            theme="dark"
            selectable={false}
            colapsar={`${colapsarMenu}`}
            title={<strong>{submenu.title}</strong>}
            onTitleClick={() => {
              if (colapsarMenu) {
                localStorage.setItem('colapsar-menu', !colapsarMenu);
                dispatch(setColapsarMenu(!colapsarMenu));
              }
            }}
          >
            {renderMenu(submenu.itens, indexP.concat('submenu'))}
          </SubMenu>
        ) : (
          <Item
            colapsar={`${colapsarMenu}`}
            key={index}
            icon={icon}
            onClick={() => {
              if (!telaDesktop) {
                localStorage.setItem('colapsar-menu', !colapsarMenu);
                dispatch(setColapsarMenu(!colapsarMenu));
              }
              return onClick?.();
            }}
          >
            <Link to={linkTo}>{label}</Link>
          </Item>
        )}
        {hr && <Hr colapsar={`${colapsarMenu}`} key={`hr${index}`} />}
      </>
    ));

  return (
    <>
      <Layout menucolapsado={`${colapsarMenu}`}>
        <Sider collapsed={colapsarMenu} colapsar={`${colapsarMenu}`}>
          <LogoContainer colapsar={`${colapsarMenu}`} />
          {!telaDesktop && (
            <CloseButton onClick={() => dispatch(setColapsarMenu(true))}>
              <CloseOutlined />
            </CloseButton>
          )}
          <MenuContainer fluido colapsar={`${colapsarMenu}`}>
            <Menu
              mode="inline"
              theme="dark"
              selectable={false}
              defaultOpenKeys={!colapsarMenu && telaDesktop ? ['0', '1'] : []}
              defaultActiveFirst={
                window.location.pathname === '/' && telaDesktop && 1
              }
            >
              {renderMenu(menuItems)}
              {telaDesktop && (
                <FooterItem
                  colapsar={`${colapsarMenu}`}
                  icon={<MenuUnfoldOutlined />}
                  onClick={() => {
                    localStorage.setItem('colapsar-menu', !colapsarMenu);
                    dispatch(setColapsarMenu(!colapsarMenu));
                  }}
                >
                  <Link to="#">Menu</Link>
                </FooterItem>
              )}
              {!telaDesktop && !colapsarMenu && renderAvatar()}
            </Menu>
          </MenuContainer>
        </Sider>
        <Layout>
          {telaDesktop && (
            <Header>
              <HeaderMenu
                mode="horizontal"
                defaultActiveFirst={window.location.pathname === '/' && 1}
              >
                {itensDesktop.map(
                  ({ itensMenu, label, icon, onClick, linkTo }, index) => (
                    <Item
                      key={index}
                      icon={icon}
                      onClick={onClick}
                      compadding="true"
                    >
                      <Link
                        to={linkTo ?? '#'}
                        onClick={() => itensMenu && setMenuItems(itensMenu)}
                      >
                        {label}
                      </Link>
                    </Item>
                  ),
                )}
                {renderAvatar()}
              </HeaderMenu>
            </Header>
          )}
          <Layout>
            <Content>{children}</Content>
          </Layout>
        </Layout>
      </Layout>

      <ModalDadosUsuario
        aberto={modalVisivel}
        fecharModal={() => setModalVisivel(false)}
      />
    </>
  );
};

MenuAplicacao.propTypes = {
  children: arrayOf(any),
};

MenuAplicacao.defaultProps = {
  children: [],
};

export default MenuAplicacao;

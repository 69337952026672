import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { apiServidor } from '../../services/api';
import { getDiferencaObjeto } from '../../utils';
import {
  setLoading,
  setTokenValido,
} from '../../store/modules/sistema/actions';

const useApi = () => {
  const dispatch = useDispatch();
  const [buscarLog, setBuscarLog] = useState([]);

  const processar = useCallback(
    async (funcao, mensagem, callback, semLoading) => {
      try {
        if (!semLoading) dispatch(setLoading(true));
        const response = await funcao();
        if (mensagem) {
          if (typeof mensagem === 'function') {
            message.success(mensagem(response.data), 4);
          } else {
            message.success(mensagem, 4);
          }
        }
        if (callback) callback();
        return response;
      } catch (error) {
        const { erro, mensagem: errorMessage } = error.response?.data || {};
        if (errorMessage === 'Não autorizado') {
          dispatch(setTokenValido(false));
        } else if (erro) {
          message.error(erro, 7);
        } else if (
          error.message === 'Network Error' &&
          localStorage.getItem('erroConnexao') !== 'true'
        ) {
          localStorage.setItem('erroConnexao', 'true');
          message.error(
            'O sistema está indisponível no momento. Tente novamente daqui a pouco!',
            7,
            () => localStorage.setItem('erroConnexao', 'false'),
          );
        }
        return { data: null };
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch],
  );

  return {
    deletar: useCallback(
      (url, mensagem, callback) => {
        return processar(() => apiServidor.delete(url), mensagem, callback);
      },
      [processar],
    ),

    buscar: useCallback(
      (url, mensagem, semLoading) => {
        setBuscarLog(state => [...state, url]);
        return processar(
          () => apiServidor.get(url),
          mensagem,
          null,
          semLoading,
        );
      },
      [processar],
    ),

    existeNoLog: useCallback(
      url => {
        return buscarLog.findIndex(item => item === url) >= 0;
      },
      [buscarLog],
    ),

    criar: useCallback(
      (url, corpo, mensagem, config) => {
        return processar(() => apiServidor.post(url, corpo, config), mensagem);
      },
      [processar],
    ),

    alterar: useCallback(
      (url, corpo, corpoAntigo, mensagem, usarPut) => {
        if (corpo instanceof FormData) {
          return processar(
            () =>
              usarPut
                ? apiServidor.put(url, corpo)
                : apiServidor.patch(url, corpo),
            mensagem,
          );
        }
        const diff = getDiferencaObjeto(corpo, corpoAntigo);
        if (Object.keys(diff).length === 0) {
          return message.warning('Nenhuma alteração detectada', 4);
        }
        return processar(
          () =>
            usarPut ? apiServidor.put(url, diff) : apiServidor.patch(url, diff),
          mensagem,
        );
      },
      [processar],
    ),
  };
};

export default useApi;

import { TabelaOperacoes } from '.';
import { telaGrande } from '../../../../utils';

export default {
  titulo: 'Operações da Ordem de Produção',
  cabecalho: {
    desktop: [
      ...TabelaOperacoes.cabecalho.desktop,
      {
        titulo: 'Tempo Ajustado',
        custom: ({ tempo }, format) =>
          telaGrande() ? `${format(tempo)} min` : `Tempo: ${format(tempo)} min`,
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 4,
      },
      {
        titulo: 'Qtde Ajustada',
        atributo: 'quantidade',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
      },
      {
        titulo: 'Redistribuir',
        atributo: 'redistribuirTempo',
        componente: 'Switch',
        align: 'center',
      },
    ],
    mobile: [
      {
        titulo: 'Operações',
        variavel: true,
        agrupar: [
          {
            custom: ({ sequencia, nome }) => `${sequencia} - ${nome}`,
            tag: {
              atributo: 'nome',
              aninhamento: 'maquina',
            },
          },
          {
            tipo: 'n-decimal',
            enumerador: 4,
            custom: ({ tempoOriginal }, format) =>
              `Tempo: ${format(tempoOriginal)} min`,
          },
          {
            tipo: 'n-decimal',
            enumerador: 4,
            custom: ({ tempo }, format) => `Ajustado: ${format(tempo)} min`,
          },
          {
            titulo: 'Redistribuir',
            atributo: 'redistribuirTempo',
            componente: 'Switch',
            align: 'center',
          },
        ],
      },
    ],
  },
};

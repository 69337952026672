import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';
import {
  DatePicker as AntDatePicker,
  Button as AntButton,
  Form,
  Radio as AntRadio,
  Input as Inputs,
  Checkbox as AntCheckbox,
} from 'antd';
import colors from '../../styles/colors';

export const Item = styled(Form.Item)`
  width: ${({ fluido }) => (fluido === 'true' ? '100%' : '174px')};
  display: ${({ block }) => block === 'true' && 'block'};
  @media only screen and (max-device-width: 640px) {
    width: 100% !important;
  }
`;

export const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;

export const RadioGroup = styled(AntRadio.Group)`
  display: ${({ inline }) => inline === 'true' && 'flex'};
  flex-wrap: wrap;

  .ant-radio-checked .ant-radio-inner {
    border-color: ${colors.primaria};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgb(236 212 5 / 8%);
  }

  .ant-radio-inner::after {
    background-color: ${colors.primaria_fundo_claro};
  }
`;

export const Grupo = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: flex;
    ${Item}:not(:last-child) {
      margin-right: 8px !important;
    }
  }
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  button {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: none;
    color: #1890ff;
  }
`;

export const Button = styled(AntButton)`
  width: 100%;
  margin-top: 5px;
`;

export const Input = styled(Inputs)`
  .ant-input {
    &:hover {
      border-color: ${colors.primaria};
    }

    &:focus {
      border-color: ${colors.primaria};
      box-shadow: 0 0 0 2px ${colors.primaria};
    }
  }
  &:hover {
    border-color: ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }
`;

export const TextArea = styled(Inputs.TextArea)`
  &:hover {
    border-color: ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }
`;

export const MaskedInputs = styled(MaskedInput)`
  &:hover {
    border-color: ${colors.primaria};
  }

  &:focus {
    border-color: ${colors.primaria};
    box-shadow: 0 0 0 2px ${colors.primaria};
  }
`;

export const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.primaria};
    border-color: ${colors.primaria};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.primaria};
  }
`;

import styled from 'styled-components';
import { Button, PageHeader as AntHeader } from 'antd';

export const Classe = styled.span`
  font-weight: normal;
  margin-right: 7px;
`;

export const TituloContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    margin: 5px 0px;
  }
`;

export const ColapsarButton = styled(Button)`
  position: absolute;
  top: ${({ descricao }) => (descricao ? '16px' : '23px')};
  right: ${({ descricao }) => (descricao ? '24px' : '0px')};
  font-size: 20px;
  padding: 0px;
  width: fit-content !important;
  background: transparent;
  border: none;
  margin: 0px !important;
`;

export const PageHeader = styled(AntHeader)`
  padding: ${({ semPadding }) => semPadding === 'true' && '16px 0px'};
  background-color: ${({ ghost }) => !ghost && '#fcfbfa'};
  .ant-tag {
    margin-left: 10px;
  }
  .ant-page-header-heading-title {
    display: flex;
  }
  @media only screen and (max-device-width: 640px) {
    padding: ${({ semPadding }) => semPadding === 'true' && '16px 0px 0px'};
    .ant-page-header-heading-title {
      margin: 0px;
      font-size: 16px;
      white-space: break-spaces;
      text-align: center;
      line-height: unset;
      display: grid;
      span {
        margin: 5px 0px;
      }
    }
    .ant-page-header-heading-left {
      width: 100%;
      display: grid;
    }
    .ant-page-header-heading-sub-title {
      text-align: center;
    }
    .ant-page-header-heading-extra {
      width: 100%;
      margin: 0px;
      button {
        width: 100%;
        display: block;
        margin: 0px 0px 10px 0px;
      }
    }
  }
`;

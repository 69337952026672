import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

export const Alert = styled(AntAlert)`
  ${({ detalhes }) =>
    detalhes
      ? `
      padding-right: 10px;
    `
      : `
      @media only screen and (min-device-width: 640px) {
        margin-top: 10px;
      }
    `}
  @media only screen and (max-device-width: 640px) {
    margin-bottom: 10px;
  }
`;

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { ClienteForm } from '../../components/forms';
import { useApi, useUrl } from '../../hooks';
import { ModalBusca } from '../../components/modais';
import { Container, Link } from '../styles';
import { CLIENTES } from '../../components/layout/Tabela/nomesCabecalhos';
import { CLIENTE_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  Tabela,
  Filtros,
  Header,
  BotaoTutorial,
  AlertaTutorial,
  BotaoDefault,
} from '../../components/layout';
import {
  CADASTRAR,
  FILTRAR,
  ATIVAR_FILTRO,
  BUSCAR,
  CLIENTE,
} from '../../hooks/useUrl/acoes';

const { Tags, TituloComFiltro } = Filtros;

const Clientes = ({ history }) => {
  const { buscar, deletar } = useApi();
  const [clientes, setClientes] = useState([]);
  const [filtro, setFiltro] = useState('');
  const { adicionarQuery, removerQuery, existeQuery, pegarValorQuery } = useUrl(
    history,
  );
  const desktop = telaGrande();
  const busca = pegarValorQuery(BUSCAR);
  const mostrarCadastro = existeQuery(CADASTRAR, CLIENTE);
  const { loading, mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const [pagina, setPagina] = useState(1);

  useEffect(() => {
    const carregarClientes = async () => {
      if (pagina === 1) {
        setClientes([]);
      }

      if (filtro === '') {
        const { data } = await buscar(
          `/clientes?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=apelido,uid&$limite=10&$pagina=${pagina}`,
        );
        setClientes(cli => [...cli, ...data]);
      }

      if (filtro !== '') {
        const { data } = await buscar(
          `/clientes?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=apelido,uid&`,
        );
        setClientes(data);
        setPagina(1);
      }
    };
    carregarClientes();
  }, [buscar, filtro, busca, pagina]);

  useEffect(() => {
    if (filtro === '') {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setPagina(page => page + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#final'));
      return () => intersectionObserver.disconnect();
    }

    return undefined;
  }, [filtro]);

  const deletarCliente = async ({ uid, nomeFantasia }) => {
    await deletar(
      `/clientes/${uid}`,
      `O cliente ${nomeFantasia} foi excluído`,
      () =>
        setClientes(
          clientes.filter(({ uid: clienteUid }) => clienteUid !== uid),
        ),
    );
  };

  const renderAlert = () =>
    clientes.length !== 0 &&
    mostrarTutorial && (
      <AlertaTutorial>
        <span>
          Parabéns! Você cadastrou{' '}
          {clientes.length > 1 ? 'seus clientes' : 'um novo cliente'}. Você pode
          continuar cadastrando, ou{' '}
          <Link to="/">clicar aqui para voltar ao seu dashboard</Link> de boas
          vindas e ver as próximas etapas.
        </span>
      </AlertaTutorial>
    );

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      {desktop && renderAlert()}
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro titulo="Clientes" history={history} busca={busca} />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoTutorial
            key="2"
            disabled={loading}
            esconder={mostrarCadastro || clientes.length !== 0}
            titulo="Novo Cliente"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, CLIENTE)}
            content={
              <h5>
                Vamos cadastrar um novo cliente. Para isso, clique no botão Novo
                Cliente.
              </h5>
            }
          >
            Novo Cliente
          </BotaoTutorial>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      {!desktop && renderAlert()}
      <Tabela
        cabecalho={CLIENTES}
        dados={clientes}
        loading={loading}
        celulaSelecionada={({ uid }) => history.push(`/clientes/${uid}`)}
        acoes={
          desktop
            ? [
                {
                  titulo: EXCLUIR,
                  onClick: deletarCliente,
                  confirmacao: true,
                },
              ]
            : null
        }
      />

      <ClienteForm
        mostrar={mostrarCadastro}
        aoFechar={() => removerQuery(CADASTRAR, CLIENTE)}
        afterSubmit={cliente => {
          if (cliente) {
            setClientes([...clientes, cliente]);
            removerQuery(CADASTRAR, CLIENTE);
          }
        }}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={CLIENTE_LAYOUT}
      />
      {filtro === '' ? <div id="final" /> : null}
    </Container>
  );
};

Clientes.propTypes = {
  history: objectOf(any).isRequired,
};

export default Clientes;

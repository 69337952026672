import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spin, Form, Radio } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  BarcodeOutlined,
  ContactsOutlined,
  ContainerOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { setConfiguracoes } from '../../../store/modules/sistema/actions';
import { BotaoPrimario, Campo, Header } from '../../../components/layout';
import { verificarBotoes } from '../functions';
import { FormContainer } from '../styles';
import { Container } from '../../styles';
import { useApi } from '../../../hooks';
import { RadioText } from './styles';
import {
  lerObjectProp,
  onFinish,
} from '../../../components/forms/commonFunctions';
import { RadioGroup } from '../../../components/forms/commonStyles';

// const { Group } = Radio;
const CODIGO = 'B';
const MANUAL = 'M';
const quaisApontamentos = {
  F: 'Apenas por Funcionário',
  C: 'Apenas por Célula',
  A: 'Usar Apontamentos por Funcionário e por Célula',
};

const ApontamentoConfig = () => {
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const dispatch = useDispatch();
  const [sofreuAlteracao, setSofreuAlteracao] = useState({
    formaApontamento: false,
  });
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const resetarCampos = useCallback(() => {
    lerObjectProp(
      {
        formaApontamento: configuracoes?.formaApontamento ?? MANUAL,
        quaisApontamentos: configuracoes?.quaisApontamentos ?? 'A',
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [configuracoes, form.resetFields, form.setFieldsValue]);

  useEffect(() => resetarCampos(), [resetarCampos]);

  const afterSubmit = config => {
    if (config) {
      setSofreuAlteracao(false);
      dispatch(setConfiguracoes(config));
    }
  };

  const editarConfiguracao = async values => {
    const { data } = await alterar(
      '/configuracoes',
      values,
      {},
      'Dados atualizados com sucesso!',
    );

    return data;
  };

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title="Configurações dos Apontamentos"
        extra={[
          <Button
            disabled={loading}
            key="1"
            htmlType="button"
            hidden={
              !sofreuAlteracao.formaApontamento &&
              !sofreuAlteracao.quaisApontamentos
            }
            onClick={() => {
              resetarCampos();
              setSofreuAlteracao(false);
            }}
          >
            Descartar a alteração
          </Button>,
          <BotaoPrimario
            disabled={loading}
            hidden={
              !sofreuAlteracao.formaApontamento &&
              !sofreuAlteracao.quaisApontamentos
            }
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => form.submit()}
          >
            Salvar
          </BotaoPrimario>,
        ]}
      />
      <FormContainer>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onChange={({ target }) =>
              verificarBotoes(target, configuracoes, setSofreuAlteracao)
            }
            onFinish={values => {
              onFinish(
                { ...values, objectUid: 1 },
                null,
                editarConfiguracao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="formaApontamento"
              label="Forma de Apontamento"
              mostrarFoco
            >
              <RadioGroup
                value={configuracoes?.formaApontamento ?? MANUAL}
                onChange={({ target }) =>
                  verificarBotoes(
                    { value: target.value, name: 'formaApontamento' },
                    configuracoes,
                    setSofreuAlteracao,
                  )
                }
              >
                <Radio value={MANUAL}>
                  <ContainerOutlined /> Manual
                </Radio>
                <Radio value={CODIGO}>
                  <BarcodeOutlined /> Código de Barras
                </Radio>
              </RadioGroup>
            </Campo>
            <Campo name="quaisApontamentos" label="Quais Apontamentos">
              <RadioGroup
                value={configuracoes?.quaisApontamentos ?? 'A'}
                onChange={({ target }) =>
                  verificarBotoes(
                    { value: target.value, name: 'quaisApontamentos' },
                    configuracoes,
                    setSofreuAlteracao,
                  )
                }
              >
                <Radio value="F">
                  <ContactsOutlined />{' '}
                  <RadioText>{quaisApontamentos.F}</RadioText>
                </Radio>
                <Radio value="C">
                  <TableOutlined /> <RadioText>{quaisApontamentos.C}</RadioText>
                </Radio>
                <Radio value="A">
                  <ContainerOutlined />{' '}
                  <RadioText>{quaisApontamentos.A}</RadioText>
                </Radio>
              </RadioGroup>
            </Campo>
          </Form>
        </Spin>
      </FormContainer>
    </Container>
  );
};

export default ApontamentoConfig;

export default {
  titulo: 'Operações da Ordem de Produção',
  cabecalho: {
    desktop: [
      {
        titulo: '#',
        atributo: 'sequencia',
        align: 'right',
      },
      {
        titulo: 'Operação',
        atributo: 'nome',
        variavel: true,
        tag: {
          atributo: 'nome',
          aninhamento: 'maquina',
        },
      },
      {
        titulo: 'Tempo total',
        custom: ({ tempoCalcular }, format) => `${format(tempoCalcular)} min`,
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 4,
        width: '120px',
      },
      {
        titulo: 'Quantidade',
        tipo: 'n-decimal',
        enumerador: 0,
        atributo: 'quantidadeCalcular',
        align: 'right',
      },
      {
        width: '120px',
        titulo: 'Tempo Unitário',
        custom: ({ tempoOriginal }, format) => `${format(tempoOriginal)} min`,
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 4,
      },
    ],
    mobile: [
      {
        titulo: 'Operações',
        variavel: true,
        agrupar: [
          {
            custom: ({ sequencia, nome }) => `${sequencia} - ${nome}`,
            tag: {
              atributo: 'nome',
              aninhamento: 'maquina',
            },
          },
          {
            tipo: 'n-decimal',
            enumerador: 4,
            custom: ({ tempoOriginal }, format) =>
              `Tempo: ${format(tempoOriginal)} min`,
          },
        ],
      },
    ],
  },
};

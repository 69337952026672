import React from 'react';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Spin } from 'antd';
import { bool, func } from 'prop-types';
import { onFinish } from '../../commonFunctions';
import { DatePicker } from '../../commonStyles';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { telaGrande } from '../../../../utils';

const GerarPontoForm = ({ afterSubmit, mostrar, aoFechar }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  const gerarPontos = async ({ data: dia }) => {
    const { data } = await criar(
      `/registros-ponto/gerar-padrao/${moment(dia).format('yyyy-MM-DD')}`,
      {},
      `Os pontos em ${dia.format('DD/MM/YYYY')} foram gerados`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 250 : '100%'}
      title="Gerar pontos"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="gerarpontos"
          onFinish={values => {
            onFinish(values, gerarPontos, null, afterSubmit, form.resetFields);
          }}
          initialValues={{
            data: moment(),
          }}
        >
          <Campo
            fluido
            name="data"
            label="Data do Ponto"
            obrigatorio
            mostrarFoco={mostrar}
            comPopover
            tituloPopover="Data dos Pontos Gerados"
            textoPopover="Informe a data do ponto que será lançada para todos os funcionários."
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Selecione uma data"
              allowClear={false}
            />
          </Campo>

          <Campo fluido>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

GerarPontoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
};

GerarPontoForm.defaultProps = {
  aoFechar: null,
  afterSubmit: null,
  mostrar: false,
};

export default GerarPontoForm;

import React, { useState, useEffect, useCallback } from 'react';
import { any, objectOf } from 'prop-types';

import { Tabela } from '../../layout';
import { Card, Row, Container } from './styles';
import { useApi, useUrl } from '../../../hooks';
import { formatar } from '../../../utils';
import { TabPeriodos } from '..';

import { PERIODO } from '../../../hooks/useUrl/acoes';
import { PREMIO_PREVISAO } from '../../layout/Tabela/nomesCabecalhos';
import { SummaryCell } from '../../../styles/global';

const PrevisaoPremioTab = ({ history }) => {
  const { buscar } = useApi();
  const { pegarValorQuery } = useUrl(history);

  const periodo = pegarValorQuery(PERIODO);

  // const [premiosRecalculados, setPremiosRecalculados] = useState([]);

  const [premios, setPremios] = useState([]);

  const buscarprevisoes = useCallback(async () => {
    if (periodo) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      const { data } = await buscar(
        `/apuracoes-previsao?dataInicial=${dataInicio}&dataFinal=${dataFinal}`,
      );

      setPremios(data.premiosRecalculados);
    }
  }, [periodo, buscar]);

  const calcularTotalCelula = useCallback(premios => {
    if (premios?.length === 0) {
      return null;
    }

    let total = 0;
    premios.forEach(premio => {
      total += premio.valorPrevisto;
    });

    return total;
  }, []);

  const calcularTotalFaturado = useCallback(() => {
    let total = 0;
    premios.forEach(({ premios }) => {
      // total += premios.valorPrevisto;
      premios.forEach(premio => {
        total += premio.valorPrevisto;
      });
    });

    return total;
  }, [premios]);

  useEffect(() => {
    buscarprevisoes();
  }, [buscarprevisoes]);

  return (
    <>
      {premios.map(({ celula, premios }) => (
        <>
          <h2>{celula.nome}</h2>
          <Card>
            <Tabela
              dados={premios}
              paginacao
              cabecalho={PREMIO_PREVISAO}
              summary={() => (
                <Row>
                  <SummaryCell>
                    Total previsto célula:
                    {formatar('moeda-compacta', calcularTotalCelula(premios))}
                  </SummaryCell>
                </Row>
              )}
            />
          </Card>
        </>
      ))}
      <Container>
        <h2>
          Total Previsto:{formatar('moeda-compacta', calcularTotalFaturado())}
        </h2>
        <TabPeriodos history={history} tirarmargem transparente />
      </Container>
    </>
  );
};

PrevisaoPremioTab.propTypes = {
  history: objectOf(any).isRequired,
};

export default PrevisaoPremioTab;

import React, { useCallback, useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useApi, useUrl } from '../../../hooks';
import { Tabs } from '../../../styles/global';
import { dataNoIntervalo, formatar } from '../../../utils';
import PontoForm from '../../forms/PontoFuncionarioForm/PontoForm';
import GraficoEficiencia from '../../graficos/GraficoEficiencia';
import { Tabela } from '../../layout';
import { TabPeriodos } from '..';
import { EXCLUIR } from '../../layout/Tabela/nomesAcoes';
import {
  FUNCIONARIO_APONTAMENTO,
  PONTOS_DO_FUNCIONARIO,
} from '../../layout/Tabela/nomesCabecalhos';
import {
  APONTAMENTO,
  CADASTRAR,
  EFICIENCIA,
  PERIODO,
  PONTO,
  TAB,
} from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;
const DetalhesFuncionarioTab = ({ history, funcionario }) => {
  const { buscar, deletar } = useApi();
  const [pontosFuncionario, setPontosFuncionario] = useState([]);
  const [apontamentos, setApontamentos] = useState([]);
  const [ponto, setPonto] = useState({});
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const { adicionarQuery, existeQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const mostrarEdicaoPonto = existeQuery(CADASTRAR, PONTO);
  const tabAtiva = pegarValorQuery(TAB) ?? EFICIENCIA;
  const periodo = pegarValorQuery(PERIODO);

  const carregarPontos = useCallback(
    async (dataInicio, dataFinal) => {
      const { data: dataPontos } = await buscar(
        `/registros-ponto/?$ordem=-data&funcionarioUid[eq]=${funcionario?.uid}${
          dataInicio && dataFinal
            ? `&data[between]=${dataInicio}&data[between]=${dataFinal}`
            : ''
        }`,
      );
      setPontosFuncionario(dataPontos);
    },
    [buscar, funcionario],
  );

  const carregarApontamentos = useCallback(
    async (dataInicio, dataFinal) => {
      const { data } = await buscar(
        `/apontamentos?funcionarioUid[eq]=${
          funcionario?.uid
        }&$rel[funcionario]=nomeCompleto&$rel[operacao]=nome&$rel[operacao>ordemProducao]=nome,numero${
          dataInicio && dataFinal
            ? `&data[between]=${dataInicio}&data[between]=${dataFinal}`
            : ''
        }`,
      );
      setApontamentos(data);
    },
    [buscar, funcionario],
  );

  useEffect(() => {
    if (funcionario?.uid && periodo) {
      const [dataInicio, dataFinal] = periodo?.split('/');
      carregarPontos(dataInicio, dataFinal);
      carregarApontamentos(dataInicio, dataFinal);
    }
  }, [funcionario, buscar, periodo, carregarPontos, carregarApontamentos]);

  const deletarApontamento = async ({ uid, operacao, quantidade, data }) => {
    await deletar(
      `/apontamentos/${uid}`,
      `O apontamento de ${quantidade} peça(s) de ${operacao?.nome} da ordem ${
        operacao?.ordemProducao?.numero
      } em ${formatar('data', data)} por ${
        funcionario?.nomeCompleto
      } foi excluído`,
    );
    setApontamentos(
      apontamentos.filter(({ uid: apontamentoUid }) => apontamentoUid !== uid),
    );
  };

  const deletarPonto = async ({ uid, data }) => {
    await deletar(
      `/registros-ponto/${uid}`,
      `O ponto do dia ${formatar('data', data)} de ${
        funcionario?.nomeCompleto
      } foi excluído`,
      () =>
        setPontosFuncionario(
          pontosFuncionario.filter(({ uid: pontoUid }) => pontoUid !== uid),
        ),
    );
  };

  return (
    <>
      <Tabs
        defaultActiveKey={tabAtiva}
        activeKey={tabAtiva}
        onChange={key => adicionarQuery(TAB, key)}
      >
        <TabPane tab="Eficiência" key={EFICIENCIA}>
          <GraficoEficiencia
            funcionarioUid={funcionario?.uid}
            celulaUid={funcionario?.celulaUid}
          />
        </TabPane>
        <TabPane tab="Registros Ponto" key={PONTO}>
          <TabPeriodos tirarmargem transparente history={history}>
            <Tabela
              loading={loading}
              dados={pontosFuncionario}
              cabecalho={PONTOS_DO_FUNCIONARIO}
              celulaSelecionada={pontoSelecionado => {
                setPonto(pontoSelecionado);
                adicionarQuery(CADASTRAR, PONTO);
              }}
              acoes={[
                { titulo: EXCLUIR, onClick: deletarPonto, confirmacao: true },
              ]}
            />
          </TabPeriodos>
        </TabPane>
        <TabPane tab="Apontamentos" key={APONTAMENTO}>
          <TabPeriodos history={history} tirarmargem transparente>
            <Tabela
              loading={loading}
              cabecalho={FUNCIONARIO_APONTAMENTO}
              dados={apontamentos}
              acoes={[
                {
                  titulo: EXCLUIR,
                  onClick: deletarApontamento,
                  confirmacao: true,
                },
              ]}
            />
          </TabPeriodos>
        </TabPane>
      </Tabs>

      <PontoForm
        aoFechar={() => removerQuery(CADASTRAR, PONTO)}
        mostrar={mostrarEdicaoPonto}
        ponto={ponto}
        afterSubmit={pontoEditado => {
          if (pontoEditado) {
            if (dataNoIntervalo(pontoEditado, 'data', periodo?.split('/'))) {
              const index = pontosFuncionario.findIndex(
                ({ uid }) => uid === pontoEditado.uid,
              );
              pontosFuncionario[index] = pontoEditado;
              setPontosFuncionario([...pontosFuncionario]);
            } else {
              setPontosFuncionario([
                ...pontosFuncionario.filter(
                  ({ uid }) => uid !== pontoEditado.uid,
                ),
              ]);
            }
            removerQuery(CADASTRAR, PONTO);
          }
        }}
      />
    </>
  );
};

DetalhesFuncionarioTab.propTypes = {
  funcionario: objectOf(any).isRequired,
  history: objectOf(any).isRequired,
};

export default DetalhesFuncionarioTab;

import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Tag } from 'antd';
import { useApi, useUrl } from '../../../hooks';
import { CADASTRAR, MOTIVO } from '../../../hooks/useUrl/acoes';
import {
  BotaoExcluir,
  BotaoPrimario,
  Header,
  Tabela,
} from '../../../components/layout';
import { MotivoForm } from '../../../components/forms';
import { MOTIVOS_PARADAS } from '../../../components/layout/Tabela/nomesCabecalhos';
import { DetalhesContainer } from '../../styles';
import { telaGrande } from '../../../utils';

const DetalhesParadas = ({ history, match }) => {
  const { existeQuery, adicionarQuery, removerQuery } = useUrl(history);
  const [paradas, setParadas] = useState([]);
  const { id } = match.params;
  const { buscar, deletar } = useApi();
  const [motivo, setMotivo] = useState({});
  const mostrarCadastro = existeQuery(CADASTRAR, MOTIVO);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    const carregarMotivo = async () => {
      const { data } = await buscar(`/motivos-parada/${id}`);
      setMotivo(data);
    };
    const carregarParadas = async () => {
      const { data } = await buscar(
        `/registros-ponto/paradas?motivoParadaUid[eq]=${id}&$rel[registroPonto]=data&$rel[registroPonto>funcionario]=nomeCompleto&$rel[registroPonto>celula]=nome&$rel[maquina]=nome`,
      );
      setParadas(data);
    };

    carregarParadas();
    carregarMotivo();
  }, [buscar, id]);

  const deletarMotivo = async () => {
    await deletar(
      `/motivos-parada/${motivo?.uid}`,
      'Motivo excluído com sucesso',
      () => history.goBack(),
    );
  };

  return (
    <>
      <Header
        descricao
        classe="Motivo"
        onBack={() => history.goBack()}
        titulo={() => (
          <>
            {motivo?.nome ?? ''}
            {motivo && (
              <Tag color={!motivo?.falta ? 'blue' : 'red'}>
                {!motivo?.falta
                  ? 'Não é Considerado como Falta'
                  : 'Considerado como Falta'}
              </Tag>
            )}
          </>
        )}
        extra={[
          <BotaoPrimario
            disabled={loading}
            onClick={() => adicionarQuery(CADASTRAR, MOTIVO)}
            key="1"
            type="primary"
          >
            Alterar
          </BotaoPrimario>,
        ]}
      >
        {!telaGrande() && (
          <BotaoExcluir onConfirm={deletarMotivo}>Excluir Motivo</BotaoExcluir>
        )}
      </Header>

      <DetalhesContainer>
        <Tabela
          titulo
          loading={loading}
          cabecalho={MOTIVOS_PARADAS}
          dados={paradas}
          celulaSelecionada={({ registroPontoUid }) =>
            history.push(`/registros-ponto/${registroPontoUid}`)
          }
        />
      </DetalhesContainer>

      <MotivoForm
        aoFechar={() => removerQuery(CADASTRAR, MOTIVO)}
        mostrar={mostrarCadastro}
        motivo={motivo}
        afterSubmit={novoMotivo => {
          if (novoMotivo) {
            setMotivo(novoMotivo);
            removerQuery(CADASTRAR, MOTIVO);
          }
        }}
      />
    </>
  );
};

DetalhesParadas.propTypes = {
  history: objectOf(any).isRequired,
  match: objectOf(any).isRequired,
};

export default DetalhesParadas;

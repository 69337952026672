import React from 'react';
import { bool, func } from 'prop-types';
import { Descritor } from '../../layout';
import { Card, Modal } from './styles';

const { Meta } = Card;
const imagem =
  'https://rdironworks.com/wp-content/uploads/2017/12/dummy-200x200.png';

const dados = {
  usuario: {
    id: 1,
    nome: 'Nome e Sobrenome Aqui',
    email: 'email@email.teste.com',
    avatar: 'url',
  },
  empresa: {
    id: 1,
    razaoSocial: 'Razão social incrível aqui',
    nomeFantasia: 'Fantasia de Natal',
    cnpjCpf: 'xxx.xxx.xxx-xx',
    telefone: '(xx) xxxxx-xxxx',
    email: 'empresa@email.teste.com',
  },
};

const descritorEmpresa = [
  {
    itens: [
      { nome: 'Nome', propriedade: 'nomeFantasia' },
      { nome: 'Razão Social', propriedade: 'razaoSocial' },
      { nome: 'Email', propriedade: 'email' },
    ],
  },
  {
    itens: [
      { nome: 'CPF/CNPJ', propriedade: 'cnpjCpf' },
      { nome: 'Telefone', propriedade: 'telefone' },
    ],
  },
];
const descritorConta = [
  {
    itens: [
      { nome: 'Nome', propriedade: 'nome' },
      { nome: 'Email', propriedade: 'email' },
    ],
  },
];

const ModalDadosUsuario = ({ aberto, fecharModal }) => {
  return (
    <Modal visible={aberto} centered footer={null} onCancel={fecharModal}>
      <Card title="Dados do Usuário">
        <Meta
          avatar={<img src={imagem} alt="avatar" />}
          description={
            <Descritor
              semSection
              objeto={dados.usuario}
              propriedades={descritorConta}
            />
          }
        />
      </Card>
      <Card title="Dados da Empresa" bordered={false}>
        <Meta
          avatar={<img src={imagem} alt="avatar" />}
          description={
            <Descritor
              semSection
              objeto={dados.empresa}
              propriedades={descritorEmpresa}
            />
          }
        />
      </Card>
    </Modal>
  );
};

ModalDadosUsuario.propTypes = {
  aberto: bool.isRequired,
  fecharModal: func.isRequired,
};

export default ModalDadosUsuario;

import styled from 'styled-components';
import { Tag as AntTag } from 'antd';

export const Tag = styled(AntTag)`
  font-weight: 400;
`;

export const SituacaoContainer = styled.div`
  @media only screen and (max-device-width: 640px) {
    display: block;
  }
`;

export const Situacao = styled.span`
  color: ${({ cor }) => cor};
  font-weight: 400;
  font-size: 14px;
  span {
    margin-left: 5px;
  }
  @media only screen and (max-device-width: 320px) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 640px) {
    margin-left: 5px;
  }
  @media only screen and (max-device-width: 640px) {
    display: block;
  }
`;

export const SubTitulo = styled.span`
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
  margin: 0px 0px 0px 10px;
  @media only screen and (max-device-width: 640px) {
    display: block;
    margin: 0px;
  }
`;

export const TituloContainer = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: inline-flex;
    margin-left: 5px;
  }
`;

import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Form, Select, Spin, Drawer, Switch } from 'antd';
import moment from 'moment';
import { func, string, objectOf, any, bool } from 'prop-types';
import { removerValorFalso, formatar, telaGrande } from '../../../../utils';
import { DatePicker, Input, TextArea } from '../../commonStyles';
import { onFinish, lerObjectProp } from '../../commonFunctions';
import { BotaoPrimario, Campo, SelectDinamico } from '../../../layout';
import { useApi } from '../../../../hooks';
import { ContaForm } from '../..';

const { Option } = Select;

const CriarLancamentoForm = ({
  afterSubmit,
  tipoLancamento,
  mostrar,
  aoFechar,
  lancamento,
  ano,
  mes,
  ordem,
}) => {
  const dispatch = useDispatch();
  const { criar, alterar, buscar, existeNoLog } = useApi();
  const [contas, setContas] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [mostrarFormConta, setMostrarConta] = useState(false);
  const [quitar, setQuitar] = useState(true);
  const [form] = Form.useForm();
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  useEffect(() => {
    if (!mostrarFormConta) {
      if (lancamento) {
        setTitulo('Alterar Lançamento');
      } else if (ordem) {
        setTitulo('Novo Recebimento');
      } else {
        setTitulo(tipoLancamento === 'entrada' ? 'Nova Entrada' : 'Nova Saída');
      }
    }
  }, [dispatch, lancamento, mostrarFormConta, tipoLancamento, ordem]);

  useEffect(() => {
    form.setFieldsValue({
      valor: null,
      historico: null,
      contaUid: null,
    });
  }, [form, tipoLancamento]);

  useEffect(() => {
    if (ordem) {
      const {
        contaUidRecebimento,
        valorTotal,
        valorRecebido,
        cliente,
        numero,
        nome,
      } = ordem;
      lerObjectProp(
        {
          valor: Number(valorTotal ?? 0) - Number(valorRecebido ?? 0),
          data: moment(),
          contaUid: contaUidRecebimento ?? configuracoes?.contaUidRecebimento,
          historico:
            cliente?.apelido &&
            numero &&
            nome &&
            `Recebimento de ${cliente?.apelido} referente Ordem ${numero} - ${nome}`,
        },
        form.setFieldsValue,
        form.resetFields,
      );
    } else if (lancamento) {
      lerObjectProp(
        {
          ...lancamento,
          valor: Math.abs(lancamento?.valor),
          data: moment(lancamento?.data),
        },
        form.setFieldsValue,
        form.resetFields,
      );
    }
  }, [lancamento, form.resetFields, form.setFieldsValue, ordem, configuracoes]);

  useEffect(() => {
    if (ano && mes) {
      form.setFieldsValue({
        data:
          moment(`${ano}-${mes}`, 'yyyy-MM').isSame(moment(), 'year') &&
          moment(`${ano}-${mes}`, 'yyyy-MM').isSame(moment(), 'month')
            ? moment()
            : null,
      });
    }
  }, [ano, mes, form]);

  useEffect(() => {
    const carregarContas = async () => {
      const url = '/contas';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setContas(data);
      }
    };
    if (mostrar) {
      carregarContas();
    }
  }, [buscar, existeNoLog, mostrar]);

  const selectOnChange = conta => {
    if (!ordem) {
      const contaSelecionada = contas.find(({ uid }) => uid === conta);
      form.setFieldsValue({
        historico: contaSelecionada?.historicoPadrao || '',
      });
    }
  };

  const cadastrarLancamentoConta = async values => {
    delete values.quitarOrdem;
    const { valor, contaUid, data } = values;
    if (ordem) {
      const { data: response } = await criar(
        `/ordens-producao/${ordem?.uid}/recebimentos`,
        {
          ...values,
          valor: Number(valor),
          data: data.format('YYYY-MM-DD'),
          quitar,
        },
        `O recebimento de ${formatar('moeda', valor)} em ${data.format(
          'DD/MM/YYYY',
        )} na conta ${
          contas?.find(({ uid }) => uid === contaUid)?.nome
        } referente a ordem ${ordem?.numero} - ${ordem?.nome} foi criado`,
      );
      return response;
    }
    const { data: novoLancamento } = await criar(
      '/lancamentos',
      {
        ...removerValorFalso(values),
        data: data.format('YYYY-MM-DD'),
        valor:
          tipoLancamento === 'entrada' ? +Math.abs(valor) : -Math.abs(valor),
      },

      `O lançamento de ${valor} em ${formatar('data', data)} na conta
      ${contas.find(({ uid: id }) => id === contaUid).nome} foi criado`,
    );
    return novoLancamento;
  };

  const editarLancamento = async values => {
    const dados = !values.lancamentoUidEstornado
      ? removerValorFalso(values)
      : {
          historico: values.historico,
        };
    const { valor, data, contaUid } = dados;
    const { natureza, uid } = lancamento ?? {};

    const { data: novoLancamento } = await alterar(
      `/lancamentos/${uid}`,
      {
        ...dados,
        valor:
          natureza === 'R' && valor !== null
            ? Math.abs(valor)
            : -Math.abs(valor),
      },
      lancamento,
      `O lançamento de ${valor} em ${formatar('data', data)} na conta
      ${contas.find(({ uid: id }) => id === contaUid)?.nome} foi editado`,
    );
    return novoLancamento;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 320 : '100%'}
      title={titulo}
      onClose={aoFechar}
    >
      {mostrarFormConta ? (
        <ContaForm
          mostrar={mostrarFormConta}
          aoVoltar={() => setMostrarConta(false)}
          afterSubmit={conta => {
            if (
              (tipoLancamento === 'entrada' && conta?.natureza === 'R') ||
              (tipoLancamento !== 'entrada' && conta?.natureza !== 'R')
            ) {
              setContas([conta, ...contas]);
              form.setFieldsValue({
                contaUid: conta?.uid,
                historico: conta?.historicoPadrao,
              });
            }
          }}
        />
      ) : (
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onFinish={values => {
              onFinish(
                { ...values, objectUid: lancamento?.uid },
                cadastrarLancamentoConta,
                editarLancamento,
                afterSubmit,
                () =>
                  form.setFieldsValue({
                    valor: null,
                    historico: null,
                    contaUid: null,
                  }),
              );
            }}
          >
            <Campo
              name="contaUid"
              label="Conta"
              obrigatorio
              mostrarFoco={mostrar}
              fluido
            >
              <SelectDinamico
                form={form}
                nomeCampo="contaUid"
                disabled={lancamento?.lancamentoUidEstornado}
                placeholder="Selecione uma conta"
                onChange={selectOnChange}
                aoAdicionar={() => setMostrarConta(true)}
              >
                {contas
                  ?.filter(({ natureza }) => {
                    return lancamento?.conta?.natureza === 'R' ||
                      tipoLancamento === 'entrada' ||
                      ordem
                      ? natureza === 'R'
                      : natureza !== 'R';
                  })
                  ?.map(({ uid, nome }) => (
                    <Option key={uid} value={uid}>
                      {nome}
                    </Option>
                  ))}
              </SelectDinamico>
            </Campo>

            <Campo name="data" label="Data" obrigatorio fluido>
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Selecione uma data"
              />
            </Campo>

            <Campo name="valor" label="Valor" obrigatorio fluido>
              <Input
                type="number"
                addonBefore="R$"
                placeholder="Digite um valor"
                disabled={lancamento?.lancamentoUidEstornado}
                maxLength={15}
              />
            </Campo>

            <Campo name="historico" label="Histórico" fluido>
              <TextArea rows={4} placeholder="Preencha o histórico" />
            </Campo>

            {ordem && (
              <Campo name="quitarOrdem" label="Quitar Ordem" fluido>
                <Switch
                  checked={quitar}
                  onChange={value => {
                    setQuitar(value);
                  }}
                />
              </Campo>
            )}

            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

CriarLancamentoForm.propTypes = {
  tipoLancamento: string,
  afterSubmit: func,
  aoFechar: func,
  lancamento: objectOf(any),
  ordem: objectOf(any),
  ano: string,
  mes: string,
  mostrar: bool,
};

CriarLancamentoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  lancamento: null,
  tipoLancamento: null,
  afterSubmit: null,
  ordem: null,
  ano: null,
  mes: null,
};

export default CriarLancamentoForm;

import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

export const Select = styled(AntSelect)`
  width: 200px;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width ?? '100%'};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartContainer = styled.div`
  height: 500px;
  .google-visualization-tooltip {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px black;
  }
  #itemLegenda {
    width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  #corLegenda {
    width: 10px;
    margin-right: 10px;
    height: 10px;
    background-color: ${({ cor }) => cor};
    border: 1px solid black;
  }
`;

import React from 'react';
import {
  any,
  arrayOf,
  bool,
  elementType,
  func,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Classe, ColapsarButton, PageHeader, TituloContainer } from './styles';
import { setColapsarMenu } from '../../../store/modules/sistema/actions';
import { telaGrande } from '../../../utils';

const Header = ({
  children,
  titulo,
  subTitulo,
  classe,
  transparente,
  descricao,
  extra,
  ...props
}) => {
  const dispatch = useDispatch();
  const { colapsarMenu } = useSelector(({ reducer }) => reducer, shallowEqual);
  return (
    <PageHeader
      ghost={transparente}
      title={
        <>
          {classe && <Classe>{classe}</Classe>}
          {typeof titulo === 'function' ? (
            titulo?.()
          ) : (
            <TituloContainer>{titulo}</TituloContainer>
          )}
        </>
      }
      subTitle={typeof subTitulo === 'function' ? subTitulo?.() : subTitulo}
      extra={[
        ...extra,
        colapsarMenu && !telaGrande() && (
          <ColapsarButton
            onClick={() => dispatch(setColapsarMenu(false))}
            descricao={descricao}
          >
            <MenuOutlined />
          </ColapsarButton>
        ),
      ]}
      {...props}
    >
      {children}
    </PageHeader>
  );
};

Header.propTypes = {
  children: oneOfType([elementType, object]),
  titulo: oneOfType([string, func]),
  subTitulo: oneOfType([string, func]),
  extra: arrayOf(any),
  transparente: bool,
  descricao: bool,
  classe: string,
};

Header.defaultProps = {
  descricao: null,
  transparente: null,
  extra: [],
  children: null,
  titulo: null,
  subTitulo: null,
  classe: null,
};

export default Header;

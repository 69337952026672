import React from 'react';
import { Popover } from 'antd';
import { any, bool, func, objectOf, string } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { telaGrande } from '../../../utils';
import BotaoPrimario from '../BotaoPrimario';

const BotaoTutorial = ({
  children,
  disabled,
  onClick,
  titulo,
  content,
  esconder,
  ...props
}) => {
  const { mostrarTutorial, colapsarMenu } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const visivel = mostrarTutorial && !esconder;

  return (
    <Popover
      visible={telaGrande() ? visivel : visivel && colapsarMenu}
      placement={telaGrande() ? 'bottomRight' : 'bottom'}
      content={content}
      title={titulo}
    >
      <BotaoPrimario
        disabled={disabled}
        type="primary"
        htmlType="button"
        onClick={onClick}
        {...props}
      >
        {children}
      </BotaoPrimario>
    </Popover>
  );
};

BotaoTutorial.propTypes = {
  disabled: bool,
  esconder: bool,
  onClick: func,
  titulo: string,
  children: objectOf(any),
  content: objectOf(any),
};

BotaoTutorial.defaultProps = {
  disabled: false,
  esconder: false,
  onClick: null,
  titulo: null,
  children: null,
  content: null,
};

export default BotaoTutorial;

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Popconfirm, Empty, Tag } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import {
  DeleteOutlined,
  SyncOutlined,
  StopOutlined,
  ExceptionOutlined,
  PrinterOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
} from '@ant-design/icons';
import {
  string,
  object,
  arrayOf,
  bool,
  func,
  objectOf,
  any,
  oneOfType,
} from 'prop-types';
import { isArray } from 'lodash';
import { getTabela } from './functions';
import { formatar, telaGrande } from '../../../utils';
import { Icone } from '../../../styles/global';
import {
  ESTORNAR,
  EXCLUIR,
  FALTAR,
  IMPRIMIR,
  PARAR,
  PRIORIZAR,
  REMOVER_PRIORIDADE,
} from './nomesAcoes';
import {
  ButtonContainer,
  Button,
  Cell,
  Table,
  AtributoAgrupado,
  CellContainer,
  ArrayContainer,
  Switch,
} from './styles';

const type = 'DragableBodyRow';

const Tabela = ({
  dados,
  cabecalho,
  titulo,
  loading,
  acoes,
  celulaSelecionada,
  colunas,
  compacta,
  expandir,
  celulaOnChange,
  draggable,
  onDrag,
  paginacao,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);
  const [tituloTabela, setTituloTabela] = useState(null);
  // const [pagina, setPagina] = useState(1);

  useEffect(() => {
    setDadosTabela(dados);
  }, [dados]);

  useEffect(() => {
    const gerarComponente = (componente, objeto, valor) => {
      const componentes = {
        Switch: (
          <Switch
            checked={valor}
            onChange={value => celulaOnChange[componente]?.(value, objeto)}
          />
        ),
      };
      return componentes[componente];
    };

    const renderTag = (record, tags) => {
      if (isArray(tags)) {
        return tags.map((tag, index) => (
          <Fragment key={index}>{renderTag(record, tag)}</Fragment>
        ));
      }
      const {
        atributo,
        subAninhamento,
        aninhamento,
        tipo,
        enumerador,
        color,
        naoMostrarSe,
        texto,
      } = tags;
      let textoFormatado = record;
      if (texto) {
        textoFormatado = typeof texto === 'function' ? texto(record) : texto;
      } else {
        if (aninhamento) {
          textoFormatado = textoFormatado?.[aninhamento];
        }
        if (subAninhamento) {
          textoFormatado = textoFormatado?.[subAninhamento];
        }
        if (tipo) {
          textoFormatado = formatar(
            tipo,
            textoFormatado?.[atributo],
            enumerador,
          );
        } else {
          textoFormatado = textoFormatado?.[atributo];
        }
      }
      return (
        !naoMostrarSe?.(record) &&
        textoFormatado && (
          <Tag color={typeof color === 'function' ? color?.(record) : color}>
            {textoFormatado}
          </Tag>
        )
      );
    };

    const { titulo: nomeTabela, cabecalho: cabecalhoConfig } = cabecalho
      ? getTabela(cabecalho)
      : colunas;
    const cabecalhoTabela = telaGrande()
      ? cabecalhoConfig.desktop
      : cabecalhoConfig.mobile;

    setTituloTabela(nomeTabela);

    const renderArray = (record, aninhamento, array) => (
      <>
        {record[aninhamento].map((item, key) => (
          <ArrayContainer key={key} itens={record[aninhamento]?.length}>
            {array.map((propriedades, i) => (
              <span key={i}>
                {propriedades.custom
                  ? propriedades.custom(
                      item[propriedades.atributo],
                      valor =>
                        propriedades.tipo &&
                        valor &&
                        formatar(
                          propriedades.tipo,
                          valor,
                          propriedades.enumerador,
                        ),
                    )
                  : item[propriedades.atributo]}
              </span>
            ))}
          </ArrayContainer>
        ))}
      </>
    );

    const renderTexto = texto => {
      if (texto === true) {
        return 'Sim';
      }
      if (texto === false) {
        return 'Não';
      }
      return texto;
    };

    const gerarIcone = (record, { alt, atributo }, key) => {
      const imagem40 = record?.[atributo];
      let textoAlt;
      alt.forEach(atr => {
        const texto = record?.[atr];
        if (texto && !textoAlt) {
          const [p1, p2] = texto.split(' ');
          if (p2) {
            textoAlt = `${p1[0]}${p2[0]}`;
          } else {
            textoAlt = `${p1[0]}${p1[1] ?? ''}`;
          }
        }
      });
      return (
        textoAlt && (
          <Icone imagem={imagem40} key={key} absoluto="true">
            {!imagem40 && textoAlt.toUpperCase()}
          </Icone>
        )
      );
    };

    const gerarAgrupamentos = (agrupar, record) => {
      let existeIcone = false;
      return (
        <>
          {agrupar.map(
            (
              {
                atributo: atr,
                icone,
                tag: tagAgrupada,
                valorMonetario,
                aninhamento,
                subAninhamento,
                componente,
                tipo,
                enumerador,
                custom,
                array,
              },
              key,
            ) => {
              let value = aninhamento ? record?.[aninhamento] : record;
              value = subAninhamento ? value?.[subAninhamento] : value;

              if (custom) {
                value = custom(
                  record,
                  valor =>
                    tipo && valor && formatar(tipo, valor, enumerador ?? 0),
                );
              } else if (icone) {
                existeIcone = true;
                return gerarIcone(value, icone, key);
              } else {
                value = tipo
                  ? formatar(tipo, value?.[atr], enumerador ?? 0)
                  : value?.[atr];
              }
              if (array) {
                return renderArray(record, aninhamento, array);
              }
              if (componente) {
                return gerarComponente(componente, record, value);
              }
              return (
                <Fragment key={key}>
                  {value && (
                    <AtributoAgrupado
                      valorMonetario={valorMonetario}
                      icone={`${existeIcone}`}
                      texto={value}
                    >
                      {value} {tagAgrupada && renderTag(record, tagAgrupada)}
                    </AtributoAgrupado>
                  )}
                </Fragment>
              );
            },
          )}
        </>
      );
    };

    const cabecalhos = cabecalhoTabela.map(
      (
        {
          agrupar,
          aninhamento,
          atributo,
          subAninhamento,
          titulo: title,
          tipo,
          uid,
          enumerador,
          onCell,
          variavel,
          width,
          componente,
          valorMonetario,
          align,
          tag,
          custom,
          fundo,
          cor,
          array,
        },
        index,
      ) => {
        let dataIndex = aninhamento ? [aninhamento, atributo] : atributo;
        if (subAninhamento) {
          dataIndex = [aninhamento, subAninhamento, atributo];
        }
        if (agrupar) {
          dataIndex = aninhamento ? [aninhamento, index] : index;
        }
        return {
          title,
          dataIndex,
          onCell,
          align: variavel ? 'left' : align || 'left',
          width: !variavel && !width ? 'min-content' : width,
          render: (text, record) => {
            let texto;
            if (custom) {
              texto = custom(
                record,
                valor => tipo && valor && formatar(tipo, valor, enumerador),
              );
            } else {
              texto = tipo && text ? formatar(tipo, text, enumerador) : text;
            }
            return {
              props: { colSpan: 1 },
              children: typeof (text !== 'object' || array) && (
                <CellContainer
                  key={uid || index}
                  agrupar={agrupar}
                  componente={componente}
                  background={
                    typeof fundo === 'function' ? fundo(record) : fundo
                  }
                >
                  {componente ? (
                    gerarComponente(componente, record, texto)
                  ) : (
                    <Cell
                      cor={cor}
                      align={variavel ? 'left' : align || 'left'}
                      clickable={celulaSelecionada}
                      valorMonetario={valorMonetario}
                      texto={renderTexto(texto)}
                      onClick={e =>
                        !e.target.className.includes('ant-switch') &&
                        celulaSelecionada?.(record, text, title)
                      }
                    >
                      {agrupar ? (
                        gerarAgrupamentos(
                          agrupar,
                          aninhamento ? record[aninhamento] : record,
                        )
                      ) : (
                        <>
                          {array ? (
                            renderArray(record, aninhamento, array)
                          ) : (
                            <>
                              {renderTexto(texto)}{' '}
                              {tag && renderTag(record, tag)}
                            </>
                          )}
                        </>
                      )}
                    </Cell>
                  )}
                </CellContainer>
              ),
            };
          },
        };
      },
    );

    const adicionarBotao = (nome, funcao, record, confirmacao, index) => {
      const renderButton = () => (
        <Button
          key={index}
          title={nome}
          onClick={!confirmacao ? () => funcao(record) : null}
        >
          {nome === PRIORIZAR && <UpSquareOutlined />}
          {nome === REMOVER_PRIORIDADE && <DownSquareOutlined />}
          {nome === IMPRIMIR && <PrinterOutlined />}
          {nome === FALTAR && <ExceptionOutlined />}
          {nome === PARAR && <StopOutlined />}
          {nome === ESTORNAR && <SyncOutlined />}
          {nome === EXCLUIR && <DeleteOutlined size="large" />}
        </Button>
      );

      return confirmacao === true ? (
        <Popconfirm
          key={index}
          title="Confirmar ação"
          onConfirm={() => funcao(record)}
          okText="Sim"
          cancelText="Não"
        >
          {renderButton()}
        </Popconfirm>
      ) : (
        renderButton()
      );
    };
    /* eslint-disable react/prop-types */
    if (acoes) {
      cabecalhos.push({
        title: 'Ações',
        align: 'center',
        width: 'fit-content',
        render: (text, record) => (
          <ButtonContainer numerobotoes={acoes?.length} key="acoes">
            {acoes?.map(
              (
                { titulo: tituloBotao, onClick, confirmacao, naoMostrarSe },
                index,
              ) => {
                return (
                  !naoMostrarSe?.(record) &&
                  adicionarBotao(
                    tituloBotao,
                    onClick,
                    record,
                    confirmacao,
                    index,
                  )
                );
              },
            )}
          </ButtonContainer>
        ),
      });
    }
    /* eslint-enable react/prop-types */
    setColumns(cabecalhos);
  }, [acoes, cabecalho, celulaOnChange, celulaSelecionada, colunas]);

  const DragableBodyRow = ({
    /* eslint-disable react/prop-types */
    index,
    moveRow,
    className,
    style,
    ...restProps
    /* eslint-enable react/prop-types */
  }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop(
      () => ({
        accept: type,
        collect: monitor => {
          const { index: dragIndex } = monitor.getItem() || {};
          if (dragIndex === index) {
            return {};
          }
          return {
            isOver: monitor.isOver(),
            dropClassName:
              dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
          };
        },
        drop: item => {
          moveRow(item.index, index);
        },
      }),
      [index],
    );
    const [, drag] = useDrag(
      () => ({
        type,
        item: { index },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [index],
    );
    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    );
  };

  const onRow = (record, index) =>
    dadosTabela?.length > 0 &&
    draggable && {
      index,
      moveRow: (sourceIndex, targetIndex) => {
        if (onDrag) {
          onDrag(dadosTabela[sourceIndex], sourceIndex, targetIndex);
        }
        const [item] = dadosTabela.splice(sourceIndex, 1);
        dadosTabela.splice(targetIndex, 0, item);
        setDadosTabela([...dadosTabela]);
      },
    };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        loading={loading}
        compacta={compacta}
        size="middle"
        columns={columns}
        dataSource={dadosTabela}
        expandable={{ expandedRowRender: expandir }}
        pagination={paginacao}
        scroll
        rowKey="uid"
        title={
          titulo
            ? () => {
                if (typeof titulo === 'function') {
                  return titulo();
                }
                if (typeof titulo === 'string') {
                  return <h4>{titulo}</h4>;
                }
                return <h4>{tituloTabela}</h4>;
              }
            : null
        }
        locale={{
          filterConfirm: 'Ok',
          filterReset: 'Reset',
          emptyText: () => (
            <Empty
              description="Nada encontrado"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        components={{
          body: {
            row: draggable && DragableBodyRow,
          },
        }}
        onRow={onRow}
        {...props}
      />
      {/* <div id="final" /> */}
    </DndProvider>
  );
};

Tabela.propTypes = {
  acoes: arrayOf(any),
  expandir: func,
  onDrag: func,
  celulaOnChange: objectOf(func),
  colunas: objectOf(any),
  loading: bool,
  dados: arrayOf(object).isRequired,
  cabecalho: string,
  titulo: oneOfType([bool, func, string]),
  compacta: bool,
  draggable: bool,
  ordenarPor: string,
  ordenacao: arrayOf(string),
  paginacao: bool,
  celulaSelecionada: func,
};

Tabela.defaultProps = {
  acoes: null,
  expandir: null,
  colunas: null,
  celulaOnChange: null,
  onDrag: null,
  cabecalho: null,
  titulo: false,
  draggable: false,
  loading: false,
  compacta: false,
  ordenarPor: null,
  ordenacao: null,
  paginacao: false,
  celulaSelecionada: null,
};

export default Tabela;

export default [
  { naoNulo: true, tipo: 'string', atributo: 'nome', nome: 'Nome' },
  {
    naoNulo: true,
    tipo: 'hora',
    atributo: 'entrada1',
    nome: 'Primeira Entrada',
  },
  { naoNulo: true, tipo: 'hora', atributo: 'saida1', nome: 'Primeira Saída' },
  { tipo: 'hora', atributo: 'entrada2', nome: 'Segunda Entrada' },
  { tipo: 'hora', atributo: 'saida2', nome: 'Segunda Saída' },
  { tipo: 'hora', atributo: 'entrada3', nome: 'Terceira Entrada' },
  { tipo: 'hora', atributo: 'saida3', nome: 'Terceira Saída' },
  { naoNulo: true, tipo: 'numero', atributo: 'tempoPrevisto', nome: 'Minutos' },
];

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { Select, Form, Spin, message, Radio, Drawer } from 'antd';
import { func, objectOf, any, bool } from 'prop-types';
import { BarcodeOutlined, ContainerOutlined } from '@ant-design/icons';
import { DatePicker, Grupo, Input, RadioGroup } from '../commonStyles';
import { onFinish, lerObjectProp } from '../commonFunctions';
import { BotaoPrimario, Campo } from '../../layout';
import { useApi, useUrl } from '../../../hooks';
import { formatar, telaGrande } from '../../../utils';
import { Tabs } from '../../../styles/global';
import { TAB, CELULA, FUNCIONARIO, SEM_TAB } from '../../../hooks/useUrl/acoes';

const { TabPane } = Tabs;
const { Option } = Select;
// const { Group } = Radio;
const CODIGO = 'B';
const MANUAL = 'M';

const ApontamentoForm = ({
  history,
  mostrar,
  afterSubmit,
  aoFechar,
  apontamento,
}) => {
  const [form] = Form.useForm();
  const { existeQuery, pegarValorQuery } = useUrl(history);
  const { buscar, criar, existeNoLog } = useApi();
  const semTabs = pegarValorQuery(SEM_TAB);
  const tabCelulaAtiva = existeQuery(TAB, CELULA);
  const [formaApontamento, setFormaApontamento] = useState(null);
  const [focoAtivo, setFocoAtivo] = useState(false);
  const [ordensProducao, setOrdensProducao] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [operacoes, setOperacoes] = useState([]);
  const [celulas, setCelulas] = useState([]);
  const [data, setData] = useState(moment());
  const [operacaoUid, setOperacaoUid] = useState(null);
  const [ordemProducaoUid, setOrdemProducaoUid] = useState(null);
  const [tabAtiva, setTabAtiva] = useState(null);
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  useEffect(() => {
    setTabAtiva(tabCelulaAtiva ? CELULA : FUNCIONARIO);
  }, [tabCelulaAtiva]);

  useEffect(() => {
    if (apontamento?.ordemProducaoUid !== ordemProducaoUid) {
      setOperacaoUid(null);
      form.setFieldsValue({ ordemProducaoOperacaoUid: null });
    }
  }, [apontamento, ordemProducaoUid, form]);

  useEffect(() => {
    setOrdemProducaoUid(apontamento?.ordemProducaoUid);
    const configApontamento = configuracoes?.formaApontamento ?? MANUAL;
    lerObjectProp(
      apontamento?.uid
        ? apontamento
        : { ...apontamento, metodo: configApontamento, data: moment() },
      form.setFieldsValue,
      form.resetFields,
    );
    setFormaApontamento(configApontamento);
  }, [form.resetFields, form.setFieldsValue, apontamento, configuracoes]);

  useEffect(() => {
    const carregarOrdensProducao = async () => {
      const url = `/ordens-producao/?$limite=999999&dataConclusaoCelula[is]=null`;
      if (!existeNoLog(url)) {
        const { data: ordens } = await buscar(url);
        setOrdensProducao(ordens);
      }
    };

    if (mostrar && tabAtiva === FUNCIONARIO) {
      carregarOrdensProducao();
    }
  }, [buscar, existeNoLog, mostrar, tabAtiva]);

  useEffect(() => {
    const carregarCelulas = async () => {
      const url = '/celulas?$ordem=nome,uid';
      if (!existeNoLog(url)) {
        const { data } = await buscar(url);
        setCelulas(data);
      }
    };

    if (mostrar && tabAtiva === CELULA) {
      carregarCelulas();
    }
  }, [buscar, existeNoLog, mostrar, tabAtiva]);

  useEffect(() => {
    const carregarOperacoes = async () => {
      const { data: response } = await buscar(
        `/ordens-producao/${ordemProducaoUid}/operacoes/?dataConclusao[is]=null`,
      );
      setOperacoes(response);
    };
    if (ordemProducaoUid && mostrar && tabAtiva === FUNCIONARIO) {
      carregarOperacoes();
    }
  }, [ordemProducaoUid, buscar, mostrar, tabAtiva]);

  useEffect(() => {
    const carregarFuncionarios = async () => {
      const urlFunc = '/funcionarios?$ordem=nomeCompleto,uid';
      const urlRegistro = `/registros-ponto/?data[eq]=${moment(data).format(
        'YYYY-MM-DD',
      )}`;
      if (mostrar) {
        const { data: listaFuncionarios } = await buscar(urlFunc);
        const { data: registros } = await buscar(urlRegistro);
        setFuncionarios(
          listaFuncionarios?.filter(
            ({ uid }) =>
              registros?.filter(
                ({ funcionarioUid, presenca }) =>
                  funcionarioUid === uid && presenca > 0,
              )?.length > 0,
          ),
        );
      }
    };
    if (data && tabAtiva === FUNCIONARIO) {
      form.setFieldsValue({ funcionarioUid: null });
      carregarFuncionarios();
    }
  }, [data, buscar, form, mostrar, tabAtiva]);

  useEffect(() => {
    if (configuracoes) {
      const { quaisApontamentos } = configuracoes;
      if (!tabAtiva) {
        setTabAtiva(quaisApontamentos !== 'C' ? FUNCIONARIO : CELULA);
      }
    }
  }, [configuracoes, tabAtiva]);

  const cadastrar = async ({ data: dia, codigoBarras, ...values }) => {
    if (tabAtiva === FUNCIONARIO) {
      delete values.ordemProducaoUid;
    }
    if (formaApontamento === CODIGO) {
      delete values.ordemProducaoOperacaoUid;
    }
    delete values.metodo;
    const { data: dataApontamento, headers } = await criar(
      tabAtiva === FUNCIONARIO ? `/apontamentos` : `/apontamentos-celula`,
      {
        data: dia.format('YYYY-MM-DD'),
        codigoBarras: formaApontamento === CODIGO ? Number(codigoBarras) : null,
        ...values,
      },
      ({
        quantidade,
        funcionario,
        operacao,
        ordemProducao,
        tempoTotal,
        registroPonto,
      }) => {
        return `O apontamento de ${quantidade} ${
          operacao ? operacao?.nome : ''
        } da ordem ${
          operacao ? operacao?.ordemProducao?.numero : ordemProducao?.numero
        } ${funcionario ? `por ${funcionario?.nomeCompleto}` : ''} foi criado${
          registroPonto
            ? `, totalizando ${formatar(
                'n-decimal',
                Number(tempoTotal) + Number(registroPonto?.tempoApontado),
                2,
              )}min`
            : ''
        } em ${formatar('data', dia)}${
          registroPonto
            ? ` (${formatar('n-decimal', registroPonto?.eficiencia, 2)}%)`
            : ''
        }`;
      },
    );

    if (dataApontamento && formaApontamento === CODIGO) {
      form.setFieldsValue({ codigoBarras: null });
      setFocoAtivo(true);
    }
    if (formaApontamento === MANUAL) {
      if (headers?.['operacao-concluida'] === 'true') {
        setOperacoes(operacoes?.filter(({ uid }) => uid !== operacaoUid));
        setOperacaoUid(null);
        form.setFieldsValue({ ordemProducaoOperacaoUid: null });
      }
      if (headers?.['ordem-concluida'] === 'true') {
        // const lista = ordensProducao?.filter(
        //   ({ uid }) => uid !== ordemProducaoUid,
        // );

        const lista = ordensProducao;

        setOrdensProducao(lista);
        if (lista.length === 0) {
          aoFechar();
        }
        setOperacaoUid(null);
        form.setFieldsValue({
          ordemProducaoUid: null,
          ordemProducaoOperacaoUid: null,
        });
      }
    }
    return dataApontamento;
  };

  const finalizarCadastro = values => {
    let quantidade;
    try {
      // eslint-disable-next-line no-eval
      quantidade = eval(values.quantidade);
    } catch (error) {
      return message.error('Informe apenas números na quantidade');
    }
    return onFinish(
      { ...values, quantidade },
      cadastrar,
      null,
      afterSubmit,
      () =>
        form.setFieldsValue({
          quantidade: null,
        }),
    );
  };

  const renderFormaApontamento = () => (
    <Campo label="Forma de Apontamento" name="metodo" obrigatorio>
      <RadioGroup
        value={formaApontamento}
        onChange={({ target }) => {
          setFormaApontamento(target.value);
        }}
      >
        <Radio value={MANUAL}>
          <ContainerOutlined /> Manual
        </Radio>
        <Radio value={CODIGO}>
          <BarcodeOutlined /> Código de Barras
        </Radio>
      </RadioGroup>
    </Campo>
  );

  const renderCamposEmComum = () => (
    <>
      <Grupo>
        {formaApontamento === MANUAL && (
          <>
            <Campo
              name="ordemProducaoUid"
              label="Ordem de produção"
              obrigatorio={formaApontamento === MANUAL}
              fluido={tabAtiva === CELULA}
            >
              <Select
                placeholder="Selecione uma ordem de produção"
                showAction={['focus', 'click']}
                bordered
                showSearch
                filterOption={(input, { numero, nome }) => {
                  if (!Number.isNaN(Number(input))) {
                    return numero.includes(input);
                  }
                  return nome?.toUpperCase().includes(input.toUpperCase());
                }}
                onChange={value => {
                  setOrdemProducaoUid(value);
                  form.setFieldsValue({
                    operacaoUid: null,
                    quantidade: null,
                  });
                }}
              >
                {ordensProducao?.map(({ uid, nome, numero }) => (
                  <Option
                    key={uid}
                    value={uid}
                    numero={`${numero}`}
                    nome={nome}
                  >
                    {numero} - {nome}
                  </Option>
                ))}
              </Select>
            </Campo>
          </>
        )}
        {formaApontamento === CODIGO && (
          <Campo
            fluido
            name="codigoBarras"
            label="Código de Barras"
            obrigatorio={formaApontamento === CODIGO}
            mostrarFoco={mostrar && formaApontamento === CODIGO && focoAtivo}
          >
            <Input placeholder="Digite o código" type="number" />
          </Campo>
        )}

        {tabAtiva === FUNCIONARIO && formaApontamento !== CODIGO && (
          <Campo
            name="ordemProducaoOperacaoUid"
            label="Operação"
            obrigatorio={formaApontamento === MANUAL}
          >
            <Select
              placeholder="Selecione uma operação"
              bordered
              showSearch
              onChange={value => setOperacaoUid(value)}
              disabled={!(operacoes?.length > 0)}
              showAction={['focus', 'click']}
              filterOption={(input, { sequencia, nome }) => {
                if (!Number.isNaN(Number(input))) {
                  return sequencia.includes(input);
                }
                return nome?.toUpperCase().includes(input.toUpperCase());
              }}
            >
              {operacoes?.map(({ uid, nome, sequencia }) => (
                <Option
                  key={uid}
                  value={uid}
                  sequencia={`${sequencia}`}
                  nome={nome}
                >
                  {sequencia} - {nome}
                </Option>
              ))}
            </Select>
          </Campo>
        )}
      </Grupo>
      <Campo name="quantidade" label="Quantidade" obrigatorio>
        <Input placeholder="Digite a quantidade" maxLength={15} />
      </Campo>
      <Campo>
        <BotaoPrimario htmlType="submit" type="primary">
          Salvar
        </BotaoPrimario>
      </Campo>
    </>
  );

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 405 : '100%'}
      title={apontamento?.uid ? 'Alterar Apontamento' : 'Novo Apontamento'}
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={finalizarCadastro}
        >
          <Tabs
            semtabs={semTabs}
            transparente="true"
            tirarmargem="true"
            activeKey={tabAtiva}
            onChange={key => {
              form.setFieldsValue({
                celulaUid: null,
                funcionarioUid: null,
                ordemProducaoOperacaoUid: null,
              });
              setTabAtiva(key);
            }}
          >
            {configuracoes?.quaisApontamentos !== 'C' && (
              <TabPane tab="Por Funcionário" key={FUNCIONARIO}>
                {renderFormaApontamento()}
                <Grupo>
                  <Campo
                    name="data"
                    label="Data"
                    obrigatorio={configuracoes?.quaisApontamentos !== 'C'}
                    initialValue={moment()}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder="Selecione uma data"
                      allowClear={false}
                      onChange={valor =>
                        setData(
                          moment(valor, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        )
                      }
                    />
                  </Campo>
                  <Campo name="funcionarioUid" label="Funcionário">
                    <Select
                      placeholder="Selecione um funcionário"
                      showAction={['focus', 'click']}
                      bordered
                      showSearch
                      disabled={funcionarios?.length === 0}
                      filterOption={(input, { codigo, nome }) => {
                        if (!Number.isNaN(Number(input))) {
                          return codigo.includes(input);
                        }
                        return nome
                          ?.toUpperCase()
                          .includes(input.toUpperCase());
                      }}
                    >
                      {funcionarios?.map(({ uid, nomeCompleto, codigo }) => (
                        <Option
                          key={uid}
                          value={uid}
                          codigo={`${codigo}`}
                          nome={nomeCompleto}
                        >
                          {codigo} - {nomeCompleto}
                        </Option>
                      ))}
                    </Select>
                  </Campo>
                </Grupo>
                {renderCamposEmComum()}
              </TabPane>
            )}

            {configuracoes?.quaisApontamentos !== 'F' && (
              <TabPane tab="Por Célula" key={CELULA}>
                {renderFormaApontamento()}
                <Grupo>
                  <Campo
                    name="data"
                    label="Data"
                    obrigatorio={configuracoes?.quaisApontamentos !== 'F'}
                    initialValue={moment()}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder="Selecione uma data"
                      allowClear={false}
                      onChange={valor =>
                        setData(
                          moment(valor, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        )
                      }
                    />
                  </Campo>
                  <Campo name="celulaUid" label="Célula">
                    <Select
                      placeholder="Selecione uma célula"
                      bordered
                      showSearch
                      showAction={['focus', 'click']}
                      onChange={value => setOperacaoUid(value)}
                      disabled={!(celulas?.length > 0)}
                      filterOption={(input, { nome }) =>
                        nome?.toUpperCase().includes(input.toUpperCase())
                      }
                    >
                      {celulas?.map(({ uid, nome }) => (
                        <Option key={uid} value={uid} nome={nome}>
                          {nome}
                        </Option>
                      ))}
                    </Select>
                  </Campo>
                </Grupo>
                {renderCamposEmComum()}
              </TabPane>
            )}
          </Tabs>
        </Form>
      </Spin>
    </Drawer>
  );
};

ApontamentoForm.propTypes = {
  history: objectOf(any).isRequired,
  apontamento: objectOf(any),
  afterSubmit: func,
  aoFechar: func,
  mostrar: bool,
};
ApontamentoForm.defaultProps = {
  mostrar: false,
  afterSubmit: null,
  aoFechar: null,
  apontamento: null,
};

export default ApontamentoForm;

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Spin, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from '../../styles';
import { setConfiguracoes } from '../../../store/modules/sistema/actions';
import { BotaoPrimario, Campo, Header } from '../../../components/layout';
import { useApi } from '../../../hooks';
import { verificarBotoes } from '../functions';
import { FormContainer } from '../styles';
import {
  lerObjectProp,
  onFinish,
} from '../../../components/forms/commonFunctions';
import { Input } from '../../../components/forms/commonStyles';

const Premios = () => {
  const [form] = Form.useForm();
  const { alterar } = useApi();
  const dispatch = useDispatch();
  const [sofreuAlteracao, setSofreuAlteracao] = useState({
    percentualDistribuicao: false,
    calcularPremiosNegativo: false,
  });
  const { loading, configuracoes } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const resetarCampos = useCallback(() => {
    lerObjectProp(
      {
        percentualDistribuicao: configuracoes?.percentualDistribuicao,
        calcularPremiosNegativo: configuracoes?.calcularPremiosNegativo,
      },
      form.setFieldsValue,
      form.resetFields,
    );
  }, [configuracoes, form.resetFields, form.setFieldsValue]);

  useEffect(() => resetarCampos(), [resetarCampos]);

  const editarConfiguracao = async ({
    percentualDistribuicao,
    calcularPremiosNegativo,
  }) => {
    const { data } = await alterar(
      '/configuracoes',
      {
        percentualDistribuicao:
          !percentualDistribuicao || percentualDistribuicao === '0'
            ? null
            : percentualDistribuicao,
        calcularPremiosNegativo,
      },
      {},
      'Dados atualizados com sucesso!',
    );

    return data;
  };

  const afterSubmit = config => {
    if (config) {
      setSofreuAlteracao({
        calcularPremiosNegativo: false,
        percentualDistribuicao: false,
      });
      dispatch(setConfiguracoes(config));
    }
  };
  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title="Configurações de Prêmios"
        extra={[
          <Button
            disabled={loading}
            key="1"
            htmlType="button"
            hidden={
              !sofreuAlteracao.percentualDistribuicao &&
              !sofreuAlteracao.calcularPremiosNegativo
            }
            onClick={() => {
              resetarCampos();
              setSofreuAlteracao({
                calcularPremiosNegativo: false,
                percentualDistribuicao: false,
              });
            }}
          >
            Descartar a alteração
          </Button>,
          <BotaoPrimario
            disabled={loading}
            hidden={
              !sofreuAlteracao.percentualDistribuicao &&
              !sofreuAlteracao.calcularPremiosNegativo
            }
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => form.submit()}
          >
            Salvar
          </BotaoPrimario>,
        ]}
      />
      <FormContainer>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            name="controlled-form"
            onChange={({ target }) =>
              verificarBotoes(target, configuracoes, setSofreuAlteracao)
            }
            onFinish={values => {
              onFinish(
                { ...values, objectUid: 1 },
                null,
                editarConfiguracao,
                afterSubmit,
                form.resetFields,
              );
            }}
          >
            <Campo
              name="percentualDistribuicao"
              label="Percentual de Distribuição do Lucro Bruto"
              mostrarFoco
            >
              <Input
                name="percentualDistribuicao"
                type="number"
                placeholder="Digite o percentual"
                maxLength={255}
                min={0}
                max={100}
              />
            </Campo>

            <Form.Item
              name="calcularPremiosNegativo"
              valuePropName="checked"
              label="Calcular prêmios negativos"
            >
              <Switch
                name="calcularPremiosNegativo"
                onClick={() =>
                  setSofreuAlteracao({
                    calcularPremiosNegativo: !sofreuAlteracao.calcularPremiosNegativo,
                    percentualDistribuicao:
                      sofreuAlteracao.percentualDistribuicao,
                  })
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </FormContainer>
    </Container>
  );
};

export default Premios;

import styled from 'styled-components';
import { Row as AntRow, Card as AntCard } from 'antd';

export const Card = styled(AntCard)`
  width: 100%;
  @media only screen and (max-device-width: 640px) {
    .ant-card-body {
      padding: 5px !important;
    }
  }
`;

export const Row = styled(AntRow)`
  margin-bottom: 10px;
  padding: 0px 10px;
`;

export const Container = styled.div`
  margin-top: 10px;
`;

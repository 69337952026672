export default {
  titulo: 'Paradas da Máquina',
  cabecalho: {
    desktop: [
      {
        titulo: 'Data',
        atributo: 'data',
        tipo: 'data',
        align: 'center',
        aninhamento: 'registroPonto',
      },
      {
        titulo: 'Funcionário',
        atributo: 'nomeCompleto',
        aninhamento: 'registroPonto',
        subAninhamento: 'funcionario',
      },
      {
        titulo: 'Célula',
        atributo: 'nome',
        aninhamento: 'registroPonto',
        subAninhamento: 'celula',
      },
      {
        titulo: 'Tempo',
        align: 'right',
        tipo: 'n-decimal',
        enumerador: 0,
        custom: ({ tempo }, format) => `${format(tempo)} min`,
      },
      {
        titulo: 'Motivo',
        aninhamento: 'motivoParada',
        atributo: 'nome',
      },
      { titulo: 'Observações', atributo: 'observacao', variavel: true },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Parada',
        agrupar: [
          { atributo: 'data', tipo: 'data', aninhamento: 'registroPonto' },
          {
            atributo: 'nomeCompleto',
            aninhamento: 'registroPonto',
            subAninhamento: 'funcionario',
          },
          {
            tipo: 'n-decimal',
            enumerador: 0,
            custom: ({ tempo }, format) => `${format(tempo)} min`,
          },
          { aninhamento: 'motivoParada', atributo: 'nome' },
        ],
      },
    ],
  },
};

import React from 'react';
import { Modal } from 'antd';
import { func, objectOf, any, string, bool } from 'prop-types';
import Busca from '../../layout/Filtros/Busca';
import { useUrl } from '../../../hooks';
import { FILTRAR, ATIVAR_FILTRO } from '../../../hooks/useUrl/acoes';

const ModalBusca = ({ retornarQuery, history, nomeFiltro, mostrar }) => {
  const { removerQuery, existeQuery } = useUrl(history);

  const mostrarFiltro = existeQuery(FILTRAR, ATIVAR_FILTRO);
  return (
    <>
      <Modal
        title="Busca personalizada"
        visible={mostrar || mostrarFiltro}
        footer={null}
        onCancel={() => removerQuery(FILTRAR, ATIVAR_FILTRO)}
      >
        <Busca
          history={history}
          retornarQuery={retornarQuery}
          nomeFiltro={nomeFiltro}
        />
      </Modal>
    </>
  );
};

ModalBusca.propTypes = {
  history: objectOf(any).isRequired,
  retornarQuery: func.isRequired,
  nomeFiltro: string.isRequired,
  mostrar: bool,
};

export default ModalBusca;

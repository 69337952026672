import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Drawer, Form, Spin } from 'antd';
import moment from 'moment';
import { bool, func, string } from 'prop-types';
import { onFinish } from '../../commonFunctions';
import { DatePicker } from '../../commonStyles';
import { BotaoPrimario, Campo } from '../../../layout';
import { useApi } from '../../../../hooks';
import { MesesAno } from '../../../../utils/enum';
import { telaGrande } from '../../../../utils';

const GerarLancamentoForm = ({ afterSubmit, mes, ano, mostrar, aoFechar }) => {
  const { criar } = useApi();
  const [form] = Form.useForm();
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  useEffect(() => {
    if (mes && ano) {
      form.setFieldsValue({ mes: moment(`${ano}-${mes}`, 'yyyy-MM') });
    }
  }, [mes, ano, form]);

  const gerarLancamentosConta = async ({ mes: mesSelecionado }) => {
    const { data } = await criar(
      `/lancamentos/gerar-padrao/${mesSelecionado}`,
      {},
      `Os lançamentos em ${MesesAno[Number(mesSelecionado)]} foram gerados`,
    );
    return data;
  };

  return (
    <Drawer
      visible={mostrar}
      width={telaGrande() ? 'auto' : '100%'}
      title="Gerar Lançamentos"
      onClose={aoFechar}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          name="controlled-form"
          onFinish={values => {
            onFinish(
              { ...values, mes: moment(values.mes, 'MM').format('MM') },
              gerarLancamentosConta,
              null,
              afterSubmit,
            );
          }}
        >
          <Campo
            name="mes"
            label="Mês"
            obrigatorio
            initialValue={moment()}
            mostrarFoco={mostrar}
          >
            <DatePicker placeholder="Selecione uma data" picker="month" />
          </Campo>

          <Campo>
            <BotaoPrimario htmlType="submit" type="primary">
              Salvar
            </BotaoPrimario>
          </Campo>
        </Form>
      </Spin>
    </Drawer>
  );
};

GerarLancamentoForm.propTypes = {
  afterSubmit: func,
  aoFechar: func,
  mes: string,
  ano: string,
  mostrar: bool,
};

GerarLancamentoForm.defaultProps = {
  mostrar: false,
  aoFechar: null,
  afterSubmit: null,
  mes: null,
  ano: null,
};

export default GerarLancamentoForm;

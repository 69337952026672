import styled from 'styled-components';

export const TituloContainer = styled.span`
  display: flex;
`;

export const BalanceamentoContainer = styled.div`
  margin-top: 20px;
`;

export const BalanceamentoHeader = styled.div`
  @media only screen and (min-device-width: 640px) {
    display: flex;
    justify-content: space-between;
  }
  margin-bottom: 15px;
  span {
    font-weight: 600;
    @media only screen and (max-device-width: 640px) {
      display: block;
      margin-bottom: 5px;
    }
  }
`;

export default [
  {
    tipo: 'boolean',
    atributo: 'falta',
    nome: 'Considerado como Falta',
    valorTrue: 'Sim',
    valorFalse: 'Não',
  },
  { naoNulo: true, tipo: 'string', atributo: 'nome', nome: 'Nome' },
];

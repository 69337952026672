import React from 'react';
import { any, bool, objectOf } from 'prop-types';
import { Alert } from './styles';

const AlertaTutorial = ({ children, detalhes }) => {
  return (
    <Alert
      type="success"
      showIcon
      detalhes={detalhes}
      closable
      message={children}
    />
  );
};

AlertaTutorial.propTypes = {
  children: objectOf(any),
  detalhes: bool,
};

AlertaTutorial.defaultProps = {
  detalhes: null,
  children: null,
};

export default AlertaTutorial;

import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { any, objectOf } from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Header, Tabela } from '../../components/layout';
import { PrevisaoPremioTab } from '../../components/tabs';
import { useApi } from '../../hooks';
import { Container } from '../styles';
import { Row, Card, CardContainer, Lista, LinkButton } from './styles';
import {
  GraficoEficiencia,
  GraficoProducao,
  GraficoEficienciaFuncionario,
} from '../../components/graficos';
import { setMostrarTutorial } from '../../store/modules/sistema/actions';

import {
  ANIVERSARIANTES,
  FUNCIONARIO_EXPERIENCIA,
} from '../../components/layout/Tabela/nomesCabecalhos';

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const { buscar } = useApi();
  const [experiencias, setExperiencias] = useState([]);
  const [aniversariantes, setAniversariantes] = useState([]);
  const [buscouDados, setBuscouDados] = useState(false);
  const { mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const verificarDados = useCallback(async () => {
    const { data: funcionarios } = await buscar(`/funcionarios?$limite=1`);
    const { data: clientes } = await buscar(`/clientes?$limite=1`);
    const { data: ordens } = await buscar(`/ordens-producao?$limite=1`);
    setBuscouDados(true);
    return dispatch(
      setMostrarTutorial(
        funcionarios?.length === 0 ||
          clientes?.length === 0 ||
          ordens?.length === 0,
      ),
    );
  }, [buscar, dispatch]);

  useEffect(() => {
    const carregarExperiencias = async () => {
      const hoje = moment().format('YYYY-MM-DD');
      const { data } = await buscar(`/funcionarios/experiencia/${hoje}`);
      setExperiencias(data);
      return data?.length === 0;
    };

    const carregarAniversariantes = async () => {
      const formato = 'YYYY-MM-DD';
      const dataInicio = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(formato);
      const dataFinal = moment().add(1, 'month').endOf('month').format(formato);
      const { data } = await buscar(
        `/funcionarios?situacao=A&$ordem=dataNascimento`,
      );
      setAniversariantes(
        data?.filter(({ dataNascimento }) => {
          const nasc = moment(dataNascimento)
            .year(moment().year())
            .format(formato);
          return moment(nasc).isBetween(dataInicio, dataFinal);
        }),
      );
      return data?.length === 0;
    };

    const aniversariantesVazio = carregarAniversariantes();
    const expecirenciaVazia = carregarExperiencias();
    if (aniversariantesVazio && expecirenciaVazia) {
      verificarDados();
    }
  }, [buscar, verificarDados]);

  const pintarLinha = ({ dataNascimento }) => {
    const formato = 'YYYY-MM-DD';
    const inicioSemana = moment().startOf('week').format(formato);
    const fimSemana = moment().endOf('week').format(formato);
    const data = moment(dataNascimento).year(moment().year()).format(formato);
    const retorno = moment(data).isBetween(inicioSemana, fimSemana)
      ? 'amarelo'
      : null;
    return moment(data).isSame(moment().format(formato)) ? 'verde' : retorno;
  };

  return (
    <Container>
      <Header titulo="Dashboard" semPadding="true" transparente />
      {mostrarTutorial && buscouDados ? (
        <div>
          <h2>
            Damos as boas vindas ao Sistema de Gestão de Oficinas de Costura da
            Vexta!
          </h2>
          <h3>
            Vamos começar o uso? Abaixo você encontra links que vão ajudar no
            cadastro de alguns itens básicos:
          </h3>
          <Lista>
            <li>
              <LinkButton onClick={() => history.push('/funcionarios')}>
                Cadastre seus Funcionários
              </LinkButton>
            </li>
            <li>
              <LinkButton onClick={() => history.push('/clientes')}>
                Cadastre seus Clientes
              </LinkButton>
            </li>
            <li>
              <LinkButton onClick={() => history.push('/registros-ponto')}>
                Registre o Ponto dos Funcionários
              </LinkButton>
            </li>
            <li>
              <LinkButton onClick={() => history.push('/ordens-producao')}>
                Cadastre sua Primeira Ordem de Produção
              </LinkButton>
            </li>
          </Lista>
        </div>
      ) : (
        <>
          <Row gutter={20}>
            <Card>
              <GraficoEficiencia />
            </Card>
          </Row>
          <Row gutter={20}>
            <Card>
              <GraficoProducao />
            </Card>
          </Row>
          <Row gutter={20}>
            <Card>
              <GraficoEficienciaFuncionario />
            </Card>
          </Row>
          <Row>
            <PrevisaoPremioTab history={history} />
          </Row>
          <CardContainer>
            <Card>
              <Tabela
                titulo
                dados={experiencias}
                cabecalho={FUNCIONARIO_EXPERIENCIA}
                celulaSelecionada={({ uid }) =>
                  history.push(`/funcionarios/${uid}`)
                }
              />
            </Card>

            <Card>
              <Tabela
                titulo
                dados={aniversariantes}
                cabecalho={ANIVERSARIANTES}
                rowClassName={pintarLinha}
                celulaSelecionada={({ uid }) =>
                  history.push(`/funcionarios/${uid}`)
                }
              />
            </Card>
          </CardContainer>
        </>
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  history: objectOf(any).isRequired,
};

export default Dashboard;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import {
  Tabela,
  Filtros,
  Header,
  BotaoPrimario,
  BotaoDefault,
} from '../../components/layout';
import { TurnoForm } from '../../components/forms';
import { useApi, useUrl } from '../../hooks';
import { Container } from '../styles';
import { ModalBusca } from '../../components/modais';
import Tags from '../../components/layout/Filtros/Tags';
import { TURNOS } from '../../components/layout/Tabela/nomesCabecalhos';
import { TURNO_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  CADASTRAR,
  BUSCAR,
  FILTRAR,
  ATIVAR_FILTRO,
  TURNO,
} from '../../hooks/useUrl/acoes';

const { TituloComFiltro } = Filtros;

const Turnos = ({ history }) => {
  const { adicionarQuery, existeQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const { buscar, deletar } = useApi();
  const [filtro, setFiltro] = useState('');
  const [turnos, setTurnos] = useState([]);
  const [pagina, setPagina] = useState(1);

  const mostrarCadastro = existeQuery(CADASTRAR, TURNO);
  const busca = pegarValorQuery(BUSCAR);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);

  // função responsável por montar os turnos dia semana
  const montandoTurno = useCallback(turnos => {
    const novaListaDeTurno = [];

    turnos.forEach(turno => {
      if (!turno.detalharDia) {
        novaListaDeTurno.push(turno);
      } else {
        let entrada1 = '';
        let entrada2 = '';
        let entrada3 = '';

        let saida1 = '';
        let saida2 = '';
        let saida3 = '';

        if (turno.turnoDiaSemana.length) {
          entrada1 = turno.turnoDiaSemana[0].entrada1;
          entrada2 = turno.turnoDiaSemana[0].entrada2;
          entrada3 = turno.turnoDiaSemana[0].entrada3;

          saida1 = turno.turnoDiaSemana[0].saida1;
          saida2 = turno.turnoDiaSemana[0].saida2;
          saida3 = turno.turnoDiaSemana[0].saida3;

          turno.turnoDiaSemana.forEach(dia => {
            if (entrada1 !== dia.entrada1) {
              entrada1 = 'Distinto';
            }
            if (entrada2 !== dia.entrada2) {
              entrada2 = 'Distinto';
            }
            if (entrada3 !== dia.entrada3) {
              entrada3 = 'Distinto';
            }

            if (saida1 !== dia.saida1) {
              saida1 = 'Distinto';
            }

            if (saida2 !== dia.saida2) {
              saida2 = 'Distinto';
            }

            if (saida3 !== dia.saida3) {
              saida3 = 'Distinto';
            }
          });

          turno.entrada1 = entrada1;
          turno.entrada2 = entrada2;
          turno.entrada3 = entrada3;
          turno.saida1 = saida1;
          turno.saida2 = saida2;
          turno.saida3 = saida3;

          novaListaDeTurno.push(turno);
        }
      }

      return novaListaDeTurno;
    });

    return novaListaDeTurno;
  }, []);

  useEffect(() => {
    const carregarTurnos = async () => {
      if (pagina === 1) {
        setTurnos([]);
      }

      if (filtro === '') {
        const { data } = await buscar(
          `/turnos?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&$rel[turnoDiaSemana]&$limite=10&$pagina=${pagina}`,
        );
        setTurnos(turno => [...turno, ...montandoTurno(data)]);
      }

      if (filtro !== '') {
        const { data } = await buscar(
          `/turnos?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&$rel[turnoDiaSemana]&`,
        );
        setTurnos(montandoTurno(data));
        setPagina(1);
      }
    };
    carregarTurnos();
  }, [buscar, busca, filtro, pagina, montandoTurno]);

  useEffect(() => {
    if (filtro === '') {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setPagina(page => page + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#final'));
      return () => intersectionObserver.disconnect();
    }

    return undefined;
  }, [filtro]);

  const deletarTurno = async ({ uid, nome }) => {
    await deletar(`/turnos/${uid}`, `O turno ${nome} foi excluído`, () =>
      setTurnos(turnos.filter(({ uid: turnoUid }) => turnoUid !== uid)),
    );
  };

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro titulo="Turnos" history={history} busca={busca} />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, TURNO)}
          >
            Novo Turno
          </BotaoPrimario>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabela
        loading={loading}
        cabecalho={TURNOS}
        dados={turnos}
        celulaSelecionada={({ uid }) => history.push(`/turnos/${uid}`)}
        acoes={
          telaGrande()
            ? [{ titulo: EXCLUIR, onClick: deletarTurno, confirmacao: true }]
            : null
        }
      />

      <TurnoForm
        aoFechar={() => removerQuery(CADASTRAR, TURNO)}
        mostrar={mostrarCadastro}
        afterSubmit={turno => {
          if (turno) {
            setTurnos([...turnos, turno]);
            removerQuery(CADASTRAR, TURNO);
          }
        }}
        history={history}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={TURNO_LAYOUT}
      />
      {filtro === '' ? <div id="final" /> : null}
    </Container>
  );
};

Turnos.propTypes = {
  history: objectOf(any).isRequired,
};

export default Turnos;

import styled from 'styled-components';
import { Input as AntInput, Button as AntButton } from 'antd';

export const Input = styled(AntInput)`
  width: 400px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  margin-left: 24px;
  max-height: 32px;
  @media only screen and (max-device-width: 640px) {
    width: 100%;
    margin: 5px 0px;
  }
`;

export const Button = styled(AntButton)`
  padding: 0px 4px 0px 4px;
  border: none;
  color: #d9d9d9;
  background: transparent !important;
`;

export const ContainerBuscar = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 15px;
  }
  @media only screen and (max-device-width: 640px) {
    display: grid;
    width: 100%;
  }
`;

import React from 'react';
import {
  AreaChartOutlined,
  BankOutlined,
  BarcodeOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  FormOutlined,
  FundOutlined,
  GiftOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  PushpinOutlined,
  SaveOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SkinOutlined,
  StopOutlined,
  TableOutlined,
  TagsOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { CELULA, SEM_TAB, TAB } from '../../../hooks/useUrl/acoes';

export default [
  {
    linkTo: '/kanban-producao',
    label: 'Produção',
    icon: <BarcodeOutlined />,
    itensMenu: [
      {
        linkTo: '/',
        label: 'Dashboard',
        icon: <AreaChartOutlined />,
        hr: true,
      },
      {
        linkTo: '/kanban-producao',
        label: 'Kanban de Produção',
        icon: <ProjectOutlined />,
      },
      {
        linkTo: '/ordens-producao',
        label: 'Ordens de Produção',
        icon: <SkinOutlined />,
        hr: true,
      },
      {
        linkTo: `/apontamentos?${SEM_TAB}=true`,
        label: 'Apontamentos por Funcionário',
        icon: <TagsOutlined />,
      },
      {
        linkTo: `/apontamentos?${TAB}=${CELULA}&${SEM_TAB}=true`,
        label: 'Apontamentos por Célula',
        icon: <TagsOutlined />,
      },
      {
        linkTo: '/apontamentos',
        label: 'Apontamentos',
        icon: <TagsOutlined />,
        // hr: true,
      },
      {
        linkTo: '/compararApontamentos',
        label: 'Comparar Apontamentos',
        icon: <TagsOutlined />,
        hr: true,
      },
      {
        linkTo: '/registros-ponto',
        label: 'Registros de Ponto',
        icon: <FieldTimeOutlined />,
      },
    ],
  },
  {
    linkTo: '/planejamento',
    label: 'Planejamento',
    icon: <ScheduleOutlined />,
    itensMenu: [
      {
        linkTo: '/planejamento',
        label: 'Planejamento',
        icon: <ScheduleOutlined />,
      },
    ],
  },
  {
    linkTo: '/lancamentos-contas',
    label: 'Financeiro',
    icon: <DollarOutlined />,
    itensMenu: [
      {
        linkTo: '/lancamentos-contas',
        label: 'Lançamentos',
        icon: <DollarOutlined />,
      },
      { linkTo: '/contas', label: 'Contas', icon: <BankOutlined />, hr: true },
      { linkTo: '/dre', label: 'DRE', icon: <FundOutlined /> },
    ],
  },
  {
    linkTo: '/clientes',
    label: 'Cadastros',
    icon: <FormOutlined />,
    itensMenu: [
      {
        linkTo: '/clientes',
        label: 'Clientes',
        icon: <TeamOutlined />,
        hr: true,
      },
      {
        linkTo: '/registros-ponto',
        label: 'Registros de Ponto',
        icon: <FieldTimeOutlined />,
      },
      {
        linkTo: '/funcionarios',
        label: 'Funcionários',
        icon: <IdcardOutlined />,
        hr: true,
      },

      {
        linkTo: '/celulas',
        label: 'Célula de Trabalho',
        icon: <TableOutlined />,
      },
      {
        linkTo: '/turnos',
        label: 'Turnos',
        icon: <UserSwitchOutlined />,
        hr: true,
      },
      { linkTo: '/maquinas', label: 'Máquinas', icon: <PushpinOutlined /> },
      {
        linkTo: '/motivos',
        label: 'Motivos',
        icon: <StopOutlined />,
      },
      {
        linkTo: '/dias-nao-trabalhados',
        label: 'Dias Não Trabalhados',
        icon: <CalendarOutlined />,
      },
    ],
  },
  {
    linkTo: '/premios',
    label: 'Prêmios',
    icon: <GiftOutlined />,
    itensMenu: [
      {
        linkTo: '/premios',
        label: 'Dashboard Prêmios',
        icon: <AreaChartOutlined />,
      },
      {
        linkTo: '/apuracoes',
        label: 'Prêmios',
        icon: <GiftOutlined />,
      },
    ],
  },
  {
    label: 'Configurações',
    linkTo: '/config-valor',
    icon: <SettingOutlined />,
    itensMenu: [
      {
        icon: <OrderedListOutlined />,
        hr: true,
        submenu: {
          title: 'Métricas',
          itens: [
            {
              linkTo: '/config-valor',
              label: 'Valores',
              icon: <CalculatorOutlined />,
            },
            {
              linkTo: '/config-tempo',
              label: 'Tempos',
              icon: <FieldTimeOutlined />,
            },
            {
              linkTo: '/config-premio',
              label: 'Prêmios',
              icon: <GiftOutlined />,
            },
          ],
        },
      },
      {
        icon: <SaveOutlined />,
        submenu: {
          title: 'Produção',
          itens: [
            {
              linkTo: '/config-ordem',
              label: 'Ordens de Produção',
              icon: <SkinOutlined />,
            },
            {
              linkTo: '/config-apontamento',
              label: 'Apontamentos',
              icon: <TagsOutlined />,
            },
          ],
        },
      },
    ],
  },
];

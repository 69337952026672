import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { objectOf, any } from 'prop-types';
import { useUrl } from '../../hooks';
import { ModalBusca } from '../../components/modais';
import { Container, Link } from '../styles';
import { PONTO_FUNCIONARIO_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import PontosTab from '../../components/tabs/PontosTab';
import { telaGrande } from '../../utils';
import {
  AlertaTutorial,
  BotaoTutorial,
  Filtros,
  Header,
  BotaoDefault,
} from '../../components/layout';
import {
  FILTRAR,
  ATIVAR_FILTRO,
  CADASTRAR,
  BUSCAR,
  PONTO,
  GERAR,
} from '../../hooks/useUrl/acoes';

const { Tags, TituloComFiltro } = Filtros;

const PontosFuncionarios = ({ history }) => {
  const desktop = telaGrande();
  const [filtro, setFiltro] = useState('');
  const [numeroPontos, setNumeroPontos] = useState(0);
  const [clicouGerarPonto, setClicouGerarPonto] = useState(false);
  const { adicionarQuery, pegarValorQuery, existeQuery } = useUrl(history);
  const retornarQuery = query => filtro !== query && setFiltro(query);
  const busca = pegarValorQuery(BUSCAR);
  const { loading, mostrarTutorial } = useSelector(
    ({ reducer }) => reducer,
    shallowEqual,
  );

  const esconderTutorial =
    existeQuery(CADASTRAR, GERAR) ||
    existeQuery(CADASTRAR, PONTO) ||
    existeQuery(FILTRAR, ATIVAR_FILTRO);

  const renderAlert = () =>
    clicouGerarPonto &&
    mostrarTutorial &&
    numeroPontos !== 0 && (
      <AlertaTutorial>
        <p>
          Pronto! Parabéns! Vimos como cadastrar pontos manualmente, e também
          como gerar o ponto de todos os funcionários com um único clique.
        </p>
        <span>
          {' '}
          Você pode continuar cadastrando, ou{' '}
          <Link to="/">clicar aqui para voltar ao seu dashboard</Link> de boas
          vindas e ver as próximas etapas.
        </span>
      </AlertaTutorial>
    );

  return (
    <>
      <Container>
        {desktop && renderAlert()}
        <Header
          transparente
          semPadding="true"
          title={
            <TituloComFiltro
              titulo="Registros de Ponto"
              history={history}
              busca={busca}
            />
          }
          extra={[
            <BotaoDefault
              disabled={loading}
              key="1"
              htmlType="button"
              onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
            >
              Filtrar
            </BotaoDefault>,
            <BotaoTutorial
              key="2"
              disabled={loading}
              htmlType="button"
              titulo="Gerar Pontos"
              esconder={
                numeroPontos === 0 || esconderTutorial || clicouGerarPonto
              }
              content={
                <h5>
                  Agora vamos gerar os pontos para os seus funcionários. Para
                  isso, clique no botão Gerar Pontos.
                </h5>
              }
              onClick={() => {
                adicionarQuery(CADASTRAR, GERAR);
                setClicouGerarPonto(true);
              }}
            >
              Gerar Pontos
            </BotaoTutorial>,
            <BotaoTutorial
              key="3"
              esconder={numeroPontos !== 0 || esconderTutorial}
              disabled={loading}
              type="primary"
              htmlType="button"
              onClick={() => adicionarQuery(CADASTRAR, PONTO)}
              titulo="Novo Ponto"
              content={
                <h5>
                  Vamos cadastrar um novo ponto para os seus funcionários. Para
                  isso, clique no botão Novo Ponto.
                </h5>
              }
            >
              Novo Ponto
            </BotaoTutorial>,
          ]}
        />
        <Tags retornarQuery={retornarQuery} history={history} />
        {!desktop && renderAlert()}
      </Container>
      <PontosTab
        history={history}
        filtro={filtro}
        setNumeroPontos={setNumeroPontos}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={PONTO_FUNCIONARIO_LAYOUT}
      />
    </>
  );
};

PontosFuncionarios.propTypes = {
  history: objectOf(any).isRequired,
};

export default PontosFuncionarios;

import React, { useEffect, useState } from 'react';
import { any, objectOf } from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import TituloComFiltro from '../../components/layout/Filtros/TituloComFiltro';
import { Container } from '../styles';
import { ModalBusca } from '../../components/modais';
import { useApi, useUrl } from '../../hooks';
import {
  BotaoPrimario,
  Header,
  Tabela,
  BotaoDefault,
} from '../../components/layout';
import Tags from '../../components/layout/Filtros/Tags';
import { MotivoForm } from '../../components/forms';
import { MOTIVOS } from '../../components/layout/Tabela/nomesCabecalhos';
import { MOTIVO_LAYOUT } from '../../components/layout/Filtros/nomeFiltros';
import { EXCLUIR } from '../../components/layout/Tabela/nomesAcoes';
import { telaGrande } from '../../utils';
import {
  ATIVAR_FILTRO,
  BUSCAR,
  CADASTRAR,
  FILTRAR,
  MOTIVO,
} from '../../hooks/useUrl/acoes';

const Motivos = ({ history }) => {
  const { deletar, buscar } = useApi();
  const { existeQuery, adicionarQuery, removerQuery, pegarValorQuery } = useUrl(
    history,
  );
  const [filtro, setFiltro] = useState('');
  const [motivos, setMotivos] = useState([]);
  const busca = pegarValorQuery(BUSCAR);
  const mostrarCadastro = existeQuery(CADASTRAR, MOTIVO);
  const { loading } = useSelector(({ reducer }) => reducer, shallowEqual);
  const [pagina, setPagina] = useState(1);
  useEffect(() => {
    const buscarMotivos = async () => {
      if (pagina === 1) {
        setMotivos([]);
      }

      if (filtro === '') {
        const { data } = await buscar(
          `/motivos-parada?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&$limite=10&$pagina=${pagina}`,
        );
        setMotivos(mot => [...mot, ...data]);
      }

      if (filtro !== '') {
        const { data } = await buscar(
          `/motivos-parada?${filtro}${
            busca ? `&$buscar=${busca}` : ''
          }&$ordem=nome,uid&`,
        );
        setMotivos(data);
        setPagina(1);
      }
    };
    buscarMotivos();
  }, [buscar, filtro, busca, pagina]);

  useEffect(() => {
    if (filtro === '') {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          setPagina(page => page + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#final'));
      return () => intersectionObserver.disconnect();
    }

    return undefined;
  }, [filtro]);

  const deletarMotivo = async ({ uid }) => {
    await deletar(`/motivos-parada/${uid}`, 'Motivo excluído com sucesso', () =>
      setMotivos(motivos.filter(({ uid: uidMotivo }) => uidMotivo !== uid)),
    );
  };

  const retornarQuery = query => filtro !== query && setFiltro(query);

  return (
    <Container>
      <Header
        transparente
        semPadding="true"
        title={
          <TituloComFiltro
            titulo="Motivos de Parada"
            history={history}
            busca={busca}
          />
        }
        extra={[
          <BotaoDefault
            disabled={loading}
            key="1"
            htmlType="button"
            onClick={() => adicionarQuery(FILTRAR, ATIVAR_FILTRO)}
          >
            Filtrar
          </BotaoDefault>,
          <BotaoPrimario
            disabled={loading}
            key="2"
            type="primary"
            htmlType="button"
            onClick={() => adicionarQuery(CADASTRAR, MOTIVO)}
          >
            Novo Motivo
          </BotaoPrimario>,
        ]}
      />
      <Tags retornarQuery={retornarQuery} history={history} />
      <Tabela
        cabecalho={MOTIVOS}
        dados={motivos}
        loading={loading}
        celulaSelecionada={({ uid }) => history.push(`/motivos/${uid}`)}
        acoes={
          telaGrande()
            ? [{ titulo: EXCLUIR, onClick: deletarMotivo, confirmacao: true }]
            : null
        }
      />

      <MotivoForm
        history={history}
        mostrar={mostrarCadastro}
        aoFechar={() => removerQuery(CADASTRAR, MOTIVO)}
        afterSubmit={motivo => {
          if (motivo) {
            setMotivos([...motivos, motivo]);
            removerQuery(CADASTRAR, MOTIVO);
          }
        }}
      />

      <ModalBusca
        retornarQuery={retornarQuery}
        history={history}
        nomeFiltro={MOTIVO_LAYOUT}
      />
      {filtro === '' ? <div id="final" /> : null}
    </Container>
  );
};

Motivos.propTypes = {
  history: objectOf(any).isRequired,
};

Motivos.defaultProps = {};

export default Motivos;

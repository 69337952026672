import TabelaOperacoes from './TabelaOperacoes';

export default {
  titulo: 'Operações Balanceadas da Ordem',
  cabecalho: {
    desktop: [
      ...TabelaOperacoes.cabecalho.desktop.filter(
        ({ atributo }) =>
          atributo !== 'tempoCalcular' && atributo !== 'quantidadeCalcular',
      ),
      {
        titulo: 'Peças por Hora',
        tipo: 'n-decimal',
        enumerador: 0,
        atributo: 'quantidade',
        align: 'right',
        width: '120px',
      },
      {
        titulo: 'Minutos de Produção',
        atributo: 'tempoBalanceamento',
        align: 'right',
        custom: ({ tempoBalanceamento }, formatar) =>
          tempoBalanceamento >= 0 ? `${formatar(tempoBalanceamento)} min` : '',
        tipo: 'n-decimal',
        enumerador: 2,
        width: '160px',
      },
      {
        titulo: 'Balanceamentos',
        aninhamento: 'balanceamentos',
        array: [
          {
            atributo: 'funcionario',
            custom: valor => `Funcionário ${valor}`,
          },
          {
            atributo: 'quantidade',
            tipo: 'n-decimal',
            enumerador: 0,
            custom: (valor, formatar) => `${formatar(valor)} Peças`,
          },
          {
            atributo: 'tempo',
            tipo: 'n-decimal',
            enumerador: 2,
            custom: (valor, formatar) => `${formatar(valor)} min`,
          },
        ],
      },
    ],
    mobile: [
      {
        titulo: 'Balanceamentos',
        variavel: true,
        agrupar: [
          {
            custom: ({ sequencia, nome }) => `${sequencia} - ${nome}`,
            aninhamento: 'funcionario',
          },
          {
            titulo: 'Balanceamentos',
            aninhamento: 'balanceamentos',
            array: [
              {
                atributo: 'funcionario',
                custom: valor => `Funcionário ${valor}`,
              },
              {
                atributo: 'quantidade',
                tipo: 'n-decimal',
                enumerador: 0,
                custom: (valor, formatar) => `${formatar(valor)} Peças`,
              },
              {
                atributo: 'tempo',
                tipo: 'n-decimal',
                enumerador: 2,
                custom: (valor, formatar) => `${formatar(valor)} min`,
              },
            ],
          },
        ],
      },
    ],
  },
};

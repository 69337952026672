export default {
  titulo: 'Aniversariantes',
  cabecalho: {
    desktop: [
      {
        variavel: true,
        titulo: 'Nome',
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
          },
          {
            atributo: 'cpf',
          },
        ],
      },
      {
        titulo: 'Aniversário',
        atributo: 'dataNascimento',
        align: 'center',
        tipo: 'mes-dia',
      },
    ],
    mobile: [
      {
        variavel: true,
        titulo: 'Aniversariante',
        agrupar: [
          {
            icone: {
              atributo: 'foto40Url',
              alt: ['nomeCompleto'],
            },
          },
          {
            atributo: 'nomeCompleto',
          },
          {
            atributo: 'cpf',
          },
          { atributo: 'dataNascimento', tipo: 'mes-dia' },
        ],
      },
    ],
  },
};
